import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { submitKVM } from "../../../../reducers/manageKVM";
import {useLocation} from "react-router-dom";
import EntryCreationCategoryList from "../../../../components/EntryCreationCategoryList";


export default function CreateUpdateKVM({ forClick, mode = "create", kvm = {}, kvmParent = null }) {
  // REACT ROUTER DOM
  const location = useLocation()
  // REDUX
  const dispatch = useDispatch()
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const selectedRegion = useSelector(state => state.manageKVM.selectedRegion)
  const categoryTypes = useSelector(state => state.manageCategories.categoryTypes)

  // STATE
  const initialState = {
    visible: false,
    message: kvm.message || "",
    number: kvm.number || null,
    parent: kvmParent,
    category: kvm.category || [],
    supportingEvidence: kvm.supporting_evidence || [],
    // evidenceStatements: kvm.evidence_statements || [],
    // isEvidenceStatement: !!kvm.parent,
    page: kvm.page || 1,
    region: kvm.region || selectedRegion,
    indications: kvm.indications || (selectedIndication ? [selectedIndication.url] : []),
    document: {},
    errorMessage: "",
    step: 1
  }
  const [kvmCategoryTypes, setKVMCategoryTypesCategories] = useState([])
  const [selectedKVMCategories, setSelectedKVMCategories] = useState({})
  const [{
    visible, message, number, parent, category,
    supportingEvidence, page, region, indications, document,
    evidenceStatements, isEvidenceStatement, errorMessage, step
  }, setState] = useState(initialState)

  useEffect(() => {
    generateSelectedCategories()
  }, [])

  const generateSelectedCategories = () => {
    let module = categoryTypes.find(module => module.route === location.pathname)
    let moduleCategoryTypes = []
    if (!!module) {
      let selectedCategories = {}
      moduleCategoryTypes = module.category_types
      moduleCategoryTypes.forEach(categoryType => {
        if (!selectedCategories[categoryType.id]) selectedCategories[categoryType.id] = []
        categoryType.categories.forEach(c => {
          if (kvm && kvm.category && kvm.category.includes(c.url)) {
            selectedCategories[categoryType.id].push(c.url)
          }
        })
      })
      setSelectedKVMCategories(selectedCategories)
    }
    setKVMCategoryTypesCategories(moduleCategoryTypes)
  }

  const handleChangeCategory = (id, value) => {
    let selectedCategories = {...selectedKVMCategories}
    selectedCategories[id] = value
    setSelectedKVMCategories(selectedCategories)
  }

  const handleInput = (e) => {
    const { name, value } = e.target
    setState(prevState => ({ ...prevState, [name]: value }))
  }
  const handleSelect = (field, value) => setState(prevState => ({ ...prevState, [field]: value }))
  const handleCancel = () => setState({...initialState})

  const handleInputFile = (e) => {
    handleSelect("document", e.target.files[0])
  }

  const handleChangeEvidence = (evidenceObject) => {
    let supporting_evidence = [...supportingEvidence]

    if (!!supporting_evidence.find(evidenceUrl => evidenceUrl.url === evidenceObject.url)) {
      supporting_evidence = supporting_evidence.filter(evidence => evidence.url !== evidenceObject.url)
    } else {
      supporting_evidence = [...supporting_evidence, evidenceObject]
    }
    handleSelect("supportingEvidence", supporting_evidence)
  }

  const handleSubmit = () => {
    let allSelectedCategories = []
    Object.keys(selectedKVMCategories).forEach(c => {
      allSelectedCategories = [...allSelectedCategories, ...selectedKVMCategories[c]]
    })
    let method = mode === "create" ? "POST" : "PATCH"
    let formData = new FormData()

    formData.append("message", message)
    formData.append("document", document)
    if (number) formData.append("number", number)
    formData.append("page", page)
    formData.append("region", selectedRegion)
    if (supportingEvidence.length) {
      supportingEvidence.forEach(evidence => formData.append('supporting_evidence', evidence.url))
    } else {
      formData.append('clear_supporting_evidence', "True")
    }
    // category.forEach(c => formData.append('category', c))
    allSelectedCategories.forEach(cat => formData.append('category', cat))
    indications.forEach(indication => formData.append('indications', indication))
    formData.append("is_active", true)
    formData.append("created_by", {})
    formData.append("modified_by", {})
    if (parent) formData.append("parent", parent)

    if (mode === "update") {
      formData.append("url", kvm.url)
    }
    let type = "key value message"
    if (kvmParent) {
      type = "evidence statement"
    }
    dispatch(submitKVM(formData, method, type))
    handleCancel()
  }

  const changeStep = (selectedStep) => {
    if (step === 1) {
      if (!message) {
        handleSelect("errorMessage", "Please fill out the Message field")
        return
      // } else if (category.length < 1 && !kvmParent) {
      //   handleSelect("errorMessage", "Please select at least one category")
      //   return
      }
    } else if (step === 2 && selectedStep === 3 && !parent) {
      handleSelect("errorMessage", "Please select a Key Value Message")
      return
    }

    handleSelect("step", selectedStep)
    handleSelect("errorMessage", "")
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            kvmCategoryTypes={ kvmCategoryTypes }
            selectedKVMCategories={ selectedKVMCategories }
            handleChangeCategory={ handleChangeCategory }
            kvm={ kvm }
            message={ message }
            parent={ parent }
            changeStep={ changeStep }
            isEvidenceStatement={ isEvidenceStatement }
            evidenceStatements={ evidenceStatements }
            supportingEvidence={ supportingEvidence }
            category={ category }
            page={ page }
            kvmParent={ kvmParent }
            region={ region }
            indications={ indications }
            document={ document }
            handleInput={ handleInput }
            handleSelect={ handleSelect }
            handleInputFile={ handleInputFile }
            errorMessage={ errorMessage }
          />
        )
      case 2:
        return (
          <StepTwo
            kvm={ kvm }
            parent={ parent }
            handleSelect={ handleSelect }
            indications={ indications }
            errorMessage={ errorMessage }
          />
        )
      case 3:
        return (
          <StepThree
            supporting_evidence={ supportingEvidence }
            handleChangeEvidence={ handleChangeEvidence }
            isEvidenceStatement={ isEvidenceStatement }
            handleSubmit={ handleSubmit }
            changeStep={ changeStep }
            indications={ indications }
            errorMessage={ errorMessage }
            kvmParent={ kvmParent }
          />
        )
      case 4:
        return (
          <EntryCreationCategoryList
            entry={ kvm }
            indications={ indications }
            step={ step }
            handleCancel={ handleCancel }
            changeStep={ changeStep }
            handleSubmit={ handleSubmit }
            selectedCategories={ selectedKVMCategories }
            setSelectedCategories={ setSelectedKVMCategories }
          />
        )
      default:
        return null
    }
  }


  return (
    <React.Fragment>
      { !!forClick ?
        <forClick.type {...forClick.props} onClick={() => handleSelect("visible", true)}></forClick.type>
        :
        <Button
          // className='new_education_button'
          className="custom-btn"
          onClick={() => handleSelect("visible", true)}
        >
          { mode === "create" ? "Add" : "Edit" } Key Value Message
        </Button>
      }
      <Modal show={ visible } onHide={ handleCancel } size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{ mode === "create" ? "Add" : "Edit" } Key Value Message</Modal.Title>
        </Modal.Header>
        { renderStep() }
      </Modal>
    </React.Fragment>
  )
}

CreateUpdateKVM.propTypes = {
  mode: PropTypes.string,
  forClick: PropTypes.node,
  kvm: PropTypes.object,
}