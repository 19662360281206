import React, {useEffect, useState} from 'react'
import PopupPDFViewer from '../../../components/PopupPDFViewer'
import {Button} from 'antd'
import { Col, Row } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'
import axios from "axios";
import PropTypes from "prop-types";


export default function SearchResult({ data }) {
  // STATE
  const [imageUrl, setImageUrl] = useState(require("../../../static/images/spinner.gif"))
  const [className, setClassName] = useState("thumbnail-pic")
  const [showImage, setShowImage] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const handleShowImage = () => setShowImage(!showImage)

  const getImage = () => {
    //THIS FUNCTION ALLOWS THE THUMBNAIL TO BE FETCHED AND IMPLEMENTED
    //AND REPLACES THE THUMBNAIL IF IT CAN'T GET IT WITH A SAMPLE PICTURE
    setIsLoadingImage(true)
    if (data.thumbnail) {
      axios.get(data.thumbnail, {
        responseType: "blob"
      }).then(response => {
        if (response.type === "text/html") {
          setImageUrl(require("../../../static/images/no-img-available.jpg"))
        } else {
          // setImageUrl(URL.createObjectURL(response.data))
          setImageUrl(URL.createObjectURL(new Blob([response.data])))
          setClassName("thumbnail-pic expandable")
        }
      }).catch(error => {
        setImageUrl(require("../../../static/images/no-img-available.jpg"))
      }).finally(() => {
        setIsLoadingImage(false)
      })
    } else {
      setImageUrl(require("../../../static/images/no-img-available.jpg"))
    }
  }

  useEffect(() => {
    getImage()
  }, [])

  return (
    <div className="csr-search-result">
      <Modal size="lg" show={ showImage } onHide={ handleShowImage }>
        <Modal.Header closeButton>
          <Modal.Title>{ data.name }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            className="csr-picture-modal"
            id={`${data._id} + ${data._index}`}
            src={imageUrl}
            alt={`${data._id} + ${data._index}`}
          />
        </Modal.Body>
      </Modal>
      <Row>
        <Col sm={3} md={3} lg={3}>
          <div className={className}>
            { isLoadingImage ?
              <div className="loader-wrapper"><span className="loader"></span></div>
              :
              <img
                id={`${data._id} + ${data._index}`}
                src={imageUrl}
                alt={`${data._id} + ${data._index}`}
                width="150px"
              />
            }
          </div>
        </Col>
        <Col sm={9} md={9} lg={9}>
          <div>
            <div>
              <h5>
                { data.name }
              </h5>
            </div>
            {/*<div>
              Tags:
              <div style={{display: 'inline-block',minWidth:'100px'}}>
                {data.tag.map((tag,index) => <span key={index}>&nbsp;<Button type="primary" style={{color:"black",cursor:"default"}} disabled>{tag.name.charAt(0).toUpperCase() + tag.name.slice(1)}</Button>&nbsp;</span>)}
              </div>
            </div>*/}

            {data.description ?
              <div className="csr-description">
                {data.description}
              </div>
              :
              null
            }

            <div>
              <div>
                <strong>Source:</strong> {data.clinical_study_report.title}<br/>
                <strong>Section:</strong> {data.section.name}<br/>
                <strong>Subsection:</strong> {data.subsection.name}<br/>
              </div>
            </div>
            <div>
              <div>
                <PopupPDFViewer
                  file={data.clinical_study_report.document}
                  title={data.name}
                  page={data.page}
                  forClick={
                    <Button
                      className="csr-action-button"
                    >
                      View {data.type} {data.number} on page {data.page}
                    </Button>
                  }
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

    </div>
  )
}

SearchResult.propTypes = {
  data: PropTypes.object.isRequired
}