import React from 'react'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { Button, Checkbox, Input } from "antd";
import { requestFunctions } from "../../../../functions/requestFunctions";
import LoadingAnimation from "../../../../components/LoadingAnimation";


export default function StepTwo({ errorMessage, kvm, parent, handleSelect, changeStep }) {
  // REDUX
  const dispatch = useDispatch()
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)

  // STATE
  const [parentKVMs, setParentKVMs] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [fetchingParentKVMs, setFetchingParentKVMs] = useState(false)

  const getParentKVMs = () => {
    setFetchingParentKVMs(true)
    axios.get(`${process.env.REACT_APP_URL}key-value-messages/`,{
      params: { filters: { searchTerm }, indication: selectedIndication.id, parents: true },
      headers: { "Authorization": "JWT "+localStorage.getItem("token") }
     })
    .then(response => {
      let parentList = response.data.filter(parentObject => parentObject.url !== kvm.url)
      setParentKVMs(parentList.sort((a, b) => parseInt(a.number, 10) - parseInt(b.number, 10)))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting key value messages...")
    }).finally(() => {
      setFetchingParentKVMs(false)
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getParentKVMs()
  }

  useEffect(() => {
    getParentKVMs()
  }, [])

  let kvmList = parentKVMs.map((kvm, index) => {
    return (
      <tr
        key={index}
        className="kvm-label"
        onClick={(e) => {
          e.preventDefault()
          handleSelect("parent", kvm.url)
        }}
      >
        <td className="kvm-checkbox" style={{ textAlign: "center" }}>
          <Checkbox
            // value={ kvm.value }
            id={"kvm" + kvm.id}
            onChange={() => null}
            checked={ parent === kvm.url }
            onClick={(e) => {
              e.stopPropagation()
              handleSelect("parent", kvm.url)
            }}
          />
        </td>
        <td className="kvm-id" style={{ textAlign: "center" }}>
          { kvm.number }
        </td>
        <td>
        <label
          className="kvm-message"
          htmlFor={"kvm"+kvm.id}
        >
          {kvm.message}
        </label>
        </td>
      </tr>
    )
  })

  if (!parentKVMs.length) {
    kvmList = (
      <tr className="kvm-label">
        <td></td>
        <td></td>
        <td style={{ maxWidth: '20vw', width: '100%', overflow: 'hidden' }}>
          There are no Key Value Messages for the selected indications...
        </td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      <Modal.Body>
        <div id="newkvm" className="translucent-background-with-padding">
          <p className="title">
            Select parent Key Value Message
          </p>
          <center>
            <form onSubmit={ handleSubmit }>
              <Input
                value={ searchTerm }
                onChange={ (e) => setSearchTerm(e.target.value) }
                placeholder="Search..."
              />
              </form>
          </center>
          <br/>
          <div id="kvm-evidence-statement-list">
            <table className="kvmtable" style={{width: "100%"}}>
              <colgroup>
                <col className="kvm-column" />
                <col className="kvm-column" />
              </colgroup>
              <thead className="table-header new-evidence">
                <tr>
                  <th className='kvm-checkbox-header' style={{ width:"50%" }}>Selected</th>
                  <th>Number</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                { fetchingParentKVMs ?
                  <tr className="kvm-label">
                    <td></td>
                    <td></td>
                    <td style={{ width: '100%', overflow: 'hidden', padding: "100px" }}>
                      <br/>
                      <LoadingAnimation />
                    </td>
                  </tr>
                  : kvmList }
              </tbody>
            </table>
          </div>
          { errorMessage ?
            <Alert variant="danger">{ errorMessage }</Alert> : null
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ () => changeStep(1) }>Back</Button>
        <Button onClick={ () => changeStep(3) }>Select Associated Evidence</Button>
      </Modal.Footer>
    </React.Fragment>
  )
}
