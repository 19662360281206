import React, {Fragment, useState} from 'react'
import { Icon } from 'antd'
import PopupPDFViewer from '../../../../components/PopupPDFViewer'
import {Col, ListGroup, Row} from "react-bootstrap";


export default function Subsection({ subsection, csrDocument }) {
  // STATE
  const [visible, setVisible] = useState(false)
  const [visibleTables, setVisibleTables] = useState(false)
  const [visibleFigures, setVisibleFigures] = useState(false)

  let tables = subsection.tables.map(table => {
    return (
      <PopupPDFViewer
        key={`table-${table.name}-${table.number}-page-${table.page}`}
        page={table.page}
        file={csrDocument}
        onClick={(e) => e.stopPropagation()}
        forClick={
          <ListGroup.Item
            className="figure-table"
            style={{display: visibleTables ? "block" : "none"}}
            action
          >
            <Col sm={{ offset: 2, span: 10}} md={{ offset: 2, span: 10}} lg={{ offset: 2, span: 10}}>
              {table.name}
            </Col>
          </ListGroup.Item>
        }
      />
    )
  })

  let figures = subsection.figures.map(figure => {
    return (
      <PopupPDFViewer
        key={`figure-${figure.name}-${figure.number}-page-${figure.page}`}
        page={figure.page}
        file={csrDocument}
        onClick={(e) => e.stopPropagation()}
        forClick={
          <ListGroup.Item
            className="figure-table"
            style={{display: visibleFigures ? "block" : "none"}}
            action
          >
            <Col sm={{ offset: 2, span: 10}} md={{ offset: 2, span: 10}} lg={{ offset: 2, span: 10}}>
              {figure.name}
            </Col>
          </ListGroup.Item>
        }
      />
    )
  })

  return (
    <div>
      <ListGroup.Item
        key={subsection.name}
        className={`csr-subsection-item ${!visible || "active"}`}
        action
        onClick={() => tables.length || figures.length ? setVisible(!visible) : null}
      >
        <Row>
          <Col sm={{ offset: 1, span: 9}} md={{ offset: 1, span: 9}} lg={{ offset: 1, span: 9}}>
            <PopupPDFViewer
              page={subsection.page}
              file={csrDocument}
              forClick={<span className="csr-link">{subsection.name}</span>}
            />
          </Col>
          <Col sm={2} md={2} lg={2}>
            { tables.length || figures.length ?
              <Icon
                style={{cursor:"pointer", padding: '5px', float: "right"}}
                type={visible ? "caret-down" : "caret-right"}
              /> : null
            }
          </Col>
        </Row>
      </ListGroup.Item>

      {tables.length && visible ?
        <Fragment>
          <ListGroup.Item
            action
            className={`csr-section-item ${!visibleTables || "active"}`}
            onClick={(e) => {
              e.stopPropagation()
              setVisibleTables(!visibleTables)
            }}>
            <Row>
              <Col sm={{ offset: 2, span: 8}} md={{ offset: 2, span: 8}} lg={{ offset: 2, span: 8}}>
                Tables
              </Col>
              <Col sm={2} md={2} lg={2}>
                <Icon
                  style={{cursor:"pointer", padding: '5px', float: "right"}}
                  type={visibleTables ? "caret-down" : "caret-right"}
                />
              </Col>
            </Row>
          </ListGroup.Item>
          {tables}
        </Fragment>
        :
        null
      }

      {figures.length && visible ?
        <Fragment>
          <ListGroup.Item
            action
            className={`csr-section-item ${!visibleFigures || "active"}`}
            onClick={(e) => {
              e.stopPropagation()
              setVisibleFigures(!visibleFigures)
            }}>
            <Row>
              <Col sm={{ offset: 2, span: 8}} md={{ offset: 2, span: 8}} lg={{ offset: 2, span: 8}}>
                Figures
              </Col>
              <Col sm={2} md={2} lg={2}>
                <Icon
                  style={{cursor:"pointer", padding: '5px', float: "right"}}
                  type={visibleFigures ? "caret-down" : "caret-right"}
                />
              </Col>
            </Row>
          </ListGroup.Item>
          {figures}
        </Fragment>
        :
        null
      }
    </div>
  )
}