import React from 'react'
import {Col, Row} from "react-bootstrap";
import { Icon, Popconfirm } from "antd";
import CreateUpdateCategory from "./CreateUpdateCategory";
import axios from "axios";
import {requestFunctions} from "../../../functions/requestFunctions";
import {useDispatch} from "react-redux";


export default function Category({ isCategoryOrderEditable, category, categoryTypes, getCategories, source, index, snapshot, ...props }) {
  // REDUX
  const dispatch = useDispatch()

  const getItemStyle = (isDragging, draggableStyle) => ({
    border: "1px solid",
    borderColor: isDragging ? "var(--brand-color-2)" : "var(--brand-color-1)",
    marginLeft: "0",
    marginRight: "0",
    margin: "5px 0",
    padding: "5px 10px",
    cursor: isCategoryOrderEditable ? "grab" : "default",
    borderRadius: "10px",
    backgroundColor: "white",
    transition: ".25s",
    ...draggableStyle
  });

  const handleDelete = () => {
    requestFunctions.loadingAnimation("Deleting filter...")
    axios.delete(category.url, {
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully deleted a filter")
      getCategories()
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting the filter...")
    })
  }

  return (
    <Row
      className={ `category ${ isCategoryOrderEditable ? "movable" : null }` }
      ref={props.innerRef}
      { ...props.provided.draggableProps }
      { ...props.provided.dragHandleProps }
      style={getItemStyle(
        snapshot.isDragging,
        props.provided.draggableProps.style
      )}
    >
      <Col xs={ 7 } md={ 7 } lg={ 7 } style={{ padding: "0" }}>
        {category.name}
      </Col>
      <Col style={{ padding: "0" }}>
        <div style={{ float: "right" }}>
          { !isCategoryOrderEditable ?
            <React.Fragment>
              <CreateUpdateCategory
                forClick={<span className="action-btn">Edit</span>}
                getCategories={ getCategories }
                categoryTypes={ categoryTypes }
                category={ category }
              />
              &nbsp;&nbsp;
              <Popconfirm
                title="Are you sure to delete this filter?"
                placement="left"
                onConfirm={ handleDelete }
                okText="Yes"
                cancelText="No"
              >
                <span className="action-btn required">Delete</span>
              </Popconfirm>
            </React.Fragment>
            :
            <Icon type="menu" />
          }
        </div>
      </Col>
      {props.provided.placeholder}
    </Row>
  )
}