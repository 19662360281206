import React, { useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink, useHistory} from 'react-router-dom'
import { Form, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { getModules } from '../reducers/manageModules'
import { clearEverything, refreshToken } from '../reducers/manageUsers'
import { submitClick } from '../reducers/manageActivity'
import { selectIndication } from '../reducers/manageIndications'
import GVDViewer from './components/GVDViewer'
import ReactiveSearchTopNav from './components/ReactiveSearchTopNav'
import 'bootstrap/dist/css/bootstrap.min.css';
import './navbar.css'


export default function NavBar() {
  // REACT ROUTER DOM
  const history = useHistory()

  // REDUX
  const dispatch = useDispatch()
  const user = useSelector(state => state.manageUsers.user)
  const modules = useSelector(state => state.manageModules.modules)
  const fetchingModules = useSelector(state => state.manageModules.fetchingModules)
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const gvds = useSelector(state => state.manageEducation.gvds)

  // STATE
  const [navBarClass, setNavBarClass] = useState("")

  useEffect(() => {
    if (localStorage.token) {
      dispatch(getModules())
    }
  }, [])

  const handleRoute = (route) => {
		const clickedModule = modules.find(module => route.includes(module.route))
    if (route === "/login") {
      dispatch(clearEverything())
			history.replace("/login")
		} else {
			dispatch(refreshToken())
			dispatch(submitClick({content_type: "module", module: clickedModule.name, object_id: clickedModule.id, action: "navigate"}))
			// dispatch(setMode(route))
      if (route === "/home") {
        history.replace("/")
      } else {
        history.replace(route)
      }
		}
  }

  const handleSwitchIndication = () => {
    dispatch(selectIndication(null))
    localStorage.removeItem("indication")
    history.replace("/indications")
  }

  const toggleExpand = () => {
    if (navBarClass === "") {
      setNavBarClass("responsive")
    } else {
      setNavBarClass("")
    }
  }

  if (user && !fetchingModules && selectedIndication) {
    return (
      <div style={{ height: "75px" }}>
        <Navbar bg="light" id="NavBar" expand="lg" fixed="top">
          <Navbar.Brand href="/">
          {selectedIndication.name.includes("POMC") &&
            <img
              className="topnav-drug-logo"
              src={require('../static/images/imcivree-logo.svg')}
              alt="Rhythm logo"
            />
          }
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={ NavLink } onClick={() => handleRoute("/home")} exact to="/">
                Home
              </Nav.Link>
              <Nav.Link as={ NavLink } onClick={() => handleRoute("/keyvaluemessages")} to="/keyvaluemessages">
                Key Value Messages
              </Nav.Link>
              <Nav.Link as={ NavLink } onClick={() => handleRoute("/library")} to="/library">
                Evidence Library
              </Nav.Link>
              <Nav>
                <NavDropdown alignRight title="Study Center" id="basic-nav-dropdown">
                  <NavDropdown.Item as={ NavLink } onClick={() => handleRoute("/studycenter/studies")} to="/studycenter/studies">
                    Studies
                  </NavDropdown.Item>
                  <NavDropdown.Item as={ NavLink } onClick={() => handleRoute("/studycenter/timelines")} to="/studycenter/timelines">
                    Timelines
                  </NavDropdown.Item>
                  <NavDropdown.Item as={ NavLink } onClick={() => handleRoute("/studyreport")} to="/studyreport">
                    Clinical Study Report
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav.Link as={ NavLink } onClick={() => handleRoute("/datacollection")} to="/datacollection">
                Data Collection
              </Nav.Link>
              { gvds.length ?
                <NavDropdown title="Resources" id="basic-nav-dropdown">
                  <GVDViewer forClick={<NavDropdown.Item>View Dossiers</NavDropdown.Item>} />
                  <NavDropdown.Item as={ NavLink } onClick={() => handleRoute("/resources")} to="/resources">
                    Key Resources
                  </NavDropdown.Item>
                </NavDropdown>
                :
                <Nav.Link as={ NavLink } onClick={() => handleRoute("/resources")} to="/resources">
                  Key Resources
                </Nav.Link>
              }
              {/*<NavDropdown title="Resources" id="basic-nav-dropdown">*/}
              {/*  { !!gvds.length && <GVDViewer forClick={<NavDropdown.Item>View Dossiers</NavDropdown.Item>} /> }*/}
              {/*  <NavDropdown.Item as={ NavLink } onClick={() => handleRoute("/resources")} to="/resources">*/}
              {/*    Key Resources*/}
              {/*  </NavDropdown.Item>*/}
              {/*</NavDropdown>*/}
            </Nav>
            <Form inline>
              {/* <Button className="mr-sm-2 navbar-btn">Site Wide Filters</Button> */}
              {/* <Button id="site-wide-filters-btn" className="mr-sm-2 navbar-btn" onClick={ () => setState({ showFilterModal: true }) }>
                Show Site Wide Filters
              </Button>
              <SiteWideFilter showModal={ state.showFilterModal } onCancel={ () => setState({ showFilterModal: false }) } /> */}
              <ReactiveSearchTopNav
                handleClick={handleRoute}
                toggleNavBarExpand={toggleExpand}
                toggleSearchBarModal={() => null}
              />
              {/* <FormControl type="text" className="navbar-input" placeholder="Search"  /> */}
            </Form>
            <Nav>
              <NavDropdown alignRight title={ user.first_name } id="basic-nav-dropdown">
                <NavDropdown.Item as={ NavLink } to="/usage">
                  Usage Statistics
                </NavDropdown.Item>
                <NavDropdown.Item as={ NavLink } to="/user-management">
                  Manage Users
                </NavDropdown.Item>
                <NavDropdown.Item as={ NavLink } to="/filter-management">
                  Manage Filters
                </NavDropdown.Item>
                <NavDropdown.Item onClick={ handleSwitchIndication }>Switch Indication</NavDropdown.Item>
                <NavDropdown.Item as={ NavLink } to="/settings">
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => handleRoute("/login")}>Log Out</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  } else {
    return null
  }
}