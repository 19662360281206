import axios from 'axios'
import { submitClick } from './manageActivity'
import {requestFunctions} from "../functions/requestFunctions";


let defaultState = {
  show: false,
  education: [],
  allEducation: [],
  totalEducationCount: 0,
  fetchingEducation: false,
  gvds: [],
  gvd: {},
  fetchingGVDs: false,
  educationGroups: [],
  fetchingEducationGroups: false,
  currentPage: 1,
  limit: 12,
  searchTerm: "",
  selectedGroup:"All"
}

export default function manageEducation(state = defaultState, action){
  switch (action.type) {
    case 'SHOW_EDUCATION':
      return { ...state, show: action.payload }
    case 'FETCHING_EDUCATION':
      return { ...state, fetchingEducation: true }
    case 'FETCHING_GVDS':
      return { ...state, fetchingGVDs: action.payload }
    case 'SET_EDUCATION':
      return  { ...state, education: action.payload, fetchingEducation: false }
    case 'SET_ALL_EDUCATION':
      return { ...state, allEducation: action.payload, fetchingEducation: false }
    case 'SET_EDUCATION_LIMIT':
      return  { ...state, limit: action.payload }
    case "CHANGE_EDUCATION_SEARCH_TERM":
      return { ...state, searchTerm: action.payload }
    case 'CHANGE_EDUCATION_PAGE':
      return { ...state, currentPage: action.payload }
    case 'CHANGE_SELECTED_GROUP':
      return { ...state, selectedGroup: action.payload, currentPage: 1 }
    case 'SET_GVDS':
      let gvd = {}
      if (action.payload.length) {
        gvd = action.payload[0]
      }
      return  { ...state, gvds: action.payload, gvd: gvd }
    case 'SELECT_GVD':
      return  { ...state, gvd: action.payload }
    case 'UPDATED_EDUCATION':
      const index = state.education.findIndex(ed => ed.id === action.payload.id)
      return {
        ...state,
        show: state.show,
        education: [...state.education.slice(0, index), action.payload, ...state.education.slice(index + 1)]
      }
    case 'DELETED_EDUCATION':
      let newEducation = state.education.filter(edu => edu.id !== action.payload)
      return {
        ...state,
        education: newEducation
      }
    case 'SUBMITTED_EDUCATION':
      return {
        ...state,
        education: [...state.education, action.payload]
      }
    case 'CLEAR_EDUCATIONAL_RESOURCES':
      return defaultState
    case 'FETCHING_EDUCATION_GROUPS':
      return { ...state, fetchingEducationGroups: true }
    case 'SET_TOTAL_EDUCATION_COUNT':
      return { ...state, totalEducationCount: action.payload }
    case 'SET_EDUCATION_GROUPS':
      return {
        ...state,
        educationGroups: action.payload,
        fetchingEducationGroups: false,
      }
    default:
		  return state
	}
}

export const deletedEducation = (id) => {
  return {
    type: 'DELETED_EDUCATION',
    payload: id
  }
}

export const editedEducation = (data) => {
  return { type: 'UPDATED_EDUCATION', payload: data }
}

export const showEducation = (show) => {
  return { type: 'SHOW_EDUCATION', payload: show }
}

export const selectGVD = (gvd) => {
  return { type: 'SELECT_GVD', payload: gvd }
}

const fetchingEducation = () => {
  return { type: 'FETCHING_EDUCATION' }
}

export const gotEducation = data => {
  return { type: 'SET_EDUCATION', payload: data }
}

const gotAllEducation = data => {
  return { type: 'SET_ALL_EDUCATION', payload: data }
}

const gotGVDs = data => {
  return { type: 'SET_GVDS', payload: data }
}

const fetchingEducationGroups = () => {
  return { type: 'FETCHING_EDUCATION_GROUPS' }
}

const gotEducationGroups = data => {
  return { type: 'SET_EDUCATION_GROUPS', payload: data }
}

export const changePage = (page) => {
  return { type: "CHANGE_EDUCATION_PAGE", payload: page }
}

export const setEducationLimit = (page) => {
  return { type: "SET_EDUCATION_LIMIT", payload: page }
}

export const changeSearchTerm = (term) => {
  return { type: "CHANGE_EDUCATION_SEARCH_TERM", payload: term }
}

export const changeSelectedGroup = (group) => {
  return { type: "CHANGE_SELECTED_GROUP", payload: group }
}

export const editEducation = (education) => {
  return (dispatch, useState) => {
    requestFunctions.loadingAnimation("Editing resource. It might take a minute if the file is large")

    // const { limit, searchTerm } = useState().manageEducation
    // const { selectedKeyResourceCategories, selectedKeyResourceSubFolder } = useState().manageCategories
    // const { countries } = useState().manageGlobalFilters
    // const { selectedFolder, selectedSubFolder } = useState().manageFolders
    // let folder = selectedSubFolder || selectedFolder || ""

    axios.patch(education.get("url"), education, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "JWT "+localStorage.getItem("token")
      }
    }).then(response => {
      requestFunctions.successAnimation("The resource has been updated successfully")
      dispatch(submitClick({content_type: "educationalresource", module: "Key Resources", object_id: response.data.id, action: "modify" }))
      //
      // const filters = {
      //   term: searchTerm,
      //   categories: `${selectedKeyResourceCategories}`,
      //   countries: `${countries}`,
      //   subFolder: selectedKeyResourceSubFolder,
      //   folder: folder
      // }
      dispatch(changePage(1))
      dispatch(getEducation(1))
      // dispatch(getCategories())
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in updating the resource...")
    })
  }
}

export const submitEducation = (education) => {
  return (dispatch, useState) => {
    requestFunctions.loadingAnimation("Submitting resource. It might take a minute if the file is large.")

    // const { limit, searchTerm } = useState().manageEducation
    // const { selectedKeyResourceCategories, selectedKeyResourceSubFolder } = useState().manageCategories
    // const { countries } = useState().manageGlobalFilters
    const { selectedIndication } = useState().manageIndications
    
    education.append("indication", selectedIndication.url)

    axios.post(process.env.REACT_APP_URL+"educational-resources/", education, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "JWT "+localStorage.getItem("token")
      }
    }).then(response => {
      requestFunctions.successAnimation("The resource has been updated successfully")
      dispatch(submitClick({content_type: "educationalresource", module: "Key Resources", object_id: response.data.id, action: "create" }))

      // const filters = {
      //   term: searchTerm,
      //   categories: `${selectedKeyResourceCategories}`,
      //   countries: `${countries}`,
      //   subFolder: `${selectedKeyResourceSubFolder}`,
      //   folders: ""
      // }
      dispatch(changePage(1))
      dispatch(getEducation(1))
      // dispatch(getCategories())
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in uploading the resource...")
    })
  }
}

export const deleteEducation = (education) => {
  return (dispatch, useState) => {
    requestFunctions.loadingAnimation("Deleting the resource...")

    // const { limit, searchTerm } = useState().manageEducation
    // const { selectedKeyResourceCategories, selectedKeyResourceSubFolder } = useState().manageCategories
    // const { countries } = useState().manageGlobalFilters

    axios.delete(education.url, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "JWT "+localStorage.getItem("token")
      }
    }).then(response => {
      requestFunctions.successAnimation("The resource has been deleted successfully")
      // const filters = {
      //   term: searchTerm,
      //   categories: `${selectedKeyResourceCategories}`,
      //   countries: `${countries}`,
      //   subFolder: selectedKeyResourceSubFolder
      // }

      dispatch(changePage(1))
      dispatch(getEducation(1))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting the resource...")
    })
  }
}

/**
 * @summary Gets resources for the currently selected indication.
 * @param {number=} limit - Number of resources to get from the backend.
 * @param {object=} filters - Object that contains filters.
 * @param {number=} page - Page number.
 *
 */
export const getEducation = (page=1) => {
  return (dispatch, useState) => {

    dispatch(fetchingEducation())
    const { limit, searchTerm, currentPage } = useState().manageEducation
    const { countries } = useState().manageGlobalFilters
    const { selectedIndication } = useState().manageIndications
    const { selectedCategories } = useState().manageCategories
    const { modules } = useState().manageModules
    // const { selectedFolder, selectedSubFolder } = useState().manageFolders
    // let folder = selectedSubFolder || selectedFolder || ""
    let offset = limit * (currentPage - 1)

    let filteredCategories = []
    const selectedModule = modules.find(module => module.route === "/resources")
    if (selectedModule) {
      if (selectedCategories[selectedModule.id]) {
        Object.keys(selectedCategories[selectedModule.id]).forEach(filterType => {
          filteredCategories.push(...selectedCategories[selectedModule.id][filterType])
        })
      }
      filteredCategories = filteredCategories.map(category => category.id)
    }

    const filters = {
      term: searchTerm,
      categories: filteredCategories,
      countries: `${countries}`,
      // folder: folder,
      // subFolder: selectedKeyResourceSubFolder
    }
    axios.get(`${process.env.REACT_APP_URL}educational-resources/`, {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")},
      params: { limit, filters, offset, indication: selectedIndication.id },
    }).then(response => {
      if(Object.keys(response.data).includes("results")) {
        if(response.data.results.length) {
          dispatch({ type: "SET_TOTAL_EDUCATION_COUNT", payload: response.data.count })
        }
        dispatch(gotEducation(response.data.results))  
      } else {
        if(response.data.length) {
          dispatch({ type: "SET_TOTAL_EDUCATION_COUNT", payload: response.data.length })
        }
        dispatch(gotEducation(response.data))
      }
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data...")
      dispatch(gotEducation([]))
    })
  }
}

export const getAllEducation = () => {
  return (dispatch) => {
    dispatch(fetchingEducation())
    axios.get(`${process.env.REACT_APP_URL}educational-resources/`, {
        headers: { "Authorization": "JWT "+localStorage.getItem("token") }
    }).then(response => {
      dispatch(gotAllEducation(response.data))  
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data...")
      dispatch(gotEducation([]))
    })
  }
}

export const getEducationGroups = () => {
  return (dispatch) => {
    dispatch(fetchingEducationGroups())
    axios.get(`${process.env.REACT_APP_URL}resource-categories/`, {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    })
    .then(response => {
      dispatch(gotEducationGroups(response.data))
    })
    .catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data...")
      dispatch(gotEducationGroups([]))
    })
  }
}

export const getGVDs = (indicationID) => {
  return (dispatch) => {
    dispatch({ type: "FETCHING_GVDS", payload: true })
    axios.get(`${process.env.REACT_APP_URL}gvds/`, {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")},
      params: { indication: indicationID }
    })
    .then(response => {
      dispatch(gotGVDs(response.data))
    })
    .catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data...")
      dispatch(gotGVDs([]))
    }).finally(() => {
      dispatch({ type: "FETCHING_GVDS", payload: false })
    })
  }
}
