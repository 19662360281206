import React, { Fragment, useState } from 'react'
import { Button, Input, Select } from "antd"
// import DatePicker from "react-datepicker"
import {Alert, Col, Modal, Row} from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux'
import { submitCommunicationTarget } from '../../../../../reducers/manageStudies'
import {CustomDatePicker} from "../../../../../components/DatePickers";
import formatDateForServer from "../../../../../functions/formatDateForServer";


export default function NewTargetForm({ closeModal }) {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  // STATE
  const [venue, setVenue] = useState("")
  const [content, setContent] = useState("")
  const [htas, setHtas] = useState([])
  const [medicalProviders, setMedicalProviders] = useState([])
  const [other, setOther] = useState([])
  const [patientOrganizations, setPatientOrganizations] = useState([])
  const [payers, setPayers] = useState([])
  const [regulators, setRegulators] = useState([])
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [errorMessage, setErrorMessage] = useState("")

  const handleCancel = () => {
    setVenue("")
    setContent("")
    setHtas([])
    setMedicalProviders([])
    setOther([])
    setPatientOrganizations([])
    setPayers([])
    setRegulators([])
    setDateRange([null, null])
    closeModal()
  }

  const handleSubmit = () => {
    setErrorMessage("")
    if (!venue || !content || !startDate || !endDate) {
      setErrorMessage("Please fill out all required fields")
      return
    }
    let newCommunicationTarget = new FormData()
    newCommunicationTarget.append("venue", venue)
    newCommunicationTarget.append("content", content)
    newCommunicationTarget.append("studies", selectedStudy.url)
    newCommunicationTarget.append("study", selectedStudy.url)
    newCommunicationTarget.append("key_date", formatDateForServer(startDate))
    newCommunicationTarget.append("end_date", formatDateForServer(endDate))
    selectedStudy.indications.forEach(indication => newCommunicationTarget.append("indications", indication))
    newCommunicationTarget.append("created_by", {})
    htas.forEach(target => newCommunicationTarget.append("htas", target))
    medicalProviders.forEach(target => newCommunicationTarget.append("medical_providers", target))
    other.forEach(target => newCommunicationTarget.append("other", target))
    patientOrganizations.forEach(target => newCommunicationTarget.append("patient_organizations", target))
    payers.forEach(target => newCommunicationTarget.append("payers", target))
    regulators.forEach(target => newCommunicationTarget.append("regulators", target))

    dispatch(submitCommunicationTarget(newCommunicationTarget))
  }

  return (
    <Fragment>
      <Modal.Body>
        <Row>
          <Col md={6} lg={6} xl={6}>
            <div><strong>Venue</strong></div>
            <Input value={ venue } placeholder="Enter the venue..." onChange={ (e) => setVenue(e.target.value) }/>
          </Col>
          <Col md={6} lg={6} xl={6}>
            <div><strong>Date</strong></div>
            <CustomDatePicker
              selectsRange={ true }
              startDate={ startDate }
              endDate={ endDate }
              onChange={ (update) => setDateRange(update)}
              placeholderText="Enter date range..."
            />
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={6} lg={6} xl={6}>
            <div><strong>Content <span className="required">*</span></strong></div>
            <Input.TextArea
              rows={4}
              value={ content }
              placeholder="Enter content..."
              name="content"
              onChange={ (e) => setContent(e.target.value) }
            />
          </Col>
        </Row>
        <br/>
        <Row className="field">
          <Col md={6} lg={6} xl={6}>
            <div><strong>HTAs</strong></div>
            <Select
              value={ htas }
              style={{ width: "100%" }}
              placeholder="Enter HTAs..."
              onChange={ setHtas }
              mode="multiple"
            >
              { selectedStudy.htas.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
            </Select>
          </Col>
          <Col md={6} lg={6} xl={6}>
            <div><strong>Medical Providers</strong></div>
            <Select
              value={ medicalProviders }
              style={{ width: "100%" }}
              placeholder="Enter medical providers..."
              onChange={ setMedicalProviders }
              mode="multiple"
            >
              { selectedStudy.medical_providers.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
            </Select>
          </Col>
        </Row>
        <br/>
        <Row className="field">
          <Col md={6} lg={6} xl={6}>
            <div><strong>Patient Organizations</strong></div>
            <Select
              value={ patientOrganizations }
              style={{ width: "100%" }}
              placeholder="Enter patient organizations..."
              onChange={ setPatientOrganizations }
              mode="multiple"
            >
              { selectedStudy.patient_organizations.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
            </Select>
          </Col>
          <Col md={6} lg={6} xl={6}>
            <div><strong>Payers</strong></div>
            <Select
              value={ payers }
              style={{ width: "100%" }}
              placeholder="Enter payers..."
              onChange={ setPayers }
              mode="multiple"
            >
              { selectedStudy.payers.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
            </Select>
          </Col>
        </Row>
        <br/>
        <Row className="field">
          <Col md={6} lg={6} xl={6}>
            <div><strong>Regulators</strong></div>
            <Select
              value={ regulators }
              style={{ width: "100%" }}
              placeholder="Enter regulators..."
              onChange={ setRegulators }
              mode="multiple"
            >
              { selectedStudy.regulators.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
            </Select>
          </Col>
          <Col md={6} lg={6} xl={6}>
            <div><strong>Other</strong></div>
            <Select
              value={ other }
              style={{ width: "100%" }}
              placeholder="Enter other..."
              onChange={ setOther }
              mode="multiple"
            >
              { selectedStudy.other.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
            </Select>
          </Col>
        </Row>
        <br/>
          <Row>
            <Col>
              <span className="required">* - Marks required fields</span>
            </Col>
          </Row>
          { errorMessage && <Fragment><br/><Alert variant="danger">{ errorMessage }</Alert></Fragment> }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ handleCancel }>Cancel</Button>
        <Button onClick={ handleSubmit }>Submit</Button>
      </Modal.Footer>
    </Fragment>
  )
}