import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom';
import { Row } from 'antd'
import Search from './search/Search';
import Login from './login/Login';
import KeyValueInterface from './keyvaluemessages/KeyValueInterface';
import Home from './home/Home';
import DataViewerContainer from './dataviewer/DataViewerContainer';
import BrandFooter from '../components/BrandFooter'
import Account from './account/Account';
import NavBar from '../NavBar';
import SettingsContainer from './settings/SettingsContainer';
import IndicationContainer from './indications/IndicationContainer';
import UsageContainer from './usage/UsageContainer'
import UserManagementContainer from './user-management/UserManagementContainer';
import FilterManagementContainer from "./filter-management/FilterManagementContainer";
import { useSelector } from 'react-redux';
import PrivateRoute from '../components/PrivateRoute';
import LibraryContainer from "./library/LibraryContainer";
import KeyResourceContainer from "./resources/KeyResourceContainer";
import PageNotFound from "../components/PageNotFound";
import DataCollectionContainer from './datacollection/DataCollectionContainer';
import StudyContainer from './studycenter/StudyContainer';
import TimelineContainer from './studycenter/TimelineContainer';


const RouteContainer = (props) => {
  const { selectedIndication } = useSelector(state => state.manageIndications)

  if (selectedIndication) {
    document.title = `IEP Demo - ${selectedIndication.name}`
  } else {
    document.title = `IEP Demo`
  }

  return (
    <Fragment>
      <NavBar />
      <div id="route-container">
        <Row id="main-content">
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute path="/home" component={Home} />
            <Route path="/indications" component={IndicationContainer} />
            <PrivateRoute exact path="/keyvaluemessages" component={KeyValueInterface} />
            <PrivateRoute exact path="/library" component={LibraryContainer} />
            <PrivateRoute path="/datacollection" component={DataCollectionContainer} />
            <PrivateRoute path="/resources" component={KeyResourceContainer} />
            <PrivateRoute path="/studyreport" component={DataViewerContainer} />
            <PrivateRoute path="/account" component={Account} />
            <PrivateRoute path="/search" component={Search} />
            <PrivateRoute path="/studycenter/studies/:id?/:tab?" component={StudyContainer} />
            <PrivateRoute path="/studycenter/timelines" component={TimelineContainer} />
            <PrivateRoute path="/settings" component={SettingsContainer} />
            <PrivateRoute path="/usage" component={UsageContainer} />
            <PrivateRoute path="/filter-management" component={FilterManagementContainer} />
            <PrivateRoute path="/user-management" component={UserManagementContainer} />
            <PrivateRoute path="*" component={PageNotFound} />
          </Switch>
        </Row>
      </div>
      <BrandFooter />
    </Fragment>
  )
}

export default RouteContainer
