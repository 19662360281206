import axios from 'axios'
import React, {useEffect, useState} from 'react'
import Swal from "sweetalert2";
import {useDispatch} from 'react-redux'
import { Button, Select, Col, Icon, Row } from 'antd'
import { Modal } from 'react-bootstrap'
import { submitClick } from '../../../reducers/manageActivity'
import {requestFunctions} from "../../../functions/requestFunctions";
import 'antd/dist/antd.css'
import LoadingAnimation from "../../../components/LoadingAnimation";


export default function KVMEvidence(props) {
	// PROPS
	const {
		action, contentType, entry, module, relevantEvidence,
		buttonClassName, buttonType, buttonText
	} = props

	// REDUX
	const dispatch = useDispatch()

	// STATE
	const [visible, setVisible] = useState(false)
	const [downloading, setDownloading] = useState(false)
	const [current, setCurrent] = useState(0)
	const [inFrame, setInFrame] = useState(<div style={{ marginTop: "300px" }}><LoadingAnimation /></div>)

	const options = relevantEvidence.map((evidence,index) => {
		return (
			<Select.Option key={index} value={index}>
				{ evidence.short_reference }
			</Select.Option>
		)
	})

	const initiateDownload = () => {
		setDownloading(true)
		axios.get(`${entry.url}zip_relevant_evidence/`, {
			responseType: "blob",
			headers: {
				"Content-Type": "application/zip",
				"Authorization": "JWT " + localStorage.getItem("token")
			}
		}).then(response => {
			Swal.fire({
				title: "Your file is ready!",
				icon: "success",
				confirmButtonColor: "var(--brand-color-2)",
				confirmButtonText: "Download"
			}).then((isClicked) => {
				if (isClicked) {
					let a = document.createElement('a')
					a.href = URL.createObjectURL(response.data)
					a.download = "relevant_evidence.zip"
					a.click()
				}
			})
		}).catch(error => {
			requestFunctions.handleError(error, dispatch, "Sorry, your file could not be downloaded.")
		}).finally(() => {
			setDownloading(false)
		})
	}

	const showModal = () => {
		setInFrame(<div style={{ marginTop: "300px" }}><LoadingAnimation /></div>)
		if (!visible) {
			dispatch(submitClick({content_type: contentType, module: module, object_id: entry.id || entry._id, action: action }))
		}
		setVisible(true)

		axios.get(`${relevantEvidence[current].document || relevantEvidence[current].document_url}#zoom=100`, {
			responseType: "blob",
			headers: { "Content-Type": "application/pdf" }
		}).then(response => {
			dispatch(submitClick({content_type: "supportingevidence", module: "Evidence Library", object_id: relevantEvidence[current].id, action: "view" }))
			setInFrame(
				<iframe
					src={URL.createObjectURL(response.data)}
					title="document"
					frameBorder="0"
					style={ { height: "80vh", width: "100%" } }
				>
				</iframe>
			)
		}).catch(error => {
			requestFunctions.handleError(error, dispatch, "Document could not be loaded...")
			setInFrame("Document could not be loaded...")
		})
	}

	useEffect(() => {
		if (visible) {
			showModal()
		}
	}, [current])

	const antIcon = <Icon type="loading" style={{ verticalAlign: "0rem" }} spin />;

	return (
		<div>
			<Button className={ buttonClassName } type={ buttonType || "default" } onClick={ showModal }>{ buttonText }</Button>
			{ visible ?
				<Modal
					show={visible}
					size="lg"
					onHide={() => setVisible(false)}
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							Associated Evidence
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<strong>
							Select a Reference:
						</strong>
						<br/>
						<Row>
							<Col xs={18} sm={18} md={18} lg={18}>
								<Select
									style={{ width: "50%" }}
									value={current}
									onChange={value => {
										setCurrent(value)
										setVisible(true)
									}}
								>
									{ options }
								</Select>
							</Col>
							<Col xs={6} sm={6} md={6} lg={6}>
								<Button
									style={{ float: "right", marginTop: "0px" }}
									disabled={ downloading }
									onClick={ initiateDownload }
									className="kvm-show-btn ant-btn-background"
								>
									Download All Evidence { downloading ? antIcon : null }
								</Button>
							</Col>
						</Row>
						<hr />
						{ inFrame }
					</Modal.Body>
				</Modal>
				:
				null
			}
		</div>
	);
}