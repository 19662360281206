import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

// reusable chart components
import ActionBreakdownChart from '../reusable-charts/ActionBreakdownChart'
import NavigationChart from '../reusable-charts/NavigationChart'
import ViewChart from '../reusable-charts/ViewChart'
import ModifyChart from '../reusable-charts/ModifyChart'
import CreateChart from '../reusable-charts/CreateChart'

import { getAllFacts } from '../../../../reducers/manageFacts'


const FactSheetsCharts = ({ clicks, ...props }) => {

  useEffect(() => {
    props.getAllFacts()
  }, [])

  const factSheetsViewClicks = clicks.filter(click => click.action === "view supporting materials - Data Collection")
  const factSheetsModifyClicks = clicks.filter(click => click.action === "modify - Data Collection")
  const factSheetsCreateClicks = clicks.filter(click => click.action === "create - Data Collection")

  // RENDER
  return (
    <div>
      {!props.fetchingFacts && !props.allFacts.length ?
        <div>
          Fetching Data Collection usage data...
        </div>
        :
        <div>
          <h6>Breakdown of all Data Collection tracking actions:</h6>
          <ActionBreakdownChart clicks={clicks} />
          <br />
          <hr />

          <h6>Navigation clicks to Data Collection by date and count:</h6>
          <NavigationChart clicks={clicks} />
          <br />
          <hr />

          {factSheetsViewClicks ?
            <div>
              <h6>Number of times supporting materials for Data Collections were <strong>viewed</strong>:</h6>
              <ViewChart clicks={factSheetsViewClicks} viewObjsArr={props.allFacts} context="Data Collection" />
              <hr />
              <br />
            </div>
            :
            null
          }

          {factSheetsModifyClicks ?
            <div>
              <h6>Number of times individual Data Collections were <strong>modified</strong>:</h6>
              <ModifyChart clicks={factSheetsModifyClicks} modifyObjsArr={props.allFacts} context="Data Collection" />
              <hr />
              <br />
            </div>
            :
            null
          }

          {factSheetsCreateClicks ?
            <div>
              <h6>List of individual Data Collections that have been <strong>created</strong>:</h6>
              <CreateChart clicks={factSheetsCreateClicks} createObjsArr={props.allFacts} context="Data Collection" />
              <hr />
              <br />
            </div>
            :
            null
          }
        </div>
      }
    </div>
  )
}


// REDUX
const mapStateToProps = (state) => {
  return {
    allFacts: state.manageFacts.allFacts,
    fetchingFacts: state.manageFacts.fetchingFacts
  }
}

const mapDispatchToProps = {
  getAllFacts
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FactSheetsCharts))