import React from 'react'

// Rechart components
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'


const CreateChart = ({ clicks, createObjsArr, context }) => {

  const createObjMap = new Map()
  clicks.forEach(click => {
    const objectId = click.object_id.id
    if (createObjMap.has(objectId)) {
      createObjMap.set(objectId, createObjMap.get(objectId) + 1)
    } else {
      createObjMap.set(objectId, 1)
    }
  })

  const data = []
  createObjMap.forEach((count, objectId) => {
    const createObj = createObjsArr.find(obj => obj.id === objectId)
    if (!createObj) {
      return
    }

    let createObjSubstring
    switch(context) {
      case "Data Collection":
        createObjSubstring = createObj.description
        break
      case "Key Resources":
        createObjSubstring = createObj.title
        break
      default:
        break
    }

    data.push({ message: createObjSubstring, creations: count })
  })


  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart height={300} data={data}>
        <XAxis dataKey="message" tick={false} />
        <YAxis />
        <Tooltip contentStyle={{whiteSpace: "normal", maxWidth: "300px"}} />
        <Bar dataKey="creations" fill="rgba(34, 35, 92, 1)" maxBarSize={200} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default CreateChart