import React from 'react'
import {AutoComplete, Input, Select, Row, Col, Button} from 'antd'
import {useSelector} from 'react-redux'
import {Alert, Modal} from 'react-bootstrap'


export default function StepOne(props) {
  // REDUX
  const indicationsFromReducer = useSelector(state => state.manageIndications.indications)
  const outcomes = useSelector(state => state.manageFacts.outcomes)


  return (
    <React.Fragment>
      <Modal.Body>
        <div id="newfact" className="translucent-background-with-padding">
          <Row>
            <Col xs={22} sm={22} md={22} lg={11} style={{marginRight: '5px'}}>
              <div className="field">
                <p>
                  Outcome <span className='required'>*</span>
                </p>
                <AutoComplete
                  style={{ width: '99%' }}
                  value={ props.outcome }
                  name="outcome"
                  onChange={ (value) => props.handleSelect("outcome", value) }
                  dataSource={ outcomes }
                  placeholder="Please enter the outcome"
                  filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                />
              </div>
            </Col>
          </Row>
          <div className="field">
            <Row>
              <Col xs={22} sm={22} md={22} lg={22}>
                <p>
                  Description <span className='required'>*</span>
                </p>
                <Input.TextArea
                  className="longInput"
                  autoSize={{ minRows: 4, maxRows: 10 }}
                  type="text"
                  name="description"
                  value={ props.description }
                  onChange={ props.handleInput }
                  placeholder="Please provide a brief description of the outcome (e.g. for outcome 'MS prevalence' description could be 'Annual prevalance rate of MS in Poland')"
                />
              </Col>
            </Row>
          </div>
          <div className="field">
            <Row>
              <Col xs={22} sm={22} md={22} lg={11} className="large-left">
                <p>
                  Indications: <span className='required'>*</span>
                </p>
                <Select
                  style={ { width: "99%" } }
                  value={ props.indications }
                  mode="multiple"
                  placeholder="Please select the indications..."
                  onChange={ (value) => props.handleSelect("indications", value) }
                  filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                >
                  {
                    indicationsFromReducer.map(indication => {
                      return (
                        <Select.Option key={ indication.id } value={ indication.url }>{ indication.name }</Select.Option>
                      )
                    })
                  }
                </Select>
              </Col>
            </Row>
          </div>
          {/*{ props.dataCategoryTypes.map(categoryType => {*/}
          {/*  return (*/}
          {/*    <div key={ categoryType.id } className="field" id="edit-evidence-categories">*/}
          {/*      <p>{ categoryType.name }:</p>*/}
          {/*      <Select*/}
          {/*        style={ { minWidth: "350px" } }*/}
          {/*        value={ props.selectedDataCategories[categoryType.id] }*/}
          {/*        mode="multiple"*/}
          {/*        placeholder={ `Please select a ${categoryType.name}...` }*/}
          {/*        onChange={ (value) => props.handleChangeCategory(categoryType.id, value) }*/}
          {/*        filterOption={ (inputValue, option) =>  {*/}
          {/*          return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        { categoryType.categories.map(category => {*/}
          {/*          return (*/}
          {/*            <Select.Option key={ category.id } value={ category.url }>{ category.name }</Select.Option>*/}
          {/*          )*/}
          {/*        }) }*/}
          {/*      </Select>*/}
          {/*    </div>*/}
          {/*  )*/}
          {/*}) }*/}
          <Row>
            <Col>
              <span className="required">* - Marks required fields</span>
            </Col>
          </Row>
          { props.errorMessage ?
            <div className="alert-wrapper"><Alert variant="danger" >{props.errorMessage}</Alert></div>
          : null }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button key="1" onClick={ props.handleCancel }>Cancel</Button>
        <Button key="2" onClick={ () => props.changeStep(2) }>Select Supporting Material</Button>
      </Modal.Footer>
    </React.Fragment>
  )
}