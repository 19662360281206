import axios from "axios";
import {requestFunctions} from "../functions/requestFunctions";

const defaultState = {
  folders: [],
  fetchingFolders: false,
  selectedFolder: null,
  subFolders: [],
  fetchingSubFolders: false,
  selectedSubFolder: null,
  breadcrumbs: []
}


export default function manageFolders(state = defaultState, action) {
  switch (action.type) {
    case "SET_FOLDERS":
      return { ...state, folders: action.payload }
    case "SELECT_FOLDER":
      return { ...state, selectedFolder: action.payload }
    case "FETCHING_FOLDERS":
      return { ...state, fetchingFolders: action.payload }
    case "SET_SUB_FOLDERS":
      return { ...state, subFolders: action.payload }
    case "SELECT_SUB_FOLDER":
      return { ...state, selectedSubFolder: action.payload }
    case "SET_BREADCRUMBS":
      return { ...state, breadcrumbs: action.payload }
    case "FETCHING_SUB_FOLDERS":
      return { ...state, fetchingSubFolders: action.payload }
    case "CLEAR_FOLDERS":
      return defaultState
    default:
      return state
  }
}

export const selectFolder = (folderId) => {
  return { type: "SELECT_FOLDER", payload: folderId }
}
export const selectSubFolder = (subFolderId) => {
  return { type: "SELECT_SUB_FOLDER", payload: subFolderId }
}
export const setBreadcrumbs = (folder) => {
  return { type: "SET_BREADCRUMBS", payload: folder }
}


/**
 * @summary - Gets folders for the currently selected module and indication.
 * @param {string} moduleName - Name of the module to filter the folders by.
 * @param {boolean=} parent - Leave this blank if you only want the parentless folders.
 */
export const getFolders = (moduleName, parent=true) => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    dispatch({ type: "FETCHING_FOLDERS", payload: true })
    axios.get(`${process.env.REACT_APP_URL}folders/`, {
      params: { module__name: moduleName, parent_isnull: parent, indication: selectedIndication.id },
      headers: { "Authorization": `JWT ${localStorage.token}` }
    }).then(response => {
      dispatch({ type: "SET_FOLDERS", payload: response.data })
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error with fetching folders...")
    }).finally(() => {
      dispatch({ type: "FETCHING_FOLDERS", payload: false })
    })
  }
}

/**
 * @summary - Gets sub-folders for the selected folder.
 * @param {string} folderId - ID of the folder to filter the sub-folders by.
 * @param {boolean} asFolder - Pushes the results into the folder list instead of sub-folders
 */
export const getSubFolders = (folderId, asFolder=false) => {
  return dispatch => {
    dispatch({ type: "FETCHING_SUB_FOLDERS", payload: true })
    axios.get(`${process.env.REACT_APP_URL}folders/`, {
      params: { parent: folderId },
      headers: { "Authorization": `JWT ${localStorage.token}` }
    }).then(response => {
      if (asFolder) {
        dispatch({ type: "SET_FOLDERS", payload: response.data })
      } else {
        dispatch({ type: "SET_SUB_FOLDERS", payload: response.data })
      }
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error with fetching sub-folders...")
    }).finally(() => {
      dispatch({ type: "FETCHING_SUB_FOLDERS", payload: false })
    })
  }
}

/**
 * @summary - Creates or Edits a folder based on the passed parameters.
 * @param {object} folder - Folder object that contains the data of the new folder.
 * @param {string=} method - POST or PATCH method.
 * @param {string} moduleName - Name of the module to filter the folders by.
 */
export const submitFolder = (folder, method="POST", moduleName) => {
  return (dispatch, useState) => {
    const { breadcrumbs } = useState().manageFolders
    requestFunctions.loadingAnimation("Creating folder...")
    axios({
      method,
      url: folder.url || `${process.env.REACT_APP_URL}folders/`,
      data: folder,
      headers: { "Authorization": `JWT ${localStorage.token}` }
    }).then(response => {
      requestFunctions.successAnimation("Folder successfully created!")
      if (breadcrumbs.length) {
        let parentBreadcrumb = breadcrumbs[breadcrumbs.length - 1]
        console.log(parentBreadcrumb)
        dispatch(getSubFolders(parentBreadcrumb.id, true))
      } else {
        dispatch(getFolders(moduleName))
      }
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error with creating the folder...")
    })
  }
}