import React from 'react'
import { connect } from 'react-redux'
import { Input, Button, Row, Col, Select, message } from 'antd'
import { getUsers } from '../../../reducers/manageUsers'
import axios from 'axios'

const Option = Select.Option

class ActivateUsers extends React.Component {

  state = {
    inactiveUsers: [],
    selectedUserEmail: "",
    selectedUserObject: "",
    firstName: "",
    lastName: "",
    role: "",
    countries: []
  }

  componentDidMount() {
    const inactiveUsers = this.props.users.filter(user => user.is_active === false)
    this.setState({ inactiveUsers })
  }

  handleFirstNameChange = (event) => {
    this.setState({firstName: event.target.value})
  }
  handleLastNameChange = (event) => {
    this.setState({lastName: event.target.value})
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const { firstName, lastName, role, selectedUserObject } = this.state
    const { countries } = this.props

    if (!firstName || !lastName || !role || !countries.length) {
      return message.error("Please fill out all of the required fields")
    } else {
      let countryUrls = []

      if(role === "Global") {
        countryUrls = [countries.find(countryObject => countryObject.name === "Global").url]
      } else {
        countryUrls = this.state.countries.map(country => {
          return countries.find(countryObject => {
            return countryObject.name === country
          }).url
        })
      }

      axios.patch(`${process.env.REACT_APP_URL}users/${selectedUserObject.id}/`, {
          first_name: firstName,
          last_name: lastName,
          is_active: !selectedUserObject.is_active,
          role: role,
          countries: countryUrls
        },
        { headers: {
          "Authorization": "JWT "+localStorage.getItem("token"),
        }}
      ).then(response => {
        const inactiveUsers = this.state.inactiveUsers.filter(user => user.email !== this.state.selectedUserEmail)
        message.success("User Activated Successfully")
        this.setState({
          inactiveUsers,
          selectedUserEmail: "",
          selectedUserObject: {},
          firstName: "",
          lastName: "",
          role: "",
          countries: []
        }, () => {
          this.props.getUsers()
        })
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          window.localStorage.clear()
          window.location = '/login'
        }
        message.error("There was an error activating the user. Please try again.")
      })
    }
  }

  handleSelectUser = (email) => {
    if (email) {
      let user = this.props.users.find(user => user.email === email)
      let userCountries = [...user.countries]

      if(userCountries.includes("Global")) {
        userCountries = userCountries.filter(country => country !== "Global")
      }

      this.setState({
        selectedUserObject: user,
        selectedUserEmail: email,
        firstName: user.first_name || "",
        lastName: user.last_name || "",
        role: user.role || "",
        countries: userCountries || []
      })
    } else {
      this.setState({
        selectedUserObject: {},
        selectedUserEmail: "",
        firstName: "",
        lastName: "",
        role: "",
        countries: []
      })
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  howManyPending = (pending) => {
    if (pending > 1) {
      return `${pending} users are pending activation:`
    } else if (pending === 1) {
      return `${pending} user is pending activation:`
    } else {
      return `No users are pending activation`
    }
  }

  render () {
    const { inactiveUsers, selectedUserEmail, firstName, lastName, role, countries } = this.state

    let countrieList = [...this.props.countries]
    countrieList = countrieList.filter(country => country.name !== "Global")

    return(
      <div className="card">
        <div>
          {this.howManyPending(inactiveUsers.length)}
          <br/>
          <br/>
          <Select
            style={{ maxWidth: 400, width: "100%" }}
            value={this.state.selectedUserEmail}
            onChange={this.handleSelectUser}
          >
            <Option value="">Select User</Option>
            {inactiveUsers.map(user => {
              return (
                <Option
                  key={user.id}
                  value={user.email}
                >
                  {user.email}
                </Option>
              )
            })
            }
          </Select>
        </div>
        <br/>
        <br/>
        <div>
          {selectedUserEmail ?
            <div className="user-form">
              <Row>
                <Col md={11} sm={24}>
                  <label>First Name</label>
                <br/>
                  <Input
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    onChange={this.handleChange}
                  />
                </Col>

                <Col md={2} sm={0}></Col>

                <Col md={11} sm={24}>
                  <label>Last Name</label>
                <br/>
                  <Input
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={11} sm={24}>
                  <label>Role</label>
                  <br/>
                  <Select
                    name="role"
                    style={{ width: '100%' }}
                    value={role}
                    onChange={(role) => this.setState({ role })}
                  >
                    <Option value="Global">Global</Option>
                    <Option value="Affiliate">Affiliate</Option>
                  </Select>
                </Col>

                <Col md={2} sm={0}></Col>

                <Col md={11} sm={24}>
                  <label>Country</label>
                  <br/>
                  <Select
                    name="country"
                    mode="multiple"
                    style={{ width: '100%' }}
                    value={ this.state.role !== "Global" ? countries : ["Global"]}
                    disabled={this.state.role === "Global"}
                    onChange={(countries) => this.setState({ countries })}
                  >
                    {countrieList.sort((a, b) => a.name.localeCompare(b.name))
                      .map(country => {
                        return (
                          <Option value={country.name} key={country.id}>
                            {country.name}
                          </Option>
                        )
                    })}
                  </Select>
                </Col>
              </Row>
              <br/>
              <Button
                className="user-acct-btn"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.manageUsers.user,
    users: state.manageUsers.users,
    countries: state.manageUsers.countries
  }
}

export default connect(mapStateToProps, { getUsers })(ActivateUsers);
