import React, {useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import { Row, Col, Select} from 'antd'
import LoadingAnimation from '../../components/LoadingAnimation'
import DataEntry from './components/DataEntry'
import CreateUpdateDataEntry from './components/CreateUpdateDataEntry'
import Pagination from "../../components/Pagination"
import {
  setFactLimit,
  getFacts,
  changeSearchTerm,
  changePage } from '../../reducers/manageFacts'
import { getCategoryTypesByModule } from "../../reducers/manageCategories"
import 'react-select/dist/react-select.css'
import './data_collection.css'
import FilterSideBar from "../../components/FilterSidebar";
// import FilterSideBar from '../../components/FilterSideBar'


export default function DataCollectionContainer() {
  // REACT ROUTER DOM
  const location = useLocation()
  // REDUX
  const dispatch = useDispatch()
  const facts = useSelector(state => state.manageFacts.facts)
  const factLimit = useSelector(state => state.manageFacts.limit)
  const currentPage = useSelector(state => state.manageFacts.currentPage)
  const fetchingFacts = useSelector(state => state.manageFacts.fetchingFacts)
  const totalFactCount = useSelector(state => state.manageFacts.totalFactCount)
  const searchTerm = useSelector(state => state.manageFacts.searchTerm)
  const fetchingCategoryTypes = useSelector(state => state.manageCategories.fetchingCategoryTypes)

  useEffect(() => {
    dispatch(getCategoryTypesByModule(location.pathname, () => dispatch(getFacts())))
  }, [])


  const handlePageChange = (selectedPage) => {
    dispatch(changePage(selectedPage))
    dispatch(getFacts())
  }

  const handleLimitChange = (limit) => {
    dispatch(setFactLimit(limit))
    dispatch(getFacts())
  }

  const handleSearch = (searchTerm) => {
    dispatch(changeSearchTerm(searchTerm))
    dispatch(getFacts())
  }

  let numberOfPages = 1
  if (factLimit) {
    numberOfPages = Math.ceil(totalFactCount / factLimit)
  }

  let relevantFacts = facts.map((fact, index) => {
    return <DataEntry key={`F-${index}`} fact={fact} />
  })

  return (
    <div id="factsheet">
      { fetchingCategoryTypes ?
      <div className='home-loader'>
        <LoadingAnimation />
      </div>
      :
        <React.Fragment>
          <Col xs={24} sm={24} md={7} lg={6}>
            {/*<FilterSideBar*/}
            {/*  categories={ categories }*/}
            {/*  fetchingCategories={ fetchingCategories }*/}
            {/*  searchTerm={ searchTerm }*/}
            {/*  handleSearch={ handleSearch }*/}
            {/*  changeSearchTerm={ changeSearchTerm }*/}
            {/*  selectedCategories={ selectedCategories }*/}
            {/*  handleCategorySelect={ changeCategories }*/}
            {/*/>*/}
            <FilterSideBar
              searchTerm={ searchTerm }
              handleSearch={ handleSearch }
              fetchingEntries={ fetchingFacts }
              getEntries={ () => handlePageChange(1) }
            />
          </Col>
          <Col xs={24} sm={24} md={17} lg={18}>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <Row>
                <Col span={8}>
                  <div className="limiter">
                    View&nbsp;
                    <Select
                      value={ factLimit === 0 ? 'All' : parseInt(factLimit,10) }
                      onChange={ handleLimitChange }
                    >
                      <Select.Option value={0}>All</Select.Option>
                      <Select.Option value={10}>10</Select.Option>
                      <Select.Option value={25}>25</Select.Option>
                      <Select.Option value={50}>50</Select.Option>
                    </Select> Entries
                  </div>
                </Col>
                <Col span={8}>
                  <Pagination
                    totalPages={numberOfPages}
                    currentPage={currentPage}
                    pageNeighbors={1}
                    handlePageChange={handlePageChange}
                  />
                </Col>
                <Col span={8}>
                  <CreateUpdateDataEntry mode="create" />
                </Col>
              </Row>
            </div>
              <div className="fact-table-container">
                <center>
                  <table className="factsheet-table">
                    <thead className="table-header">
                      <tr>
                        <th className="table-header outcome">
                          Outcome
                        </th>
                        <th className="table-header description">Description</th>
                        {/* <th>Input Value</th> */}
                        <th>Supporting Materials</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody style={{overflow:'auto'}}>
                      { fetchingFacts ?
                        <tr>
                          <td colSpan="5">
                            <LoadingAnimation />
                          </td>
                        </tr> : relevantFacts.length > 0 ? relevantFacts : <tr><td colSpan="5"><h4 style={{marginTop: "10px"}}>There is no data that matches the filters</h4></td></tr>
                      }
                    </tbody>
                  </table>
                </center>
              </div>
          </Col>
        </React.Fragment>
        }
      </div>
  )

}
