import React, {useState} from 'react'
import { Alert } from 'react-bootstrap'
import { Button, Form, Input } from 'antd'
import {useDispatch} from 'react-redux'
import { changePassword } from '../../reducers/manageUsers'
import './settings.css'


export default function SettingsContainer() {
  // REDUX
  const dispatch = useDispatch()

  // STATE
  const [oldPassword, setOldPassword] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!oldPassword || !password || !confirmPassword) {
      setError("Please fill out all required fields")
    } else if(password !== confirmPassword) {
      setError("Passwords must match")
    } else {
      setError("")
      dispatch(changePassword(oldPassword, password, confirmPassword))
    }
  }

  return (
    <div id="SettingsContainer">
      <div id="change-password-form">
        <center><h5>Change Password</h5></center>
        <br/>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          onSubmit={ handleSubmit }
        >
          <Form.Item
            label={<span>Old Password<span className="required">*</span></span>}
            name="oldPassword"
            rules={[{ required: true, message: 'Please input your old password!' }]}
          >
            <Input.Password
              name="oldPassword"
              value={ oldPassword }
              onChange={(e) => setOldPassword(e.target.value) }
            />
          </Form.Item>

          <Form.Item
            label={<span>New Password<span className="required">*</span></span>}
            autoComplete="new-password"
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password
              value={ password }
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label={<span>Confirm New Password<span className="required">*</span></span>}
            name="newPassword"
            autoComplete="new-password"
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password
              value={ confirmPassword }
              onChange={(e) => setConfirmPassword(e.target.value) }
            />
          </Form.Item>
          {error ? <Alert variant="danger"><center>{ error }</center></Alert> : null}
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button className="custom-btn" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}