import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { Button, Input, Select } from 'antd'
import { Alert, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { submitStudy } from '../../../reducers/manageStudies'
import { CustomDatePicker } from "../../../components/DatePickers";
import formatDateForServer from "../../../functions/formatDateForServer";


export default function AddStudy() {
  // REACT ROUTER DOM
  const history = useHistory()

  // REDUX
  const dispatch = useDispatch()
  const countryList = useSelector(state => state.manageUsers.countries)
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const indicationList = useSelector(state => state.manageIndications.indications)
  
  // STATE
  const [showModal, toggleModal] = useState(false)
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [status, setStatus] = useState("")
  const [countries, setCountries] = useState([])
  const [indications, setIndications] = useState([selectedIndication.url])
  const [internalLeadInvestigators, setInternalLeadInvestigators] = useState("")
  const [startDateRange, setStartDateRange] = useState([null, null])
  const [endDateRange, setEndDateRange] = useState([null, null])
  const [startStartDate, endStartDate] = startDateRange
  const [startEndDate, endEndDate] = endDateRange
  const [errorMessage, setErrorMessage] = useState("")

  const handleCancel = () => {
    toggleModal(false)
    setName("")
    setDescription("")
    setStatus("")
    setCountries([])
    setIndications([selectedIndication.url])
    setInternalLeadInvestigators("")
    setStartDateRange([null ,null])
    setEndDateRange([null ,null])
  }

  const handleSubmit = () => {
    setErrorMessage("")
    if (!name || !description || !status || !countries.length ||
        !indications.length || !startStartDate || !startEndDate || !endStartDate || !endEndDate) {
      setErrorMessage("Please fill out all required fields")
      return
    }

    if ((startStartDate > startEndDate) || (endStartDate > startEndDate)) {
      setErrorMessage("Start Date cannot be greater than the End Date")
    }
    
    let study = new FormData()
    study.append("name", name)
    study.append("description", description)
    study.append("status", status)
    study.append("internal_lead_investigators", internalLeadInvestigators)
    countries.forEach(c => study.append('countries', c))
    indications.forEach(i => study.append('indications', i))
    study.append("indications", selectedIndication.url)
    study.append("start_date", `${[formatDateForServer(startStartDate), formatDateForServer(endStartDate)]}`)
    study.append("end_date", `${[formatDateForServer(startEndDate), formatDateForServer(endEndDate)]}`)
    study.append("created_by", {})

    dispatch(submitStudy(study, history))
    handleCancel()
  }

  return (
    <div>
      <center>
        <Button className="new-study-btn" onClick={() => toggleModal(true)}>Add Study</Button>
      </center>
      <Modal 
        show={ showModal }
        onHide={ () => toggleModal(false) }
        size="lg"
        className="ant-modal-body-custom"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add Study
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="AddStudy">
          <Row className="field">
            <Col md={6} lg={6} xl={6}>
              <strong>Name: <span className='required'>*</span></strong>
              <Input
                value={ name }
                placeholder="Enter a name here..."
                name="name"
                onChange={ (e) => setName(e.target.value) }
              />
            </Col>
          </Row>
          <Row className="field">
            <Col md={6} lg={6} xl={6}>
              <strong>Description: <span className='required'>*</span></strong>
              <Input.TextArea
                value={ description }
                rows={4}
                placeholder="Enter a name here..."
                name="description"
                onChange={ (e) => setDescription(e.target.value) }
              />
            </Col>
          </Row>
          <Row className="field">
            <Col md={6} lg={6} xl={6}>
              <strong>Status: <span className='required'>*</span></strong>
              <Select
                name="status"
                style={ { width: "100%" } }
                value={ status }
                onChange={ setStatus }
              >
                <Select.Option value="">Select a status...</Select.Option>
                <Select.Option value="In Planning">In Planning</Select.Option>
                <Select.Option value="Ongoing">Ongoing</Select.Option>
                <Select.Option value="Completed">Completed</Select.Option>
                <Select.Option value="Canceled">Canceled</Select.Option>
              </Select>
            </Col>
          </Row>
          <Row className="field">
            <Col md={6} lg={6} xl={6}>
              <strong>Countries: <span className='required'>*</span></strong>
              <Select
                name="countries"
                value={ countries }
                style={ { width: "100%" } }
                mode="multiple"
                onChange={ setCountries }
                filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                placeholder="Select countries..."
              >
                { countryList.map(country => <Select.Option key={ country.id } value={ country.url }>{ country.name }</Select.Option>) }
              </Select>
            </Col>
          </Row>
          <Row className="field">
            <Col md={6} lg={6} xl={6}>
              <strong>Internal Lead Investigators:</strong>
              <Input
                placeholder="Enter internal lead investigators here..."
                value={ internalLeadInvestigators }
                name="internalLeadInvestigators"
                onChange={ (e) => setInternalLeadInvestigators(e.target.value) }
              />
            </Col>
          </Row>
          <Row className="field">
            <Col>
              <strong>Start Date: <span className='required'>*</span></strong>
              <CustomDatePicker
                selectsRange={ true }
                startDate={ startStartDate }
                endDate={ endStartDate }
                onChange={ (update) => setStartDateRange(update)}
                placeholderText="Enter start date range..."
              />
            </Col>
            <Col>
              <strong>End Date: <span className='required'>*</span></strong>
              <CustomDatePicker
                selectsRange={ true }
                startDate={ startEndDate }
                endDate={ endEndDate }
                onChange={ (update) => setEndDateRange(update)}
                placeholderText="Enter end date range..."
              />
            </Col>
          </Row>
          <Row className="field">
            <Col md={6} lg={6} xl={6}>
              <strong>Indications: <span className='required'>*</span></strong>
              <Select
                style={ { width: "100%" } }
                name="indications"
                value={ indications }
                mode="multiple"
                placeholder="Select indications..."
                onChange={ setIndications }
                filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              >
                { indicationList.map(indication => <Select.Option key={ indication.id } value={ indication.url }>{ indication.name }</Select.Option>) }
              </Select>
            </Col>
          </Row>
          <div>
            <span className="required">* - Marks required fields</span>
          </div>
          { errorMessage ?
            <div className="field" style={{ width: "50%", display: "block", marginTop: "25px" }}>
              <Alert variant="danger">
               <span className='required'>*</span> { errorMessage }
              </Alert>
            </div>
          : null }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleCancel }>Cancel</Button>
          <Button onClick={ handleSubmit }>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}