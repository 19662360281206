import React from 'react'
import AddUpdateKeyDate from "./AddUpdateKeyDate";
import {Button, Popconfirm} from "antd";
import {deleteKeyDate} from "../../../../reducers/manageStudies";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";


export default function KeyDate({ keyDate }) {
  // REDUX
  const dispatch = useDispatch()

  const formatDate = (date) => {
    const months = [
      "January", "February", "March",
      "April", "May", "June",
      "July", "August", "September",
      "October", "November", "December",
    ]
    let splitDate = date.split("T")[0].split("-")
    return `${months[splitDate[1] - 1]} ${splitDate[2]}, ${splitDate[0]}`
  }

  return (
    <tr key={ keyDate.id } className="study-key-date">
      <td>
        <div>{ formatDate(keyDate.date) }{ !!keyDate.end_date ? ` - ${ formatDate(keyDate.end_date)}` : null }</div>
      </td>
      <td>
        <div>{ keyDate.name }</div>
      </td>
      <td>
        <div>
          <AddUpdateKeyDate
            mode="update"
            keyDate={ keyDate }
          />
          <Popconfirm
            title="Are you sure you want to delete this key date?"
            placement="left"
            onConfirm={ () => dispatch(deleteKeyDate(keyDate.url)) }
            okText="Yes"
            cancelText="No"
            disabled={keyDate.name === "Start Date" || keyDate.name === "End Date" || !!keyDate.communication_target}
          >
            <Button
              className="delete-study-btn"
              disabled={keyDate.name === "Start Date" || keyDate.name === "End Date" || !!keyDate.communication_target}
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
      </td>
    </tr>
  )
}


KeyDate.propTypes = {
  keyDate: PropTypes.object.isRequired
}