import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, Checkbox, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import LoadingAnimation from '../../../../components/LoadingAnimation'
import { getListOfKVMs } from '../../../../reducers/manageKVM'
import { updateStudy } from '../../../../reducers/manageStudies'

export default function KVMAndEvidenceList(props) {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  const listOfKVMs = useSelector(state => state.manageKVM.listOfKVMs)
  const fetchingListOfKVMs = useSelector(state => state.manageKVM.fetchingListOfKVMs)
  
  // STATE
  let [showModal, toggleModal] = useState(false)
  let [searchTerm, setSearchTerm] = useState("")
  let [kvms, setKVMs] = useState(selectedStudy.key_value_messages.map(kvm => kvm.url) || [])


  useEffect(() => {
    if (showModal) {
      const indicationIDs = selectedStudy.indications.map(indication => {
        indication = indication.split("/")
        return indication[indication.length - 2]
      })
      dispatch(getListOfKVMs(indicationIDs))
    }
  }, [showModal])

  const handleCancel = () => {
    toggleModal(false)
    setSearchTerm("")
    setKVMs(selectedStudy.key_value_messages.map(kvm => kvm.url) || [])
  }

  const handleSearch = (e) => {
    e.preventDefault()
    const indicationIDs = selectedStudy.indications.map(indication => {
      indication = indication.split("/")
      return indication[indication.length - 2]
    })
    dispatch(getListOfKVMs(indicationIDs, searchTerm))
  }

  const handleKVMSelect = (url) => {
    let updatedList = [...kvms]
    if (updatedList.includes(url)) {
      updatedList = updatedList.filter(item => item !== url)
    } else {
      updatedList.push(url)
    }
    setKVMs(updatedList)
  }

  const handleSubmit = () => {
    let study = new FormData()
    study.append("id", selectedStudy.id)
    study.append("url", selectedStudy.url)
    if (kvms.length) {
      kvms.forEach(kvm => study.append("key_value_messages", kvm))
    } else {
      study.append("clear_key_value_messages", "true")
    }
    dispatch(updateStudy(study))
  }

  let kvmList = listOfKVMs.map((kvm, index) => {
    return (
      <tr
        key={index}
        className="kvm-label"
        onClick={(e) => {
          e.preventDefault()
          handleKVMSelect(kvm.url)
        }}
      >
        <td className="kvm-checkbox" style={{ textAlign: "center" }}>
          <Checkbox
            onChange={() => null}
            checked={ !!kvms.includes(kvm.url) }
            onClick={(e) => {
              e.stopPropagation()
              handleKVMSelect(kvm.url)
            }}
          />
        </td>
        <td className="kvm-id" style={{ textAlign: "center" }}>
          { kvm.parent_number ? `${ kvm.parent_number }.${ kvm.number }` : kvm.number }
        </td>
        <td>
        <label
          className="kvm-message"
          htmlFor={"kvm"+kvm.id}
        >
          {kvm.message}
        </label>
        </td>
      </tr>
    )
  })

  if (!listOfKVMs.length) {
    kvmList = (
      <tr className="kvm-label">
        <td></td>
        <td></td>
        <td style={{ maxWidth: '20vw', width: '100%', overflow: 'hidden' }}>
          There are no Key Value Messages for the selected indications...
        </td>
      </tr>
    )
  }

  return (
    <div>
      <Button className="new-study-btn" onClick={() => toggleModal(true)}>
        Update associated KVMs or Evidence Statements
      </Button>
      <Modal show={ showModal } onHide={ handleCancel } size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Key Value Messages and Evidence Statements
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <form onSubmit={ handleSearch }>
              <Input
                value={ searchTerm }
                onChange={ (e) => setSearchTerm(e.target.value) }
                placeholder="Search..."
              />
              </form>
          </center>
          <br/>
          <div id="kvm-evidence-statement-list">
            <table className="kvmtable" style={{width: "100%"}}>
              <colgroup>
                <col className="kvm-column" />
                <col className="kvm-column" />
              </colgroup>
              <thead className="table-header new-evidence">
                <tr>
                  <th className='kvm-checkbox-header' style={{ width:"50%" }}>Selected</th>
                  <th>Number</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                { fetchingListOfKVMs ? 
                  <tr className="kvm-label">
                    <td></td>
                    <td></td>
                    <td style={{ width: '100%', overflow: 'hidden', padding: "100px" }}>
                      <br/>
                      <LoadingAnimation />
                    </td>
                  </tr>
                  : kvmList }
              </tbody>
          </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleCancel }>Cancel</Button>
          <Button onClick={ handleSubmit }>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}