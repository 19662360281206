/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Button, Col, Dropdown, Menu, Popconfirm, Row} from "antd";
import {DownOutlined} from "@ant-design/icons";
import KVMEvidence from "./KVMEvidence";
import PopupPDFViewer from "../../../components/PopupPDFViewer";
import CreateUpdateKVM from "./CreateUpdateKVM";
import {useDispatch} from "react-redux";
import {deleteKVM} from "../../../reducers/manageKVM";


export default function EvidenceStatement({ evidenceStatement, index, isOrderEditable, message, snapshot, ...props }) {
  // REDUX
  const dispatch = useDispatch()

  const numberClass = String(evidenceStatement.id).length > 1 ? "two-digit" : "one-digit"
  const getItemStyle = (isDragging, draggableStyle) => ({
    border: "1px solid",
    borderColor: isDragging ? "var(--brand-color-1)" : "var(--brand-color-2)",
    marginLeft: "0",
    marginRight: "0",
    ...draggableStyle
  });

  const menu = (
    <Menu>
      {/* <Menu.Item key="0">
        <a onClick={(e) => e.preventDefault()}>View Supporting Studies</a>
      </Menu.Item> */}
      {/* <a onClick={(e) => e.preventDefault()}>View Supporting Evidence</a> */}
      { evidenceStatement.supporting_evidence.length ?
        <Menu.Item key="1">
          <KVMEvidence
            entry={ evidenceStatement }
            relevantEvidence={evidenceStatement.supporting_evidence}
            buttonText="View Associated Evidence"
            buttonClassName="kvm-view-evidence-btn"
            module="Key Value Messages"
            contentType="keyvaluemessage"
            action="view associated evidence"
          />
        </Menu.Item>
        :
        null
      }
      {evidenceStatement.document_url ?
        <Menu.Item key="3">
          <PopupPDFViewer
            page={0}
            file={evidenceStatement.document_url}
            title="Summary Slides"
            entry={evidenceStatement}
            module="Key Value Messages"
            contentType="keyvaluemessage"
            action="view summary slides"
            forClick={<a onClick={(e) => e.preventDefault()}>View Summary Slides</a>}
          />
        </Menu.Item>
      : null}
      <Menu.Item key="4">
        <CreateUpdateKVM kvm={ evidenceStatement } kvmParent={ message.url } mode="update" forClick={<a>Edit Evidence Statement</a>} />
      </Menu.Item>
      <Menu.Item key="5">
        <Popconfirm
          title="Are you sure to delete this evidence statement?"
          placement="left"
          onConfirm={ () => dispatch(deleteKVM(evidenceStatement, "evidence statement")) }
          okText="Yes"
          cancelText="No"
        >
          Delete Evidence Statement
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )

  return (
    <div
      className="message evidence-statement"
      ref={props.innerRef}
      {...props.provided.draggableProps}
      {...props.provided.dragHandleProps}
      style={getItemStyle(
        snapshot.isDragging,
        props.provided.draggableProps.style
      )}
    >
      <div>
        <Row>
          <Col xs={9} sm={4} md={3} lg={3}>
            <div className="number-container">
              <span className={"number " + numberClass}>
                {evidenceStatement.number !== "0" ?
                  `${evidenceStatement.parent_number}.${ evidenceStatement.number }`
                  :
                  null
                }
              </span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16}>
            <p className="text">
              {evidenceStatement.message}
            </p>
          </Col>
          <Col xs={24} sm={24} md={5} lg={5}>
            { !isOrderEditable ?
              <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                <Button className="kvm-show-btn ant-btn-background">
                  Show More <DownOutlined />
                </Button>
              </Dropdown>
              :
              null
            }
          </Col>
        </Row>
      </div>
      {props.provided.placeholder}
    </div>
  )
}