import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Row, Col } from 'antd'
import DataViewerReactiveSearch from './components/DataViewerReactiveSearch'
import TableOfContents from './components/TableOfContents'
import {getCSRTableOfContents} from '../../reducers/manageCSRContents'
import LoadingAnimation from "../../components/LoadingAnimation";
import './clinical_data_viewer.css'
import {useLocation} from "react-router-dom";


export default function DataViewerContainer() {
  // REACT ROUTER DOM
  const { search } = useLocation()

  // REDUX
  const dispatch = useDispatch()
  const fetchingCSRTableOfContents = useSelector(state => state.manageCSRContents.fetchingCSRTableOfContents)
  const fetchingSections = useSelector(state => state.manageSections.fetchingSections)
  const fetchingFigureTableSections = useSelector(state => state.manageSections.fetchingFigureTableSections)
  const fetchingFigureTableSubsections = useSelector(state => state.manageSections.fetchingFigureTableSubsections)

  // STATE
  const [expanded, setExpaded] = useState(true)

  useEffect(() => {
    const studyId = new URLSearchParams(search).get('studyId');
    if (studyId) {
      dispatch(getCSRTableOfContents(studyId))
    } else {
      dispatch(getCSRTableOfContents())
    }
  }, [])

  const isLoading = fetchingCSRTableOfContents || fetchingSections || fetchingFigureTableSections || fetchingFigureTableSubsections
  return (
    <div>
      { isLoading ?
        <div style={{ marginTop: "50%" }}>
          <LoadingAnimation />
        </div>
        :
        <div id="data-viewer">
          <Row>
            <Col
              xs={24} sm={24} md={6} lg={6}
              id="data-viewer-table-of-contents"
              className={expanded ? 'expanded' : null}
            >
              <TableOfContents
                expanded={expanded}
                handleExpand={() => setExpaded(!expanded)}
              />
            </Col>
            <Col xs={24} sm={24} md={17} lg={17}>
              <DataViewerReactiveSearch />
            </Col>
          </Row>
        </div>
      }
    </div>
  )
}