import axios from 'axios'
import {requestFunctions} from "../functions/requestFunctions";
import {sortingCallbacks} from "../functions/sortingCallbacks";


let defaultState = {
  searchTerm: "",
  currentKVM: -1,
  currentKVMGroup: "All",
  expandedKVM: -1,
  KVMs: [],
  listOfKVMs: [],
  editableKVMList: [],
  overarchingValuePropositions: [],
  fetchingOverarchingValuePropositions: false,
  regions: ["US", "EU"],
  selectedRegion: "US",
  fetchingKVMs: false,
  fetchingListOfKVMs: true,
  KVMGroups: [],
  fetchingKVMGroups: false,
  displayAllKVMs: false,
  isOrderEditable: false,
}


export default function KVM(state = defaultState, action){
  switch (action.type) {
    case 'FETCHING_KVMS':
      return { ...state, fetchingKVMs: action.payload }
    case 'FETCHING_OVERARCHING_VALUE_PROPOSITIONS':
      return { ...state, fetchingOverarchingValuePropositions: action.payload }
    case 'SET_KVMS':
      return { ...state, KVMs: action.payload, fetchingKVMs: false }
    case "SET_LIST_OF_KVMs":
      return { ...state, listOfKVMs: action.payload, fetchingListOfKVMs: false }
    case 'SET_EDITABLE_KVM_LIST':
      return { ...state, editableKVMList: action.payload }
    case 'SET_OVERARCHING_VALUE_PROPOSITIONS':
      return { ...state, overarchingValuePropositions: action.payload, fetchingOverarchingValuePropositions: false }
    case 'SET_CURRENT_KVM':
      return { ...state, currentKVM: action.payload }
    case 'FETCHING_KVM_GROUPS':
      return { ...state, fetchingKVMGroups: true }
    case 'TOGGLE_FETCHING_LIST_OF_KVMs':
      return { ...state, fetchingListOfKVMs: action.payload }
    case 'SET_KVM_GROUPS':
      return { ...state, KVMGroups: action.payload, fetchingKVMGroups: false }
    case 'SET_CURRENT_KVM_GROUP':
      return { ...state, currentKVMGroup: action.payload }
    case 'SET_EXPANDED_KVM':
      return { ...state, expandedKVM: action.payload }
    case 'SET_SELECTED_REGION':
      return { ...state, selectedRegion: action.payload }
    case "CHANGE_KVM_SEARCH_TERM":
      return { ...state, searchTerm: action.payload }
    case "TOGGLE_DISPLAY_ALL_KVMS":
      return { ...state, displayAllKVMs: action.payload }
    case "TOGGLE_IS_ORDER_EDITABLE":
      return { ...state, isOrderEditable: action.payload }
    case "UPDATE_KVM":
      const index = state.KVMs.findIndex(kvm => kvm.id === action.payload.id)
      return {
        ...state,
        KVMs: [...state.KVMs.slice(0, index), action.payload, ...state.KVMs.slice(index+1)]
      }
    case 'CLEAR_KVMS':
      return defaultState
    default:
		  return state
	}
}

export const setEditableKVMList = (kvms) => {
  return { type: "SET_EDITABLE_KVM_LIST", payload: kvms }
}

const fetchingKVMs = (fetching) => {
  return { type: "FETCHING_KVMS", payload: fetching }
}

const fetchingKVMGroups = () => {
  return { type: "FETCHING_KVM_GROUPS" }
}

const fetchingListOfKVMs = (fetching) => {
  return {type: 'TOGGLE_FETCHING_LIST_OF_KVMs', payload: fetching}
}

const updatedKVM = (data) => {
  return { type: "UPDATE_KVM", payload: data }
}

export const changeSearchTerm = (term) => {
  return { type: "CHANGE_KVM_SEARCH_TERM", payload: term }
}

export const toggleDisplayAllKVMs = (boolean) => {
  return { type: "TOGGLE_DISPLAY_ALL_KVMS", payload: boolean }
}

export const changeSelectedRegion = (region) => {
  return { type: "SET_SELECTED_REGION", payload: region }
}

export const toggleIsOrderEditable = (boolean) => {
  return { type: "TOGGLE_IS_ORDER_EDITABLE", payload: boolean }
}

export const updateKVM = (message) => {
  return (dispatch) => {
    axios({
      method: "PATCH",
      url: message.url,
      data: message,
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    }).then(response => {
      dispatch(updatedKVM(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in updating the key value message...")
    })
  }
}

export const saveKVMList = () => {
  return (dispatch, useState) => {
    requestFunctions.loadingAnimation("Saving...")
    const previousKVMs = [...useState().manageKVM.KVMs]
    const editableKVMList = useState().manageKVM.editableKVMList
    let allKVMs = editableKVMList.map((kvm, index) => {
      let updatedKvm = { ...kvm, number: index + 1 }
      updatedKvm.evidence_statements = updatedKvm.evidence_statements.map((es, es_index) => {
        return { ...es, number: es_index + 1, parent_number: index + 1 }
      })
      return updatedKvm
    })

    dispatch(setEditableKVMList(allKVMs))
    dispatch({type: 'SET_KVMS', payload: allKVMs})
    dispatch(toggleIsOrderEditable(false))

    axios.post(`${process.env.REACT_APP_URL}key-value-messages/edit_kvm_numbers/`, allKVMs, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token") }`}
    }).then(response => {
      requestFunctions.successAnimation("Saved!")
    }).catch(error => {
      console.log(error, error.response)
      dispatch({type: 'SET_KVMS', payload: previousKVMs})
      dispatch(setEditableKVMList(previousKVMs))
      requestFunctions.handleError(error, dispatch)
    })
  }
}

const gotKVMs = (data) => {
  return { type: 'SET_KVMS', payload: data }
}

export const getKVMs = (getParents = null) => {
  return (dispatch, useState) => {
    dispatch(fetchingKVMs(true))
    dispatch(toggleIsOrderEditable(false))

    const { searchTerm, selectedRegion } = useState().manageKVM
    const { selectedIndication } = useState().manageIndications
    const { selectedCategories } = useState().manageCategories
    const { modules } = useState().manageModules


    let filteredCategories = []
    const selectedModule = modules.find(module => module.route === "/keyvaluemessages")
    if (selectedModule) {
      if (selectedCategories[selectedModule.id]) {
        Object.keys(selectedCategories[selectedModule.id]).forEach(filterType => {
          filteredCategories.push(...selectedCategories[selectedModule.id][filterType])
        })
      }
      filteredCategories = filteredCategories.map(category => category.id)
    }

    let keyValueMessageFilters = {
      categories: filteredCategories,
      searchTerm,
      region: selectedRegion
    }

    if (getParents && searchTerm) getParents = null

    axios.get(`${process.env.REACT_APP_URL}key-value-messages/`,{
      params: { filters: keyValueMessageFilters, indication: selectedIndication.id, parents: getParents },
      headers: { "Authorization": "JWT "+localStorage.getItem("token") }
     })
    .then(response => {
      if (searchTerm) {
        dispatch(toggleDisplayAllKVMs(true))
      } else {
        dispatch(toggleDisplayAllKVMs(false))
      }

      let kvms = response.data
      kvms = kvms.map(kvm => {
        return {
          ...kvm,
          evidence_statements: kvm.evidence_statements.sort((a, b) => a.number - b.number )
        }
      }).sort(sortingCallbacks.KVMSort)

      dispatch(setEditableKVMList(kvms))
      dispatch(gotKVMs(kvms))
    })
    .catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting key value messages...")
      dispatch(gotKVMs([]))
    })
  }
}


/**
 * @summary Creates a new or updates an existing Key Value Message depending on what method was chosen.
 *
 * @param {FormData} kvm - KVM object to be sent to the backend.
 * @param {string} method - Request method (POST or PATCH).
 * @param {string} type - Can be "key value message" or "evidence statement" - used for the animation message.
 */
export const submitKVM = (kvm, method, type = "key value message") => {
  return dispatch => {
    requestFunctions.loadingAnimation(`${ method === "POST" ? "Adding" : "Modifying" } the ${ type }...`)
    axios({
      method: method,
      url: kvm.get('url') ? kvm.get('url') : `${process.env.REACT_APP_URL}key-value-messages/`,
      data: kvm,
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation(`Successfully ${ method === "POST" ? "added" : "modified" } the ${ type }`)
      dispatch(getKVMs(true))
    }).catch(error => {
      requestFunctions.handleError(
        error,
        dispatch,
        `There was an error in ${ method === "POST" ? "adding" : "modifying" } the ${ type }...`
      )
    })
  }
}

/**
 * @summary - Deletes a KVM.
 * @param {object} kvm - KVM object.
 * @param {string=} type - Can be "key value message" or "evidence statement" - used for the animation message.
 */
export const deleteKVM = (kvm, type = "key value message") => {
  return dispatch => {
    requestFunctions.loadingAnimation(`Deleting the ${type}...`)

    axios.delete(kvm.url, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation(`Successfully removed the ${type}`)
      dispatch(getKVMs(true))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, `There was an error in removing the ${type}...`)
    })
  }
}

const gotKVMGroups = (data) => {
  return { type: 'SET_KVM_GROUPS', payload: data }
}

export const getKVMGroups = (inputs) => {
  return (dispatch) => {
    dispatch(fetchingKVMGroups())
    axios.get(`${process.env.REACT_APP_URL}groups/`, {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    }).then(response => {
      dispatch(gotKVMGroups(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting key value message groups...")
      dispatch(gotKVMGroups([]))
    })
  }
}

export const setCurrentKVM = (input) => {
  return { type: 'SET_CURRENT_KVM', payload: input }
}

export const setCurrentKVMGroup = (input) => {
  return { type: 'SET_CURRENT_KVM_GROUP', payload: input }
}

export const setExpandedKVM = (input) => {
  return { type: 'SET_EXPANDED_KVM', payload: input }
}

export const getOverarchingValuePropositions = () => {
  return (dispatch, useState) => {
    const selectedRegion = useState().manageKVM.selectedRegion
    const { selectedIndication } = useState().manageIndications

    dispatch({ type: "FETCHING_OVERARCHING_VALUE_PROPOSITIONS", payload: true})
    dispatch(toggleIsOrderEditable(false))

    axios.get(`${process.env.REACT_APP_URL}overarching-value-propositions/`,{
      params: { indication: selectedIndication.id, region: selectedRegion },
      headers: { "Authorization": "JWT "+localStorage.getItem("token") }
     })
    .then(response => {
      dispatch({ type: "SET_OVERARCHING_VALUE_PROPOSITIONS", payload: response.data })
    })
    .catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting overarching value propositions...")
      dispatch({ type: "SET_OVERARCHING_VALUE_PROPOSITIONS", payload: [] })
    }).finally(() => {
      dispatch({ type: "FETCHING_OVERARCHING_VALUE_PROPOSITIONS", payload: false})
    })
  }
}

/**
 * @summary Get the list of supporting evidence.
 * @param {string[]=} indications - List of indication ids to filter kvms by.
 * @param {string=} searchTerm - Searcg term to filter kvms by.
 */
export const getListOfKVMs = (indications=null, searchTerm=null) => {
  return dispatch => {
    // GRABS KVMs FOR EVIDENCE CREATION
    dispatch(fetchingListOfKVMs(true))
    axios.get(`${process.env.REACT_APP_URL}key-value-messages/get_kvms_for_evidence/`,{
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}`},
      params: { indications, searchTerm }
    }).then(response => {
      const sortedList = response.data.sort(sortingCallbacks.KVMSort)
      dispatch({ type:"SET_LIST_OF_KVMs", payload: sortedList })
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting the list of KVMs...")
    }).finally(() => {
      dispatch(fetchingListOfKVMs(false))
    })
  }
}
