import React from 'react'

// Rechart components
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'


const ModifyChart = ({ clicks, modifyObjsArr, context }) => {

  const modifyObjMap = new Map()
  clicks.forEach(click => {
    const objectId = click.object_id.id
    if (modifyObjMap.has(objectId)) {
      modifyObjMap.set(objectId, modifyObjMap.get(objectId) + 1)
    } else {
      modifyObjMap.set(objectId, 1)
    }
  })

  const data = []
  modifyObjMap.forEach((count, objectId) => {
    const modifyObj = modifyObjsArr.find(obj => obj.id === objectId)
    if (!modifyObj) {
      return
    }

    let modifyObjSubstring
    switch(context) {
      case "Evidence":
        modifyObjSubstring = (modifyObj.full_reference.substring(0, 130) + "...")
        break
      case "Data Collection":
        modifyObjSubstring = (modifyObj.description)
        break
      case "Key Resources":
        modifyObjSubstring = modifyObj.title
        break
      default:
        break
    }

    data.push({ message: modifyObjSubstring, edits: count })
  })


  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart height={300} data={data}>
        <XAxis dataKey="message" tick={false} />
        <YAxis />
        <Tooltip contentStyle={{whiteSpace: "normal", maxWidth: "300px"}} />
        <Bar dataKey="edits" fill="rgba(34, 35, 92, 1)" maxBarSize={200} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ModifyChart