import React, {useState} from "react";
import axios from "axios"
import {useDispatch, useSelector} from "react-redux";
import {Button, /*Select*/} from "antd";
import {Alert, Col, Modal, Row} from "react-bootstrap";
import ReactQuill from "react-quill";
import {requestFunctions} from "../../../functions/requestFunctions";
import {getOverarchingValuePropositions} from "../../../reducers/manageKVM";


export default function CreateOverarchingValueProposition() {
  // REDUX
  const dispatch = useDispatch()
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const selectedRegion = useSelector(state => state.manageKVM.selectedRegion)

  // STATE
  const initialState = {
    visible: false,
    description: "",
    indications: [selectedIndication.url],
    document: null,
    errorMessage: ""
  }

  const [{ visible, description, indications, document, errorMessage }, setState] = useState(initialState)

  const handleSelect = (field, value) => setState(prevState => ({ ...prevState, [field]: value }))
  const handleCancel = () => setState({...initialState})

  const handleSubmit = () => {
    if (!description.length) {
      handleSelect("errorMessage", "Please fill out the description")
      return
    }
    handleSelect("errorMessage", "")

    const newOvp = new FormData()
    newOvp.append("description", description)
    newOvp.append("region", selectedRegion)
    newOvp.append("document", document)
    indications.forEach(indication => newOvp.append("indications", indication))
    newOvp.append("created_by", {})
    newOvp.append("modified_by", {})

    requestFunctions.loadingAnimation("Adding an overarching value proposition...")
    axios.post(`${process.env.REACT_APP_URL}overarching-value-propositions/`, newOvp, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully added an overarching value proposition")
      handleSelect("visible", false)
      dispatch(getOverarchingValuePropositions())
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error adding the overarching value proposition...")
      console.log(error)
    })
  }

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return (
    <div className="create-ovp">
      <Button
        onClick={() => handleSelect("visible", true)}
        className="new-ovp-button custom-btn"
      >
        Add Overarching Value Proposition
      </Button>
      <Modal show={ visible } onHide={ handleCancel } size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Overarching Value Proposition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <strong>File:</strong>*/}
          {/*    <br/>*/}
          {/*    <input*/}
          {/*      type="file"*/}
          {/*      id="file-upload"*/}
          {/*      onChange={ (e) => handleSelect("document", e.target.files[0]) }*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<br/>*/}
          <Row>
            <Col>
              <strong style={{ color: "var(--brand-color-1)" }}>Description: <span className='required'>*</span></strong>
              <ReactQuill modules={ modules } formats={ formats } value={ description } onChange={ (value) => handleSelect("description", value) } />
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <strong style={{ color: "var(--brand-color-2)" }}>Region: <span className='required'>*</span></strong>*/}
          {/*    <br/>*/}
          {/*    <Select value={region} onChange={(value) => handleSelect("region", value)} style={{ margin: "10px 0" }}>*/}
          {/*      <Select.Option value="US">US</Select.Option>*/}
          {/*      <Select.Option value="EU">EU</Select.Option>*/}
          {/*    </Select>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col>
              <span className="required">* - Marks required fields</span>
            </Col>
          </Row>
          { !!errorMessage.length && <div className="alert-wrapper"><Alert variant="danger">{ errorMessage }</Alert></div>}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleCancel }>Cancel</Button>
          <Button onClick={ handleSubmit }>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
