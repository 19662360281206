import React from 'react'

// Rechart components
import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts'
// import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts'


const ActionBreakdownChart = ({ clicks, context = "module" }) => {

  // colors for pie charts
  const COLORS = [
    "#a4401b",
    "#1ccac8",
    "#ced98e",
    "#6e9d0f",
    "#8d18ae",
    "#0381dd",
    "#3125c2",
    "#b604f6",
    "#cdc7fe",
    "#bb7466",
    "#2d5136",
    "#6bdd14",
    "#ca131e",
    "#840166",
    "#1a1965",
    "#cf079c",
    "#516770",
    "#d78305",
  ]

  /*
    The following code block formats the clicks prop into a data array for the reusable
    pie chart component, based on of the context prop of a single module or for all 
    aggregated modules
  */
  const data = []
  if (context === "module") {

    const clickActions = new Map()
    clicks.forEach(click => {
      const actionType = click.action.split(" - ")[0]
      if (clickActions.has(actionType)) {
        clickActions.set(actionType, clickActions.get(actionType) + 1)
      } else {
        clickActions.set(actionType, 1)
      }
    })
  
    clickActions.forEach((count, action) => {
      data.push({name: action, count: count})
    })

  } else if (context === "action") {

    const actionModules = new Map()
    clicks.forEach(click => {
      const actionModule = click.action.split(" - ")[1]
      if (actionModules.has(actionModule)) {
        actionModules.set(actionModule, actionModules.get(actionModule) + 1)
      } else {
        actionModules.set(actionModule, 1)
      }
    })
  
    actionModules.forEach((count, module) => {
      data.push({name: module, count: count})
    })

  } else if (context === "all") {

    const clickActions = new Map()
    clicks.forEach(click => {
      if (clickActions.has(click.action)) {
        clickActions.set(click.action, clickActions.get(click.action) + 1)
      } else {
        clickActions.set(click.action, 1)
      }
    })
  
    clickActions.forEach((count, action) => {
      // if (action.includes("Key Value Messages")) {
      //   action = action.replace("Key Value Messages", "Key Value Messages")
      // } else
      // if (action.includes("Evidence Library")) {
      //   action = action.replace("Evidence Library", "Scientific Publications")
      // }
      data.push({name: action, count: count})
    })

  }
  data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  /* 
    end code block 
  */

  const payload = data.map((entry, index) => {
    return {value: entry.name, type: "square", color: COLORS[index]}
  })


  // RENDER
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart height={300} data={data}>
        <XAxis dataKey="name" tick={false} />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Bar dataKey="count" maxBarSize={200}>
          {data.map((entry, index) => <Cell key={index} fill={COLORS[index]} />)}
        </Bar>
        <Legend
          payload={payload}
          layout="vertical"
          align="right"
          verticalAlign="middle"
          wrapperStyle={{right: -10}}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ActionBreakdownChart
