import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Row, Col} from 'antd'
import {Redirect} from 'react-router'
import ManageUsers from './components/ManageUsers'
import ActivateUsers from './components/ActivateUsers'
import UserProfile from './components/UserProfile'
import ActivityViewer from './components/ActivityViewer'
import {setMode} from '../../reducers/manageMode'
import {editUser, getUsers, getCountries} from '../../reducers/manageUsers'
import {getServes, getLogins, getAggregatedClicks} from '../../reducers/manageActivity'
import {getEducation} from '../../reducers/manageEducationalResources'
import {getKVMs} from '../../reducers/manageKVM'
import {getEvidence} from '../../reducers/manageSupportingEvidence'
import {getSections} from '../../reducers/manageSections'

import './user_profile.css'

class Account extends Component {

  state = {
    mode: "activity",
    loading: true,
  }

  componentDidMount() {
    // const {
    //   users,
    //   countries,
    //   logins,
    //   serves,
    //   aggregatedClicks,
    //   evidence,
    //   kvms,
    //   educations,
    //   sections,
    // } = this.props

    // if (
    //   (!users || users.length === 0) ||
    //   (!countries || countries.length === 0) ||
    //   (!logins || logins.length === 0) ||
    //   (!serves || users.length === 0) ||
    //   (!aggregatedClicks || Object.keys(aggregatedClicks).length === 0) ||
    //   (!evidence || evidence.length === 0) ||
    //   (!kvms || kvms.length === 0) ||
    //   (!educations || educations.length === 0) ||
    //   (!sections || sections.length === 0)
    //   ) {

    //   this.props.setMode('/loading')

    // } else {
      this.props.setMode('/account')
      this.setState({ loading: false })
    // }

    this.props.getServes()
    this.props.getLogins()
    this.props.getAggregatedClicks()
    this.props.getUsers()
    this.props.getCountries()
    this.props.getEducation()
    this.props.getKVMs()
    this.props.getEvidence()
    this.props.getSections()
  }

  componentDidUpdate(prevProps) {
    const {
      fetchingLogins,
      fetchingServes,
      fetchingAggregatedClicks,
      fetchingUsers,
      fetchingCountries,
      fetchingEvidence,
      fetchingKVMs,
      fetchingEducation,
      fetchingSections,
    } = this.props

    const previouslyLoading = prevProps.fetchingLogins || prevProps.fetchingServes || prevProps.fetchingAggregatedClicks || prevProps.fetchingUsers || prevProps.fetchingCountries || prevProps.fetchingEvidence || prevProps.fetchingKVMs || prevProps.fetchingEducation || prevProps.fetchingSections

    const isLoading = fetchingLogins || fetchingServes || fetchingAggregatedClicks || fetchingUsers || fetchingCountries || fetchingEvidence || fetchingKVMs || fetchingEducation || fetchingSections


    if (previouslyLoading === true && isLoading === false) {
      this.props.setMode("/account")
      this.setState({ loading: false })
    }
  }

  checkMode = () => {
    const { role } = this.props.user
    const { mode } = this.state

    if (role === "Global" && mode === "activity") {
      return <ActivityViewer />
    } else if (role === "Global" && mode === "managing") {
      return <ManageUsers />
    } else if (role === "Global" && mode === "activate") {
      return <ActivateUsers />
    } else if (this.state.mode === "profile" || role !== "Global") {
      return <UserProfile />
    }
  }

  render() {
    if (this.props.user && this.state.loading === false) {
      const profileCss = this.state.mode === "profile" ? " active" : " inactive"
      const activityCss = this.state.mode === "activity" ? " active" : " inactive"
      const managingCss = this.state.mode === "managing" ? " active" : " inactive"
      const activateCss = this.state.mode === "activate" ? " active" : " inactive"

      return (
        <div id="homepage">
          <Row>
            { this.props.user.role === "Global" ?
              <Col xs={24} sm={24} md={24} lg={4} className="sidebar">
                <center>
                  <Col xs={12} sm={12} md={6} lg={24}>
                    <div
                      onClick={() => {this.setState({mode: "profile"})}}
                      className={"group-button" + profileCss}
                    >
                      My Profile
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={24}>
                    <div
                      onClick={() => {this.setState({mode: "activity"})}}
                      className={"group-button" + activityCss}
                    >
                      User Activity
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={24}>
                    <div
                      onClick={() => {this.setState({mode: "managing"})}}
                      className={"group-button" + managingCss}
                    >
                      Manage Users
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={24}>
                    <div
                      onClick={() => {this.setState({mode: "activate"})}}
                      className={"group-button" + activateCss}
                    >
                      Activate Users
                    </div>
                  </Col>
                </center>
              </Col>
              :
              null
            }
            <Col xs={24} sm={24} md={24} lg={20}>
              <div
                className="brand-image-background full-view-height-with-padding"
              >
                <center>
                  {this.checkMode()}
                </center>
              </div>
            </Col>
          </Row>
        </div>
      )
    } else if (this.state.loading === true) {
      return (
				<div className='home-loader'>
          <div className="loader-wrapper"><span className="loader"></span></div>
          <p className='home-loader-text'>
            Loading User Account
          </p>
        </div>
			)
    }
    else {
      return (
        <Redirect to="/login" />
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.manageUsers.user,

    users: state.manageUsers.users,
    countries: state.manageUsers.countries,
    logins: state.manageActivity.logins,
    serves: state.manageActivity.serves,
    aggregatedClicks: state.manageActivity.aggregatedClicks,
    evidence: state.manageSupportingEvidence.evidence,
    kvms: state.manageKVM.KVMs,
    educations: state.manageEducation.education,
    sections: state.manageSections.sections,

    fetchingLogins: state.manageActivity.fetchingLogins,
    fetchingServes: state.manageActivity.fetchingServes,
    fetchingAggregatedClicks: state.manageActivity.fetchingAggregatedClicks,
    fetchingUsers: state.manageUsers.fetchingUsers,
    fetchingCountries: state.manageUsers.fetchingCountries,
    fetchingEvidence: state.manageSupportingEvidence.fetchingEvidence,
    fetchingKVMs: state.manageKVM.fetchingKVMs,
    fetchingEducation: state.manageEducation.fetchingEducation,
    fetchingSections: state.manageSections.fetchingSections,

    mode: state.manageMode.mode,
  }
}

const mapDispatchToProps = {
  editUser,
  getServes,
  getLogins,
  getUsers,
  getCountries,
  getAggregatedClicks,
  setMode,
  getEducation,
  getKVMs,
  getEvidence,
  getSections,
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
