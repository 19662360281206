import React from 'react'

// reusable chart components
import ActionBreakdownChart from '../reusable-charts/ActionBreakdownChart'
import NavigationChart from '../reusable-charts/NavigationChart'


const ClinicalDataCharts = ({ clicks }) => {

  return (
    <div>

      <h6>Breakdown of all Clinical Study Report tracking actions:</h6>
      <ActionBreakdownChart clicks={clicks} />
      <br />
      <hr />

      <h6>Navigation clicks to Clinical Study Report by date and count:</h6>
      <NavigationChart clicks={clicks} />
      
    </div>
  )
}

export default ClinicalDataCharts