import axios from 'axios'
import { submitClick } from './manageActivity'
import {requestFunctions} from "../functions/requestFunctions";

let defaultState = {
  facts: [],
  allFacts: [],
  outcomes: [],
  listOfEvidence: [],
  selectedCategory: "All",
  selectedCountries: ["Global"],
  fetchingFacts: false,
  fetchingListOfEvidence: true,
  totalFactCount: 0,
  currentPage: 1,
  limit: 10,
  searchTerm: ""
}

export default function manageFacts(state = defaultState, action) {
  switch(action.type){
    case 'TOGGLE_FETCHING_FACTS':
      return { ...state, fetchingFacts: action.payload }
    case 'TOGGLE_FETCHING_LIST_OF_EVIDENCE':
      return { ...state, fetchingListOfEvidence: action.payload }
    case 'SET_FACTS':
      return { ...state, facts: action.payload }
    case 'SET_ALL_FACTS':
      return { ...state, allFacts: action.payload }
    case 'SET_FACT_OUTCOMES':
      return { ...state, outcomes: action.payload }
    case 'SET_LIST_OF_EVIDENCE':
      return { ...state, listOfEvidence: action.payload }
    case 'CHANGE_FACTS_PAGE':
      return { ...state, currentPage: action.payload }
    case 'CHANGE_CATEGORY':
      return { ...state, selectedCategory: action.payload }
    case 'CHANGE_COUNTRIES':
      return { ...state, selectedCountries: action.payload }
    case "CHANGE_FACT_SEARCH_TERM":
      return { ...state, searchTerm: action.payload, currentPage: 1 }
    case 'SET_TOTAL_FACT_COUNT':
      return { ...state, totalFactCount: action.payload }
    case "SET_FACT_LIMIT":
      return { ...state, limit: action.payload, currentPage: 1 }
    case 'CLEAR_FACTS':
      return defaultState
    default:
		  return state
	}
}

const fetchingFacts = (fetching) => {
  return {type: 'TOGGLE_FETCHING_FACTS', payload: fetching}
}

const toggleFetchingListOfEvidence = (fetching) => {
  return {type: 'TOGGLE_FETCHING_LIST_OF_EVIDENCE', payload: fetching}
}

const gotFacts = (data) => {
  return {type: 'SET_FACTS', payload: data}
}

const gotAllFacts = (data) => {
  return { type: "SET_ALL_FACTS", payload: data }
}

export const changeSelectedCategory = (category) => {
  return { type: "CHANGE_CATEGORY", payload: category }
}

export const changeSelectedCountries = (countries) => {
  return { type: "CHANGE_COUNTRIES", payload: countries }
}

export const changePage = (page) => {
  return { type: "CHANGE_FACTS_PAGE", payload: page }
}

export const changeSearchTerm = (term) => {
  return { type: "CHANGE_FACT_SEARCH_TERM", payload: term }
}

export const setFactLimit = (limit) => {
  return { type: "SET_FACT_LIMIT", payload: limit }
}

/**
 * @summary Get all unique existing fact outcomes.
 */
export const getOutcomes = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_URL}facts/get_all_outcomes/`,{
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}`}
    }).then(response => {
      dispatch({ type: "SET_FACT_OUTCOMES", payload: response.data.outcomes })
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting outcomes...")
    })
  }
}

/**
 * @summary Get the list of supporting evidence.
 * @param {string[]=} indications - List of indication IDs to filter evidence by.
 * @param {string=} searchTerm - Searcg term to filter evidence by.
 */
export const getListOfEvidence = (indications=null, searchTerm=null) => {
  return dispatch => {
    // GRABS EVIDENCE FOR THE 3RD STEP OF FACT CREATION
    dispatch(toggleFetchingListOfEvidence(true))
    axios.get(`${process.env.REACT_APP_URL}supporting-evidences/get_evidence_for_facts/`,{
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}`},
      params: { indications, searchTerm }
    }).then(response => {
      dispatch({ type:"SET_LIST_OF_EVIDENCE", payload: response.data })
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting the list of evidence...")
    }).finally(() => {
      dispatch(toggleFetchingListOfEvidence(false))
    })
  }
}

/**
 * @summary Gets facts for the currently selected indication.
 * @param {number=} limit - Number of facts to get from the backend.
 * @param {object=} filters - Object that contains filters.
 * @param {number=} page - Page number.
 *
 */
export const getFacts = () => {
  return (dispatch, useState) => {
    dispatch(fetchingFacts(true))
    const { selectedIndication } = useState().manageIndications
    const { limit, currentPage, searchTerm } = useState().manageFacts
    const { modules } = useState().manageModules
    const { selectedCategories } = useState().manageCategories
    const { countries } = useState().manageGlobalFilters
    // OFFSET IS CALCULATED AND SENT TO THE BACKEND
    // INSTEAD OF THE PAGE NUMBER BECAUSE DJANGO's
    // USING LimitOffsetPagination
    let offset = limit * (currentPage - 1)

    let filteredCategories = []
    const selectedModule = modules.find(module => module.name === "Data Collection")
    if (selectedModule) {
      if (selectedCategories[selectedModule.id]) {
        Object.keys(selectedCategories[selectedModule.id]).forEach(filterType => {
          filteredCategories.push(...selectedCategories[selectedModule.id][filterType])
        })
      }
      filteredCategories = filteredCategories.map(category => category.id)
    }

    let filters = { searchTerm, categories: filteredCategories, countries }

    axios.get(process.env.REACT_APP_URL+"facts/",{
      params: { limit, filters, offset, indication: selectedIndication.id },
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      if(Object.keys(response.data).includes("results")) {
        dispatch(getOutcomes())
        dispatch(getListOfEvidence())
        dispatch({ type: "SET_TOTAL_FACT_COUNT", payload: response.data.count})
        dispatch(gotFacts(response.data.results))  
      } else {
        dispatch(gotFacts(response.data))
      }
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data")
    }).finally(() => {
      dispatch(fetchingFacts(false))
    })
  }
}

/**
 * @summary Gets all facts without pagination for the currently selected indication.
 */
export const getAllFacts = () => {
  return (dispatch, useState) => {
    dispatch(fetchingFacts(true))
    const { selectedIndication } = useState().manageIndications
    axios.get(process.env.REACT_APP_URL+"facts/",{
      params: { indication: selectedIndication.id },
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      dispatch(gotAllFacts(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data")
    }).finally(() => {
      dispatch(fetchingFacts(false))
    })
  }
}

/**
 * @summary Edits a fact.
 * @param {FormData} fact - A Form Data object that contains the fact with updated values.
 */
export const editFact = (fact) => {
  return (dispatch, useState) => {
    requestFunctions.loadingAnimation("Editing a fact...")
    const { limit, currentPage, searchTerm, selectedCountries } = useState().manageFacts
    const { selectedFactCategories } = useState().manageCategories

    axios.patch(fact.get("url"), fact, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully updated data")
      dispatch(submitClick({content_type: "fact", module: "Data Collection", object_id: response.data.id, action: "modify" }))
      dispatch(getOutcomes())
      dispatch(getListOfEvidence())
      const filters = { 
        countries: selectedCountries,
        categories: selectedFactCategories,
        searchTerm: searchTerm
      }
      dispatch(getFacts(limit, filters, currentPage))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting data...")
    })
  }
}

/**
 * @summary Creates a fact.
 * @param {FormData} fact - A Form Data object that contains the fact data.
 */
export const submitFact = (fact) => {
  return (dispatch, useState) => {
    requestFunctions.loadingAnimation("Submitting data...")
    const { limit, currentPage, searchTerm, selectedCountries } = useState().manageFacts
    const { selectedFactCategories } = useState().manageCategories

    axios.post(process.env.REACT_APP_URL+"facts/", fact, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully added data")
      dispatch(submitClick({content_type: "fact", module: "Data Collection", object_id: response.data.id, action: "create" }))
      dispatch(getOutcomes())
      dispatch(getListOfEvidence())
      const filters = { 
        countries: selectedCountries,
        categories: selectedFactCategories,
        searchTerm: searchTerm
      }
      dispatch(getFacts(limit, filters, currentPage))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in adding data...")
    })
  }
}

/**
 * @summary Deletes a fact.
 * @param {object} fact - Fact object that is meant to be deleted.
 */
export const deleteFact = (fact) => {
  return (dispatch, useState) => {
    requestFunctions.loadingAnimation("Deleting data...")

    const { limit, searchTerm } = useState().manageFacts
    const { selectedFactCategories } = useState().manageCategories
    const { countries } = useState().manageGlobalFilters

    const filters = { 
      countries: countries,
      categories: selectedFactCategories,
      searchTerm: searchTerm
    }
  
    axios.delete(fact.url, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully deleted data")
      dispatch(changePage(1))
      dispatch(getFacts(limit, filters, 1))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting data...")
    })
  }
}