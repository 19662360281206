import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Input } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import AddStudy from './AddStudy'
import { setStudyPage, setStudySearchTerm, getStudies } from '../../../reducers/manageStudies'
import Pagination from '../../../components/Pagination'
import LoadingAnimation from '../../../components/LoadingAnimation'

export default function StudyList(props) {
  // REACT ROUTER DOM
  const history = useHistory()

  // REDUX
  const dispatch = useDispatch()
  const fetchingStudies = useSelector(state => state.manageStudies.fetchingStudies)
  const studies = useSelector(state => state.manageStudies.studies)
  const studySearchTerm = useSelector(state => state.manageStudies.studySearchTerm)
  const studyLimit = useSelector(state => state.manageStudies.studyLimit)
  const totalStudyCount = useSelector(state => state.manageStudies.totalStudyCount)
  const studyPage = useSelector(state => state.manageStudies.studyPage)
  
  const handleNavigate = (e, study, route) => {
    e.stopPropagation()
    history.replace(`/studycenter/studies/${study.id}/${route}`)
  }

  const handlePageChange = (page) => {
    dispatch(setStudyPage(page))
    dispatch(getStudies(studyLimit, page))
  }

  const handleSearch = (value) => {
    if (!value) {
      dispatch(setStudySearchTerm(""))
    } else {
      dispatch(setStudySearchTerm(value))
    }
    dispatch(setStudyPage(1))
    dispatch(getStudies())
  }

  let numberOfPages = 1
  if (studyLimit) {
    numberOfPages = Math.ceil(totalStudyCount / studyLimit)
  }

  return (
    <div id="StudyList">
      <h4>Studies</h4>
      <Input.Search
        placeholder="Search"
        className="study-search-input"
        defaultValue={ studySearchTerm }
        allowClear
        onSearch={ handleSearch }
      />
      <br/>

      { fetchingStudies ?
        <div style={{ marginTop: "300px" }}>
          <LoadingAnimation />
        </div>
        :
        studies.map(study => {
          return (
            <div key={ study.id } onClick={() => history.replace(`/studycenter/studies/${study.id}/basicinfo`) }>
              <div className={`study ${ study.id.toString() === props.match.params.id ? "active" : null }`}>{ study.name }</div>
              { study.id.toString() === props.match.params.id ? 
                <div onClick={(e) => e.stopPropagation()}>
                  <div className={ `study-route ${props.match.params.tab === "basicinfo" ? "active" : null}` } onClick={(e) => handleNavigate(e, study, "basicinfo")}>Basic Info</div>
                  <div className={ `study-route ${props.match.params.tab === "populationcharacteristics" ? "active" : null}` } onClick={(e) => handleNavigate(e, study, "populationcharacteristics")}>Population Characteristics</div>
                  <div className={ `study-route ${props.match.params.tab === "outcomes" ? "active" : null}` } onClick={(e) => handleNavigate(e, study, "outcomes")}>Outcomes</div>
                  <div className={ `study-route ${props.match.params.tab === "documents" ? "active" : null}` } onClick={(e) => handleNavigate(e, study, "documents")}>
                    Key Documents&nbsp;
                    { (
                      study.has_protocol_document &&
                      study.has_irbs_document &&
                      study.has_synopsis_document
                      ) ? null : <ExclamationCircleOutlined style={{ color: "red", verticalAlign: "0.125em" }} />}
                  </div>
                  <div className={ `study-route ${props.match.params.tab === "kvmsandevidencestatements" ? "active" : null}` } onClick={(e) => handleNavigate(e, study, "kvmsandevidencestatements")}>KVMs & Evidence Statements</div>
                  <div className={ `study-route ${props.match.params.tab === "resultsdata" ? "active" : null}` } onClick={(e) => handleNavigate(e, study, "resultsdata")}>Results/Data</div>
                  <div className={ `study-route ${props.match.params.tab === "keydates" ? "active" : null}` } onClick={(e) => handleNavigate(e, study, "keydates")}>Key Dates</div>
                  <div className={ `study-route ${props.match.params.tab === "stakeholdertargets" ? "active" : null}` } onClick={(e) => handleNavigate(e, study, "stakeholdertargets")}>Stakeholder Targets</div>
                  <div className={ `study-route ${props.match.params.tab === "communicationtargets" ? "active" : null}` } onClick={(e) => handleNavigate(e, study, "communicationtargets")}>Communication Targets</div>
                </div>
              : null }
            </div>
          )
        }) }
      <br/>
      <center>
        <AddStudy />
      </center>
      <br/>
      <center>
        <Pagination 
          totalPages={ numberOfPages }
          currentPage={ studyPage }
          pageNeighbors={ 1 }
          handlePageChange={ handlePageChange }
        />
      </center>
    </div>
  )
}