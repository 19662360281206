import React, {useState} from 'react'
import ReactQuill from "react-quill";
import { Col, Row } from 'react-bootstrap'
import {updateStudy} from "../../../../reducers/manageStudies";
import {useDispatch, useSelector} from "react-redux";
import { Button } from 'antd'


export default function InclusionExclusionCriteria() {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)

  // STATE
  const [isCriteriaEditable, setIsCriteriaEditable] = useState(false)
  const [inclusionCriteria, setInclusionCriteria] = useState(selectedStudy.inclusion_criteria)
  const [exclusionCriteria, setExclusionCriteria] = useState(selectedStudy.exclusion_criteria)
  const handleCancel = () => {
    setIsCriteriaEditable(false)
    setInclusionCriteria(selectedStudy.inclusion_criteria)
    setExclusionCriteria(selectedStudy.exclusion_criteria)
  }

  const handleSubmit = () => {
    let newStudy = new FormData()

    newStudy.append("url", selectedStudy.url)
    newStudy.append("id", selectedStudy.id)
    newStudy.append("inclusion_criteria", inclusionCriteria)
    newStudy.append("exclusion_criteria", exclusionCriteria)

    dispatch(updateStudy(newStudy))
    setIsCriteriaEditable(false)
  }

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]
  return (
    <div>
      <Row>
        <Col>
          <h5>Inclusion/Exclusion Criteria</h5>
        </Col>
        <Col>
          { isCriteriaEditable ?
            <React.Fragment>
              <Button className="new-study-btn" onClick={ handleCancel }>Cancel</Button>
              <Button className="new-study-btn" onClick={ handleSubmit }>Submit</Button>

            </React.Fragment>
              :
            <Button className="new-study-btn population-btns" onClick={ () => setIsCriteriaEditable(true) }>Update Criteria</Button>
          }
        </Col>
      </Row>
      <div style={{ marginLeft: "25px" }}>
        <h6><strong>Inclusion Criteria</strong></h6>
        {/*{ isInclusionCriteriaEditable ?*/}
        { isCriteriaEditable ?
          <ReactQuill
            modules={modules}
            formats={formats}
            value={inclusionCriteria}
            onChange={setInclusionCriteria}
            placeholder="Input Inclusion Criteria..."
          />
          :
          <p
            className="criteria-text ql-editor"
            dangerouslySetInnerHTML={
              {__html: selectedStudy.inclusion_criteria || "N/A"}
            }
          ></p>
        }
        <h6><strong>Exclusion Criteria</strong></h6>
        {isCriteriaEditable ?
          <ReactQuill
            modules={ modules }
            formats={ formats }
            value={ exclusionCriteria }
            onChange={ setExclusionCriteria }
            placeholder="Input Exclusion Criteria..."
          />
            :
          <p
            className="criteria-text ql-editor"
            dangerouslySetInnerHTML={
              {__html: selectedStudy.exclusion_criteria || "N/A" }
            }
          ></p>
        }
      </div>
    </div>
  )
}