const defaultState = {
  country: "Global",
  category: "All"
}

export default function manageFactSheet(state = defaultState, action){
  switch(action.type){
    case 'SET_CATEGORY':
      return Object.assign({}, state, {category: action.payload})
    case 'SET_COUNTRY':
      return Object.assign({}, state, {country: action.payload})
    case 'CLEAR_FACT_SHEET':
      return defaultState
    default:
		  return state
	}
}

export const setCategory = (category) => {
  return {type: "SET_CATEGORY", payload: category}
}

export const setCountry = (country) => {
  return {type: "SET_COUNTRY", payload: country}
}
