import React from 'react'
import {Select} from "antd";
import { Col } from 'react-bootstrap'


export default function CategoryTypeFields(props) {
  return (
    <React.Fragment>
      { props.categoryTypes.map(categoryType => {
        if (
          (categoryType.type === "RADIO" && categoryType.categories.length > 1)
          || (categoryType.type === "CHECKBOX" && categoryType.categories.length)
        ) {
          return (
            <Col key={ categoryType.id } className="field" id="edit-evidence-categories" sm={6} md={6} lg={6}>
              <p>{ categoryType.name }:</p>
              <Select
                style={ { minWidth: "100%" } }
                value={ props.selectedCategories[categoryType.id] }
                mode="multiple"
                placeholder={ `Please select a ${categoryType.name}...` }
                onChange={ (value) => props.handleChangeCategories(categoryType.id, value) }
                filterOption={ (inputValue, option) =>  {
                  return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }}
              >
                { categoryType.categories.sort((a, b) => a.order - b.order).map(category => {
                  return (
                    <Select.Option key={ category.id } value={ category.url }>{ category.name }</Select.Option>
                  )
                }) }
              </Select>
            </Col>
          )
        } else {
          return null
        }
      }) }
    </React.Fragment>
  )
}