/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import { Button/*, Select*/, Col, Row, Popover/*, Collapse*/ } from 'antd'
import PopupPDFViewer from '../../../components/PopupPDFViewer'
import MediaDownloader from '../../../components/MediaDownloader'
import { setMode } from '../../../reducers/manageMode'
import { connect } from 'react-redux'


class SearchPDFs extends Component {

  state = {
    // selectedPage: this.props.pdf.pages[0].page,
    isClicked: false,
    visible: false,
    concatReference: '',
    contexts: []
  }

  hide = (e) => {
    e.preventDefault()
    this.setState({ visible: false })
  }

  handleVisibleChange = (visible) => this.setState({ visible })

  handleClick = () => {
    this.setState({ isClicked: !this.state.isClicked }, () => {
      if (this.state.isClicked && this.props.searchTerm) {
        /////// THIS HANDLES THE SEARCH TERM HIGHLIGHTING WITHIN THE CONTEXT
        let results = document.querySelectorAll('.context-td')

        for (let i = 0; i < results.length; i++) {
          // debugger
          let capitalizedSearchTerm = this.props.searchTerm[0].toUpperCase() + this.props.searchTerm.slice(1)
          let upCaseSearchTerm = this.props.searchTerm.toUpperCase()
          let lowerCaseSearchTerm = this.props.searchTerm.toLowerCase()

          if (results[i].innerHTML.includes(capitalizedSearchTerm)) {
            results[i].innerHTML = results[i].innerHTML.replace(capitalizedSearchTerm,`<span class='marked'>${capitalizedSearchTerm}</span>`)

          } else if (results[i].innerHTML.includes(upCaseSearchTerm)) {
            results[i].innerHTML = results[i].innerHTML.replace(upCaseSearchTerm,`<span class='marked'>${upCaseSearchTerm}</span>`)

           } else if (results[i].innerHTML.includes(lowerCaseSearchTerm)) {
            results[i].innerHTML = results[i].innerHTML.replace(lowerCaseSearchTerm,`<span class='marked'>${lowerCaseSearchTerm}</span>`)
          }
        }
      }
    })
  }

  // handleChange = (value) => {
  //   this.setState({selectedPage: value})
  // }

  handlePageClick = (event) => {
    event.preventDefault()
    return (
      <PopupPDFViewer
        file={this.props.pdf.content_object.document_url}
        page={event.target.innerHTML}
      />
    )
  }

  concatSentence = (sentence) => {
    if (sentence) {
      if (sentence.length > 100) {
        sentence = sentence.slice(0,99)
        return sentence + '...'
      } else {
        return sentence
      }
    }
  }

  getAllContexts = () => {
    let contexts = []
    for (let i = 0; i < this.props.pdf.pages.length; i++) {
      for (let j = 0; j < this.props.pdf.pages[i].positions.length; j++) {
        contexts.push({page: this.props.pdf.pages[i].page, context: this.props.pdf.pages[i].positions[j].context})
      }
    }
    this.setState({contexts: [...this.state.contexts,contexts]})
  }

  componentDidMount() {
    this.getAllContexts()
    this.setState({
      concatReference: this.concatSentence(this.props.pdf.content_object.full_reference)
    })
  }

  gettingTheImage = () => {
    fetch(`${this.props.pdf.content_object.thumbnail_url}`, {
      method:'GET',
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    })
    .then(res => res.blob())
    .then(response => {
      if (response.type === "text/html") {
        if (document.getElementById(`${this.props.pdf.name}`)) {
          let img = document.getElementById(`${this.props.pdf.name}`)
          img.src = require("../../../static/images/no-img-available.jpg")
        }
      } else {
        let imageUrl = URL.createObjectURL(response);
        if (imageUrl && document.getElementById(`${this.props.pdf.name}`)) {
          let img = document.getElementById(`${this.props.pdf.name}`)
          img.src = imageUrl
        }
      }
    })
  }

  render() {
    return (
      <div className="search-pdfs">
        <Row>
          <Col sm={24} md={12} lg={5}>
            <center>
              <div className='pdf-thumbnail'>
                <Popover
                  content={
                    <div style={{textAlign:'center'}}>
                      <PopupPDFViewer
                        file={this.props.pdf.content_object.document_url}
                        forClick={<Button onClick={this.hide}>View Document</Button>}
                      />
                      <br/>
                      <br/>
                      <a
                        href={this.props.pdf.content_object.document_url}
                        target='_blank'
                        rel="noopener noreferrer"
                        download
                      >
                        <Button>
                          Download Document
                        </Button>
                      </a>
                      <br/>
                      <br/>
                      <a onClick={this.hide}>Close</a>
                    </div>
                  }
                  title={this.props.pdf.content_object.filename.replace(/.pdf/,'')}
                  trigger="click"
                  visible={this.state.visible}
                  onVisibleChange={this.handleVisibleChange}
                >
                  <img
                    id={this.props.pdf.name}
                    src={this.gettingTheImage()}
                    width='140px'
                    alt='thumbnail'
                    style={{cursor:'pointer'}}
                  />
                </Popover>
              </div>
            </center>
            <Row style={{marginTop: '15px'}}>
              <center>
                <Button
                  onClick={this.handleClick}
                  style={{cursor:'pointer'}}
                >
                  {!this.state.isClicked ? 'Show Contexts' : 'Hide Contexts'}
                </Button>
              </center>
            </Row>
          </Col>

          <Col sm={24} md={12} lg={13}>
            <Row>
              <PopupPDFViewer
                file={this.props.pdf.content_object.document_url}
                forClick={
                  <h2>
                  <a onClick={(e) => e.preventDefault()}>
                    {this.props.pdf.content_object.filename.replace(/.pdf/,'')}
                  </a>
                  </h2>
                }
              />
            </Row>

            <Row style={{marginTop: '10px'}}>
              <strong style={{fontSize: '9pt'}}>Reference:</strong>
            </Row>

            <Row>
              {this.state.concatReference ?
                <Popover
                  content={<p className='popover-paragraph'>{this.props.pdf.content_object.full_reference}</p>}
                  title='Reference'
                >
                  <a onClick={(e) => e.preventDefault()}>{this.state.concatReference}</a>
                </Popover>
                :
                <p>N/A</p>
              }
            </Row>

            <Row style={{marginTop: '10px'}}>
              <strong style={{fontSize: '9pt'}}>First Context:</strong>
            </Row>

            <Row>
              <p onClick={(e) => e.preventDefault()}>hi{this.props.pdf.pages[0].positions[0].context}</p>
            </Row>
            {/* <Row style={{marginTop: '15px'}}>
              <Button onClick={this.handleClick} style={{cursor:'pointer'}}>{!this.state.isClicked ? 'Show Contexts...' : 'Hide Contexts...'}</Button>
            </Row> */}
          </Col>

          <Col sm={0} md={0} lg={1}></Col>

          <Col sm={24} md={24} lg={5}>
              <div className="vertical-center-col">
                <Row style={{marginTop: '10px', textAlign: 'center'}}>
                    <strong>Total Hits:</strong> {this.props.pdf.PDF_hits}
                </Row>
                <center>
                <Row style={{marginTop: '10px'}}>
                  <MediaDownloader
                    file={this.props.pdf.content_object.document_url}
                    type='button'
                    module="Search"
                    filename={this.props.pdf.content_object.filename}
                  />
                </Row>
                </center>
              </div>

          </Col>
        </Row>
        {this.state.isClicked ?
          <div id='context-div'>

            <Row>
              <Col span={1}></Col>
              <Col span={22} style={{overflowX: 'auto'}}>
                {/*
                  <Select onChange={this.handleChange} defaultValue={this.props.pdf.pages[0].page} style={{width:'100px'}}>
                  {this.props.pdf.pages.map(page => {
                    return (
                      <Option key={page.page_content_length} value={page.page}>{page.page}</Option>
                    )})}
                  </Select>
                  <PopupPDFViewer file={this.props.pdf.content_object.document_url} page={this.state.selectedPage} forClick={<Button>View Page</Button>} />
                */}
                <table className="table">
                  <thead className="table-header">
                    <tr>
                      <th>Context &nbsp;|&nbsp; {this.props.pdf.content_object.filename.replace(/.pdf/,'')}</th>
                      <th>Page of occurrence</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.state.contexts[0] ?
                      this.state.contexts[0].map(context => {
                        return (
                          <tr className="result-row" key={context.context}>
                            <td className='context-td'>
                              {context.context}
                            </td>
                            <td>
                              <PopupPDFViewer
                                file={this.props.pdf.content_object.document_url} page={context.page}
                                forClick={<Button>View Page {context.page}</Button>}
                              />
                            </td>
                          </tr>
                        )
                      })
                      :
                      null
                    }
                  </tbody>
                </table>
                {/* <Row><a onClick={this.handleClick} style={{cursor:'pointer'}}>{!this.state.isClicked ? 'Show Contexts...' : 'Hide Contexts...'}</a></Row>
                <Row><Button onClick={this.handleClick} style={{cursor:'pointer'}}>{!this.state.isClicked ? 'Show Contexts...' : 'Hide Contexts...'}</Button></Row>*/}
              </Col>
              <Col span={1}></Col>
            </Row>
          </div>
          :
          null
        }
        {/* <hr/> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    searchTerm: state.manageSearch.searchTerm
  }
}

export default connect(mapStateToProps, {setMode})(SearchPDFs)
