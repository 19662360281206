import React, { useState } from 'react'
import { Alert, Modal } from "react-bootstrap";
import {Button, Col, Input, Row, Select} from 'antd'
import PropTypes from "prop-types";
import axios from "axios";
import { requestFunctions } from "../../../functions/requestFunctions";
import { useDispatch } from "react-redux";


export default function CreateUpdateCategory({ category = null, forClick, categoryTypes, getCategories, categoryType = null, selectedModule = "" }) {
  // REDUX
  const dispatch = useDispatch()

  // STATE
  const [display, toggleDisplay] = useState(false)
  const [type, setType] = useState(category ? category.type : categoryType.url)
  const [name, setName] = useState(category ? category.name : "")
  const [errorMessage, setErrorMessage] = useState("")

  const handleCancel = () => {
    toggleDisplay(false)
    setType(category ? category.type : "")
    setName(category ? category.name : "")
  }

  const handleSave = () => {
    setErrorMessage("")
    if (!name || !type) {
      setErrorMessage("Please fill out all required fields")
      return
    }
    requestFunctions.loadingAnimation(`${ category ? "Editing" : "Adding" } a filter...`)

    let categoryObject = {
      type: type,
      name: name,
      indication: categoryType.indication,
      created_by: {},
      modified_by: {},
      module: category ? category.module : categoryType.module
    }

    if (category) categoryObject["url"] = category.url

    const method = categoryObject["url"] ? "PATCH" : "POST"
    const url = categoryObject["url"] ? categoryObject["url"] : `${process.env.REACT_APP_URL}categories/`

    axios({
      method,
      url,
      data: categoryObject,
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` }
    }).then(response => {
      console.log(response)
      requestFunctions.successAnimation(`Successfully ${ category ? "edited" : "added" } a filter`)
      getCategories()
    }).catch(error => {
      console.log(error)
      requestFunctions.handleError(error, dispatch, `There was an error with ${category ? "editing" : "adding"} a filter...`)
    })
  }

  return (
    <span>
      <forClick.type {...forClick.props} onClick={ () => toggleDisplay(true) } />
      <Modal show={ display } onHide={ handleCancel } size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{category ? "Update" : "Create"} Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="neweducation" className="translucent-background-with-padding">
            <Row>
              <Col sm={ 12 } md={ 12 } lg={ 12 }>
                <div className="field">
                  <p>Filter Type <span className="required">*</span></p>
                  <Select value={ type } onChange={ setType } style={{ width: "99%" }}>
                    { categoryTypes.map(type => <Select.Option key={ type.id } value={ type.url }>{ type.name }</Select.Option> )}
                  </Select>
                </div>
              </Col>
              <Col sm={ 12 } md={ 12 } lg={ 12 }>
                <div className="field">
                  <p>Name <span className="required">*</span></p>
                  <Input type="text" value={ name } onChange={(e) => setName(e.target.value)} placeholder="Please enter a name..."/>
                </div>
              </Col>
            </Row>
            <div>
              <span className="required">*</span> - Marks required fields
            </div>
            { errorMessage ?
              <div className="alert-wrapper"><Alert variant="danger">Please fill out all required fields.</Alert></div> : null
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleCancel }>Cancel</Button>
          <Button onClick={ handleSave }>Submit</Button>
        </Modal.Footer>
      </Modal>
    </span>
  )
}


CreateUpdateCategory.propTypes = {
  category: PropTypes.object,
  forClick: PropTypes.node.isRequired
}