import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row, Select} from 'antd'
import {
  changePage,
  changeSearchTerm,
  deleteEvidence,
  getEvidence,
  setEvidenceLimit
} from '../../reducers/manageSupportingEvidence'
import { getCategoryTypesByModule } from '../../reducers/manageCategories'
import Pagination from '../../components/Pagination'
import Evidence from './components/Evidence'
import LoadingAnimation from '../../components/LoadingAnimation'
import CreateUpdateEvidence from "./components/CreateUpdateEvidence";
import './evidence_library.css'
import FilterSideBar from "../../components/FilterSidebar";
import {useLocation} from "react-router-dom";


export default function LibraryContainer(props) {
  // REACT ROUTER DOM
  const location = useLocation()

  // REDUX
  const dispatch = useDispatch()
  const evidence = useSelector(state => state.manageSupportingEvidence.evidence)
  const fetchingEvidence = useSelector(state => state.manageSupportingEvidence.fetchingEvidence)
  const evidenceLimit = useSelector(state => state.manageSupportingEvidence.limit)
  const totalEvidenceCount = useSelector(state => state.manageSupportingEvidence.totalEvidenceCount)
  const currentPage = useSelector(state => state.manageSupportingEvidence.currentPage)
  const searchTerm = useSelector(state => state.manageSupportingEvidence.searchTerm)
  const fetchingCategoryTypes = useSelector(state => state.manageCategories.fetchingCategoryTypes)

  useEffect(() => {
    dispatch(getCategoryTypesByModule(location.pathname, () => dispatch(getEvidence())))
  }, [])

  const handleSearch = (term) => {
    dispatch(changeSearchTerm(term))
    dispatch(getEvidence())
  }

  const handleLimitChange = (limit) => {
    dispatch(setEvidenceLimit(limit))
    dispatch(getEvidence())
  }

  const handlePageChange = (selectedPage) => {
    dispatch(changePage(selectedPage))
    dispatch(getEvidence())
  }

  const handleDelete = (evidence) => {
    dispatch(deleteEvidence(evidence))
  }

  let numberOfPages = 1
  if (evidenceLimit) {
    numberOfPages = Math.ceil(totalEvidenceCount / evidenceLimit)
  }

  const table = evidence.map(row => {
    const id = row.id

    if (row.item) {
      row = row.item
    }

    return (
      <center key={id}>
        <Evidence
          handleDelete={ handleDelete }
          evidence={ row }
        />
      </center>
    )
  })

  return (
    <div id="evidence_table">
      { fetchingCategoryTypes ?
        <div className='home-loader'>
          <LoadingAnimation />
        </div>
        :
        <Row>
          <Col xs={22} sm={22} md={7} lg={6}>
            <FilterSideBar
              searchTerm={ searchTerm }
              handleSearch={ handleSearch }
              fetchingEntries={ fetchingEvidence }
              getEntries={ () => handlePageChange(1) }
            />
          </Col>


          <Col xs={24} sm={24} md={17} lg={18}>
            <Row style={{marginBottom: '10px'}}>
              <div className="evidence_fixed">
                <Col span={6}>
                  <div className="limiter">
                    View&nbsp;
                    <Select
                      value={ evidenceLimit === 0 ? 'All' : parseInt(evidenceLimit,10) }
                      onChange={ handleLimitChange }
                      // style={{ width: "100px" }}
                    >
                      <Select.Option value={0}>All</Select.Option>
                      <Select.Option value={10}>10</Select.Option>
                      <Select.Option value={25}>25</Select.Option>
                      <Select.Option value={50}>50</Select.Option>
                    </Select> Entries
                  </div>
                </Col>
                <Col span={12}>
                  <Pagination
                    totalPages={numberOfPages}
                    currentPage={currentPage}
                    pageNeighbors={1}
                    handlePageChange={handlePageChange}
                  />
                </Col>

                <Col span={6} style={{paddingRight: "25px"}}>
                  <CreateUpdateEvidence mode="create" />
                </Col>
              </div>
            </Row>
            <Row>
              <div className="evidence_table background">
                { fetchingEvidence ?
                  <LoadingAnimation />
                    :
                  evidence.length ?
                    table
                      :
                    <center>
                      <h4>No Evidence to Display</h4>
                    </center>
                }
              </div>
            </Row>
          </Col>
        </Row>
      }
    </div>
  )
}