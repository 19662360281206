import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Button, Card, Popconfirm} from 'antd'
import { Col, Row } from 'react-bootstrap'
import KVMsAndEvidenceStatements from './KVMsAndEvidenceStatements'
import BasicInfo from './BasicInfo'
import PopulationCharacteristics from './PopulationCharacteristics'
import KeyDocuments from './KeyDocuments'
import ResultDocuments from './ResultsData'
import KeyDates from './KeyDates'
import Outcomes from './Outcomes'
import StakeholderTargets from './StakeholderTargets'
import CommunicationTargets from './CommunicationTargets'
import { useHistory } from 'react-router-dom'
import { deleteStudy } from '../../../reducers/manageStudies'
import RenameStudy from './RenameStudy'


export default function Study(props) {
  // REACT ROUTER DOM
  const history = useHistory()
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)

  function renderContent() {
    if (props.match.params.tab) {
      switch (props.match.params.tab) {
        case "basicinfo":
          return <BasicInfo />
        case "populationcharacteristics":
          return <PopulationCharacteristics />
        case "outcomes":
          return <Outcomes />
        case "documents":
          return <KeyDocuments />
        case "kvmsandevidencestatements":
          return <KVMsAndEvidenceStatements />
        case "resultsdata":
          return <ResultDocuments />
        case "keydates":
          return <KeyDates />
        case "stakeholdertargets":
          return <StakeholderTargets />
        case "communicationtargets":
          return <CommunicationTargets />
        default:
          return null
      }
    } else {
      return null
    }
  }

  const handleRemove = () => {
    dispatch(deleteStudy(selectedStudy.url, history))
  }

  return (
    <div>
      <div id="StudyContent">
        <Card title={
          <Row>
            <Col>
              <h3>
                { selectedStudy.name }
              </h3>
            </Col>
            <Col>
              <div style={{ float: "right" }}>
                <RenameStudy study={ selectedStudy } />
                <Popconfirm
                  title="Are you sure to remove the study?"
                  placement="left"
                  onConfirm={() => handleRemove(selectedStudy.url)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="delete-study-btn">Delete</Button>
                </Popconfirm>
              </div>
            </Col>
          </Row>
        }>
          { renderContent() }
        </Card>
      </div>
    </div>
  )
}