import React, { useState } from 'react';
import { Checkbox, Form, Input, Icon, Button } from 'antd'
import {useDispatch} from 'react-redux'
import {handleUserLogin} from '../../../reducers/manageUsers'
import {setCountries} from "../../../reducers/manageGlobalFilters";
import { useHistory } from 'react-router-dom';
import {requestFunctions} from "../../../functions/requestFunctions";
import {Alert, Spinner} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
// import swal from 'sweetalert'


export default function LoginSideBar() {
	// REACT ROUTER DOM
	const history = useHistory()
	
	// REDUX
	const dispatch = useDispatch()

	// STATE
	const [authenticating ,setAuthenticating] = useState(false)
	const [email, setEmail] = useState(localStorage.getItem("email") || "")
	const [password, setPassword] = useState("")
	const [rememberMe, setRememberMe] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")

	const authenticate = () => {
		setAuthenticating(true)
    requestFunctions.loadingAnimation("Logging In...")

    axios.post(`${process.env.REACT_APP_URL}login/`, {
      email: email,
      password: password
    }).then(response => {
      const token = response.data.token
      localStorage.setItem("token", token)
      if (rememberMe) {
        localStorage.setItem("email", email)
      } else {
        localStorage.removeItem("email")
      }
      dispatch(handleUserLogin(response.data.user))
      dispatch(setCountries(response.data.user.countries))
      dispatch({ type: "CLEAR_INDICATIONS" })
			setAuthenticating(false)
      // swal.close()
			Swal.close()
      if (history.location.state && history.location.state.from) {
        history.replace({ pathname: '/indications', state: history.location.state });
      } else {
        history.replace("/indications")
      }
    }).catch(error => {
      console.log(error)
      console.log(error.response)
			setAuthenticating(false)
      let message = "There was an issue logging in. Please try again."
      if (error && error.response) {
        message = Object.values(error.response.data)[0][0]
      }
			requestFunctions.handleError(error, dispatch, message)
			setErrorMessage(message)
    })
	}

	const handleLogin = (e) => {
		e.preventDefault()
		let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if(!email && !password){
			setErrorMessage("Must provide an email and password")
		}
		else if(!re.test(email)){
			setErrorMessage("Must provide a valid email")
		}
		else if (!email) {
			setErrorMessage("Must provide an email")
		}else if(!password.length){
			setErrorMessage("Must provide a password")
		} else {
			setErrorMessage("")
			authenticate()
		}
	}

	return (
		<div id="LoginSideBar">
			<div className='form-welcome'>
				WELCOME
			</div>

			<div>
				<div className="login-inputs">
					<Form onSubmit={handleLogin}>
						<div>
							<label htmlFor="email" className="inputLabels">
								Email
							</label>
							<br/>
							<Input
								value={email}
								prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }}/>}
								id="email"
								onChange={(e) => setEmail(e.target.value)}
								className="email-field"
							/>

							<br/>
							<label htmlFor="password" className="inputLabels">
								Password
							</label>
							<br/>
							<Input.Password
								type="password"
								value={password}
								id="password"
								prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }}/>}
								onChange={(e) => setPassword(e.target.value)}
								className="password-field"
							/>

							<center className="rememberme">
								<Checkbox
									onClick={ () => setRememberMe(!rememberMe) }
									checked={rememberMe}
								/> Remember Me
							</center>
							<div className="login-btn">
								<Button
									onClick={handleLogin}
									className="loginButtons"
									htmlType='submit'
									disabled={authenticating}
								>
									{authenticating ?
										<Spinner animation="border" role="status" size="sm" />
										:
										'Login'
									}
									{/*Login*/}
								</Button>
							</div>
							{/* <div className="login-btn">
								<Button
									onClick={this.handleRegisterButton}
									className="registerButtons"
								>
									Register
								</Button>
							</div> */}
						</div>
					</Form>
				</div>
				<div className="login-error">
					{!!errorMessage &&
						<Alert variant="danger">
							{ errorMessage }
						</Alert>
					}
				</div>
				<div className="login-footer">
					{/*<h2 className="login-egvd">*/}
					{/*	Value & Evidence*/}
					{/*</h2>*/}
					{/*<img className="login-logo" alt="logo" src={require("../../../static/images/EVA_Logo_2022_February_for_dark_background.svg")}/>*/}
					<img className="login-logo" alt="logo" src={require('../../../static/images/logo-genesis-white-orange.png')}/>
					{/*<img className="login-logo" alt="logo" src={require("../../../static/images/IEP_Portal_Logo.svg")}/>*/}
				</div>

			</div>
		</div>
	)
}