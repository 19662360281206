import { Button, Checkbox, Input } from 'antd'
import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getKeyDates } from '../../../reducers/manageStudies'
import { setSelectedStudyList } from '../../../reducers/manageStudyTimelines'


export default function TimelineStudyListModal() {
  // REDUX
  const dispatch = useDispatch()
  const timelineStudyList = useSelector(state => state.manageStudyTimelines.timelineStudyList)
  const selectedStudyList = useSelector(state => state.manageStudyTimelines.selectedStudyList)
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)

  // STATE
  let [showModal, toggleModal] = useState(false)
  let [selectedStudies, setSelectedStudies] = useState(selectedStudyList)
  let [search, setSearch] = useState("")

  const handleCheck = (e) => {
    let studyList = [...selectedStudies]
    if (studyList.includes(e.target.value)) {
      studyList = studyList.filter(study => study !== e.target.value)
    } else {
      studyList = [...studyList, e.target.value]
    }
    setSelectedStudies(studyList)
  }

  const handleCloseModal = () => {
    toggleModal(false)
    setSelectedStudies(selectedStudyList)
    setSearch("")
  }

  const handleClearAll = () => {
    setSelectedStudies([])
  }

  const handleSelectAll = () => {
    let filteredList = timelineStudyList.filter(study => study.name.toLowerCase().includes(search.toLowerCase())).map(study => study.id)
    setSelectedStudies(filteredList)
  }

  const handleApply = () => {
    toggleModal(false)
    dispatch(setSelectedStudyList(selectedStudies))
    dispatch(getKeyDates(selectedStudies, selectedIndication.id))
    setSearch("")
  }

  return (
    <div>
      <Button onClick={() => toggleModal(true) } type="link">All studies ({timelineStudyList.length})</Button>
      <Modal size="lg" show={ showModal } onHide={ handleCloseModal }>
        <Modal.Header closeButton>
          <Modal.Title>Study List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={8} lg={8} xl={8}>
              <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
            </Col>
            <Col md={2} lg={2} xl={2}>
              <Button onClick={ handleClearAll } style={{ width: "100%" }} className="modal-action-btns">Clear All</Button>
            </Col>
            <Col md={2} lg={2} xl={2}>
              <Button onClick={ handleSelectAll } style={{ width: "100%" }} className="modal-action-btns">Select All</Button>
            </Col>
          </Row>
          <Row className="timeline-study-list-modal">
            { timelineStudyList.filter(study => study.name.toLowerCase().includes(search.toLowerCase())).map(study => {
              return (
                <Col key={study.id} md={4} lg={4} xl={4}>
                  <Checkbox checked={ !!selectedStudies.includes(study.id) } value={ study.id } onChange={ handleCheck }>
                    { study.name }
                  </Checkbox>
                </Col>
              )
            }) }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleCloseModal }>Cancel</Button>
          <Button onClick={ handleApply }>Apply</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}