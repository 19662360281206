import React from 'react'
import {Modal} from "react-bootstrap";
import {Button} from "antd";


export default function DeleteKVMModal({ message, show, toggleShow, handleDeleteKVM }) {

  const handleCancel = () => {
    toggleShow(false)
  }

  let kvmList = message.evidence_statements.map((kvm, index) => {
    return (
      <tr
        key={index}
        className="kvm-label delete-modal"
      >
        <td className="kvm-id delete-modal" style={{ color: "inherit", backgroundColor: "inherit", textAlign: "center" }}>
          { kvm.number }
        </td>
        <td>
        <label
          className="kvm-message"
          htmlFor={"kvm"+kvm.id}
        >
          {kvm.message}
        </label>
        </td>
      </tr>
    )
  })

  return (
    <Modal show={ show } onHide={ handleCancel } size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Delete Key Value Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="newkvm" className="translucent-background-with-padding">
          <p className="title"><strong>Deleting this key value message will also remove the following evidence statements:</strong></p>
          <div id="kvm-evidence-statement-list">
            <table className="kvmtable" style={{width: "100%"}}>
              <colgroup>
                <col className="kvm-column" />
                <col className="kvm-column" />
              </colgroup>
              <thead className="table-header new-evidence">
                <tr>
                  <th>Number</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                { kvmList }
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ () => toggleShow(false) }>Cancel</Button>
        <Button onClick={ handleDeleteKVM }>Confirm</Button>
      </Modal.Footer>
    </Modal>
  )
}