import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "antd";
import {Modal} from "react-bootstrap";
import swal from "sweetalert";
import {editFact, getListOfEvidence, submitFact} from "../../../../reducers/manageFacts";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import EntryCreationCategoryList from "../../../../components/EntryCreationCategoryList";


export default function CreateUpdateDataEntry({ fact, mode }) {
  // REDUX
  const dispatch = useDispatch()
  const listOfEvidence = useSelector(state => state.manageFacts.listOfEvidence)
  const countries = useSelector(state => state.manageUsers.countries)
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const user = useSelector(state => state.manageUsers.user)

  // STATE
  const initialState = {
    visible: false,
    outcome: fact ? fact.outcome : "",
    value: fact ? fact.value : "",
    description: fact ? fact.description : "",
    category: fact ? fact.category : [],
    indications: fact ? fact.indications : (selectedIndication ? [selectedIndication.url] : []),
    supportingEvidence: fact ? fact.supporting_evidence.map(evidence => evidence.url) : [],
    factCountries: fact ? fact.countries : ["Global"],
    step: 1,
    errorMessage: ""
  }

  const [selectedDataCategories, setSelectedDataCategories] = useState({})
  const [{
    visible, outcome, value,
    description, category, indications,
    supportingEvidence, factCountries,
    step, errorMessage
  }, setState] = useState(initialState)

  const handleChangeCategory = (id, value) => {
    let selectedCategories = {...selectedDataCategories}
    selectedCategories[id] = value
    setSelectedDataCategories(selectedCategories)
  }

  const handleInput = (e) => {
    const { name, value } = e.target
    setState(prevState => ({ ...prevState, [name]: value }))
  }

  const handleSelect = (field, value) => setState(prevState => ({ ...prevState, [field]: value }))

  const handleCancel = () => setState({ ...initialState })

  const onChangeEvidence = (url) => {
    let supporting_evidence = [...supportingEvidence]

    if (supporting_evidence.find(evidenceUrl => evidenceUrl === url)) {
      supporting_evidence = supporting_evidence.filter(evidenceUrl => evidenceUrl !== url)
    } else {
      let selectedEvidence = listOfEvidence.find(evidence => evidence.url === url)
      supporting_evidence = [...supporting_evidence, selectedEvidence.url]
    }
    handleSelect("supportingEvidence", supporting_evidence)
  }

  const changeStep = (newStep) => {
    if (step === 1) {
      if (!outcome) {
        handleSelect("errorMessage", "Please fill out the outcome")
      } else if (!description) {
        handleSelect("errorMessage", "Please fill out the description")
      } else if (!category) {
        handleSelect("errorMessage", "Please select a category")
      } else if (indications.length < 1) {
        handleSelect("errorMessage", "Please select at least one indication")
      } else {
        handleSelect("errorMessage", "")
        handleSelect("step", newStep)
      }
    } else {
      handleSelect("errorMessage", "")
      handleSelect("step", newStep)
    }
  }

  const handleSubmit = () => {
    let allSelectedCategories = []
    Object.keys(selectedDataCategories).forEach(c => {
      allSelectedCategories = [...allSelectedCategories, ...selectedDataCategories[c]]
    })

    if (!outcome || !description || !indications.length || !allSelectedCategories.length) {
      swal({
        title: "Error",
        text: "Please complete all required fields.",
        icon: "error",
        button: "Continue!",
      })
      return null
    }

    let formData = new FormData()
    const country = user.role === "Global" ? "Global" : user.country
    let countryURLs = factCountries.map(country => {
      return countries.find(countryObject => {
        return country === countryObject.name
      }).url
    })

    formData.append("outcome", outcome)
    formData.append("value", value)
    formData.append("description", description)
    countryURLs.forEach(c => formData.append('countries', c))
    allSelectedCategories.forEach(cat => formData.append('category', cat))
    formData.append("country", country)
    indications.forEach(indication => formData.append('indications', indication))
    supportingEvidence.forEach(evidence => formData.append('supporting_evidence', evidence))
    formData.append("is_active", true)
    formData.append("created_by", {})
    formData.append("modified_by", {})

    if (mode === "create" || mode === "create-fact") {
      handleCancel()
      dispatch(submitFact(formData))
    } else if (mode === "edit") {
      formData.append("url", fact.url)
      dispatch(editFact(formData))
      handleSelect("visible", false)
      handleSelect("step", 1)
    }

    if (mode === "create-fact") {
      dispatch(getListOfEvidence())
    }
  }

  const displayStep = () => {
    if (step === 1) {
      return (
        <StepOne
          handleChangeCategory={ handleChangeCategory }
          fact={ fact }
          mode={ mode }
          outcome={ outcome }
          value={ value }
          description={ description }
          category={ category }
          indications={ indications }
          supporting_evidence={ supportingEvidence }
          countries={ countries }
          errorMessage={ errorMessage }
          handleSelect={ handleSelect }
          handleInput={ handleInput }
          handleCancel={ handleCancel }
          changeStep={ changeStep }
        />
      )
    } else if (step === 2) {
      return (
        <StepTwo
          onChangeEvidence={ onChangeEvidence }
          fact={ fact }
          mode={ mode }
          outcome={ outcome }
          value={ value }
          description={ description }
          category={ category }
          errorMessage={ errorMessage }
          indications={ indications }
          supporting_evidence={ supportingEvidence }
          handleInput={ handleInput }
          handleCancel={ handleCancel }
          changeStep={ changeStep }
        />
      )
    } else if (step === 3) {
      return (
        <EntryCreationCategoryList
          entry={ fact }
          indications={ indications }
          step={ step }
          handleCancel={ handleCancel }
          changeStep={ changeStep }
          handleSubmit={ handleSubmit }
          selectedCategories={ selectedDataCategories }
          setSelectedCategories={ setSelectedDataCategories }
        />
      )
    } else {
      return <div>Error. Please refresh the page.</div>
    }
  }

  // let footerButtons = []
  // if (step === 1) {
  //   footerButtons = [
  //     <Button key="1" onClick={ handleCancel }>Cancel</Button>,
  //     <Button key="2" onClick={ () => changeStep(2) }>Select Supporting Material</Button>
  //   ]
  // } else if (step === 2) {
  //   footerButtons = [
  //     <Button key="1" onClick={ handleCancel }>Cancel</Button>,
  //     <Button key="2" onClick={ () => changeStep(1) }>Back</Button>,
  //     <Button key="3" onClick={ handleSubmit }>Submit</Button>
  //   ]
  // }

  return (
    <div className={ mode === "create" ? "new_fact" : "edit_fact_btn" }>
      <Button
        onClick={() => handleSelect("visible", true) }
        // disabled={ user.role !== "Global" }
        className={ mode === "create" ? "edit_fact" : "action_btn" }
      >
        { mode === "create" ? "Add Data" : "Edit" }
      </Button>
      <Modal
        show={ visible }
        onHide={ handleCancel }
        size="lg"
        className="ant-modal-body-custom"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            { mode === "create" ? "Add Data" : "Edit Data" }
          </Modal.Title>
        </Modal.Header>
        {/*<Modal.Body>*/}
          { displayStep() }
        {/*</Modal.Body>*/}
        {/*<Modal.Footer>*/}
        {/*  { footerButtons }*/}
        {/*</Modal.Footer>*/}
      </Modal>
    </div>
  )
}