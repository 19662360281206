function formatSingleDigitAsDoubleDigit(num) {
  const numAsString = `${num}`
  if (numAsString.length === 1) {
    return `0${numAsString}`
  }
  return numAsString
}


/**
 * Formats the Date object into a string that ignores timezones and returns it.
 *
 * @param {Date} date - Date object
 * @returns {string|null}
 */
export default function formatDateForServer(date) {
  if (!date || !date instanceof Date || isNaN(date)) {
    return null
  }
  // make the date unaware of time and timezone before running the `toJSON` or any
  // other date methods, which sets the time to UTC and can reset the date to the previous or next day,
  // depending on time of entry.
  const day = date.getDate()
  const month = date.getMonth() + 1 // add 1 to month because months are 0-indexed (January === 0, February === 1)
  const year = date.getFullYear()
  return `${year}-${formatSingleDigitAsDoubleDigit(month)}-${formatSingleDigitAsDoubleDigit(day)}`
}
