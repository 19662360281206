import React, { useState } from 'react'
import { Alert, Col, Modal, Row } from 'react-bootstrap'
import { Button, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { updateStudy } from '../../../../reducers/manageStudies'

export default function UpdatePopulationCharacteristics({ study }) {
  // REDUX
  const dispatch = useDispatch()
  
  // STATE
  let [showModal, toggleModal] = useState(false)
  let [minAge, setMinAge] = useState( study.min_population_age || 0 )
  let [maxAge, setMaxAge] = useState( study.max_population_age || 0 )
  let [populationAge, setPopulationAge] = useState( study.population_age || "" )
  let [numberOfPatients, setNumberOfPatients] = useState( study.number_of_patients || 0 )
  let [errorMessage, setErrorMessage] = useState("")

  const handleCancel = () => {
    toggleModal(false)
    setMinAge( study.min_population_age || 0 )
    setMaxAge( study.max_population_age || 0 )
    setPopulationAge( study.population_age || "" )
    setNumberOfPatients( study.number_of_patients || 0 )
  }

  const handleSubmit = () => {
    setErrorMessage("")
    if (parseInt(minAge, 10) > parseInt(maxAge, 10)) {
      setErrorMessage("Minimum Age cannot be greater than Maximum Age")
      return
    } else if (!populationAge) {
      setErrorMessage("Population age cannot be blank")
      return
    }
    let newStudy = new FormData()
    newStudy.append("url", study.url)
    newStudy.append("id", study.id)
    // newStudy.append("min_population_age", minAge)
    // newStudy.append("max_population_age", maxAge)
    newStudy.append("population_age", populationAge)
    newStudy.append("number_of_patients", numberOfPatients)
    
    dispatch(updateStudy(newStudy))
    toggleModal(false)
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Button className="new-study-btn population-btns" onClick={ () => toggleModal(true) }>Update Population</Button>
      <Modal show={ showModal } onHide={ handleCancel } size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Update Population
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="field">
            {/*<Col md={4} lg={4} xl={4}>*/}
            {/*  <div><strong>Minimum Age</strong></div>*/}
            {/*  <Input placeholder="Enter minimun age..." min="0" type="number" value={ minAge } onChange={ (e) => setMinAge(e.target.value)}/>*/}
            {/*</Col>*/}
            {/*<Col md={4} lg={4} xl={4}>*/}
            {/*  <div><strong>Maximum Age</strong></div>*/}
            {/*  <Input placeholder="Enter maximum age..." min="0" type="number" value={ maxAge } onChange={ (e) => setMaxAge(e.target.value)}/>*/}
            {/*</Col>*/}
            <Col md={6} lg={6} xl={6}>
              <div><strong>Population Age</strong></div>
              <Input placeholder="Enter population age..." value={ populationAge } onChange={ (e) => setPopulationAge(e.target.value)}/>
            </Col>
            <Col md={6} lg={6} xl={6}>
              <div><strong>Number of Patients</strong></div>
              <Input placeholder="Enter number of patients..." min="0" type="number" value={ numberOfPatients } onChange={ (e) => setNumberOfPatients(e.target.value)}/>
            </Col>
          </Row>
          <br/>
          { errorMessage ? 
            <Alert variant="danger">{ errorMessage }</Alert>
          : null }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleCancel }>Cancel</Button>
          <Button onClick={ handleSubmit }>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}