import {combineReducers} from 'redux'

import manageMode from './manageMode'
import manageFactSheet from './manageFactSheet'
import manageFacts from './manageFacts'
import manageFolders from './manageFolders'
import manageUsers from './manageUsers.js'
import manageSummaries from './manageSummaries.js'
import manageSupportingEvidence from './manageSupportingEvidence'
import manageKVM from './manageKVM'
import manageEducation from './manageEducationalResources'
import manageEducationalVideos from './manageEducationalVideos'
import manageCategories from './manageCategories'
import manageSections from './manageSections'
import manageStudies from './manageStudies'
import manageCSRContents from './manageCSRContents'
import manageGlobalFilters from './manageGlobalFilters'
import manageModules from './manageModules'
import manageIndications from './manageIndications'
import manageActivity from './manageActivity'
import manageSearch from './manageSearch'
import manageStudyTimelines from './manageStudyTimelines'

const rootReducer = combineReducers({
   manageSummaries, manageUsers, manageKVM, manageSupportingEvidence, manageEducation, manageMode,
   manageFactSheet, manageFacts, manageCategories, manageCSRContents, manageEducationalVideos,
   manageSearch, manageSections, manageActivity, manageGlobalFilters, manageModules, manageIndications,
   manageStudies, manageStudyTimelines, manageFolders
})

export default rootReducer
