import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { Col, Row } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { updateStudy } from '../../../../reducers/manageStudies'

export default function UpdateStakeholderTarget({ study }) {
  // REDUX
  const dispatch = useDispatch()
  const stakeholderTargets = useSelector(state => state.manageStudies.stakeholderTargets)

  // STATE
  const [editable, setEditable] = useState(false)
  const [htas, setHtas] = useState(study.htas)
  const [medicalProviders, setMedicalProviders] = useState(study.medical_providers)
  const [patientOrganizations, setPatientOrganizations] = useState(study.patient_organizations)
  const [payers, setPayers] = useState(study.payers)
  const [regulators, setRegulators] = useState(study.regulators)
  const [other, setOther] = useState(study.other)

  const handleCancel = () => {
    setEditable(false)
    setHtas(study.htas)
    setMedicalProviders(study.medical_providers)
    setPatientOrganizations(study.patient_organizations)
    setPayers(study.payers)
    setRegulators(study.regulators)
    setOther(study.other)
  }

  const handleSubmit = () => {
    let updatedStudy = new FormData()
    updatedStudy.append("id", study.id)
    updatedStudy.append("url", study.url)

    // DJANGO WILL NOT UPDATE ASSOCIATIONS IF NOT PROVIDED THE NAME OF THE COLUMN
    // ex. IF THE htas COLUMN IS EMPTY IT WILL NOT BE SENT OVER TO DJANGO
    // AND IN THAT CASE DJANGO WILL NOT CLEAR ALL ASSOCIATIONS...
    // TO TEMPORARILY COUNTER THIS THE "clear_" TAGS WERE ADDED WHICH WHEN SENT TO THE BACKEND
    // TELL IT TO CLEAR THE ASSOCIATIONS FOR THE APPROPRIATE COLUMNS
    htas.forEach(target => target.url ? updatedStudy.append("htas", target.url) : updatedStudy.append("new_htas", target.name))
    medicalProviders.forEach(target => target.url ? updatedStudy.append("medical_providers", target.url) : updatedStudy.append("new_medical_providers", target.name))
    patientOrganizations.forEach(target => target.url ? updatedStudy.append("patient_organizations", target.url) : updatedStudy.append("new_patient_organizations", target.name))
    payers.forEach(target => target.url ? updatedStudy.append("payers", target.url) : updatedStudy.append("new_payers", target.name))
    regulators.forEach(target => target.url ? updatedStudy.append("regulators", target.url) : updatedStudy.append("new_regulators", target.name))
    other.forEach(target => target.url ? updatedStudy.append("other", target.url) : updatedStudy.append("new_other", target.name))

    if (!updatedStudy.get("htas")) updatedStudy.append("clear_htas", true)
    if (!updatedStudy.get("medical_providers")) updatedStudy.append("clear_medical_providers", true)
    if (!updatedStudy.get("patient_organizations")) updatedStudy.append("clear_patient_organizations", true)
    if (!updatedStudy.get("payers")) updatedStudy.append("clear_payers", true)
    if (!updatedStudy.get("regulators")) updatedStudy.append("clear_regulators", true)
    if (!updatedStudy.get("other")) updatedStudy.append("clear_other", true)
    
    dispatch(updateStudy(updatedStudy))
  }

  useEffect(() => {
    setHtas(study.htas)
    setMedicalProviders(study.medical_providers)
    setPatientOrganizations(study.patient_organizations)
    setPayers(study.payers)
    setRegulators(study.regulators)
    setOther(study.other)
  }, [study])

  return (
    <div>
      <Row className="field">
        <Col>
        { !editable ?
          <Button onClick={ () => setEditable(true) } className="new-study-btn">Update</Button>
          :
          <Fragment>
            <Button onClick={ handleCancel } className="new-study-btn">Cancel</Button>
            <Button onClick={ handleSubmit } className="new-study-btn">Submit</Button>
          </Fragment>
          }
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>HTAs</strong></div>
          <Typeahead
            disabled={ !editable }
            labelKey="name"
            allowNew
            id="custom-selections-example"
            multiple
            newSelectionPrefix="Add a new HTA: "
            options={ stakeholderTargets.htas }
            selected={ htas }
            onChange={ setHtas }
            placeholder={ editable ? "Enter HTAs..." : "N/A"}
          />
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Medical Providers</strong></div>
          <Typeahead
            disabled={ !editable }
            labelKey="name"
            allowNew
            id="custom-selections-example"
            multiple
            newSelectionPrefix="Add a new HTA: "
            options={ stakeholderTargets.medicalProviders }
            selected={ medicalProviders }
            onChange={ setMedicalProviders }
            placeholder={ editable ? "Enter medical providers..." : "N/A"}
          />
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Patient Organizations</strong></div>
          <Typeahead
            disabled={ !editable }
            labelKey="name"
            allowNew
            id="custom-selections-example"
            multiple
            newSelectionPrefix="Add a new HTA: "
            options={ stakeholderTargets.patientOrganizations }
            selected={ patientOrganizations }
            onChange={ setPatientOrganizations }
            placeholder={ editable ? "Enter patient organizations..." : "N/A"}
          />
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Payers</strong></div>
          <Typeahead
            disabled={ !editable }
            labelKey="name"
            allowNew
            id="custom-selections-example"
            multiple
            newSelectionPrefix="Add a new HTA: "
            options={ stakeholderTargets.payers }
            selected={ payers }
            onChange={ setPayers }
            placeholder={ editable ? "Enter payers..." : "N/A" }
          />
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Regulators</strong></div>
          <Typeahead
            disabled={ !editable }
            labelKey="name"
            allowNew
            id="custom-selections-example"
            multiple
            newSelectionPrefix="Add a new regulator: "
            options={ stakeholderTargets.regulators }
            selected={ regulators }
            onChange={ setRegulators }
            placeholder={ editable ? "Enter regulators..." : "N/A"}
          />
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Other</strong></div>
          <Typeahead
            disabled={ !editable }
            labelKey="name"
            allowNew
            id="custom-selections-example"
            multiple
            newSelectionPrefix="Add a new HTA: "
            options={ stakeholderTargets.other }
            selected={ other }
            onChange={ setOther }
            placeholder={ editable ? "Enter other..." : "N/A"}
          />
        </Col>
      </Row>
    </div>
  )
}