import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
// import {getCountries} from "../../reducers/manageUsers";
import {changeSearchTerm, getEducation, changePage, setEducationLimit} from "../../reducers/manageEducationalResources";
import {Button, Col, Row, Select} from "antd";
import Education from "./components/Education";
import Pagination from "../../components/Pagination";
import { getCategoryTypesByModule } from "../../reducers/manageCategories";
import LoadingAnimation from "../../components/LoadingAnimation";
import CreateUpdateEducation from "./components/CreateUpdateEducation";
import './educational_resources.css'
import FilterSideBar from "../../components/FilterSidebar";
import {useLocation} from "react-router-dom";
// import FilterSideBar from "../../components/FilterSideBar";
// import FolderSideBar from "./components/FolderSideBar";


export default function KeyResourceContainer() {
  // REACT ROUTER DOM
  const location = useLocation()

  // REDUX
  const dispatch = useDispatch()
  const education = useSelector(state => state.manageEducation.education)
  const totalEducationCount = useSelector(state => state.manageEducation.totalEducationCount)
  const searchTerm = useSelector(state => state.manageEducation.searchTerm)
  const educationLimit = useSelector(state => state.manageEducation.limit)
  const currentPage = useSelector(state => state.manageEducation.currentPage)
  const fetchingCategoryTypes = useSelector(state => state.manageCategories.fetchingCategoryTypes)
  const fetchingEducation = useSelector(state => state.manageEducation.fetchingEducation)
  const user = useSelector(state => state.manageUsers.user)
  // const selectedKeyResourceSubFolder = useSelector(state => state.manageCategories.selectedKeyResourceSubFolder)
  // const selectedFolder = useSelector(state => state.manageFolders.selectedFolder)
  // const selectedSubFolder = useSelector(state => state.manageFolders.selectedSubFolder)


  useEffect(() => {
    dispatch(getCategoryTypesByModule(location.pathname, () => dispatch(getEducation())))
  }, [])

  const handleSearch = (term) => {
    dispatch(changeSearchTerm(term))
    dispatch(changePage(1))
    dispatch(getEducation())
  }

  const handlePageChange = (selectedPage) => {
    dispatch(changePage(selectedPage))
    dispatch(getEducation(selectedPage))
  }

  const handleLimitChange = (limit) => {
    dispatch(setEducationLimit(limit))
    dispatch(changePage(1))
    dispatch(getEducation())
  }

  let educationResources = []

  if (education) {
    educationResources = [...education]
  }

  // Create display elements for educations
  educationResources = educationResources.map((education, index) => {
    return (
      <Col sm={24} md={12} lg={12} xl={8} key={education.id} className="education-column-card">
        <Education index={index} education={education} />
      </Col>
    )
  })

  let numberOfPages = 1
  if (educationLimit) {
    numberOfPages = Math.ceil(totalEducationCount / educationLimit)
  }

  return (
    <div id="educational_resources">
      {fetchingCategoryTypes ?
        <div className='home-loader'>
          <LoadingAnimation/>
        </div>
        :
        <Row>
          <Col xs={24} sm={24} md={7} lg={6}>
            {/*{ !selectedFolder && <h5 style={{marginLeft: "25px", marginTop: "5px"}}>Please select a resource folder</h5> }*/}
            {/*<FolderSideBar />*/}
            {/*<FilterSideBar*/}
            {/*  categories={categories}*/}
            {/*  fetchingCategories={fetchingCategories}*/}
            {/*  searchTerm={searchTerm}*/}
            {/*  changeSearchTerm={changeSearchTerm}*/}
            {/*  handleSearch={handleSearch}*/}
            {/*  selectedCategories={selectedKeyResourceCategories}*/}
            {/*  handleCategorySelect={handleChangeSelectedCategories}*/}
            {/*/>*/}
            <FilterSideBar
              searchTerm={ searchTerm }
              handleSearch={ handleSearch }
              fetchingEntries={ fetchingEducation }
              getEntries={ () => handlePageChange(1) }
            />
          </Col>
          <Col xs={24} sm={24} md={17} lg={18}>
            {/*{ selectedFolder ?*/}
              <div id="education-field">
                <Row>
                  <Col span={8}>
                    <center className="limiter">
                      View&nbsp;
                      <Select
                        value={educationLimit === 0 ? 'All' : parseInt(educationLimit, 10)}
                        onChange={handleLimitChange}
                        // style={{ width: "100px" }}
                      >
                        <Select.Option value={0}>All</Select.Option>
                        <Select.Option value={6}>6</Select.Option>
                        <Select.Option value={12}>12</Select.Option>
                        <Select.Option value={24}>24</Select.Option>
                      </Select> Entries
                    </center>
                  </Col>
                  <Col span={8}>
                    <center>
                      <Pagination
                        totalPages={numberOfPages}
                        currentPage={currentPage}
                        pageNeighbors={1}
                        handlePageChange={handlePageChange}
                      />
                    </center>
                  </Col>
                  <Col span={8}>
                    <center>
                      <CreateUpdateEducation mode="create" forClick={
                        <Button
                          disabled={user.role !== "Global"}
                          className='new_education_button'
                        >
                          New Key Resource
                        </Button>
                      } />
                    </center>
                  </Col>
                </Row>
                  <div className="resource_table background">
                    <center>
                      {fetchingEducation ?
                        <LoadingAnimation/>
                          :
                          <Row style={{padding: '1vh 1vw'}}>
                            {educationResources.length ?
                              educationResources
                              :
                              <h3>
                                {/*{ !!selectedSubFolder ? "There are no resources in the selected resource folder" :  "Please select a specific sub-folder" }*/}
                                Please select a category
                              </h3>
                            }
                          </Row>
                      }
                    </center>
                  </div>
              </div>
            {/*: null }*/}
          </Col>
        </Row>
      }
    </div>
  )
}