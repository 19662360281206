import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

// reusable chart components
import ActionBreakdownChart from '../reusable-charts/ActionBreakdownChart'
import NavigationChart from '../reusable-charts/NavigationChart'
import ViewChart from '../reusable-charts/ViewChart'
import ModifyChart from '../reusable-charts/ModifyChart'
import CreateChart from '../reusable-charts/CreateChart'

import { getAllEducation } from '../../../../reducers/manageEducationalResources'


const KeyResourcesCharts = ({ clicks, ...props }) => {

  useEffect(() => {
    props.getAllEducation()
  }, [])

  const keyResourcesViewClicks = clicks.filter(click => click.action === "view - Key Resources")
  const keyResourcesModifyClicks = clicks.filter(click => click.action === "modify - Key Resources")
  const keyResourcesCreateClicks = clicks.filter(click => click.action === "create - Key Resources")

  // RENDER
  return (
    <div>
      {!props.allEducation.length ?
        <div>
          Fetching Key Resources usage data...
        </div>
        :
        <div>
          <h6>Breakdown of all Key Resources tracking actions:</h6>
          <ActionBreakdownChart clicks={clicks} />
          <br />
          <hr />
    
          <h6>Navigation clicks to Key Resources by date and count:</h6>
          <NavigationChart clicks={clicks} />
          <br />
          <hr />
    
          {keyResourcesViewClicks ?
            <div>
              <h6>Number of times supporting materials for Key Resources were <strong>viewed</strong>:</h6>
              <ViewChart clicks={keyResourcesViewClicks} viewObjsArr={props.allEducation} context="Key Resources" />
              <hr />
              <br />
            </div>
            :
            null
          }
    
          {keyResourcesCreateClicks ?
            <div>
              <h6>List of individual Key Resources that have been <strong>created</strong>:</h6>
              <CreateChart clicks={keyResourcesCreateClicks} createObjsArr={props.allEducation} context="Key Resources" />
              <hr />
              <br />
            </div>
            :
            null
          }

          {keyResourcesModifyClicks ?
            <div>
              <h6>Number of times individual Key Resources were <strong>modified</strong>:</h6>
              <ModifyChart clicks={keyResourcesModifyClicks} modifyObjsArr={props.allEducation} context="Key Resources" />
              <hr />
              <br />
            </div>
            :
            null
          }
        </div>
      }
    </div>
  )
}

// REDUX
const mapStateToProps = (state) => {
  return {
    allEducation: state.manageEducation.allEducation
  }
}

const mapDispatchToProps = {
  getAllEducation
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KeyResourcesCharts))