import React, {Fragment, useEffect, useState} from 'react'
import axios from 'axios'
import {Col, Row} from "react-bootstrap";
import PopupPDFViewer from "../../../../components/PopupPDFViewer";
import {Button} from "antd";
import PropTypes from "prop-types";
const noImageAvailable = require("../../../../static/images/no-img-available.jpg")


export default function Data({ data }) {
  // STATE
  const [thumbnail, setThumbnail] = useState(require("../../../../static/images/spinner.gif"))
  const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(true)

  const getThumbnail = () => {
    let imageUrl;
    if (data.thumbnail) {
      imageUrl = data.thumbnail
    } else if (data.pdf && data.pdf.thumbnail) {
      imageUrl = data.pdf.thumbnail
    } else {
      setThumbnail(noImageAvailable)
      return
    }
    setIsLoadingThumbnail(true)
    axios.get(imageUrl, { responseType: "blob" })
      .then(response => {
        if (response.data.type === "text/html") {
          setThumbnail(noImageAvailable)
        } else {
          const thumbnailUrl = URL.createObjectURL(new Blob([response.data]))
          setThumbnail(thumbnailUrl)
        }
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          window.localStorage.clear()
          window.location = '/login'
        }
        console.log(error, error.response)
        setThumbnail(noImageAvailable)
      }).finally(() => {
        setIsLoadingThumbnail(false)
      })
  }

  const capitalizeSentence = (sentence) => {
    if (sentence) {
      return sentence.toLowerCase().split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
    }
  }

  useEffect(() => {
    getThumbnail()
  }, [])

  const elasticPrefix = process.env.REACT_APP_ELASTIC_PREFIX

  if (data._index === `${elasticPrefix}_supporting_evidences`) {
    return (
      <div className="search-result">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h3 className="title">
              {data.filename.replace(".pdf", "") || data.short_reference}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={2} lg={2}>
            <div className="thumbnail-pic">
              { isLoadingThumbnail ?
                <div className="loader-wrapper"><span className="loader"></span></div>
                :
                <img
                  id={`${data._id} + ${data._index}`}
                  src={thumbnail}
                  alt={`${data._id} + ${data._index}`}
                  width="150px"
                />
              }
            </div>
            <div>
              <PopupPDFViewer
                contentType="supportingevidence"
                module="Evidence Library"
                action="view search result"
                entry={data}
                file={data.document}
                title={data.filename}
                forClick={<Button className="data-view-btn">View Document</Button>}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={10} lg={10}>
            <div className="search-result-information">
              <div className="supporting-description">
                {data.full_reference}
              </div>
            </div>
          </Col>

        </Row>
      </div>
    )

  } else if (data._index === `${elasticPrefix}_facts`) {
    // data.supporting_evidence.forEach(evidence => {
    //   evidence["document_url"] = evidence.document
    //   evidence["document"] = evidence.document
    // })
    return (
      <Row className="search-result">
        <Col>
          <div>
            <strong>Outcome:</strong> {data.outcome}
          </div>
          <div>
            <strong>Description:</strong> {data.description}
          </div>
        </Col>
        {/* <Col>
          <strong>Input Value:</strong> {data.value}
        </Col>
        <Col>
          <KVMEvidence
            entry={ data }
            relevantEvidence={data.supporting_evidence}
            buttonText="View"
            buttonClassName=""
            module="Data Collection"
            contentType="fact"
            action="view supporting materials"
            />
        </Col> */}
      </Row>
    )
  } else if (data._index === `${elasticPrefix}_educational_resources`) {

    return (
      <Fragment>
        <center>
          <h3>{capitalizeSentence(data.title)}</h3>
        </center>
        <Row className="search-result">
          <Col xs={12} sm={12} md={2} lg={2}>
            <div className="thumbnail-pic">
              { isLoadingThumbnail ?
                <div className="loader-wrapper"><span className="loader"></span></div>
                :
                <img
                  id={`${data._id} + ${data._index}`}
                  src={thumbnail}
                  alt={`${data._id} + ${data._index}`}
                  width="150px"
                />
              }
            </div>
            <PopupPDFViewer
              contentType="educationalresource"
              module="Key Resources"
              action="view search result"
              entry={data}
              file={data.document}
              title={data.filename}
              forClick={<Button className="data-view-btn">View Document</Button>}
            />
          </Col>
          <Col xs={12} sm={12} md={10} lg={10}>
            <div className="supporting-description" style={{textAlign: 'center'}}>
              {data.uploader_notes}
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  } else if (data._index === `${elasticPrefix}_parsed_pdf_pages`) {
    let contentType = "Key Resources", module = "educationalresource", moduleName = "Key Resource"

    if (data.pdf.content_type_string === "supportingevidence") {
      contentType = "supportingevidence"
      module = "Evidence Library"
      moduleName = "Evidence Library"
    }

    return (
      <Fragment>
        <center>
          <h3>{capitalizeSentence(data.pdf.title)}</h3>
        </center>
        <Row className={`parsed_pdf_pages ${capitalizeSentence(data.pdf.title)} search-result`}>
          <Col xs={12} sm={12} md={2} lg={2}>
            <div className="thumbnail-pic">
              { isLoadingThumbnail ?
                <div className="loader-wrapper"><span className="loader"></span></div>
                :
                <img
                  id={`${data._id} + ${data._index}`}
                  src={thumbnail}
                  alt={`${data._id} + ${data._index}`}
                  width="150px"
                />
              }
            </div>
            <PopupPDFViewer
              contentType={contentType}
              module={module}
              action="view search result"
              entry={data}
              file={data.document}
              title={data.filename} page={data.page}
              forClick={<Button className="data-view-btn">View Page {data.page}</Button>}
            />
          </Col>
          <Col xs={12} sm={12} md={10} lg={10}>
            <div
              className="supporting-description"
              style={{textAlign: 'center'}}
              dangerouslySetInnerHTML={
                {__html: `PDF: ${moduleName}<br/>${data.content}`}
              }
            >
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

Data.propTypes = {
  data: PropTypes.object.isRequired
}
