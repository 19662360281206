import axios from 'axios'
import swal from 'sweetalert'
import {requestFunctions} from "../functions/requestFunctions";

const defaultState = {
  searchResults: [],
  searchTerm: ''
}

export default function manageSearch(state = defaultState, action){
  switch(action.type){
    case 'SEARCHED_DATABASE':
		  return {
        ...state,
        searchResults: action.payload
      }
    case 'SEARCHED_PDF_DATABASE':
      swal("Search complete!", {
        buttons: false,
        icon: "success",
        timer: 2000,
      });
      return {
        ...state,
        searchResults: action.payload
      }
    case 'ENTERED_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload
      }
    case 'CLEAR_SEARCH':
      return defaultState
    default:
		return state
	}
}

export const setSearchTerm = (term) => {
  return {type: 'ENTERED_SEARCH_TERM', payload: term}
}

export const queriedDatabase = data => {
  return {type: 'SEARCHED_DATABASE', payload: data}
}

export const queriedPDFDatabase = data => {
  return {type: 'SEARCHED_PDF_DATABASE', payload: data}
}

export const queryDatabase = inputs => {
  return function(dispatch){
    axios.get(process.env.REACT_APP_URL+'search/', {
      params: inputs
    }).then(response => {
			dispatch(queriedDatabase(response.data))
		}).catch(error => {
      requestFunctions.handleError(error, dispatch)
    })
  }
}

export const queryPDFDatabase = inputs => {
  return function(dispatch){
    requestFunctions.loadingAnimation("Searching...")

    axios.get(`${process.env.REACT_APP_SEARCH_URL}${inputs.search}`, {
      headers:{
        "Authorization": `JWT ${localStorage.getItem('token')}`,
        "Content-Type": "application/json"
      }
    }).then(response => {
      dispatch(queriedPDFDatabase(response.data.searched_PDFs))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "No results were found!")
    })
  }
}
