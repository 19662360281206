import React, { useState } from "react"
import { Button, Select } from "antd"
import { Col, Modal, Row } from "react-bootstrap"
import NewTargetForm from "./NewTargetForm"
import ExistingTargetForm from "./ExistingTargetForm"

export default function AddCommunicationTarget() {
  // STATE
  let [showModal, toggleModal] = useState(false)
  let [option, setOption] = useState("")

  const closeModal = () => {
    setOption("")
    toggleModal(false)
  }

  const renderDisplay = () => {
    switch (option) {
      case "new":
        return <NewTargetForm closeModal={ closeModal }/>
      case "existing":
        return <ExistingTargetForm closeModal={ closeModal }/>
      default:
        return null
    }
  }
  

  return (
    <div style={{ display: "inline-block" }}>
      <Button className="new-study-btn" onClick={ () => toggleModal(true) }>Add Communication Target</Button>
      <Modal show={ showModal } size="lg" onHide={ () => toggleModal(false) }>
        <Modal.Header closeButton>
          <Modal.Title>
            Add Communication Target
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} lg={6} xl={6}>
              <div><strong>Do you want to select an existing communication target</strong></div>
              <Select value={ option } onChange={ setOption } style={{ width: "100%" }}>
                <Select.Option value="">Please select an option...</Select.Option>
                <Select.Option value="existing">Choose existing</Select.Option>
                <Select.Option value="new">Create new</Select.Option>
              </Select>
            </Col>
          </Row>
        </Modal.Body>
        { renderDisplay() }
        { !option ? 
          <Modal.Footer>
            <Button onClick={ closeModal }>Cancel</Button>
          </Modal.Footer>
        : null}
      </Modal>
    </div>
  )
}