import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {PersistGate} from 'redux-persist/integration/react';
import rootReducer from './reducers/rootReducer';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';
import {composeWithDevTools} from 'redux-devtools-extension';
import { ConfigProvider } from 'antd';
import en_US from 'antd/lib/locale-provider/en_US'
import { unregister } from './registerServiceWorker';
import RouteContainer from './routes/RouteContainer'
import './static/css/style.css'
import './static/css/animate.min.css'
import "react-datepicker/dist/react-datepicker.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

try {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
} catch (error) {
}

if (process.env.REACT_APP_MODE === 'DEV') {
  console.log(`${process.env.REACT_APP_VERSION}-DEV`)
} else {
  console.log(process.env.REACT_APP_VERSION)
  // Sentry.init({
  //   dsn: "https://2bf18bff5ea142ef88b7fa48586a2ec3@sentry.genesisrg.com/11",
  //   integrations: [new Integrations.BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });
}

let store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<p>Loading</p>} persistor={persistor}>
      <ConfigProvider locale={en_US}>
        <BrowserRouter basename="/">
          <RouteContainer />
          { process.env.REACT_APP_MODE === "DEV" ? <div id="watermark">DEV</div> : null }
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
unregister();
