import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { Button, Select } from 'antd'
import { getStudy } from '../../../reducers/manageStudies'
import LoadingAnimation from '../../../components/LoadingAnimation'


export default function EventModal({ events, date, handleClose }) {
  // REDUX
  const dispatch = useDispatch()
  const countries = useSelector(state => state.manageUsers.countries)
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  const fetchingStudy = useSelector(state => state.manageStudies.fetchingStudy)

  // STATE
  const [selectedEvent, setSelectedEvent] = useState(undefined)

  const handleSelect = (eventId) => {
    setSelectedEvent(eventId)
    const event = events.find(event => event.id === eventId)
    dispatch(getStudy(event.study.id))
  }

  useEffect(() => {
    if (events && events.length === 1) {
      handleSelect(events[0].id)
    }
  }, [events])

  const onHide = () => {
    setSelectedEvent(undefined)
    handleClose()
  }

  return (
    <Modal show={ !!events } onHide={ onHide } size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Events</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { events && events.length ?
          <div>
            { events.length !== 1 ? 
              <div>
                <Select
                  onChange={ handleSelect }
                  placeholder="Select an event..."
                  value={ selectedEvent }
                  style={{ width: "50%", marginBottom: "25px" }}
                >
                  { events.filter(event => !event.communication_target).map(event => {
                    return (
                      <Select.Option key={ event.id } value={ event.id }>{event.title}</Select.Option>
                    )})
                  }
                </Select>
              </div>
              : null 
            }
            {
              selectedEvent ?
                fetchingStudy ? 
                  <div style={{ marginTop: "300px" }}>
                    <LoadingAnimation />
                  </div> 
                  :
                  <div>
                    <div>
                      <h6>
                        <strong>Name:</strong> { selectedStudy.name }
                      </h6>
                      <h6>
                        <strong>Description:</strong> { selectedStudy.description || "N/A" }
                      </h6>
                      <h6>
                        <strong>Primary Objective:</strong> { selectedStudy.primary_objective || "N/A" }
                      </h6>
                      <h6>
                        <strong>Secondary Objective:</strong> { selectedStudy.secondary_objective || "N/A" }
                      </h6>
                      <h6>
                        <strong>Exploratory Objective:</strong> { selectedStudy.exploratory_objective || "N/A" }
                      </h6>
                      <h6>
                        <strong>Status:</strong> { selectedStudy.status }
                      </h6>
                      <h6>
                        <strong>Type:</strong> { selectedStudy.type || "N/A" }
                      </h6>
                      <h6>
                        <strong>Regions/Countries:</strong> { selectedStudy.countries.map(country => {
                          return countries.find(c => c.url === country).name
                        }).join(", ") || "N/A" }
                      </h6>
                      <h6>
                        <strong>Duration:</strong> { selectedStudy.duration }
                      </h6>
                      <h6>
                        <strong>Interventions:</strong> { selectedStudy.interventions || "N/A" }
                      </h6>
                      <div>
                        <h6>
                          <strong>Lead Investigators:</strong>
                        </h6>
                        <ul>
                          <li>
                            <strong>Internal:</strong> { selectedStudy.internal_lead_investigators }
                          </li>
                          <li>
                            <strong>External:</strong> { selectedStudy.external_lead_investigators || "N/A" }
                          </li>
                        </ul>
                      </div>
                    </div>
                    <Link to={`/studycenter/studies/${selectedStudy.id}/basicinfo`}>
                      Go to { selectedStudy.name }
                    </Link>
                  </div>
                :
                null
            }
          </div>
        : `There are no events on the selected date...` }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ onHide }>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}