import axios from "axios"
import { clearEverything } from "./manageUsers"

const defaultState = {
  indications: [],
  selectedIndication: null,
  fetchingIndications: false,
  errorMessage: ""
}

export default function manageIndications(state = defaultState, action) {
  switch (action.type) {
    case "TOGGLE_FETCHING_INDICATIONS":
      return {
        ...state,
        fetchingIndications: action.payload
      }
    case "SET_INDICATIONS":
      return {
        ...state,
        errorMessage: "",
        fetchingIndications: false,
        indications: action.payload
      }
    case "SET_INDICATION_ERROR_MESSAGE":
      return {
        ...state,
        errorMessage: action.payload,
        fetchingIndications: false
      }
    case "SELECT_INDICATION":
      return {
        ...state,
        selectedIndication: action.payload
      }
    case "CLEAR_INDICATIONS":
      return defaultState
    default:
      return state
  }
}

export const getIndications = (history) => {
  return dispatch => {
    dispatch({ type: "TOGGLE_FETCHING_INDICATIONS", payload: true })
    axios.get(`${process.env.REACT_APP_URL}indications/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      // let rdgo = response.data.find(indication => indication.name.includes("POMC/PCSK1"))
      // let indications = [rdgo, ...response.data.filter(indication => indication.id !== rdgo.id)]
      // dispatch({ type: "SET_INDICATIONS", payload: indications })
      dispatch({ type: "SET_INDICATIONS", payload: response.data })
    }).catch(error => {
      console.log(error.response)
      dispatch({ type: "SET_INDICATION_ERROR_MESSAGE", payload: "There was an error fetching indications..." })
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        dispatch(clearEverything())
        history.replace("/login")
      }
    })
  }
}

export const selectIndication = (indication) => {
  return dispatch => {
    if (!indication) {
      dispatch({ type: "CLEAR_ACTIVITIES" })
      dispatch({ type: "CLEAR_CATEGORIES" })
      dispatch({ type: "CLEAR_CLICKS" })
      dispatch({ type: "CLEAR_CSR_CONTENTS" })
      dispatch({ type: "CLEAR_EDUCATIONAL_RESOURCES" })
      dispatch({ type: "CLEAR_EDUCATIONAL_VIDEOS" })
      dispatch({ type: "CLEAR_FACTS" })
      dispatch({ type: "CLEAR_FACT_SHEET" })
      dispatch({ type: "CLEAR_FOLDERS" })
      dispatch({ type: "CLEAR_GLOBAL_FILTERS" })
      dispatch({ type: "CLEAR_KVMS" })
      dispatch({ type: "CLEAR_MODE" })
      dispatch({ type: "CLEAR_MODULES" })
      dispatch({ type: "CLEAR_SEARCH" })
      dispatch({ type: "CLEAR_SECTIONS" })
      dispatch({ type: "CLEAR_SUMMARIES" })
      dispatch({ type: "CLEAR_STUDIES" })
      dispatch({ type: "CLEAR_STUDY_TIMELINES" })
      dispatch({ type: "CLEAR_SUPPORTING_EVIDENCE" })
    }
    dispatch({ type: "SELECT_INDICATION", payload: indication })
  }
}