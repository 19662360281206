import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import LoadingAnimation from '../../../../components/LoadingAnimation'
import UpdateCommunicationTarget from './UpdateCommunicationTarget'
import {useHistory} from "react-router-dom";
import {clearEverything} from "../../../../reducers/manageUsers";


export default function CommunicationTarget({ communicationTarget }) {
  // REACT ROUTER DOM
  const history = useHistory()

  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)

  // STATE
  let [fetchingCommunicationTargetDetails, toggleFetchingCommunicationTargetDetails] = useState(true)
  let [communicationTargetDetails, setCommunicationTargetDetails] = useState([])


  const getCommunicationTargetDetails = (communicationTargetId, studyId) => {
    toggleFetchingCommunicationTargetDetails(true)
    axios.get(`${process.env.REACT_APP_URL}communication-study-details/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
      params: { communication_target: communicationTargetId, study: studyId }
    }).then(response => {
      setCommunicationTargetDetails(response.data)
    }).catch(error => {
      console.log(error.response)
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(clearEverything())
        history.replace("/")
      }
    }).finally(() => {
      toggleFetchingCommunicationTargetDetails(false)
    })
  }

  useEffect(() => {
    getCommunicationTargetDetails(communicationTarget.id, selectedStudy.id)
  }, [])

  
  return (
    <div id="CommunicationTarget">
      <div key={ communicationTarget.id }>
        { fetchingCommunicationTargetDetails ? 
          <div style={{ marginTop: "300px" }}>
            <LoadingAnimation />
          </div>
          :
          <div>
            <UpdateCommunicationTarget
              communicationTarget={ communicationTarget }
              communicationTargetDetails={ communicationTargetDetails[0] }
            />
          </div>
        }
      </div>
    </div>
  )
}