import Swal from 'sweetalert2'
import {clearEverything} from "../reducers/manageUsers";

/**
 *
 * @param {string} title - Loading animation title message.
 */
const loadingAnimation = (title) => {
  Swal.fire({
    title: `<div class="loader-wrapper"><span class="loader"></span></div>` +
           `<div>${ title }</div>`,
    text: "Please wait.",
    showCloseButton: false,
    showConfirmButton: false,
    allowOutsideClick: false,
  })
}

/**
 *
 * @param {string} text - Success animation description message.
 */
const successAnimation = (text) => {
  Swal.fire({
    title: "Success",
    text: text,
    icon: "success",
    confirmButtonColor: "var(--brand-color-2)"
  })
}

/**
 *
 * @param {object} error - Error response.
 * @param {Dispatch<any>} dispatch - React-Redux dispatch object [useDispatch()] - used to dispatch redux functions.
 * @param {string=} message - Custom animation error message.
 * @param {History=} history - React Router Dom history object[useHistory()] - used to redirect.
 * If this object is not passed the app will be redirected using 'window.location'.
 *
 * @summary - Handles error responses and displays the error animation.
 */
const handleError = (error, dispatch, message="There was an error with the request.", history=null) => {
  console.log(error, error.response)
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 403) {
      message = "Your session has expired"
      dispatch(clearEverything())
      if (history) {
        history.replace("/login")
      } else {
        window.location = '/login'
      }
    } else if (error.response.status === 404) {
      message = "Entry not found"
    } else if (error.response.data && error.response.data.non_field_errors) {
      message = error.response.data.non_field_errors[0]
    }
  }
  Swal.fire({
    title: "Error",
    text: message,
    icon: "error",
    confirmButtonColor: "var(--brand-color-1)"
  })
}


export const requestFunctions = {
  loadingAnimation,
  successAnimation,
  handleError,
}