import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Input, Row, Col/*, Icon, Modal, Switch*/ } from 'antd'
import { setSearchTerm, queryPDFDatabase } from '../../reducers/manageSearch'
import ReactiveSearch from './components/ReactiveSearch'
import SearchPDFs from './components/SearchPDFs'
import './search.css'


export default function Search(props) {
  // REDUX
  const dispatch = useDispatch()
  const searchResults = useSelector(state => state.manageSearch.searchResults)

  // STATE
  const [searchCols] = useState([
    "educational-resources", "key-value-messages",
    "summary-points", "supporting-evidences"
  ])
  const [selectedTab, setSelectedTab] = useState("All")
  const [strictSearch/*, setStrictSearch*/] = useState(false)
  const [pdfSearchTerm, setPdfSearchTerm] = useState("")

  const handleSearch = () => {
    if (!pdfSearchTerm.length < 1) {
      let submit_array = {"search": pdfSearchTerm}
      for (let i = 0 ; i < searchCols.length ; i++) {
        submit_array[searchCols[i]] = 1
      }
      dispatch(setSearchTerm(pdfSearchTerm))
      dispatch(queryPDFDatabase(submit_array))
    }
  }

  // const info = () => {
  //   Modal.info({
  //     title: 'Info',
  //     content: (
  //       <div>
  //         <p>
  //           <strong>Adaptive:</strong><br/>
  //           Adaptive search allows for a website wide search of data,
  //           supporting evidences, key value messages, and educational resources, and it
  //           also uses text prediction.
  //         </p>
  //         <p>
  //           <strong>Strict:</strong><br/>
  //           Strict search searches the database for the contexts of the search term only in pdf documents, and
  //           it does not use text prediction.
  //         </p>
  //       </div>
  //     )
  //   })
  // }


  let results = []
  if (searchResults) {
    results = searchResults.map(entry => {
      if (entry.content_object){
        return <SearchPDFs key={entry.name} pdf={entry} />
      } else {
        return null
      }
    })
  }

  return (
    <div id="search">
      {/*<br/>*/}
      {/*Adaptive &nbsp;*/}
      {/*<Switch*/}
      {/*  onChange={() => setStrictSearch(!strictSearch)}*/}
      {/*/>*/}
      {/*  &nbsp; Strict &nbsp;&nbsp;*/}
      {/*  <Icon*/}
      {/*    style={{cursor: "pointer"}}*/}
      {/*    onClick={info}*/}
      {/*    type="info-circle-o"*/}
      {/*  />*/}
      {/*<br/>*/}
      <br/>
      {strictSearch ?
        <div>
          <Row>
            <Col span={3}></Col>
            <Col span={18}>
              <div className='search-input-form'>
                <Input.Search
                  placeholder="Enter search term..."
                  value={pdfSearchTerm}
                  onChange={(e) => setPdfSearchTerm(e.target.value)}
                  onSearch={handleSearch}
                  enterButton
                  />
              </div>
            </Col>
            <Col span={3}></Col>
          </Row>
          <br/>
          <div>
            {results}
          </div>
        </div>
        :
        <center>
          <ReactiveSearch
            selectTabs={(column) => setSelectedTab(column)}
            selectedTab={selectedTab}
          />
        </center>
      }
    </div>
  )
}