import React, {useState} from 'react'
import {Button, Input, Select} from "antd";
import {Alert, Modal} from "react-bootstrap";
import axios from "axios"
import {requestFunctions} from "../../../functions/requestFunctions";
import {useDispatch, useSelector} from "react-redux";


export default function CreateCategoryType({ selectedModule, getCategoryTypes }) {
  // REDUX
  const dispatch = useDispatch()
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const indicationsFromReducer = useSelector(state => state.manageIndications.indications)

  // STATE
  const [show, toggleShow] = useState(false)
  const [name, setName] = useState("")
  const [type/*, setType*/] = useState("CHECKBOX")
  const [indication, setIndication] = useState(selectedIndication.url)
  const [errorMessage, setErrorMessage] = useState("")

  const handleCancel = () => {
    toggleShow(false)
  }

  const handleSave = () => {
    setErrorMessage("")
    if (!name) {
      setErrorMessage("Please fill out all required fields")
      return
    }

    const categoryType = {
      name,
      type,
      module: selectedModule[0].url,
      indication: selectedIndication.url,
      created_by: {},
      modified_by: {}
    }

    requestFunctions.loadingAnimation("Creating a filter type...")

    axios.post(`${process.env.REACT_APP_URL}category-types/`, categoryType, {
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully created a filter type")
      getCategoryTypes()
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error with creating the filter type...")
      console.log(error)
    })
    handleCancel()
  }

  return (
    <React.Fragment>
      <Button
        className="custom-btn"
        onClick={() => toggleShow(true)}
        style={{ width: "100%" }}
      >
        Add Filter Type
      </Button>
      <Modal show={ show } onHide={ handleCancel }>
        <Modal.Header closeButton>
          <Modal.Title>
            Create Filter Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="neweducation" className="translucent-background-with-padding">
            <div className="field">
              <p>Name <span className="required">*</span></p>
              <Input type="text" value={ name } onChange={(e) => setName(e.target.value)} placeholder="Please enter a name..."/>
            </div>
            {/*<div className="field">*/}
            {/*  <p>Type <span className="required">*</span></p>*/}
            {/*  <Select style={{ width: "100%" }} value={ type } onChange={ (value) => setType(value) }>*/}
            {/*    <Select.Option value="CHECKBOX">Checkbox</Select.Option>*/}
            {/*    <Select.Option value="RADIO">Radio</Select.Option>*/}
            {/*  </Select>*/}
            {/*</div>*/}
            <div className="field">
              <p>
                Indication: <span className='required'>*</span>
              </p>
              <Select
                style={ { width: "100%" } }
                value={ indication }
                placeholder="Please select the indications..."
                onChange={ setIndication }
                filterOption={(inputValue, option) => {
                  return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }}
              >
                {
                  indicationsFromReducer.map(indicationFromReducer => {
                    return (
                      <Select.Option key={ indicationFromReducer.id } value={ indicationFromReducer.url }>{ indicationFromReducer.name }</Select.Option>
                    )
                  })
                }
              </Select>
            </div>
            { errorMessage ?
              <div className="alert-wrapper"><Alert variant="danger">Please fill out all required fields.</Alert></div> : null
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleCancel }>Cancel</Button>
          <Button onClick={ handleSave }>Submit</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}