import React, {useState} from 'react';
import { ReactiveBase, ReactiveList, DataSearch, SingleDropdownList } from '@appbaseio/reactivesearch';
import { Row, Col } from 'antd'
import {useSelector} from 'react-redux'
import Pagination from '../../../../components/Pagination';
import Data from "./Data";


export default function ReactiveSearch() {
  // REDUX
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)

  // STATE
  const [selected] = useState(`${process.env.REACT_APP_ELASTIC_PREFIX}_supporting_evidences,${process.env.REACT_APP_ELASTIC_PREFIX}_parsed_pdf_pages,${process.env.REACT_APP_ELASTIC_PREFIX}_educational_resources`)

  const capitalizeSentence = (sentence) => {
    if (sentence) {
      return sentence.toLowerCase().split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
    }
  }

  const checkListItem = (label) => {
    ///// THIS FUNCTION EDITS THE TEXT OF THE DROP DOWN SELECT
    ///// BASED ON THE LABEL'S INITIAL LABEL
    if (label === `${process.env.REACT_APP_ELASTIC_PREFIX}_educational_resources`) {
      return "Key Resources"
    } else if (label === `${process.env.REACT_APP_ELASTIC_PREFIX}_parsed_pdf_pages`) {
      return "PDF Pages"
    } else if (label === `${process.env.REACT_APP_ELASTIC_PREFIX}_facts`) {
      return "Data"
    } else if (label === `${process.env.REACT_APP_ELASTIC_PREFIX}_supporting_evidences`) {
      return "Evidence"
    } else if (label === "All") {
      return "All"
    } else {
      return capitalizeSentence(label.split("_").slice(1).join("_"))
    }
  }


  const customQuery = () => {
    return {
      "query": {
        "nested": {
          "path": "indications",
          "query": {
            "bool": {
              "must": [
                {"match": {"indications.id": selectedIndication.id}}
              ]
            }
          }
        }
      }
    }
  }


  return (
    <div>
      <ReactiveBase
        app={selected}
        credentials={`${process.env.REACT_APP_ES_USERNAME}:${process.env.REACT_APP_ES_PASSWORD}`}
        url={`${process.env.REACT_APP_ES_URL}`}
        >
        <Row>
          <Col sm={24} md={11} style={{marginBottom: '10px'}}>
            <DataSearch
              componentId="SearchSensor"
              dataField={["abstract","abstract.search","pdf.title","content","title","full_reference","category.category","description"]}
              placeholder="Site Wide Search"
              autosuggest={true}
              fuzziness={"AUTO"}
              showIcon={false}
              URLParams={true}
              highlight={true}
              highlightField="content"
              react={{
                and: "FilterSensor"
              }}
              innerClass={{
                input: 'big-reactive-search-input'
              }}
            />
          </Col>

          <Col xs={0} sm={0} md={2} lg={2}></Col>

          <Col sm={24} md={11} style={{marginBottom: '10px'}}>
            <SingleDropdownList
              componentId="FilterSensor"
              dataField="_index"
              size={10}
              showFilter
              selectAllLabel="All"
              placeholder="Filter By Category"
              defaultQuery={ customQuery }
              innerClass={{
                select: 'big-reactive-search-input',
                list: 'big-reactive-search-input big-reactive-single-dropdown',
              }}
              react={{
                and: "SearchSensor"
              }}
              renderItem={(label, count, isSelected) => (
                <div>
                  {checkListItem(label)}
                  <span style={{marginLeft: 5, color: isSelected ? 'red' : 'dodgerblue'}}>
                    {count}
                  </span>
                </div>
              )}
              renderLabel={(items) => {
                return (
                <div>
                  {items ? checkListItem(items) : "Filter By Category"}
                </div>
              )}}
            />
          </Col>
        </Row>

        <br/>

        <ReactiveList
          componentId="SearchResult"
          dataField="abstract, abstract.search, pdf.title, content, full_reference, category.category, description, title"
          className="result-list-container"
          pagination={true}
          paginationAt="bottom"
          loader={"Loading Results..."}
          pages={10}
          defaultQuery={ customQuery }
          react={{
            and: ["SearchSensor", "FilterSensor"]
          }}
          renderItem={(data) => <Data key={data._id} data={data}/>}
          renderPagination={({ pages, totalPages, currentPage, setPage, setSize }) => {
            return (
              <div className="reactive-search-pagination-container">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage + 1}
                  pageNeighbors={1}
                  handlePageChange={setPage}
                  reactiveSearch={true}
                />
              </div>
            )
          }}
        />
        </ReactiveBase>
    </div>
  )
}