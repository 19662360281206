import React from 'react'

// reusable chart components
import ActionBreakdownChart from '../reusable-charts/ActionBreakdownChart'
import NavigationChart from '../reusable-charts/NavigationChart'


const UserAccountCharts = ({ clicks }) => {
  return (
    <div>

      <h6>Breakdown of all User Account tracking actions:</h6>
      <ActionBreakdownChart clicks={clicks} />
      <br />
      <hr />

      <h6>Navigation clicks to User Account page by date and count:</h6>
      <NavigationChart clicks={clicks} />
      <br />
      <hr />
      
    </div>
  )
}

export default UserAccountCharts