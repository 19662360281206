import React from 'react'
import {Checkbox, Radio} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectCategories} from "../../reducers/manageCategories";
import PropTypes from "prop-types";


export default function FilterGroup({ selectedModule, filterGroup, getEntries }) {
  // REDUX
  const dispatch = useDispatch()
  const selectedCategories = useSelector(state => state.manageCategories.selectedCategories)

  const handleChange = (selectedCategory) => {
    let updatedSelection = {...selectedCategories}

    if (!updatedSelection[selectedModule.id]) updatedSelection[selectedModule.id] = {}
    if (!updatedSelection[selectedModule.id][filterGroup.id]) updatedSelection[selectedModule.id][filterGroup.id] = []

    const existingFilterObject = updatedSelection[selectedModule.id][filterGroup.id].find(category => {
      return category.id === selectedCategory.id
    })

    if (filterGroup.type === "RADIO") {
      updatedSelection[selectedModule.id][filterGroup.id] = [selectedCategory]
    } else if (!!existingFilterObject) {
      updatedSelection[selectedModule.id][filterGroup.id] = updatedSelection[selectedModule.id][filterGroup.id].filter(category => {
        return category.id !== selectedCategory.id
      })
      if (!updatedSelection[selectedModule.id][filterGroup.id].length) delete updatedSelection[selectedModule.id][filterGroup.id]
    } else {
      updatedSelection[selectedModule.id][filterGroup.id].push(selectedCategory)
    }

    dispatch(selectCategories(updatedSelection))
    getEntries()
  }

  const handleSelectAll = () => {
    let updatedSelection = {...selectedCategories}
    if (!updatedSelection[selectedModule.id]) updatedSelection[selectedModule.id] = {}
    updatedSelection[selectedModule.id][filterGroup.id] = filterGroup.categories
    dispatch(selectCategories(updatedSelection))
    getEntries()
  }

  const handleClear = () => {
    let updatedSelection = {...selectedCategories}
    if (!updatedSelection[selectedModule.id]) updatedSelection[selectedModule.id] = {}
    delete updatedSelection[selectedModule.id][filterGroup.id]
    dispatch(selectCategories(updatedSelection))
    getEntries()
  }

  const renderCategories = () => {
    if (filterGroup.categories) {
      return filterGroup.categories.sort((a, b) => a.order - b.order).map(category => {
        let checkedCategory = false
        let existingModule = selectedCategories[selectedModule.id]
        if (!!existingModule) {
          let selectedModuleCategories = selectedCategories[selectedModule.id][filterGroup.id] || []
          checkedCategory = !!selectedModuleCategories.find(selectedCategory => category.id === selectedCategory.id)
        }
        if (filterGroup.type === "RADIO") {
          return (
            <Radio
              onChange={ () => handleChange(category) }
              value={ category.id }
              key={ category.id }
              checked={ checkedCategory }
            >
              { category.name }
            </Radio>
          )
        }
        return (
          <Checkbox
            onChange={ () => handleChange(category) }
            value={ category.id }
            key={ category.id }
            checked={ checkedCategory }
          >
            { category.name }
          </Checkbox>
        )
      })
    } else {
      return "No categories"
    }
  }

  return (
    <div>
      <h5>
        <span className="filter-title">{ filterGroup.name }</span>
        {filterGroup.type === "RADIO" ? null :
          <div className="filter-action-btns">
            <div className="filter-select-all-btn" onClick={handleSelectAll}>Select All</div>
            <div className="filter-clear-all-btn" onClick={handleClear}>Clear All</div>
          </div>
        }
      </h5>
      <div className="filter-categories">
        { filterGroup.type === "RADIO" ?
          <Radio.Group value={
            selectedCategories[selectedModule.id] &&
            selectedCategories[selectedModule.id][filterGroup.id] &&
            selectedCategories[selectedModule.id][filterGroup.id].length ?
              selectedCategories[selectedModule.id][filterGroup.id][0].id
              :
              null
          }>
            { renderCategories() }
          </Radio.Group>
        :
          renderCategories()
        }
      </div>
    </div>
  )
}

FilterGroup.propType = {
  filterGroup: PropTypes.object.isRequired,
  selectedModule: PropTypes.object.isRequired,
  getEntries: PropTypes.func.isRequired
}