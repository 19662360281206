import React, {useEffect, useState} from 'react'
import './style.css'
import {Typeahead} from "react-bootstrap-typeahead";
import {useDispatch, useSelector} from "react-redux";
import {getModules} from "../../reducers/manageModules";
import LoadingAnimation from "../../components/LoadingAnimation";
import CategoryTypeList from "./components/CategoryTypeList";


export default function FilterManagementContainer() {
  // REDUX
  const dispatch = useDispatch()
  const modules = useSelector(state => state.manageModules.modules)
  const fetchingModules = useSelector(state => state.manageModules.fetchingModules)

  // STATE
  const [selectedModule, setSelectedModule] = useState([])

  useEffect(() => {
    dispatch(getModules())
  }, [])

  return (
    <div className="filter-management">
      <div className="filter-management-content">
        <h5>Select a specific module to see related filters:</h5>
        {fetchingModules ?
          <div style={{marginTop: "300px"}}>
            <LoadingAnimation/>
          </div>
          :
          <div>
            <center>
              <Typeahead
                className="module-input"
                id="basic-typeahead-single"
                labelKey="name"
                options={ modules.filter(module => module.editable_filters) }
                placeholder="Select a module..."
                selected={selectedModule}
                onChange={setSelectedModule}
              />
            </center>
            { selectedModule.length ?
              <CategoryTypeList selectedModule={ selectedModule } />
              :
              null
            }
          </div>
        }
      </div>
    </div>
  )
}