import React from 'react'
import {Button, Input, Select} from 'antd'
import {Alert, Col, Modal, Row} from 'react-bootstrap'
import { useSelector } from 'react-redux'
// import CategoryTypeFields from "../../../../components/CategoryTypeFields";


export default function StepOne(props) {
  // REDUX
  const indicationsFromReducer = useSelector(state => state.manageIndications.indications)


  return (
    <React.Fragment>
      <Modal.Body>
        <div id="newevidence" className="translucent-background-with-padding">
          <div className="field">
            Choose Document: <span className='required'>*</span>
            <br/>
            <input
              type="file"
              id="file-upload"
              onChange={ props.handleInputFile }
            />
          </div>
          <div className="field">
            <p>
              Short Reference: <span className='required'>*</span>
            </p>
            <Input.TextArea
              value={ props.short_reference }
              placeholder="Please enter a short reference..."
              className="longInput"
              name="shortReference"
              onChange={ props.handleInput }
            />
          </div>
          <div className="field">
            <p>
              Reference: <span className='required'>*</span>
            </p>
            <Input.TextArea
              value={ props.full_reference }
              placeholder="Please enter a reference..."
              className="longInput"
              rows={4}
              name="fullReference"
              onChange={ props.handleInput }
            />
          </div>
          <div className="field">
            <p>
              Evidence Abstract/Summary: <span className='required'>*</span>
            </p>
            <Input.TextArea
              value={ props.abstract }
              placeholder="Please enter an abstract/summary..."
              className="longInput"
              rows={4}
              name="abstract"
              onChange={ props.handleInput }
            />
          </div>
          <div className="field">
            <p>
              Indications: <span className='required'>*</span>
            </p>
            <Select
              style={ { minWidth: "350px" } }
              value={ props.indications }
              mode="multiple"
              placeholder="Please select the indications..."
              onChange={ (value) => props.handleSelect("indications", value) }
              filterOption={(inputValue, option) => {
                return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }}
            >
              {
                indicationsFromReducer.map(indication => {
                  return (
                    <Select.Option key={ indication.id } value={ indication.url }>{ indication.name }</Select.Option>
                  )
                })
              }
            </Select>
          </div>
          {/*<CategoryTypeFields*/}
          {/*  categoryTypes={ props.evidenceCategoryTypes }*/}
          {/*  selectedCategories={ props.selectedEvidenceCategories }*/}
          {/*  handleChangeCategories={ props.handleChangeCategory }*/}
          {/*/>*/}
          <Row>
            <Col>
              <span className="required">* - Marks required fields</span>
            </Col>
          </Row>
          { props.errorMessage ?
            <div className="alert-wrapper"><Alert variant="danger">Please fill out all required fields.</Alert></div> : null
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button key="1" onClick={ props.handleCancel }>Cancel</Button>
        <Button key="2" onClick={ () => props.changeStep(2) }>Select KVMs</Button>
      </Modal.Footer>
    </React.Fragment>
  )
}