import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { Route, Redirect, useHistory } from 'react-router-dom'
import {clearEverything, handleUserLogin} from '../reducers/manageUsers'
import { selectIndication } from '../reducers/manageIndications'


export default function PrivateRoute({ component: Component, ...rest }) {
  // REDUX
  const dispatch = useDispatch()
  
  // STATE
  const [authenticating, setAuthenticating] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  const [redirect, setRedirect] = useState(false)
  
  // REACT ROUTER DOM
  const history = useHistory()

  const checkIndication = () => {
    const indicationID = localStorage.getItem("indication")
    const token = localStorage.getItem("token")

    if (indicationID) {
      axios.get(`${process.env.REACT_APP_URL}indications/${indicationID}/`, {
        headers: { "Authorization": `JWT ${ token }`}
      }).then(response => {
        dispatch(selectIndication(response.data))
      }).catch(error => {
        dispatch(selectIndication(null))
        localStorage.removeItem("indication")
        history.replace("/indications")
      }).finally(() => {
        setAuthenticating(false)
        setAuthenticated(true)
      })
    } else {
      dispatch(selectIndication(null))
      setAuthenticating(false)
      setAuthenticated(true)
    }
  }

  const checkAuthentication = () => {
    const token = localStorage.getItem("token")
    if (rest.computedMatch.path !== "/studycenter/studies/:id?/:tab?") {
      setAuthenticated(false)
      setAuthenticating(true)
    }
    axios.get(process.env.REACT_APP_TOKEN_URL, {
      headers: { "Authorization": `JWT ${ token }` }
    }).then(response => {
      if (response.data.user) {
        dispatch(handleUserLogin(response.data.user))
      }
      checkIndication()
    }).catch(error => {
      setAuthenticating(false)
      setAuthenticated(false)
      dispatch(clearEverything())
      setRedirect(true)
    })
  }

  useEffect(() => {
    // USING rest.computedMatch.path INSTEAD OF history.location.pathname
    // BECAUSE SOME ROUTES SHARE THE SAME PATH AND DO NOT NEED TO BE
    // PUT THROUGH A LOADING SCREEN ON EVERY pathname CHANGE
    checkAuthentication()
    // eslint-disable-next-line
  // }, [rest.computedMatch.path])
  }, [history.location.pathname])

  return (
    <Route { ...rest } render={(routerProps) => {
      if (localStorage.getItem("token") && authenticated) {
        if (localStorage.getItem("indication")) {
          return <Component {...routerProps} />
        } else {
          return <Redirect to={{ pathname: "/indications", state: { from: routerProps.location }}} />  
        }
      } else if (authenticating) {
        return(
          <div className='home-loader'>
            <div className="loader-wrapper"><span className="loader"></span></div>
          </div>
        )
      } else if (redirect) {
        return <Redirect to={{ pathname: "/login", state: { from: routerProps.location }}} />
      } else {
        // return <Redirect to={{ pathname: "/login" }} />
        return <div>There has been an issue with the server</div>
      }
    }}/>
  )
}