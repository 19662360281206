import React from 'react'
import { Checkbox, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getKeyDates } from '../../../reducers/manageStudies'
import {
  changeSearchTerm,
  getTimelineStudyList,
  setSelectedStudyList,
  setSelectedTimelineFilters
} from '../../../reducers/manageStudyTimelines'
import LoadingAnimation from '../../../components/LoadingAnimation'
import TimelineStudyListModal from './TimelineStudyListModal'


export default function TimelineFilters() {
  // REDUX
  const dispatch = useDispatch()
  const fetchingKeyDates = useSelector(state => state.manageStudies.fetchingKeyDates)
  const timelineStudyList = useSelector(state => state.manageStudyTimelines.timelineStudyList)
  const selectedStudyList = useSelector(state => state.manageStudyTimelines.selectedStudyList)
  const searchTerm = useSelector(state => state.manageStudyTimelines.searchTerm)
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const fetchingTimelineStudyList = useSelector(state => state.manageStudyTimelines.fetchingTimelineStudyList)
  const timelineFilters = useSelector(state => state.manageStudyTimelines.timelineFilters)
  const selectedTimelineFilters = useSelector(state => state.manageStudyTimelines.selectedTimelineFilters)

  const handleCheckStudy = (e) => {
    let studyList = [...selectedStudyList]
    if (studyList.includes(e.target.value)) {
      studyList = studyList.filter(study => study !== e.target.value)
    } else {
      studyList = [...studyList, e.target.value]
    }
    dispatch(setSelectedStudyList(studyList))
    dispatch(getKeyDates(studyList, selectedIndication.id, null, 0, 0))
  }
  const handleCheckFilter = (e) => {
    let filterList = [...selectedTimelineFilters]
    if (filterList.includes(e.target.value)) {
      filterList = filterList.filter(study => study !== e.target.value)
    } else {
      filterList = [...filterList, e.target.value]
    }
    dispatch(setSelectedTimelineFilters(filterList))
  }

  const handleClearAllStudies = (e) => {
    if (selectedStudyList.length) {
      dispatch(setSelectedStudyList([]))
      dispatch(getKeyDates([], selectedIndication.id, null, 0, 0))
    }
  }

  const handleApplyAllStudies = (e) => {
    let studyIdList = timelineStudyList.map(study => study.id)
    dispatch(setSelectedStudyList(studyIdList))
    dispatch(getKeyDates(studyIdList, selectedIndication.id, null, 0, 0))
  }

  const handleClearAllTimelineFilters = () => dispatch(setSelectedTimelineFilters([]))
  const handleApplyAllTimelineFilters = () => dispatch(setSelectedTimelineFilters(timelineFilters))

  const handleSearch = (value) => {
    if (!value) {
      dispatch(changeSearchTerm(""))
    }
    dispatch(getTimelineStudyList())
  }

  return (
    <div id="StudyList" className={ fetchingKeyDates ? "transparent" : null }>
      <h4>Timeline Filters</h4>
      <div>
        <h5>Studies</h5>
        <Input.Search
          placeholder="Search"
          className="study-search-input"
          value={ searchTerm }
          onChange={(e) => dispatch(changeSearchTerm(e.target.value))}
          allowClear
          onSearch={ handleSearch }
          />
          { fetchingTimelineStudyList ?
            <div style={{ marginTop: "300px" }}>
              <LoadingAnimation />
            </div>
            :
            <div>
              { fetchingKeyDates ? <div className="loading-key-dates"></div> : null}
              { timelineStudyList.slice(0,10).map(study => {
                return (
                  <div key={ study.id }><Checkbox
                    checked={ !!selectedStudyList.includes(study.id) }
                    value={ study.id }
                    onChange={ handleCheckStudy }
                  >
                    { study.name }
                  </Checkbox></div>
                )
              }) }
              <div className="timeline-filter-action-btns">
                { timelineStudyList.length ?
                  <div >
                    <div className="filter-clear-all-btn" onClick={ handleClearAllStudies }>Clear All</div>
                    <div className="filter-clear-all-btn" onClick={ handleApplyAllStudies }>Apply All</div>
                  </div> : null }
                { timelineStudyList.length > 10 ? <TimelineStudyListModal /> : null }
              </div>
            </div>
          }
      </div>
      <div style={{ marginTop: "1rem" }}>
        <h5>Additional Filters</h5>
        { timelineFilters.map(filter => {
          return (
            <div key={ filter }>
              <Checkbox
                checked={ !!selectedTimelineFilters.find(selectedFilter => selectedFilter === filter) }
                value={ filter }
                onChange={ handleCheckFilter }
              >
                { filter }
              </Checkbox>
            </div>
          )
        }) }
        <div className="timeline-filter-action-btns">
          { timelineStudyList.length ?
            <div>
              <div className="filter-clear-all-btn" onClick={ handleClearAllTimelineFilters }>Clear All</div>
              <div className="filter-clear-all-btn" onClick={ handleApplyAllTimelineFilters }>Apply All</div>
            </div> : null }
          { timelineStudyList.length > 10 ? <TimelineStudyListModal /> : null }
        </div>
      </div>
    </div>
  )
}