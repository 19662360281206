import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { updateStudy } from '../../../../reducers/manageStudies'
import ReactQuill from "react-quill";


export default function Outcomes() {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)

  // STATE
  const [areOutcomesEditable, setAreOutcomesEditable] = useState(false)
  const [primaryOutcomes, setPrimaryOutcomes] = useState(selectedStudy.primary_outcomes)
  const [secondaryOutcomes, setSecondaryOutcomes] = useState(selectedStudy.secondary_outcomes)

  const handleCancel = () => {
    setAreOutcomesEditable(false)
    setPrimaryOutcomes(selectedStudy.primary_outcomes)
    setSecondaryOutcomes(selectedStudy.secondary_outcomes)
  }

  const handleSubmit = () => {
    let newStudy = new FormData()

    newStudy.append("url", selectedStudy.url)
    newStudy.append("id", selectedStudy.id)
    newStudy.append("primary_outcomes", primaryOutcomes)
    newStudy.append("secondary_outcomes", secondaryOutcomes)

    dispatch(updateStudy(newStudy))
    setAreOutcomesEditable(false)
  }

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return (
    <div>
      <div>
        <h4>Outcomes</h4>
        <hr/>
        { areOutcomesEditable ?
          <React.Fragment>
            <Button className="new-study-btn" onClick={ handleCancel }>Cancel</Button>
            <Button className="new-study-btn" onClick={ handleSubmit }>Submit</Button>

          </React.Fragment>
            :
          <Button className="new-study-btn" onClick={ () => setAreOutcomesEditable(true) }>Update Outcomes</Button>
        }
        <br/>
        <br/>
        <h6><strong>Primary</strong></h6>
        { areOutcomesEditable ?
          <ReactQuill
            modules={modules}
            formats={formats}
            value={primaryOutcomes}
            onChange={setPrimaryOutcomes}
            placeholder="Input Primary Outcomes..."
          />
          :
          <p
            className="criteria-text ql-editor"
            dangerouslySetInnerHTML={
              {__html: selectedStudy.primary_outcomes || "N/A"}
            }
          ></p>
        }
        <br/>
        <h6><strong>Secondary</strong></h6>
        { areOutcomesEditable ?
          <ReactQuill
            modules={modules}
            formats={formats}
            value={secondaryOutcomes}
            onChange={setSecondaryOutcomes}
            placeholder="Input Secondary Outcomes..."
          />
          :
          <p
            className="criteria-text ql-editor"
            dangerouslySetInnerHTML={
              {__html: selectedStudy.secondary_outcomes || "N/A"}
            }
          ></p>
        }
      </div>
    </div>
  )
}