import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import axios from 'axios'

import './style.css'
import UserFilters from './components/UserFilters'
import UsersList from './components/UsersList'
import EditUserModal from './components/EditUserModal'
import LoadingAnimation from '../../components/LoadingAnimation'
import { getUsers } from '../../reducers/manageUsers'
import { useHistory } from 'react-router-dom'
import {requestFunctions} from "../../functions/requestFunctions";


const UserManagementContainer = () => {
  // REACT ROUTER DOM
  const history = useHistory()
  
  // REDUX
  const dispatch = useDispatch()
  const fetchingUsers = useSelector((state) => state.manageUsers.fetchingUsers)
  const loggedInUser = useSelector((state) => state.manageUsers.user)
  const selectedIndication = useSelector((state) => state.manageIndications.selectedIndication)

  useEffect(() => {
    if (!selectedIndication) {
      history.replace("/indications")
      return
    }
    dispatch(getUsers())
  }, [])


  // STATE
  const [showActiveUsers, setShowActiveUsers] = useState(true)
  const [showInactiveUsers, setShowInactiveUsers] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [showEditModal, setShowEditModal] = useState(false)
  const [userToEdit, setUserToEdit] = useState({})

  // FUNCTIONS
  function handleEditUser(user) {
    setUserToEdit(user)
    setShowEditModal(true)
  }

  function handleSubmitEditedUser(userData) {
    let user = new FormData()
    user.append("first_name", userData.first_name)
    user.append("last_name", userData.last_name)
    user.append("is_active", userData.is_active)

    if (userData.is_active === true) {
      user.append("initial_login", false)
    }
    requestFunctions.loadingAnimation("Saving Edited User...")

    axios.patch(`${process.env.REACT_APP_URL}users/${userData.id}/`, user, {
      headers: {
        "Authorization": "JWT "+localStorage.getItem("token"),
      },
    })
    .then(response => {
      requestFunctions.successAnimation("User updated successfully.")
      setShowEditModal(false)
      setUserToEdit({})
      dispatch(getUsers())
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "User was not updated successfully.", history)
    })
  }


  // RENDER
  return (
    <div className="user-management-container">
      <Row>
        <Col xs={24} sm={24} md={7} lg={6}>
          <UserFilters
            showActiveUsers={ showActiveUsers }
            setShowActiveUsers={ setShowActiveUsers }
            showInactiveUsers={ showInactiveUsers }
            setShowInactiveUsers={ setShowInactiveUsers }
            searchTerm={ searchTerm }
            setSearchTerm={ setSearchTerm }
          />
        </Col>
        <Col xs={24} sm={24} md={17} lg={18}>
          {fetchingUsers ?
            <div style={{ marginTop: "300px" }}>
              <LoadingAnimation />
            </div>
            :
            <UsersList
              loggedInUser={loggedInUser}
              searchTerm={ searchTerm }
              showActiveUsers={ showActiveUsers }
              showInactiveUsers={ showInactiveUsers }
              handleEditUser={handleEditUser}
            />
          }
        </Col>
      </Row>
      {showEditModal ?
        <EditUserModal 
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal} 
          handleSubmitEditedUser={handleSubmitEditedUser}
          user={userToEdit}
          loggedInUser={loggedInUser}
        /> :
        null
      }
    </div>
  )
}

export default UserManagementContainer