import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import swal from 'sweetalert'
import {Button, Select, Col, Row, Icon} from 'antd'
import { Modal } from 'react-bootstrap'
import 'antd/dist/antd.css'
import GVDTableOfContents from './GVDTableOfContents'
import LoadingAnimation from "../../components/LoadingAnimation";
import {requestFunctions} from "../../functions/requestFunctions";


export default function GVDViewer({ forClick }) {
  // REDUX
	const dispatch = useDispatch()
  const gvds = useSelector(state => state.manageEducation.gvds)

	// STATE
	const [visible, setVisible] = useState(false)
	const [showTableOfContents, setShowTableOfContents] = useState(false)
  const [downloading, setDownloading] = useState(false)
	const [current, setCurrent] = useState(0)
	const [inFrame, setInFrame] = useState(<div style={{ marginTop: "300px" }}><LoadingAnimation /></div>)

  const options = gvds.map((gvd,index) => {
    return (
      <Select.Option key={index} value={index}>
        { gvd.title.substring(0, 100) }
      </Select.Option>
    )
  })

  const initiateDownload = () => {
    setDownloading(true)
    axios.get(`${process.env.REACT_APP_URL}gvds/zip_gvds/`, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/zip",
        "Authorization": `JWT ${localStorage.getItem("token")}`
      }
    }).then(response => {
      swal({
        title: "Your files is ready!",
        icon: "success",
        className:"MediaDownloader-swal",
        button: {text: "Download"}
      }).then((isClicked) => {
        if (isClicked) {
          let a = document.createElement('a')
          a.href = URL.createObjectURL(response.data)
          a.download = `${process.env.REACT_APP_DRUG_NAME}-Dossiers.zip`
          a.click()
        }
      })
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "Sorry, your file could not be downloaded.")
    }).finally(() => {
      setDownloading(false)
    })
  }

	const showModal = (page=1) => {
    setShowTableOfContents(false)
    setInFrame(<div style={{ marginTop: "300px" }}><LoadingAnimation /></div>)
    setVisible(true)

    axios.get(`${gvds[current].document_url}#zoom=100`, {
      responseType: "blob",
      headers: { "Content-Type": "application/pdf" }
    }).then(response => {
      setInFrame(
        <iframe
          src={`${URL.createObjectURL(response.data)}#page=${ page }`}
          title="gvd"
          frameBorder="0"
          style={ { height: "80vh", width: "100%" } }
        >
        </iframe>
      )
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "Document could not be loaded...")
			setInFrame("Document could not be loaded...")
    })
	}

  useEffect(() => {
		if (visible) {
			showModal()
		}
	}, [current])

  const antIcon = <Icon type="loading" style={{ verticalAlign: "0rem" }} spin />;

  return (
    <div>
      { forClick ? <span onClick={ showModal }>{forClick}</span> :
        <Button className='kvm-show-btn ant-btn-background-ghost' onClick={ showModal }>View GVD</Button>
      }
      { visible ?
          <Modal
            show={visible}
            onHide={() => setVisible(false)}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Dossiers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <strong>
                Select a Dossier:
              </strong>
              <br/>
              <Row>
                <Col xs={18} sm={18} md={18} lg={18}>
                  <Select
                    style={{ width: "60%" }}
                    value={current}
                    onChange={value => {
                      setVisible(true)
                      setCurrent(value)
                    }}
                  >
                    {options}
                  </Select><br/><br/>
                  <GVDTableOfContents
                    gvd={ gvds[current] }
                    showTableOfContents={ showTableOfContents }
                    toggleShowTableOfContents={ () => setShowTableOfContents(!showTableOfContents) }
                    showModal={ showModal }
                  />
                </Col>
                {
                  gvds.length > 1 ?
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Button style={{ float: "right" }} onClick={ initiateDownload } disabled={ downloading }>
                      Download All Dossiers { downloading ? antIcon : null }
                    </Button>
                  </Col> : null
                }
              </Row>
              <hr />
              { inFrame }
            </Modal.Body>
          </Modal>
          :
          null
      }
    </div>
  );
}