// import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

import * as dates from 'date-arithmetic'
import { Navigate } from 'react-big-calendar'
import { Popover } from 'antd'

function createCalendar(currentDate) {
  if (!currentDate) {
    currentDate = moment()
  } else {
    currentDate = moment(currentDate)
  }

  const first = currentDate.clone().startOf('month')
  const last = currentDate.clone().endOf('month')
  const weeksCount = Math.ceil((first.day() + last.date()) / 7)
  const calendar = Object.assign([], { currentDate, first, last })

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = []
    calendar.push(week)
    calendar.year = currentDate.year()
    calendar.month = currentDate.month()

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set('date', day + 1 - first.day())
      date.calendar = calendar
      week.push(date)
    }
  }
  return calendar
}

function CalendarDate(props) {
  const { dateToRender, dateOfMonth, events } = props
  const today =
    dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ? 'today'
      : ''

  let dateEvents = events.filter(event => moment(event.start).format("YYYY-MM-DD") === dateToRender.format('YYYY-MM-DD') || moment(event.end).format("YYYY-MM-DD") === dateToRender.format('YYYY-MM-DD'))

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    )
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    )
  }

  const dateHasCommunicationTargets = !!dateEvents.find(event => !!event.communication_target)
  const dateHasKeyDates = !!dateEvents.find(event => !!event.study)
  const content = dateEvents.length ? dateEvents.map(event => {
    return (
      <div
      key={ event.id }
      className="event-popover-content"
      style={{ backgroundColor: event.backgroundColor }}
      onClick={() => props.onSelectEvent(event)}
      >
        {event.title}
      </div>
    )
  }) : "There are no events for the selected date."
  
  const className = `
    date
    in-month
    ${today}
    ${dateEvents.length ? "marked-date" : ""}
    ${dateHasKeyDates ? "key-dates" : ""}
    ${dateHasCommunicationTargets ? "communication-targets" : ""}
  `

  return (
    <Popover content={ content } trigger="click" title="Events">
      <button
        className={ className }
        onClick={() => {
          // if (dateEvents.length) {
          //   props.onSelectEvents(dateEvents)
          // }
        }}>
        {dateToRender.date()}
      </button>
    </Popover>
  )
}

class Calendar extends React.Component {
  state = {
    calendar: undefined
  }

  componentDidMount() {
    this.setState({ calendar: createCalendar(this.props.date) })
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.setState({ calendar: createCalendar(this.props.date) })
    }
  }

  render() {
    if (!this.state.calendar) {
      return null
    }

    return (
      <div className="month">
        <div className="month-name">
          <span 
            onClick={ () => this.props.selectQuarterDate(new Date(this.state.calendar.currentDate), "month") }
            style={{ cursor: "pointer" }}
          >
            {this.state.calendar.currentDate.format('MMMM').toUpperCase()}
          </span>
        </div>
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
          <span key={index} className="day">
            {day}
          </span>
        ))}
        {this.state.calendar.map((week, index) => (
          <div key={index}>
            {week.map(date => (
              <CalendarDate
                key={ date.date() }
                events={ this.props.events }
                dateToRender={ date }
                dateOfMonth={ this.state.calendar.currentDate }
                onSelectEvent={ this.props.onSelectEvent }
                onSelectEvents={ this.props.onSelectEvents }
              />
            ))}
          </div>
        ))}
      </div>
    )
  }
}

class Quarter extends React.Component {
  render() {
    let {
      date,
      events,
      selectQuarterDate,
      onSelectEvent,
      onSelectEvents
    } = this.props
    const months = []
    const firstMonth = dates.startOf(date, 'year')
    const currentMonthIndex = date.getMonth()
    let start = 0
    let limit = 0

    switch(currentMonthIndex % 3){
      case 0:
        start = currentMonthIndex
        limit = currentMonthIndex + 2
        break
      case 1:
        start = currentMonthIndex - 1
        limit = currentMonthIndex + 1
        break
      case 2:
        start = currentMonthIndex - 2
        limit = currentMonthIndex
        break
      default:
        start = 0
        limit = 0
        break
    }

    for (let i = start; i <= limit; i++) {
      months.push(
        <Calendar key={i + 1}
          events={events}
          selectQuarterDate={ selectQuarterDate }
          onSelectEvents={ onSelectEvents }
          onSelectEvent={ onSelectEvent }
          date={dates.add(firstMonth, i, 'month')}
        />
      )
    }

    return <div className="year">{months.map(month => month)}</div>
  }
}

Quarter.range = date => {
  return [dates.startOf(date, 'year')]
}

Quarter.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return dates.add(date, -3, 'month')

    case Navigate.NEXT:
      return dates.add(date, 3, 'month')

    default:
      return date
  }
}

Quarter.title = (date) => {
  let selectedMonthString = date.toLocaleString('default', { month: 'long' })
  const quarters = {
    "Q1": ["January", "February", "March"],
    "Q2": ["April", "May", "June"],
    "Q3": ["July", "August", "September"],
    "Q4": ["October", "November", "December"],
  }
  let quarter = ""
  
  if (quarters["Q1"].includes(selectedMonthString)) quarter = "Q1"
  else if (quarters["Q2"].includes(selectedMonthString)) quarter = "Q2"
  else if (quarters["Q3"].includes(selectedMonthString)) quarter = "Q3"
  else if (quarters["Q4"].includes(selectedMonthString)) quarter = "Q4"
  else quarter = "N/A"

  return `${quarter} ${date.getFullYear()}`
}

export default Quarter
