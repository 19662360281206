import axios from 'axios'
import {requestFunctions} from "../functions/requestFunctions";

let defaultState = {
  summaries: [],
  fetchingSummaries: false,
}


export default function manageSummaries(state = defaultState, action){
  switch (action.type) {
    case 'FETCHING_SUMMARIES':
      return {...state, fetchingSummaries: true }
    case 'SET_SUMMARIES':
      return {...state, summaries: action.payload, fetchingSummaries: false }
    case 'CLEAR_SUMMARIES':
      return defaultState
    default:
		  return state
	}
}

const fetchingSummaries = () => {
  return {type: 'FETCHING_SUMMARIES'}
}

const gotSummaries = (data) => {
  return {type: 'SET_SUMMARIES', payload: data}
}

export const getSummaries = (inputs) => {
  return (dispatch) => {
    dispatch(fetchingSummaries())
    axios({
      method: "get",
      url: process.env.REACT_APP_URL+"summary-points/",
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    }).then(response => {
      dispatch(gotSummaries(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data...")
      dispatch(gotSummaries([]))
    })
  }
}
