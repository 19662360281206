import React, {useState} from 'react'
import {Collapse, Row, Col} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {submitClick} from "../../../reducers/manageActivity";
import EvidenceStatement from "./EvidenceStatement";
import {Draggable, Droppable} from "react-beautiful-dnd";


export default function MessageEvidenceStatements({ message }) {
  // REDUX
  const dispatch = useDispatch()
  const isOrderEditable = useSelector(state => state.manageKVM.isOrderEditable)

  // STATE
  const [activeKey, setActiveKey] = useState([])

  const toggleActiveKey = () => {
    if (activeKey.length > 0){
      setActiveKey([])
    } else {
      setActiveKey(['1'])
      dispatch(submitClick({
        content_type: "keyvaluemessage",
        module: "Key Value Messages",
        object_id: message.id,
        action: "view evidence statements"
      }))
    }
  }

  const img = (
    <img
      src={require(`../../../static/images/${activeKey.length ? "OpenExpand.png" : "ClosedExpand.png"}`)}
      alt="View"
      title="View"
      style={{marginLeft: "1vw", marginRight: "1vw", width: "12px"}}
    />
  )

  return (
    <Collapse
      className="kvm-collapse"
      activeKey={activeKey}
      onChange={toggleActiveKey}
    >
      <Collapse.Panel header={<p className="heading">{img} View Evidence Statements ({ message.evidence_statement_count })</p>} key='1'>
        <div id="message-note">
          <Row>
            <Col span={24} style={{overflow:"auto"}}>
              <div>
                <Droppable droppableId={`${message.id}`} renderClone={(provided, snapshot, rubric) => {
                  return (
                    <EvidenceStatement
                      innerRef={ provided.innerRef }
                      provided={ provided }
                      snapshot={ snapshot }
                      index={ rubric.source.index }
                      evidenceStatement={message.evidence_statements[rubric.source.index]}
                      isOrderEditable={ isOrderEditable }
                      message={ message }
                    />
                  )
                } } type="ES">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: "75px" }}>
                      { message.evidence_statements.map((evidenceStatement, index) => {
                        return (
                          <Draggable key={evidenceStatement.id} draggableId={`${evidenceStatement.id}`} index={index} isDragDisabled={!isOrderEditable}>
                            {(provided, snapshot) => (
                              <EvidenceStatement
                                key={ evidenceStatement.id }
                                innerRef={ provided.innerRef }
                                provided={ provided }
                                snapshot={ snapshot }
                                index={ index }
                                evidenceStatement={ evidenceStatement }
                                isOrderEditable={ isOrderEditable }
                                message={ message }
                              />
                            )}
                          </Draggable>
                        )
                        })
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </Col>
          </Row>
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}
