import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'antd'
import {getStudies, getStudy, setKeyDatePage, setKeyDateSearchTerm} from '../../../reducers/manageStudies'

import LoadingAnimation from '../../../components/LoadingAnimation'
import StudyList from './StudyList'
import Study from './Study'

import "../studycenter.css"


export default function StudyContainer(props) {
  const history = useHistory()
  // REDUX
  const dispatch = useDispatch()
  const fetchingStudy = useSelector(state => state.manageStudies.fetchingStudy)
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)


  useEffect(() => {
    dispatch(getStudies())
  }, [])

  useEffect(() => {
    // CHECK THE URL PARAMS TO CHECK IF A STUDY ID WAS SELECTED
    if (props.match.params.id) {
      if (!props.match.params.tab) {
        props.match.params.tab = "basicinfo"
        history.replace(`/studycenter/studies/${props.match.params.id}/basicinfo`)
      }
      if ((selectedStudy && props.match.params.id !== selectedStudy.id.toString()) || !selectedStudy) {
        // IF THE STUDIES CHANGE THIS RESETS THE FILTERS FOR THE KEY DATES
        // BECAUSE OTHERWISE THE SAME FILTERS WOULD BE APPLIED ACROSS ALL KEY DATES WITHIN ALL STUDIES
        dispatch(setKeyDatePage(1))
        dispatch(setKeyDateSearchTerm(""))
      }
      dispatch(getStudy(props.match.params.id))
    } else {
      dispatch({ type: "SET_SELECTED_STUDY", payload: null })
    }
  }, [props.match.params.id, props.match.params.tab])

  return (
    <div className="study-center" id="StudyCenterContainer">
      <div>
        <Row>
          <Col  xs={24} sm={24} md={7} lg={6}>
            <StudyList match={ props.match } />
          </Col>
          <Col  xs={24} sm={24} md={17} lg={18}>
            <div id="Study" className="brand-image-background">
            { fetchingStudy ? 
              <LoadingAnimation />
              :
              !!selectedStudy ? 
                <Study match={ props.match } />
                :
                "Please select a study."
            }
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}