import React from 'react'
import AllModulesCharts from './module-containers/AllModulesCharts'
import HomeCharts from './module-containers/HomeCharts'
import KVMCharts from './module-containers/KVMCharts'
import EvidenceLibraryCharts from './module-containers/EvidenceLibraryCharts'
import FactSheetsCharts from './module-containers/FactSheetsCharts'
import KeyResourcesCharts from './module-containers/KeyResourcesCharts'
import ClinicalDataCharts from './module-containers/ClinicalDataCharts'
import UserAccountCharts from './module-containers/UserAccountCharts'
import SearchCharts from './module-containers/SearchCharts'
import StudyCenterCharts from './module-containers/StudyCenterCharts'
import IndicationsCharts from './module-containers/IndicationsCharts'
import {useSelector} from "react-redux";


const ChartsContainer = ({ selectedModule }) => {
  // REDUX
  const modules = useSelector(state => state.manageModules.modules)
  const clicks = useSelector(state => state.manageActivity.clicks)

  // FUNCTIONS
  const renderCharts = () => {
    let moduleName = "All"
    if (selectedModule !== "All") {
      moduleName = modules.find(module => module.id.toString() === selectedModule).name
    }
    switch(moduleName) {
      case "All":
        return <AllModulesCharts clicks={clicks} />
      case "Home":
        return <HomeCharts clicks={clicks} />
      case "Key Value Messages":
        return <KVMCharts clicks={clicks} />
      case "Evidence Library":
        return <EvidenceLibraryCharts clicks={clicks} />
      case "Data Collection":
        return <FactSheetsCharts clicks={clicks} />
      case "Key Resources":
        return <KeyResourcesCharts clicks={clicks} />
      case "Clinical Study Report":
        return <ClinicalDataCharts clicks={clicks} />
      case "User Account":
        return <UserAccountCharts clicks={clicks} />
      case "Search":
        return <SearchCharts clicks={clicks} />
      case "Study Center":
        return <StudyCenterCharts clicks={clicks} />
      case "Indications":
        return <IndicationsCharts clicks={clicks} />
      default:
        break
    }
  }

  // RENDER
  return (
    <div className="charts-container">
      {clicks.length ? renderCharts() : <h4>There is no usage data for the selected module...</h4>}
    </div>
  )
}

export default ChartsContainer