import React from 'react'


export default function PageNotFound() {
  return (
    <div style={{textAlign: "center"}} id="PageNotFound">
      <h3>Error 404</h3>
      <p>
        It looks like you've reached a URL that doesn't exist. <br/>Please use the navigation above
        to navigate to an existing page.
      </p>
    </div>
  )
}