import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Container, Col, Row } from "react-bootstrap";
import { Button, Input } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { requestFunctions } from "../../../functions/requestFunctions";
import CategoryType from "./CategoryType";
import CreateCategoryType from "./CreateCategoryType";
import LoadingAnimation from "../../../components/LoadingAnimation";
import axios from 'axios'
import CategoryTypeListItem from "./CategoryTypeListItem";


export default function CategoryTypeList(props) {
  // REDUX
  const dispatch = useDispatch()
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)

  // PROPS
  const { selectedModule } = props

  // STATE
  const [categoryTypes, setCategoryTypes] = useState([])
  const [fetchingCategoryTypes, toggleFetchingCategoryTypes] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedCategoryType, setSelectedCategoryType] = useState(null)
  const [isCategoryTypeOrderEditable, setIsCategoryTypeOrderEditable] = useState(true)

  const getCategoryTypes = (term="") => {
    setSearchTerm(term)
    setIsCategoryTypeOrderEditable(false)
    toggleFetchingCategoryTypes(true)
    axios.get(`${process.env.REACT_APP_URL}category-types/`, {
      headers : { "Authorization": `JWT ${ localStorage.getItem("token") }` },
      params: { module: selectedModule[0].id, name__icontains: term, indication: selectedIndication.id }
    }).then(response => {
      setCategoryTypes(response.data)
    }).catch(error => {
      requestFunctions.handleError(error, dispatch)
    }).finally(() => {
      toggleFetchingCategoryTypes(false)
    })
  }

  useEffect(() => {
    if (selectedModule.length) {
      getCategoryTypes()
    }
  }, [selectedModule])

  const cancelCategoryTypeOrdering = () => {
    setIsCategoryTypeOrderEditable(false)
    getCategoryTypes()
  }

  const handleSaveOrder = () => {
    requestFunctions.loadingAnimation("Updating the filter order...")
    const updatedOrder = categoryTypes.map((categoryType, index) => {
      return {...categoryType, order: index + 1}
    })
    axios.post(`${ process.env.REACT_APP_URL }category-types/edit_category_type_order/`, updatedOrder, {
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` }
    }).then(response => {
      console.log(response)
      requestFunctions.successAnimation("Successfully updated the filter order.")
      setIsCategoryTypeOrderEditable(false)
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error updating the filter order...")
    })
    console.log(updatedOrder)
  }

  const orderAlphabetically = () => {
    const orderedCategories = [...categoryTypes].sort((a, b) => a.name.localeCompare(b.name))
    setCategoryTypes(orderedCategories)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    if (result.type === "categoryType") {
      const items = reorder(categoryTypes, sourceIndex, destIndex)
      setCategoryTypes(items)
    }
  }


  return (
    <Container id="CategoryTypeList">
      <Row>
        <Col xs={ 6 } md={ 6 } lg={ 6 } style={{borderRight: "1px solid rgba(0,0,0,.1)"}}>
          <h6>Filter Types</h6>
          <Input.Search
            placeholder="Search"
            className="study-search-input"
            defaultValue={ searchTerm }
            // onChange={(e) => setSearchTerm(e.target.value)}
            allowClear
            onSearch={ (value) => getCategoryTypes(value) }
          />

          { fetchingCategoryTypes ?
            <div style={{marginTop: "300px"}}>
              <LoadingAnimation/>
            </div>
            :
            <React.Fragment>
              {
                categoryTypes.length ?
                  <DragDropContext onDragEnd={ onDragEnd }>
                    <Droppable droppableId="categoryType" type="categoryType" direction="vertical">
                      { (provided) => (
                        <div { ...provided.droppableProps } ref={ provided.innerRef }>
                          { categoryTypes.map((categoryType, index) =>  (
                            <Draggable key={ categoryType.id } draggableId={`${ categoryType.id }`} index={ index } isDragDisabled={ !isCategoryTypeOrderEditable }>
                              {(provided, snapshot) => (
                                <CategoryTypeListItem
                                  innerRef={ provided.innerRef }
                                  provided={ provided }
                                  categoryType={ categoryType }
                                  snapshot={ snapshot }
                                  index={ index }
                                  key={ categoryType.id }
                                  selectedCategoryType={ selectedCategoryType }
                                  setSelectedCategoryType={ setSelectedCategoryType }
                                  isCategoryTypeOrderEditable={ isCategoryTypeOrderEditable }
                                />
                              )}
                            </Draggable>
                          ))}
                          { provided.placeholder }
                        </div>
                      )
                          }
                    </Droppable>
                  </DragDropContext>
                  :
                <Row>
                  <div style={{padding: "15px"}}>There are no filter types for the selected module...</div>
                </Row>
              }
              <div style={{ marginTop: "10px" }}>
                { isCategoryTypeOrderEditable ?
                  <React.Fragment>
                    <Row style={{ padding: "0 15px", marginBottom: "5px" }}>
                      <Col sm={ 6 } md={ 6 } lg={ 6 } style={{ padding: "0 5px 0 0" }}>
                        <Button
                          className="custom-btn"
                          style={ { width: "100%" } }
                          onClick={ cancelCategoryTypeOrdering }
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col sm={ 6 } md={ 6 } lg={ 6 } style={{ padding: "0 0 0 5px" }}>
                        <Button
                          className="custom-btn"
                          style={ { width: "100%" } }
                          onClick={ handleSaveOrder }
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                    <Col sm={ 12 } md={ 12 } lg={ 12 }>
                      <Button
                        className="custom-btn"
                        style={ { width: "100%", marginBottom: "5px" } }
                        onClick={ orderAlphabetically }
                      >
                        Order Alphabetically
                      </Button>
                    </Col>
                  </Row>
                  </React.Fragment>
                :
                  <React.Fragment>
                    { !searchTerm ?
                      <Button
                        className="custom-btn"
                        style={{ width: "100%", marginBottom: "5px" }}
                        onClick={ () => setIsCategoryTypeOrderEditable(true) }
                      >
                        Edit Order
                      </Button>
                      :
                      null
                    }
                    <Row style={{ padding: "0 15px" }}>
                      <CreateCategoryType selectedModule={ selectedModule } getCategoryTypes={() => getCategoryTypes(searchTerm)}/>
                    </Row>
                  </React.Fragment>
                }
              </div>
            </React.Fragment>
            }
        </Col>
        <Col xs={ 6 } md={ 6 } lg={ 6 }>
          { !!selectedCategoryType ?
            <CategoryType
              categoryTypes={ categoryTypes }
              categoryType={ selectedCategoryType }
              getCategoryTypes={ getCategoryTypes }
              selectedModule={ selectedModule }
              setSelectedCategoryType={ setSelectedCategoryType }
            /> : "Select a filter type" }
        </Col>
      </Row>
    </Container>
  )
}