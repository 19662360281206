import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ActionBreakdownChart from '../reusable-charts/ActionBreakdownChart'
import NavigationChart from '../reusable-charts/NavigationChart'
import ViewChart from '../reusable-charts/ViewChart'
import ModifyChart from '../reusable-charts/ModifyChart'
import DownloadChart from '../reusable-charts/DownloadChart'

import { getAllEvidence } from '../../../../reducers/manageSupportingEvidence'


const EvidenceLibraryCharts = ({ clicks, ...props }) => {

  useEffect(() => {
    props.getAllEvidence()
  }, [])

  const evidenceViewClicks = clicks.filter(click => click.action === "view - Evidence Library")
  const evidenceModifyClicks = clicks.filter(click => click.action === "modify - Evidence Library")
  const evidenceDownloadClicks = clicks.filter(click => click.action === "download - Evidence Library")


  // RENDER
  return (
    <div>
      {!props.allEvidence.length ?
        <div>
          Fetching Evidence Library usage data...
        </div>
        :
        <div>
          <h6>Breakdown of all Evidence Library tracking actions:</h6>
          <ActionBreakdownChart clicks={clicks} />
          <br />
          <hr />

          <h6>Navigation clicks to Evidence Library by date and count:</h6>
          <NavigationChart clicks={clicks} />
          <br />
          <hr />

          {evidenceViewClicks ?
            <div>
              <h6>Number of times individual evidence was <strong>viewed</strong>:</h6>
              <ViewChart clicks={evidenceViewClicks} viewObjsArr={props.allEvidence} context="Evidence" />
              <hr />
              <br />
            </div>
            :
            null
          }

          {evidenceModifyClicks ?
            <div>
              <h6>Number of times individual evidence was <strong>modified</strong>:</h6>
              <ModifyChart clicks={evidenceModifyClicks} modifyObjsArr={props.allEvidence} context="Evidence" />
              <hr />
              <br />
            </div>
            :
            null
          }

          {evidenceDownloadClicks ?
            <div>
              <h6>Number of times individual evidence was <strong>downloaded</strong>:</h6>
              <DownloadChart clicks={evidenceDownloadClicks} downloadObjsArr={props.allEvidence} context="Evidence" />
              <hr />
              <br />
            </div>
            :
            null
          }
        </div>
      }

      
    </div>
  )
}


// REDUX
const mapStateToProps = state => {
  return {
    allEvidence: state.manageSupportingEvidence.allEvidence,
  }
}

const mapDispatchToProps = {
  getAllEvidence
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EvidenceLibraryCharts))