import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { Button } from 'antd'


const UsersList = (props) => {
  const {
    handleEditUser,
    searchTerm,
    showActiveUsers,
    showInactiveUsers
  } = props

  // REDUX
  const currentUser = useSelector((state) => state.manageUsers.user)
  const allUsers = useSelector((state) => state.manageUsers.users)

  // STATE
  const [userList, setUserList] = useState(allUsers)
  const [sortBy, setSortBy] = useState("email")
  const [sortByOrder, setSortByOrder] = useState("descending")
  
  useEffect(() => {
    setUserList(allUsers)
  }, [allUsers])

  useEffect(() => {
    setUserList(
      allUsers.filter(user => {
        return (
          (user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (user.first_name && user.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.last_name && user.last_name.toLowerCase().includes(searchTerm.toLowerCase()))) &&
          (user.is_active === showActiveUsers || !user.is_active === showInactiveUsers)
        )
      })
    )
  }, [searchTerm, showActiveUsers, showInactiveUsers])

  // FUNCTIONS
  function handleSetSort(column) {
    if (sortBy === column) {
      if (sortByOrder === "descending") {
        setSortByOrder("ascending")
      } else {
        setSortByOrder("descending")
      }
    } else {
      setSortBy(column)
      setSortByOrder("descending")
    }
  }

  function renderUsers() {
    return userList.sort((a, b) => {
      if(sortByOrder === "descending") {
        if (typeof a[sortBy] === "boolean") return a[sortBy] - b[sortBy]
        return a[sortBy].localeCompare(b[sortBy])
      } else {
        if (typeof a[sortBy] === "boolean") return b[sortBy] - a[sortBy]
        return b[sortBy].localeCompare(a[sortBy])
      }
    }).map((user, idx) => {
      return (
        <tr key={ idx } className={ `user-row ${ currentUser.id === user.id ? "current-user" : "" }` }>
          <td>{ user.email }</td>
          <td>{ user.first_name }</td>
          <td>{ user.last_name }</td>
          <td className="centered">{user.is_active ? <CheckOutlined /> : <CloseOutlined />}</td>
          <td className="centered"><Button onClick={() => handleEditUser(user)}>Edit</Button></td>
        </tr>
      )
    })
  }


  // RENDER
  return (
    <div id="user-list">
      <table id="user-list-table">
        <thead className="table-header">
          <tr>
            <td>
              <span onClick={ () => handleSetSort("email") }>
                Email 
                { sortBy === "email" && sortByOrder === "descending" ? <CaretDownOutlined /> : null }
                { sortBy === "email" && sortByOrder === "ascending" ? <CaretUpOutlined /> : null }
              </span>
            </td>
            <td>
              <span onClick={ () => handleSetSort("first_name") }>
                First Name
                { sortBy === "first_name" && sortByOrder === "descending" ? <CaretDownOutlined /> : null }
                { sortBy === "first_name" && sortByOrder === "ascending" ? <CaretUpOutlined /> : null }
              </span>
            </td>
            <td>
              <span onClick={ () => handleSetSort("last_name") }>
                Last Name
                { sortBy === "last_name" && sortByOrder === "descending" ? <CaretDownOutlined /> : null }
                { sortBy === "last_name" && sortByOrder === "ascending" ? <CaretUpOutlined /> : null }
              </span>
            </td>
            <td className="centered">
              <span onClick={ () => handleSetSort("is_active") }>
                Activated
                { sortBy === "is_active" && sortByOrder === "descending" ? <CaretDownOutlined /> : null }
                { sortBy === "is_active" && sortByOrder === "ascending" ? <CaretUpOutlined /> : null }
              </span>
            </td>
            <td className="centered">Actions</td>
          </tr>
        </thead>
        <tbody>
          { userList.length ? 
            renderUsers()
            : 
            <tr><td colSpan={5}>No Data</td></tr>
          }
        </tbody>
      </table>
    </div>
  )
}

export default UsersList