import axios from 'axios';

let defaultState = {
  modules: [],
  fetchingModules: false
}

export default function manageModules(state = defaultState, action) {
  switch(action.type) {
    case "FETCHING_MODULES":
      return { ...state, fetchingModules: true }
    case "SET_MODULES":
      return { ...state, modules: action.payload, fetchingModules: false }
    case "CLEAR_MODULES":
      return defaultState
    default:
      return state
  }
}

export const getModules = () => {
  return dispatch => {
    dispatch({ type: "FETCHING_MODULES" })
    axios.get(`${process.env.REACT_APP_URL}modules/`, {
			headers: {
				"Authorization": `JWT ${localStorage.token}`
			}
		})
		.then(response => {
			dispatch({ type: "SET_MODULES", payload: response.data })
		})
		.catch(error => {
      if (error.response && error.response.status === 401) {
        window.localStorage.clear()
        window.location = '/login'
      }
      dispatch({ type: "SET_MODULES", payload: [] })
			console.log(error, error.response)
		})
  }
}