/* eslint-disable */
import axios from 'axios'
import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import {Button, message} from 'antd'
import { Modal } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import 'antd/dist/antd.css'
// import pdfjsLib from 'pdfjs-dist'
import qs from 'qs'
import { clearEverything } from '../reducers/manageUsers'

class PopupVideoViewer extends Component {
	constructor(props){
		super(props)
		this.state={visible: false, loaded: false, inFrame: "Video loading..."}
	}

	showModal = () => {
    const videoURL = this.props.videoURL

		let self = this
		axios({
			method: 'GET',
			url: videoURL,
			responseType: 'blob',
			// headers: {
			// 	"Authorization": "JWT " + localStorage.getItem("token")
			// }
		})
		.then(response => {
      //// CHECK IF THE GVD WAS LOADED BEFORE SO IT KEEPS THE STATE THAT THE USER WAS PREVIOUSLY ON
      self.setState({inFrame: (
        <ReactPlayer 
          controls={true}
          url={URL.createObjectURL(response.data)}
          width="100%"
          height="100%"
        />
      )
      })
		}).catch(error => {
      console.log(error)
			console.log(error.response)
			if (error.response && (error.response.status === 401 || error.response.status === 500)) {
        this.props.clearEverything()
        window.location = '/login'
      }
			self.setState({ inFrame: "Video could not be played" })
		})

		this.setState({ visible: true })
	}

	handleCancel = (e) => this.setState({ visible: false, loaded: false, inFrame: "Loading Video..." })

	render() {
		return (
			<Fragment>
				<this.props.forClick.type {...this.props.forClick.props} onClick={this.showModal}/>

				{/* <img src="https://www.gens1.com/eGVD/src/static/images/View.png" className="action-icon" onClick={this.showModal} /> */}
				<Modal
					show={this.state.visible}
					onHide={this.handleCancel}
					size="lg"
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{this.props.title ? this.props.title : "Education Video"}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<center>{this.state.inFrame}</center>
					</Modal.Body>
				</Modal>
			</Fragment>
		)
	}
}

const mapDispatchToProps = {
	clearEverything
}

export default connect(null, mapDispatchToProps)(PopupVideoViewer)