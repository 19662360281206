import React, {useState} from "react";
import axios from 'axios'
import {Button, Dropdown, Menu, Col, Row, Popconfirm} from "antd";
import {DownOutlined} from "@ant-design/icons";
import ReactQuill from 'react-quill';
import PropTypes from "prop-types";
import 'react-quill/dist/quill.snow.css';
import {requestFunctions} from "../../../functions/requestFunctions";
import {useDispatch} from "react-redux";
import {getOverarchingValuePropositions} from "../../../reducers/manageKVM";


export default function OverarchingValueProposition({ ovp }) {
  // REDUX
  const dispatch = useDispatch()
  // STATE
  const [description, setDescription] = useState(ovp.description)
  const [editable, setEditable] = useState(false)

  const handleCancel = () => {
    setEditable(false)
    setDescription(ovp.description)
  }

  const handleSave = () => {
    requestFunctions.loadingAnimation("Editing the overarching value proposition")
    axios.patch(ovp.url, { description }, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
    }).then(response => {
      requestFunctions.successAnimation("Successfully edited the overarching value proposition")
      dispatch(getOverarchingValuePropositions())
      setEditable(false)
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in editing the overarching value proposition")
    })
  }

  const handleDelete = () => {
    requestFunctions.loadingAnimation("Deleting the overarching value proposition...")
    axios.delete(ovp.url, { headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
      .then(response => {
        requestFunctions.successAnimation("Successfully deleted the overarching value proposition")
        dispatch(getOverarchingValuePropositions())
      }).catch(error => {
        requestFunctions.handleError(error, dispatch, "There was an error in deleting the overarching value proposition...")
    })
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setEditable(true)}>Edit</Menu.Item>
      <Menu.Item>
        <Popconfirm
          title="Are you sure to delete this overarching value proposition?"
          placement="left"
          onConfirm={ handleDelete }
          okText="Yes"
          cancelText="No"
        >
          Delete
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )

  const editMenu = (
    <div>
      <Button className="kvm-show-btn ant-btn-background" style={{ width: "100px" }} onClick={ handleSave }>Save</Button>
      <Button className="kvm-show-btn ant-btn-background" style={{ width: "100px" }} onClick={ handleCancel }>Cancel</Button>
    </div>
  )

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return (
    <div className="ovp" id="message">
      <Row>
        <Col xs={ 1 } sm={ 1 } md={ 1 } lg={ 1 }></Col>
        <Col xs={ 18 } sm={ 18 } md={ 18 } lg={ 18 }>
          { !editable ?
            <p
              className="text"
              // className="supporting-description"
              // style={{textAlign: 'center'}}
              dangerouslySetInnerHTML={
                {__html: description }
              }
            ></p>
            :
            <ReactQuill modules={ modules } formats={ formats } value={ description } onChange={ setDescription } />
          }
        </Col>
        <Col xs={ 5 } sm={ 5 } md={ 5 } lg={ 5 }>
          { !editable ?
          <Dropdown overlay={ menu } trigger={['click']} placement="bottomRight">
            <Button className="kvm-show-btn ant-btn-background ovp-btn" style={{ marginTop: "0" }}>
              Actions <DownOutlined />
            </Button>
          </Dropdown> : editMenu
          }
        </Col>
      </Row>
    </div>
  )
}


OverarchingValueProposition.propTypes = {
  ovp: PropTypes.object.isRequired
}