import React from 'react'
import {useSelector} from 'react-redux'
import {Icon} from 'antd'
import {CSRContent} from "./CSRContent";


export default function TableOfContents({ expanded, handleExpand }) {
  // REDUX
  const CSRTableOfContents = useSelector(state => state.manageCSRContents.tableOfContents)

  const renderTableOfContents = () => {
    if (!Object.keys(CSRTableOfContents).length) {
      return (
        <div>
          There are no CSRs available...
        </div>
      )
    }
    const content = Object.keys(CSRTableOfContents).map(csrName => {
      const csr = CSRTableOfContents[csrName]
      if (csr) {
        return <CSRContent key={csrName} csrName={csrName} csr={csr}/>
      } else {
        return null
      }
    })
    return content
  }

  return (
    <div>
      <div onClick={handleExpand}>
        <h4>
          Table of Contents
        </h4>
        <span className="table-of-contents-caret">
          <Icon type={expanded ? "caret-down" : "caret-up"} />
        </span>
      </div>
      {renderTableOfContents()}
    </div>
  )
}