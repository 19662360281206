import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Form } from 'react-bootstrap'
import { getClicks } from '../../reducers/manageActivity'
import ChartsContainer from './components/ChartsContainer'
import './usage.css'
import LoadingAnimation from "../../components/LoadingAnimation";


export default function UsageContainer() {
  // REDUX
  const dispatch = useDispatch()
  const fetchingClicks = useSelector(state => state.manageActivity.fetchingClicks)
  const modules = useSelector(state => state.manageModules.modules)

  // STATE
  const [selectedModule, setSelectedModule] = useState("All")

  useEffect(() => {
    handleSetModuleState(selectedModule)
  }, [])

  const handleSetModuleState = (module) => {
    setSelectedModule(module)
    if (module === "All") {
      dispatch(getClicks())
    } else {
      dispatch(getClicks(module))
    }
  }

  const renderModuleList = () => {
    return modules.map(module => {
      return <option key={module.id} value={module.id}>{module.name}</option>
    })
  }

  return (
    <div className="usage-container">
        <div className="usage-content">
          <h5>Welcome to the usage page!</h5>
          <p>Select a specific module to see related usage/tracking data:</p>
          <Form.Control as="select" className="module-select" onChange={e => handleSetModuleState(e.target.value)}>
            <option value={"All"}>All Modules</option>
            {renderModuleList()}
          </Form.Control>
          {fetchingClicks ?
            <div style={{marginTop: "300px"}}>
              <LoadingAnimation />
              {/*Loading usage data...*/}
            </div>
            :
          <ChartsContainer selectedModule={selectedModule} />
          }
        </div>
    </div>
  )
}