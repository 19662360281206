/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PopupPDFViewer from '../../../components/PopupPDFViewer'
import axios from 'axios'
import { requestFunctions } from "../../../functions/requestFunctions";
// import FactDisplay from './FactDisplay'


export default function WhatsNew() {
  // REDUX
  const dispatch = useDispatch()
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)

  // STATE
  const [fetchingNews, toggleFetchingNews] = useState(true)
  // const [factSheets, setFactSheets] = useState([])
  // const [evidenceLibrary, setEvidenceLibrary] = useState([])
  // const [keyResources, setKeyResources] = useState([])
  const [updates, setUpdates] = useState([])

  const grabNews = () => {
    toggleFetchingNews(true)
    axios.get(`${process.env.REACT_APP_URL}whats-new/`, {
      headers: {
        "Authorization": `JWT ${localStorage.getItem("token")}`
      },
      params: { indication: selectedIndication.id }
    }).then(response => {
      // setFactSheets(response.data.fact_sheets)
      // setEvidenceLibrary(response.data.evidence_library)
      // setKeyResources(response.data.key_resources)
      setUpdates([...response.data.evidence_library, ...response.data.key_resources].sort((a,b) => new Date(b.time) - new Date(a.time)))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error with fetching data...")
    }).finally(() => {
      toggleFetchingNews(false)
    })
  }

  useEffect(() => {
    grabNews()
  }, [])

  return (
    <div id="WhatsNew" className="home-nav facts" style={{ 'animationName':'fadeIn', 'animationDuration':'1.3s', 'animationDelay':'1s', 'animationFillMode':'both' }}>
      { fetchingNews ?
        <center style={{ marginTop: "150px" }}>
          <div className="loader-wrapper"><span className="loader"></span></div>
          <p>
            Loading Latest Updates
          </p>
        </center> :
      <div>
        <center>
          <div className="home-nav-headline">
            RECENT UPDATES
            <br/>
            <span className="icon-circle whats-new-icon">
              <img
                src={require(`../../../static/images/lightbulb.svg`)}
                alt='lightbulb'
                className="dashIcon"
              />
            </span>
          </div>
        </center>
        {/* <br/> */}
        <div className="list">
          {/*<h4 className="highlight whats-new-heading">*/}
          {/*  Evidence Library*/}
          {/*</h4>*/}
          {/*<div className="highlight" style={{fontDecoration: 'italics', overflowY: "auto", maxHeight: "90px"}}>*/}
          {/*  <table className="whats-new-table">*/}
          {/*    <tbody>*/}
          {/*      { evidenceLibrary.length ?*/}
          {/*        evidenceLibrary.map((update, index) => {*/}
          {/*          return (*/}
          {/*            <tr key={index}>*/}
          {/*              <td className="whats-new-table-update">*/}
          {/*                <PopupPDFViewer*/}
          {/*                  page={0}*/}
          {/*                  file={ update.document_url }*/}
          {/*                  title={ update.text }*/}
          {/*                  entry={ update }*/}
          {/*                  module="Evidence Library"*/}
          {/*                  contentType="supportingevidence"*/}
          {/*                  action="view"*/}
          {/*                  forClick={<a className="whats-new-link" onClick={(e) => e.preventDefault()}>{ update.text }</a>}*/}
          {/*                />*/}
          {/*              </td>*/}
          {/*              <td className="whats-new-table-filler"></td>*/}
          {/*              <td className="whats-new-table-days-ago">{update.status}</td>*/}
          {/*            </tr>*/}
          {/*          )*/}
          {/*        })*/}
          {/*        : <tr><td className="whats-new-table-update">There were no recent updates</td></tr>*/}
          {/*      }*/}
          {/*    </tbody>*/}
          {/*  </table>*/}
          {/*</div>*/}
          {/*<br/>*/}
          {/*<h4 className="highlight whats-new-heading">*/}
          {/*  Key Resources*/}
          {/*</h4>*/}
          {/*<div className="highlight" style={{fontDecoration: 'italics', overflowY: "auto", maxHeight: "90px"}}>*/}
          {/*  <table className="whats-new-table">*/}
          {/*    <tbody>*/}
          {/*      { keyResources.length ?*/}
          {/*        keyResources.map((update, index) => {*/}
          {/*          return (*/}
          {/*            <tr key={index}>*/}
          {/*              <td className="whats-new-table-update">*/}
          {/*                <PopupPDFViewer*/}
          {/*                  page={0}*/}
          {/*                  file={ update.document_url }*/}
          {/*                  title={ update.text }*/}
          {/*                  entry={ update }*/}
          {/*                  module="Key Resources"*/}
          {/*                  contentType="educationalresource"*/}
          {/*                  action="view"*/}
          {/*                  forClick={<a className="whats-new-link" onClick={(e) => e.preventDefault()}>{ update.text }</a>}*/}
          {/*                />*/}
          {/*              </td>*/}
          {/*              <td className="whats-new-table-filler"></td>*/}
          {/*              <td className="whats-new-table-days-ago">{update.status}</td>*/}
          {/*            </tr>*/}
          {/*          )*/}
          {/*        })*/}
          {/*        : <tr><td className="whats-new-table-update">There were no recent updates</td></tr>*/}
          {/*      }*/}
          {/*    </tbody>*/}
          {/*  </table>*/}
          {/*</div>*/}
          {/*<br/>*/}
          {/*<h4 className="highlight whats-new-heading">*/}
          {/*  Recent Updates*/}
          {/*</h4>*/}
          <div className="highlight" style={{fontDecoration: 'italics', overflowY: "auto"}}>
            <table className="whats-new-table">
              <thead>
                <tr>
                  <th>Update</th>
                  <th>Related To</th>
                  <th>Added/Modified Date</th>
                  {/*<th>User</th>*/}
                </tr>
              </thead>
              <tbody>
                { updates.length ?
                  updates.map((update, index) => {
                    const months = [
                      "January", "February", "March",
                      "April", "May", "June",
                      "July", "August", "September",
                      "October", "November", "December",
                    ]
                    let date = update.time.split("T")[0].split("-")

                    date = `${months[date[1] - 1]} ${date[2]}, ${date[0]}`

                    return (
                      <tr key={index}>
                        <td className="whats-new-table-update">
                          <PopupPDFViewer
                            page={0}
                            file={ update.document_url }
                            title={ update.text }
                            entry={ update }
                            module="Key Resources"
                            contentType="educationalresource"
                            action="view"
                            forClick={<a className="whats-new-link" onClick={(e) => e.preventDefault()}>{ update.text }</a>}
                          />
                        </td>
                        <td className="whats-new-table-filler">{ update.categories.length ? update.categories.join(", ") : "N/A" }</td>
                        <td className="whats-new-table-days-ago">{date}</td>
                        {/*<td className="whats-new-table-filler">By {update.updated_by}</td>*/}
                      </tr>
                    )
                  })
                  : <tr><td className="whats-new-table-update">There were no recent updates</td></tr>
                }
              </tbody>
            </table>
          </div>
          {/*<br/>*/}
          {/*<h4 className="highlight whats-new-heading">*/}
          {/*  Data Collection*/}
          {/*</h4>*/}
          {/*<div*/}
          {/*  className="highlight"*/}
          {/*  style={{marginBottom:'20px', ontDecoration: 'italics', overflowY: "auto", maxHeight: "90px" }}*/}
          {/*>*/}
          {/*  <table className="whats-new-table">*/}
          {/*  <tbody>*/}
          {/*    { factSheets.length ?*/}
          {/*      factSheets.map((update, index) => {*/}
          {/*        return (*/}
          {/*          <tr key={index}>*/}
          {/*            <td className="whats-new-table-update">*/}
          {/*              <FactDisplay*/}
          {/*                fact={ update }*/}
          {/*                forClick={<a className="whats-new-link" onClick={(e) => e.preventDefault()}>{ update.text }</a>}*/}
          {/*              />*/}
          {/*            </td>*/}
          {/*            <td className="whats-new-table-filler"></td>*/}
          {/*            <td className="whats-new-table-days-ago">{update.status}</td>*/}
          {/*          </tr>*/}
          {/*        )*/}
          {/*      })*/}
          {/*      : <tr><td className="whats-new-table-update">There were no recent updates</td></tr>*/}
          {/*    }*/}
          {/*    </tbody>*/}
          {/*  </table>*/}
          {/*</div>*/}
        </div>
      </div>
    }
    </div>
  )
}