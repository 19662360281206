import React, { useEffect, useState } from "react"
import { Button, Input } from "antd"
import { Alert, Col, Modal, Row } from "react-bootstrap"
// import DatePicker from "react-datepicker"
import { useDispatch, useSelector } from "react-redux"
import { submitKeyDate } from "../../../../reducers/manageStudies"
import {CustomDatePicker} from "../../../../components/DatePickers";
import formatDateForServer from "../../../../functions/formatDateForServer";


export default function AddUpdateKeyDate({ keyDate, mode }) {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  const keyDates = useSelector(state => state.manageStudies.keyDates)

  // STATE
  const [showModal, toggleModal] = useState(false)
  const [name, setName] = useState(keyDate ? keyDate.name : "")
  const [dateRange, setDateRange] = useState(keyDate ? [new Date(keyDate.date), new Date(keyDate.end_date)] : [null, null])
  const [startDate, endDate] = dateRange
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    setName(keyDate ? keyDate.name : "")
    setDateRange(keyDate ? [new Date(keyDate.date), new Date(keyDate.end_date)] : [null, null])
  }, [keyDate])

  const handleCancel = () => {
    toggleModal(false)
    setName(keyDate ? keyDate.name : "")
    setDateRange(keyDate ? [new Date(keyDate.date), new Date(keyDate.end_date)] : [null, null])
  }

  const handleSubmit = () => {
    setErrorMessage("")
    const studyStartKeyDate = keyDates.find(date => (date.name === "Start Date" && date.study.url === selectedStudy.url))
    const studyEndKeyDate = keyDates.find(date => (date.name === "End Date" && date.study.url === selectedStudy.url))
    if (name === "Start Date") {
      if (startDate > new Date(studyEndKeyDate.date) || endDate > new Date(studyEndKeyDate.date)) {
        setErrorMessage("Start Date cannot be greater than the End Date")
        return
      }
    } else if (name === "End Date") {
      if (startDate < new Date(studyStartKeyDate.end_date) || endDate < new Date(studyStartKeyDate.end_date)) {
        setErrorMessage("End Date cannot be less than the Start Date")
        return
      }

    }
    let newDate = new FormData()

    newDate.append("name", name)
    newDate.append("date", formatDateForServer(startDate))
    newDate.append("end_date", formatDateForServer(endDate))
    newDate.append("study", selectedStudy.url)
    selectedStudy.indications.forEach(indication => newDate.append("indications", indication))
    newDate.append("created_by", {})

    if (keyDate) {
      newDate.append("url", keyDate.url)
      if (keyDate.communication_target) {
        newDate.delete("study")
        newDate.append("communication_target", keyDate.communication_target.url)
      }
    }

    let method = "POST"
    if (mode === "update") {
      method = "PATCH"
    }
    toggleModal(false)
    dispatch(submitKeyDate(newDate, method))
    handleCancel()
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Button className="new-study-btn" onClick={ () => toggleModal(true) }>{ mode === "add" ? "Add Key Date" : "Update" }</Button>
      <Modal show={ showModal } onHide={ () => toggleModal(false) } size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            { mode === "add" ? "Add Key Date" : "Update" }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={8} lg={8} xl={8}>
              <div><strong>Name</strong></div>
              <Input value={ name }
                disabled={
                  (name === "Start Date" || name === "End Date" || (keyDate && keyDate.communication_target))
                  && mode === "update"
                }
                placeholder="Enter a name..."
                onChange={ (e) => setName(e.target.value) }
              />
            </Col>
            <Col md={4} lg={4} xl={4}>
              <div><strong>Date</strong></div>
              <CustomDatePicker
                selectsRange={ true }
                startDate={ startDate }
                endDate={ endDate }
                onChange={ (update) => setDateRange(update)}
                placeholderText="Enter date range..."
              />
            </Col>
          </Row>
          <br/>
          { errorMessage ? 
            <Alert variant="danger">
              { errorMessage }
            </Alert> : null
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleCancel }>Cancel</Button>
          <Button onClick={ handleSubmit }>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}