import axios from 'axios'
import {requestFunctions} from "../functions/requestFunctions";

let defaultState = {
  logins: [],
  fetchingLogins: false,
  serves: [],
  fetchingServes: false,
  clicks: [],
  fetchingClicks: false,
  aggregatedClicks: {},
  fetchingAggregatedClicks: false
}

export default function manageActivity(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHING_SERVES':
      return { ...state, fetchingServes: true }
    case 'GOT_SERVES':
      return { ...state, serves: action.payload, fetchingServes: false }
    case 'FETCHING_LOGINS':
      return { ...state, fetchingLogins: true }
    case 'GOT_LOGINS':
      return { ...state, logins: action.payload, fetchingLogins: false }
    case 'FETCHING_CLICKS':
      return { ...state, fetchingClicks: true }
    case 'GOT_CLICKS':
      return { ...state, clicks: action.payload, fetchingClicks: false }
    case 'FETCHING_AGGREGATED_CLICKS':
      return { ...state, fetchingAggregatedClicks: true }
    case 'GOT_AGGREGATED_CLICKS':
      return { ...state, aggregatedClicks: action.payload, fetchingAggregatedClicks: false }
    case 'SUBMIT_CLICK':
      return { ...state, click: action.payload }
    case 'CLEAR_ACTIVITIES':
      return defaultState
    default:
		  return state
	}
}

const fetchingLogins = () => {
  return {type: "FETCHING_LOGINS"}
}

const fetchingClicks = () => {
  return {type: "FETCHING_CLICKS"}
}

const gotAggregatedClicks = (data) => {
  return {type: "GOT_AGGREGATED_CLICKS", payload: data}
}

const gotLogins = data => {
  return {type: 'GOT_LOGINS', payload: data}
}

export const getLogins = () => {
  return (dispatch) => {
    dispatch(fetchingLogins())
    axios.get(`${process.env.REACT_APP_TRACKING_URL}logins/`,{ 
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    }).then(response => {
      dispatch(gotLogins(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data")
      dispatch(gotLogins([]))
    })
  }
}

const gotServes = data => {
  return {type: 'GOT_SERVES', payload: data}
}
export const getServes = () => {
  return (dispatch) => {
    axios.get(`${process.env.REACT_APP_TRACKING_URL}serves/`, {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    }).then(response => {
      dispatch(gotServes(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data")
      dispatch(gotServes([]))
    })
  }
}

const submittedClick = data => {
  return {type: 'NEW_CLICK', payload: data}
}

export const getClicks = (moduleId = "") => {
  return function(dispatch, useState){
    const { selectedIndication } = useState().manageIndications
    dispatch(fetchingClicks())
    axios.get(`${process.env.REACT_APP_TRACKING_URL}clicks/`, {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")},
      params: { indication: selectedIndication.id, action__module: moduleId }
    }).then(response => {
      dispatch({type: "GOT_CLICKS", payload: response.data})
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data")
      dispatch(gotAggregatedClicks({}))
    })
  }
}

export const getAggregatedClicks = () => {
  return function(dispatch){
    axios.get(`${process.env.REACT_APP_TRACKING_URL}clicks-aggregate/`, {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    }).then(response => {
      dispatch(gotAggregatedClicks(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data")
      dispatch(gotAggregatedClicks({}))
    })
  }
}

export const submitClick = (click) => {
  return function(dispatch, useState){
    const { selectedIndication } = useState().manageIndications
    click.indication = selectedIndication.id
    axios.post(`${process.env.REACT_APP_TRACKING_URL}clicks/`, click, {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    }).then(response => {
      dispatch(submittedClick(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in submitting a click")
    })
  }
}
