import React from 'react'
import { Checkbox, Input } from 'antd'


const UserFilters = (props) => {
  const {
    showActiveUsers,
    showInactiveUsers,
    setShowActiveUsers,
    setShowInactiveUsers,
    searchTerm,
    setSearchTerm
  } = props

  return (
    <div className="user-filters">
      {/* <h4>Select User Group</h4>
      <div className="user-filters-btns">
        <Button className={filter === "all" ? "filter-slctd" : null} value="all" onClick={handleChangeFilter}>All Users</Button>
        <Button className={filter === "active" ? "filter-slctd" : null} value="active" onClick={handleChangeFilter}>Active Users</Button>
        <Button className={filter === "inactive" ? "filter-slctd" : null} value="inactive" onClick={handleChangeFilter}>Inactive Users</Button>
        <hr style={{width: "100%"}} />
        <Button className={filter === "new" ? "filter-slctd" : null} value="new" onClick={handleChangeFilter}>New Users</Button>
      </div> */}
      <h4>Filters</h4>
      <Input.Search
        placeholder="Search"
        className="search-input"
        value={ searchTerm }
        onChange={(e) => setSearchTerm(e.target.value)}
        allowClear
        // onSearch={ handleSearch }
        />
        <div>
          <Checkbox checked={ showActiveUsers } onChange={() => setShowActiveUsers(!showActiveUsers)}>
            Active
          </Checkbox>
          <br/>
          <Checkbox checked={ showInactiveUsers } onChange={() => setShowInactiveUsers(!showInactiveUsers)}>
            Inactive
          </Checkbox>
        </div>
    </div>
  )
}

export default UserFilters