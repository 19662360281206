import axios from 'axios'
import swal from 'sweetalert'
import { message } from 'antd';
import { setCountries } from './manageGlobalFilters'
import {requestFunctions} from "../functions/requestFunctions";
import Swal from "sweetalert2";


let defaultState = {
  user: false,
  users: [],
  fetchingUsers: false,
  error: false,
  errorCount: 0,
  countries: [],
  fetchingCountries: false,
}

export default function manageUsers(state = defaultState, action) {
  switch (action.type) {
    case "FETCHING_USERS":
      return { ...state, fetchingUsers: true }
    case "FETCHING_COUNTRIES":
      return { ...state, fetchingCountries: true }
    case "GET_USERS":
      return { ...state, users: action.payload, fetchingUsers: false }
    case "GET_COUNTRIES":
      return {...state, countries: action.payload, fetchingCountries: false }
    case "LOGIN_USER":
      return { ...state, user: action.payload }
    case "DISABLE_INITIAL_LOGIN":
      if(state.user && state.user.initial_login){
        return { ...state, user: { ...state.user, initial_login: false } }
      } else {
        return state
      }
    case "BAD_LOGIN":
      if (action.payload === "User account is disabled.") {
        swal({
          title: "Error!",
          text: "User account is disabled!",
          icon: "error",
          button: "Ok",
        })
        return { ...state, error: action.payload, errorCount: state.errorCount + 1 }
      }
      if (action.payload === "This field may not be blank.") {
        action.payload = action.payload.replace("This", "Password")
      }
      swal({
        title: "Error!",
        text: action.payload,
        icon: "error",
        button: "Ok",
      })
      return {...state, error: action.payload, errorCount: state.errorCount + 1}
    case 'REMOVE_BAD_LOGIN':
      return { ...state, error: false, errorCount: 0 }
    case 'UPDATE_USER':
      const index = state.users.findIndex(user => user.id === action.payload.id)
      return {
        ...state,
        user: action.payload,
        users: [...state.users.slice(0, index), action.payload, ...state.users.slice(index + 1)]
      }
    case 'CLEAR_USERS':
      return defaultState
    default:
		  return state
	}
}

export const clearEverything = () => {
  return dispatch => {
    dispatch({ type: "CLEAR_ACTIVITIES" })
    dispatch({ type: "CLEAR_CATEGORIES" })
    dispatch({ type: "CLEAR_CLICKS" })
    dispatch({ type: "CLEAR_CSR_CONTENTS" })
    dispatch({ type: "CLEAR_EDUCATIONAL_RESOURCES" })
    dispatch({ type: "CLEAR_EDUCATIONAL_VIDEOS" })
    dispatch({ type: "CLEAR_FACTS" })
    dispatch({ type: "CLEAR_FACT_SHEET" })
    dispatch({ type: "CLEAR_FOLDERS" })
    dispatch({ type: "CLEAR_GLOBAL_FILTERS" })
    dispatch({ type: "CLEAR_KVMS" })
    dispatch({ type: "CLEAR_MODE" })
    dispatch({ type: "CLEAR_MODULES" })
    dispatch({ type: "CLEAR_SEARCH" })
    dispatch({ type: "CLEAR_SECTIONS" })
    dispatch({ type: "CLEAR_SUMMARIES" })
    dispatch({ type: "CLEAR_STUDIES" })
    dispatch({ type: "CLEAR_STUDY_TIMELINES" })
    dispatch({ type: "CLEAR_SUPPORTING_EVIDENCE" })

    // // INDICATIONS ARE BEING CLEARED IN THE authenticate FUNCTION
    // // DUE TO AN ERROR THAT OCCURS WHEN THE TOKEN WOULD EXPIRE AND STATE CLEARS
    // // THE ERROR HAPPENS BECAUSE SOME FUNCTIONS STILL TRY TO RUN USING THE 
    // // selectedIndication.id EVEN THOUGH selectedIndication NO LONGER EXISTS
    // dispatch({ type: "CLEAR_INDICATIONS" })

    dispatch({ type: "CLEAR_USERS" })
    localStorage.clear()
  }
}

const gotUsers = data => {
  return {type: 'GET_USERS', payload: data}
}

const gotCountries = data => {
  return {type: 'GET_COUNTRIES', payload: data}
}

const editedUser = (data) => {
  return {type: 'UPDATE_USER', payload: data}
}

const fetchingUsers = () => {
  return { type: "FETCHING_USERS" }
}

const fetchingCountries = () => {
  return { type: "FETCHING_COUNTRIES" }
}

export const disableInitialLogin = () => {
  return { type: "DISABLE_INITIAL_LOGIN" }
}

export const getUsers = () => {
  return (dispatch) => {
    dispatch(fetchingUsers())
    axios.get(process.env.REACT_APP_URL+"users/", {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    }).then(response => {
      dispatch(gotUsers(response.data))
    }).catch(error => {
      if (error.response && error.response.status === 401) {
        window.localStorage.clear()
        window.location = '/login'
      }
      if (error.response && error.response.status === 403) {
        message.error("Authentication has expired, please log back in")
        dispatch({type: "CLEAR_EVERYTHING"})
      } else {
        dispatch(gotUsers([]))
        swal({
          title: "Error",
          text: "There was an error loading the data",
          icon: "error",
          button: "Ok",
        })
      }
    })
  }}

export const getCountries = () => {
  return (dispatch) => {
    dispatch(fetchingCountries())
    axios({
      method: "get",
      url: process.env.REACT_APP_URL+"countries/filter/?is_active=true",
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    })
    .then(response => {
      dispatch(gotCountries(response.data))
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        window.localStorage.clear()
        window.location = '/login'
      }
      if (error.response && error.response.status === 403) {
        dispatch({type: "CLEAR_EVERYTHING"})
      } else {
        dispatch(gotCountries([]))
        swal({
          title: "Error",
          text: "There was an error loading the data",
          icon: "error",
          button: "Ok",
        })
      }
    })
  }
}

export const handleUserLogin = (data) => {
  return {type: 'LOGIN_USER', payload: data}
}

const badLogin = (message) => {
  return {type: 'BAD_LOGIN', payload: message}
}

export const removeError = () => {
  return {type: 'REMOVE_BAD_LOGIN'}
}

export const refreshToken = () => {
  return dispatch => {
    fetch(process.env.REACT_APP_URL + 'refresh-token/',{
      method: "POST",
      headers: {
        "Authorization": "JWT "+localStorage.getItem("token"),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "token": localStorage.getItem("token")
      })
    })
    .then(res => res.json())
    .then(response => localStorage.setItem("token",response.token))
  }
}


export const loginUser = (inputs, ssoUser=null) => {
  return (dispatch) => {
    if (localStorage.getItem("token") && localStorage.getItem("user") && ssoUser) {
      dispatch(handleUserLogin(ssoUser))
      return
    }
    requestFunctions.loadingAnimation("Logging In...")
    axios.post(process.env.REACT_APP_URL+'login/', {email: inputs.email, password: inputs.password})
    .then(response => {
      if (inputs.rememberMe) {
        localStorage.setItem("email", inputs.email)
      }
      else {
        localStorage.removeItem("email")
      }
      localStorage.setItem("user", response.data.user.id)
      localStorage.setItem("token", response.data.token)
      dispatch(handleUserLogin(response.data.user))
      dispatch(setCountries(response.data.user.countries))
      // swal.close()
      Swal.close()
    })
    .catch(error => {
      console.log(error)
      console.log(error.response)
      let message = "There was an issue logging in. Please try again."
      if (error && error.response) {
        message = Object.values(error.response.data)[0][0]
      }
      dispatch(badLogin(message))
    })
  }
}

export const editUser = (user) => {
  return (dispatch) => {
    axios.patch(user.url, user, {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    })
    .then(response => {
      requestFunctions.successAnimation("Successfully updated the user")
      dispatch(editedUser(response.data))
    })
    .catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in updating the user...")
    })
  }
}

export const changePassword = (oldPassword, newPassword, confirmPassword) => {
  return dispatch => {
    requestFunctions.loadingAnimation("Changing Password...")

    axios(`${process.env.REACT_APP_URL}password/change/`,{
      method:'POST',
      headers:{
        "Authorization": "JWT "+localStorage.getItem("token"),
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      data: {
        "old_password": oldPassword,
        "new_password1": newPassword,
        "new_password2": confirmPassword
      }
    })
    .then(response => {
      requestFunctions.successAnimation("Successfully changed the password")
      dispatch(disableInitialLogin())
    })
    .catch(error => {
      console.log(error)
      console.log(error.response)
      if (error.response && error.response.status === 401) {
        window.localStorage.clear()
        window.location = '/login'
      }
      let errorMessage = ""
      if (error.response.data.old_password) {
        errorMessage = "Invalid password"
      } else if (error.response.data.new_password2){
        errorMessage = "The password is too similar to the email address"
      } else {
        errorMessage = "There was an error changing your password"
      }
      swal({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        button: "Continue!",
      });
    })

  }
}

export const checkToken = () => {
  return dispatch => {
    axios.get(process.env.REACT_APP_TOKEN_URL, { headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }})
    .then(response => {
    })
    .catch(error => {
      console.log(error, error.response)
      window.localStorage.clear()
      window.location = '/login'
      dispatch(clearEverything())
    })
  }
}

export const checkSSOToken = () => {
  return dispatch => {
    let query = window.location.search
    if (query && (query.includes("uid") && query.includes("token"))) {
      let queryArr = query.split("&")
      let uid = queryArr[0].slice(5)
      let token = queryArr[1].slice(6)
      if (token) {
        localStorage.setItem("token", token)
        localStorage.setItem("user", uid)
        axios({
          url:`${process.env.REACT_APP_URL}users/${uid}`,
          method:"GET",
          headers:{ "Authorization": "JWT "+localStorage.getItem("token") }
        })
        .then(response => {
          window.history.pushState({}, document.title, "/");
          dispatch(loginUser(null, response.data))
        })
        .catch(error => {
          dispatch(clearEverything())
          window.location = '/login'
        })
      }
    } else {
      dispatch(checkToken())
    }
  }
}