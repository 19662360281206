/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { Fragment } from 'react'
import {Button} from 'antd'
import Swal from "sweetalert2";
import { submitClick } from '../reducers/manageActivity'
import { useDispatch } from 'react-redux'
import 'antd/dist/antd.css'
import {requestFunctions} from "../functions/requestFunctions";
import PropTypes from "prop-types";


export default function MediaDownloader(props) {
  // PROPS
  const { buttonClassName, file, filename, forClick, contentType, objectId, module, type } = props

  // REDUX
  const dispatch = useDispatch()

  const initiateDownload = () => {
    requestFunctions.loadingAnimation("Preparing for Download...")

    axios.get(file, { responseType: 'blob'})
    .then(response => {
      Swal.fire({
        title: "Your file is ready!",
        icon: "success",
        confirmButtonColor: "var(--brand-color-2)",
        confirmButtonText: "Download"
      }).then((isClicked) => {
        if (isClicked) {
          dispatch(submitClick({
            content_type: contentType,
            object_id: objectId,
            module: module,
            action: "download"
          }))
          let a = document.createElement('a')
          a.href = URL.createObjectURL(response.data)
          a.download = filename
          a.click()
        }
      })
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error with downloading the file...")
    })
  }

  const checkIfLinkOrButton = () => {
    if(forClick) {
      return (
        <Fragment>
          <forClick.type { ...forClick.props } onClick={ initiateDownload }/>
        </Fragment>
      )
    }
    if (type === "button") {
      return (
        <Button
          className={ buttonClassName || "evidence-btn" }
          onClick={ initiateDownload }
        >
          Download
        </Button>
      )
    }else if (type === "link") {
      return (
        <a onClick={ initiateDownload }>
          Download
        </a>
      )
    }
  }

  return (
    checkIfLinkOrButton()
  )
}


MediaDownloader.propTypes = {
  file: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  objectId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  module: PropTypes.string.isRequired,
  type: PropTypes.string,
  forClick: PropTypes.node,
  buttonClassName: PropTypes.string,
}