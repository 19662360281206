import React, { Fragment } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Button, Popconfirm } from 'antd'
import KVMEvidence from '../../keyvaluemessages/components/KVMEvidence'
import { deleteFact } from '../../../reducers/manageFacts'
import CreateUpdateDataEntry from "./CreateUpdateDataEntry";


export default function DataEntry({ fact }) {
  // REDUX
  const dispatch = useDispatch()
  const user = useSelector(state => state.manageUsers.user)

  return (
    <tr className="fact-row">
      <td className="fact-outcome-td">
        <span style={{width: "10vw"}}>
          {fact.outcome}
        </span>
      </td>
      <td className="fact-description-td">
        <span>
          {fact.description}
        </span>
      </td>
      <td style={{width: "10vw", textAlign: "center"}}>
        {fact.supporting_evidence.length > 0 ?
          <KVMEvidence
            entry={ fact }
            relevantEvidence={fact.supporting_evidence}
            buttonText="View"
            buttonClassName=""
            module="Data Collection"
            contentType="fact"
            action="view supporting materials"
            />
          :
          <span>None</span>
        }
      </td>
      <td className="fact-action-td">
        {user.role === 'Affiliate' && !user.countries.includes(fact.country) ?
          <Button
            // onClick={() => toggleModal(fact)}
            type='primary'
            className="edit_fact"
          >
            Localize
          </Button>
          :
          <Fragment>
            <CreateUpdateDataEntry fact={ fact } mode="edit" />
            <Popconfirm
              title="Are you sure you want to delete this data?"
              placement="left"
              onConfirm={ () => dispatch(deleteFact(fact)) }
              okText="Yes"
              cancelText="No"
            >
              <Button
                type='primary'
                className="action_btn"
              >
                Delete
              </Button>
            </Popconfirm>
          </Fragment>
        }
      </td>
    </tr>
  )

}