import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { Modal } from 'react-bootstrap'
import { submitEvidence, editEvidence } from '../../../../reducers/manageSupportingEvidence'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import swal from 'sweetalert'
import EntryCreationCategoryList from "../../../../components/EntryCreationCategoryList";


export default function CreateUpdateEvidence({ evidence, mode }) {
  // REDUX
  const dispatch = useDispatch()
  const listOfKVMs = useSelector(state => state.manageKVM.listOfKVMs)
  const countries = useSelector(state => state.manageUsers.countries)
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const user = useSelector(state => state.manageUsers.user)

  // STATE
  const initialState = {
    visible: false,
    shortReference: evidence ? evidence.short_reference : "",
    fullReference: evidence ? evidence.full_reference : "",
    abstract: evidence ? evidence.abstract : "",
    sponsorship: evidence ? evidence.sponsorship : "",
    type: evidence ? evidence.type : "",
    status: evidence ? evidence.status : "",
    gvdRef: evidence ? evidence.gvd_ref : 0,
    gvdReferences: evidence ? evidence.gvd_references : [],
    evidenceCountries: evidence ? evidence.countries : ["Global"],
    category: evidence ? evidence.category : [],
    uploaderNotes: evidence ? evidence.uploader_notes : "",
    keyValueMessages: evidence ? evidence.key_value_messages : [],
    indications: evidence ? evidence.indications : (selectedIndication ? [selectedIndication.url] : []),
    file: {},
    referenceSource:
      (evidence && evidence.gvd_references.length) || (mode === "create" || mode === "create-list-item") ? "From GVD" : "External to GVD",
    step: 1,
    errorMessage: ""
  }

  const [selectedEvidenceCategories, setSelectedEvidenceCategories] = useState({})
  const [{
    visible, shortReference, fullReference,
    abstract, sponsorship, type, status,
    gvdRef, gvdReferences, evidenceCountries, category,
    uploaderNotes, keyValueMessages, indications,
    file, referenceSource, step, errorMessage
  }, setState] = useState(initialState)

  const handleInput = (e) => {
    const { name, value } = e.target
    setState(prevState => ({ ...prevState, [name]: value }))
  }
  const handleSelect = (field, value) => setState(prevState => ({ ...prevState, [field]: value }))
  const handleCancel = () => {
    setState({...initialState})
    setSelectedEvidenceCategories({})
  }

  const onChangeKVM = (id) => {
    let keyValueMessagesCopy = [...keyValueMessages]

    if (keyValueMessagesCopy.find(kvm => kvm.id === id)) {
      keyValueMessagesCopy = keyValueMessagesCopy.filter(kvm => kvm.id !== id)
    } else {
      let kvm = listOfKVMs.find(kvm => kvm.id === id)
      keyValueMessagesCopy = [...keyValueMessagesCopy, kvm]
    }
    handleSelect("keyValueMessages", keyValueMessagesCopy)
  }

  const handleInputCountries = (countries) => handleSelect("countries", countries)

  const handleInputFile = (e) => {
    handleSelect("file", e.target.files[0])
  }

  const changeStep = (newStep) => {
    if (step === 1) {
      if (!file.name && (mode === "create" || mode === "create-list-item")) {
        handleSelect("errorMessage", "Please choose a Document")
      } else if (!shortReference) {
        handleSelect("errorMessage", "Please fill out the Short Reference field")
      } else if (!fullReference) {
        handleSelect("errorMessage", "Please fill out the Reference field")
      } else if (!abstract) {
        handleSelect("errorMessage", "Please fill out the Abstract/Summary field")
      } else if (!evidenceCountries.length) {
        handleSelect("errorMessage", "Please select at least one country")
      } else if (!indications.length) {
        handleSelect("errorMessage", "Please select at least one Indication")
      } else {
        handleSelect("step", newStep)
        handleSelect("errorMessage", "")
      }
    } else {
      handleSelect("step", newStep)
      handleSelect("errorMessage", "")
    }
  }

  const handleSubmit = () => {
    if (
      ((mode === "create" || mode === "create-list-item") && !file.name) ||
      !shortReference || !fullReference || !abstract ||
      !evidenceCountries.length
    ) {
      swal({
        title: "Error",
        text: "Please fill out all required fields.",
        icon: "error",
        button: "Continue!",
      })
      return
    }

    let formData = new FormData()
    const country = user.role === "Global" ? "Global" : user.country
    let countryURLs = evidenceCountries.map(country => {
      return countries.find(countryObject => {
        return country === countryObject.name
      }).url
    })

    let keyValueMessageURLs = keyValueMessages.map(kvm => {
      if (typeof kvm === "object") {
        return kvm.url
      } else {
        return kvm
      }
    })
    let allSelectedCategories = []
    Object.keys(selectedEvidenceCategories).forEach(c => {
      allSelectedCategories = [...allSelectedCategories, ...selectedEvidenceCategories[c]]
    })

    formData.append("document", file)
    formData.append("short_reference", shortReference)
    formData.append("full_reference", fullReference)
    formData.append("abstract", abstract)
    formData.append("sponsorship", sponsorship)
    formData.append("type", type)
    formData.append("status", status)
    formData.append("gvd_ref", gvdRef)
    formData.append("gvd_references", JSON.stringify(gvdReferences))
    formData.append("uploader_notes", uploaderNotes)
    countryURLs.forEach(c => formData.append('countries', c))
    allSelectedCategories.forEach(cat => formData.append('category', cat))
    formData.append("country", country)
    keyValueMessageURLs.forEach(kvm => formData.append('key_value_messages', kvm))
    indications.forEach(indication => formData.append('indications', indication))
    formData.append("is_active", true)
    formData.append("created_by", {})
    formData.append("modified_by", {})

    if (mode === "create" || mode === "create-list-item") {
      if (document.getElementById('file-upload')) {
        document.getElementById('file-upload').value=null;
      }
      dispatch(submitEvidence(formData, mode))
      handleCancel()
    } else if (mode === "edit") {
      formData.append("url", evidence.url)
      handleSelect("visible", false)
      handleSelect("step", 1)
      dispatch(editEvidence(formData))
    }
  }

  const displayStep = () => {
    if (step === 1) {
      return (
        <StepOne
          evidence={ evidence }
          mode={ mode }
          file={ file }
          short_reference={ shortReference }
          full_reference={ fullReference }
          abstract={ abstract }
          sponsorship={ sponsorship }
          type={ type }
          status={ status }
          gvd_ref={ gvdRef }
          gvd_references={ gvdReferences }
          countries={ countries }
          category={ category }
          uploader_notes={ uploaderNotes }
          key_value_messages={ keyValueMessages }
          indications={ indications }
          referenceSource={ referenceSource }
          errorMessage={ errorMessage }
          handleInputFile={ handleInputFile }
          handleInputCountries={ handleInputCountries }
          handleSelect={ handleSelect }
          handleInput={ handleInput }
          handleCancel={ handleCancel }
          changeStep={ changeStep }
        />
      )
    } else if (step === 2) {
      return (
        <StepTwo
          onChangeKVM={ onChangeKVM }
          evidence={ evidence }
          full_reference={ fullReference }
          abstract={ abstract }
          gvd_ref={ gvdRef }
          indications={ indications }
          category={ category }
          errorMessage={ errorMessage }
          uploader_notes={ uploaderNotes }
          key_value_messages={ keyValueMessages }
          mode={ mode }
          handleInput={ handleInput }
          handleCancel={ handleCancel }
          changeStep={ changeStep }
        />
      )
    } else if (step === 3) {
      return (
        <EntryCreationCategoryList
          entry={ evidence }
          indications={ indications }
          step={ step }
          handleCancel={ handleCancel }
          changeStep={ changeStep }
          handleSubmit={ handleSubmit }
          selectedCategories={ selectedEvidenceCategories }
          setSelectedCategories={ setSelectedEvidenceCategories }
        />
      )
    } else {
      return <div>Error. Please refresh the page.</div>
    }
  }

  return (
    <div className={ mode === "create" || mode === "create-list-item" ? "new_evidence" : null }>
      <Button
        onClick={() => handleSelect("visible", true)}
        className={ mode === "create" || mode === "create-list-item" ? "add_new_evidence_btn" : "evidence-btn" }
      >
        { mode === "create" || mode === "create-list-item" ? "Add Evidence" : "Edit" }
      </Button>
      <Modal
        show={ visible }
        onHide={ handleCancel }
        size="lg"
        className="ant-modal-body-custom"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            { mode === "create" || mode === "create-list-item" ? "Add Evidence" : "Edit Evidence" }
          </Modal.Title>
        </Modal.Header>
        { displayStep() }
      </Modal>
    </div>
  )
}