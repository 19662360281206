import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Card, Row, Col, Select} from 'antd'
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts'

const Option = Select.Option

class ClicksViewer extends Component {

  state = {
    date: "all_time",
    dateTime: null
  }


  getLastWeek = (today) => {
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
    return lastWeek.toLocaleDateString()
  }

  getLastMonth = (today) => {
    let lastMonth = new Date(today.getFullYear(), today.getMonth() - 1)
    return lastMonth.toLocaleDateString()
  }

  onFilterDate = (value) => {
    if (value === "one_week") {
      this.setState({date: value, dateTime:this.getLastWeek(new Date())})
    } else if (value === "all_time") {
      this.setState({date: value, dateTime:null})
    } else if (value === "one_month") {
      this.setState({date: value, dateTime:this.getLastMonth(new Date())})
    }
  }

  checkDate = (date) => {
    /// THIS FUNCTION MAKES IT POSSIBLE TO ADD CLICKS IF THEY HAPPENED
    /// IN THE SELECTED TIME PERIOD I.E. last-week, last-month
    let dateOfClick = new Date(date)
    let dateToFilterBy = new Date(this.state.dateTime)
    if(dateOfClick.getTime() > dateToFilterBy.getTime()){
      return true
    }else{
      return false
    }

  }

  render() {
    // COMMENTED OUT BECAUSE WERE NEVER BEING USED
    const fileColors = [
      '#5499D7',
      '#F270A8',
      '#29728D',
      '#DED52F',
      '#56B45E',
      '#FFA01A',
      '#820933',
    ]

    const aggregatedClicks = this.props.aggregatedClicks[this.state.date]


    const section_access = [
      {name: "Data Collection", count: aggregatedClicks.navigated.facts},
      {name: "Key Value Messages", count: aggregatedClicks.navigated.kvm},
      {name: "Home", count: aggregatedClicks.navigated.home},
      {name: "Evidence Library", count: aggregatedClicks.navigated.evidence},
      {name: "Key Resources", count: aggregatedClicks.navigated.education},
      {name: "Search", count: aggregatedClicks.navigated.search},
    ]

    // section_use = [
    //   {name: "Affiliate Dashboard", count: aggregatedClicks.utilized.group},
    //   {name: "Key Value Messages", count: aggregatedClicks.utilized.summarypoint},
    //   {name: "Evidence Library", count: aggregatedClicks.utilized.supportingevidence},
    //   {name: "Educational Resources", count: aggregatedClicks.utilized.educationalresource},
    // ]

    return (
      <Card title={
          <div>
            Click Data
            <br/>
            <Select value={this.state.date} onChange={this.onFilterDate}>
              <Option value={"all_time"}>All Time</Option>
              <Option value={"one_week"}>Last Week</Option>
              <Option value={"one_month"}>Last Month</Option>
            </Select>
          </div>
        } style={{ width: '100%' }}>
        <Row>

          {/* <Col md={0} lg={1}></Col> */}

          <Col span={24}>
            <center>Module Navigated To</center>
            <ResponsiveContainer width={'100%'} height={300} debounce={1}>
              <PieChart>
                <Tooltip />
                <Pie
                  data={section_access}
                  dataKey="count"
                  outerRadius={100}
                  fill="#8884d8"
                >
                  {section_access.map((entry, index) => {
                    return <Cell key={index} fill={fileColors[index]}/>
                  })}
                </Pie>
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Col>

          {/* <Col md={0} lg={6}></Col>

           <Col md={24} lg={8}>
            <center>Module Utilized</center>
            <ResponsiveContainer width={'100%'} height={300} debounce={1}>
              <PieChart>
                <Tooltip />
                <Pie
                  data={section_use}
                  dataKey="count"
                  outerRadius={100}
                  fill="#8884d8"
                >
                  {section_use.map((entry, index) => {
                    return <Cell key={index} fill={fileColors[index]}/>
                  })}
                </Pie>
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Col>

          <Col md={0} lg={1}></Col> */}

        </Row>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    aggregatedClicks: state.manageActivity.aggregatedClicks
  }
}

export default connect(mapStateToProps)(ClicksViewer)
