import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import {submitClick} from "../../../reducers/manageActivity";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";


export default function HomeNavCard(props) {
  // PROPS
  const { moduleName, route, icon, highlights, animationDelay, sm=12, md=3, lg=3 } = props

  // REACT ROUTER DOM
  const history = useHistory()

  // REDUX
  const dispatch = useDispatch()
  const modules = useSelector(state => state.manageModules.modules)


	const	goToURL = () => {
		if (route === "/studycenter/studies") {
			const clickedModule = modules.find(module => module.route === "/studycenter")
			dispatch(submitClick({content_type: "module", module: clickedModule.name, object_id: clickedModule.id, action: "navigate"}))
		}
		history.push(route)
	}

  const animationStyle = {
		'animationName':'fadeIn',
		'animationDuration':'1.3s',
		'animationDelay': animationDelay || '.6s',
		'animationFillMode':'both',
		'minHeight':'300px',
	}

  return (
    <Col
      className="home-nav-col"
      sm={ sm } md={ md } lg={ lg }
      style={{ ...animationStyle }}
    >
      <div className="home-nav" onClick={ goToURL }>
        <Container>
          <Row>
            <Col>
              <div className="home-nav-headline">
                { moduleName }
                <br/>
                <span className="icon-circle">
                  <img
                    src={ icon }
                    alt='lightbulb'
                    className="dashIcon"
                  />
                </span>
              </div>
            </Col>
          </Row>
          <Row>

          </Row>
        </Container>
        <div className="list">
          { highlights.map(highlight => <p key={highlight} className="highlight">{ highlight }</p>) }
        </div>
      </div>
    </Col>
  )
}

HomeNavCard.propTypes = {
  moduleName: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  icon: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string),
  animationDelay: PropTypes.string,
  sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}