import React from 'react'

// Rechart components
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'


const NavigationChart = ({ clicks }) => {
  const navClicks = clicks.filter(click => click.action.includes("navigate"))

  /*
    Code block below reconfigures 'created_date' field of click objects into standard date string, 
    then formats both it and click counts from that date into data object for "navigation" bar charts
  */
  const clickDates = new Map()
  navClicks.forEach(click => {
    const date = new Date(click.created_date).toLocaleDateString(undefined, {year: 'numeric', month: 'short', day: 'numeric'})

    if (clickDates.has(date)) {
      clickDates.set(date, clickDates.get(date) + 1)
    } else {
      clickDates.set(date, 1)
    }
  })

  let data = []  
  clickDates.forEach((count, clickDate) => {
    data.push({date: clickDate, clicks: count})
  })


  // RENDER
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart height={300} data={data}>
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="clicks" fill="rgba(34, 35, 92, 1)" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default NavigationChart