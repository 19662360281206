import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

// reusable chart components
import ActionBreakdownChart from '../reusable-charts/ActionBreakdownChart'
import NavigationChart from '../reusable-charts/NavigationChart'
import ViewChart from '../reusable-charts/ViewChart'


const KVMCharts = ({ clicks, ...props }) => {

  const associatedEvidenceClicks = clicks.filter(click => click.action === "view associated evidence - Key Value Messages")
  const evidenceStatementClicks = clicks.filter(click => click.action === "view evidence statements - Key Value Messages")
  const summarySlidesClicks = clicks.filter(click => click.action === "view summary slides - Key Value Messages")


  // RENDER
  return (
    <div>

      <h6>Breakdown of all Key Value Message tracking actions:</h6>
      <ActionBreakdownChart clicks={clicks} />
      <hr />
      <br />

      <h6>Navigation clicks to Key Value Messages by date and count:</h6>
      <NavigationChart clicks={clicks} />
      <hr />
      <br />

      {associatedEvidenceClicks ?
        <div>
          <h6>Number of times <strong>Associated Evidence</strong> was viewed on each Key Value Message:</h6>
          <ViewChart clicks={associatedEvidenceClicks} viewObjsArr={props.KVMs} context="KVM" />
          <hr />
          <br />
        </div>
        :
        null
      }

      {evidenceStatementClicks ?
        <div>
          <h6>Number of times <strong>Evidence Statements</strong> were viewed on each Key Value Message:</h6>
          <ViewChart clicks={evidenceStatementClicks} viewObjsArr={props.KVMs} context="KVM" />
          <hr />
          <br />
        </div>
        :
        null
      }

      {summarySlidesClicks ?
        <div>
          <h6>Number of times <strong>Summary Slides</strong> were viewed on each Key Value Message:</h6>
          <ViewChart clicks={summarySlidesClicks} viewObjsArr={props.KVMs} context="KVM" />
          <hr />
          <br />
        </div>
        :
        null
      }
      
    </div>
  )
}


// REDUX
const mapStateToProps = (state) => {
  return {
    KVMs: state.manageKVM.KVMs
  }
}

const mapDispatchToProps = {
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KVMCharts))