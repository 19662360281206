import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Row, Col, Button, Popconfirm } from 'antd'
import PopupPDFViewer from '../../../components/PopupPDFViewer'
import MediaDownloader from '../../../components/MediaDownloader'
import CreateUpdateEvidence from "./CreateUpdateEvidence";
import { submitClick } from '../../../reducers/manageActivity'
import { setSelectedEvidence } from '../../../reducers/manageSupportingEvidence'
import 'antd/dist/antd.css'


export default function Evidence({ evidence, handleDelete }) {
  // REDUX
  const dispatch = useDispatch()
  const selectedEvidence = useSelector(state => state.manageSupportingEvidence.selectedEvidence)

  const handleClick = () => {
    dispatch(setSelectedEvidence(evidence))
  }

  const handleSubmitClick = (actionType) => {
    dispatch(submitClick({ content_type: "supportingevidence", module: "Evidence Library", object_id: evidence.id, action: actionType }))
  }

  const currentlySelected = evidence.url === selectedEvidence.url
  const thisCSS = currentlySelected ? "evidence_row selected" : "evidence_row"


  return (
    <div onClick={handleClick}>
      <Row className={thisCSS}>
        <Col xs={24} sm={24} md={18} lg={18} className="reference">
          <p style={{ "wordBreak": "break-word" }}>
            {evidence.full_reference}
          </p>
        </Col>
        <Col xs={24} sm={24} md={2} lg={2} className="type">
          {evidence.filetype.toUpperCase()}
        </Col>
        <Col xs={0} sm={0} md={4} lg={4}>
          <PopupPDFViewer
            file={evidence.document}
            forClick={<Button className="evidence-btn" onClick={ () => handleSubmitClick("view") }>View</Button>}
            title={evidence.title}
            entry={ evidence }
            module="Evidence Library"
            contentType="supportingevidence"
            action="view"
          />
          <CreateUpdateEvidence evidence={ evidence } mode="edit" />
          <MediaDownloader
            file={evidence.document}
            type="button"
            module="Evidence Library"
            filename={evidence.filename}
            contentType="supportingevidence"
            objectId={evidence.id}
          />
          <Popconfirm
            title="Are you sure to delete this supporting evidence?"
            placement="left"
            onConfirm={() => handleDelete(evidence)}
            okText="Yes"
            cancelText="No"
          >
            <Button className="evidence-btn">Delete</Button>
          </Popconfirm>
        </Col>
        <Col xs={8} sm={8} md={0} lg={0}>
          <PopupPDFViewer
            file={evidence.document}
            forClick={<Button className="evidence-btn" onClick={ () => handleSubmitClick("view") }>View</Button>}
            title={evidence.title}
            entry={ evidence }
            module="Evidence Library"
            contentType="supportingevidence"
            action="view"
          />
        </Col>
        <Col xs={8} sm={8} md={0} lg={0}>
          <CreateUpdateEvidence evidence={ evidence } mode="edit" />
        </Col>
        <Col xs={8} sm={8} md={0} lg={0}>
          <MediaDownloader
            file={evidence.document}
            type="button"
            module="Evidence Library"
            filename={evidence.filename}
            contentType="supportingevidence"
            objectId={evidence.id}
          />
          <Popconfirm
            title="Are you sure to delete this supporting evidence?"
            placement="left"
            onConfirm={() => handleDelete(evidence.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button className="evidence-btn">Delete</Button>
          </Popconfirm>
        </Col>
      </Row>
      { currentlySelected ?
        <Row className="evidence_row_abstract">
          {evidence.abstract}
        </Row>
        :
        null
      }
    </div>
  )
}