import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
// import DatePicker from "react-datepicker"
import { Button, Input } from "antd"
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead'
import {Alert, Col, Modal, Row} from "react-bootstrap"
import { getCommunicationTargets } from '../../../../../reducers/manageStudies'
import {CustomDatePicker} from "../../../../../components/DatePickers";
import {requestFunctions} from "../../../../../functions/requestFunctions";


export default function ExistingTargetForm({ closeModal }) {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  const communicationTargets = useSelector(state => state.manageStudies.communicationTargets)
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)

  // STATE
  const [allCommunicationTargets, setAllCommunicationTargets] = useState([])
  const [fetchingAllCommunicationTargets, toggleFetchingAllCommunicationTargets] = useState(true)
  const [selectedCommunicationTarget, setSelectedCommunicationTarget] = useState([])
  const [content, setContent] = useState("")
  const [htas, setHtas] = useState([])
  const [medicalProviders, setMedicalProviders] = useState([])
  const [other, setOther] = useState([])
  const [patientOrganizations, setPatientOrganizations] = useState([])
  const [payers, setPayers] = useState([])
  const [regulators, setRegulators] = useState([])
  const [errorMessage, setErrorMessage] = useState("")

  const getCommunicationTargetList = () => {
    toggleFetchingAllCommunicationTargets(true)
    axios.get(`${process.env.REACT_APP_URL}communication-targets/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
      params: { indication: selectedIndication.id }
    }).then(response => {
      const targets = response.data.filter(target => {
        let found = communicationTargets.find(communicationTarget => target.url === communicationTarget.url)
        return !!!found
      })
      setAllCommunicationTargets(targets)
      toggleFetchingAllCommunicationTargets(false)
    }).catch(error => {
      console.log(error.response)
      toggleFetchingAllCommunicationTargets(false)
    })
  }

  useEffect(() => {
    getCommunicationTargetList()
  }, [])

  const handleTargetChange = (target) => {
    setSelectedCommunicationTarget(target)
  }

  const handleCancel = () => {
    setAllCommunicationTargets([])
    toggleFetchingAllCommunicationTargets(true)
    setSelectedCommunicationTarget([])
    setContent("")
    setHtas([])
    setMedicalProviders([])
    setOther([])
    setPatientOrganizations([])
    setPayers([])
    setRegulators([])
    closeModal()
  }

  const handleSubmit = () => {
    const communicationTarget = selectedCommunicationTarget[0]
    setErrorMessage("")
    if (!content) {
      setErrorMessage("Please fill out all required fields")
      return
    }

    let updatedCommunicationTargetDetails = {
      study: selectedStudy.url,
      communication_target: communicationTarget.url,
      content: content,
      htas: htas.map(target => target.url),
      medical_providers: medicalProviders.map(target => target.url),
      other: other.map(target => target.url),
      patient_organizations: patientOrganizations.map(target => target.url),
      payers: payers.map(target => target.url),
      regulators: regulators.map(target => target.url)
    }
    requestFunctions.loadingAnimation("Submitting Communication Target...")
    axios.post(`${process.env.REACT_APP_URL}communication-study-details/`, updatedCommunicationTargetDetails, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully added a Communication Target")
      dispatch(getCommunicationTargets(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was a problem in adding a Communication Target...")
      console.log(error.response)
    })
  }

  const renderMenuItemChildren = (option, { text }, index) => {
    const months = [
      "January", "February", "March",
      "April", "May", "June",
      "July", "August", "September",
      "October", "November", "December",
    ]
    
    let date = option.key_dates[0].date.split("T")[0].split("-")
    
    return (
      <Fragment>
        <Highlighter search={text}>
          { option.venue }
        </Highlighter>
        <div>
          <small>
            { `${months[date[1] - 1]} ${date[2]}, ${date[0]}` }
          </small>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Modal.Body>
        <Row>
          <Col md={6} lg={6} xl={6}>
            <Typeahead
              isLoading={ fetchingAllCommunicationTargets }
              labelKey="venue"
              renderMenuItemChildren={ renderMenuItemChildren }
              id="communication-target-selection"
              options={ allCommunicationTargets }
              value={ selectedCommunicationTarget }
              onChange={ handleTargetChange }
              placeholder="Select a communication target..."
            />
          </Col>
        </Row>
        <br/>
        {
          selectedCommunicationTarget.length ?
            <div>
              <Row>
                <Col md={6} lg={6} xl={6}>
                  <div><strong>Venue</strong></div>
                  <Input
                    disabled={ true }
                    value={ selectedCommunicationTarget[0].venue }
                    onChange={ () => null }
                  />
                </Col>
                <Col md={6} lg={6} xl={6}>
                  <div><strong>Date</strong></div>
                  <CustomDatePicker
                    selectsRange={ true }
                    startDate={ new Date(selectedCommunicationTarget[0].key_dates[0].date) }
                    endDate={ selectedCommunicationTarget[0].key_dates[0].end_date ? new Date(selectedCommunicationTarget[0].key_dates[0].end_date) : null }
                    disabled={ true }
                  />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={6} lg={6} xl={6}>
                  <div><strong>Content <span className="required">*</span></strong></div>
                  <Input.TextArea
                    rows={4}
                    value={ content }
                    placeholder="Enter content..."
                    onChange={ (e) => setContent(e.target.value) }
                  />
                </Col>
              </Row>
              <br/>
              <Row className="field">
                <Col md={6} lg={6} xl={6}>
                  <div><strong>HTAs</strong></div>
                  <Typeahead
                    labelKey="name"
                    allowNew
                    id="custom-selections-example"
                    multiple
                    newSelectionPrefix="Add a new HTA: "
                    options={ selectedStudy.htas }
                    selected={ htas }
                    onChange={ setHtas }
                    placeholder="Enter HTAs..."
                  />
                </Col>
                <Col md={6} lg={6} xl={6}>
                  <div><strong>Medical Providers</strong></div>
                  <Typeahead
                    labelKey="name"
                    allowNew
                    id="custom-selections-example"
                    multiple
                    newSelectionPrefix="Add a new HTA: "
                    options={ selectedStudy.medical_providers }
                    selected={ medicalProviders }
                    onChange={ setMedicalProviders }
                    placeholder="Enter medical providers..."
                  />
                </Col>
              </Row>
              <br/>
              <Row className="field">
                <Col md={6} lg={6} xl={6}>
                  <div><strong>Patient Organizations</strong></div>
                  <Typeahead
                    labelKey="name"
                    allowNew
                    id="custom-selections-example"
                    multiple
                    newSelectionPrefix="Add a new HTA: "
                    options={ selectedStudy.patient_organizations }
                    selected={ patientOrganizations }
                    onChange={ setPatientOrganizations }
                    placeholder="Enter patient organizations..."
                  />
                </Col>
                <Col md={6} lg={6} xl={6}>
                  <div><strong>Payers</strong></div>
                  <Typeahead
                    labelKey="name"
                    allowNew
                    id="custom-selections-example"
                    multiple
                    newSelectionPrefix="Add a new HTA: "
                    options={ selectedStudy.payers }
                    selected={ payers }
                    onChange={ setPayers }
                    placeholder="Enter payers..."
                  />
                </Col>
              </Row>
              <br/>
              <Row className="field">
                <Col md={6} lg={6} xl={6}>
                  <div><strong>Regulators</strong></div>
                  <Typeahead
                    labelKey="name"
                    allowNew
                    id="custom-selections-example"
                    multiple
                    newSelectionPrefix="Add a new regulator: "
                    options={ selectedStudy.regulators }
                    selected={ regulators }
                    onChange={ setRegulators }
                    placeholder="Enter regulators..."
                  />
                </Col>
                <Col md={6} lg={6} xl={6}>
                  <div><strong>Other</strong></div>
                  <Typeahead
                    labelKey="name"
                    allowNew
                    id="custom-selections-example"
                    multiple
                    newSelectionPrefix="Add a new HTA: "
                    options={ selectedStudy.other }
                    selected={ other }
                    onChange={ setOther }
                    placeholder="Enter other..."
                  />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <span className="required">* - Marks required fields</span>
                </Col>
              </Row>
              { errorMessage && <Fragment><br/><Alert variant="danger">{ errorMessage }</Alert></Fragment> }
            </div>
          : null
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ handleCancel }>Cancel</Button>
        <Button onClick={ handleSubmit }>Submit</Button>
      </Modal.Footer>
    </Fragment>
  )
}