import axios from 'axios'
import React, {Fragment, useState} from 'react'
import { Modal } from 'react-bootstrap'
import 'antd/dist/antd.css'
import {useDispatch} from 'react-redux'
import LoadingAnimation from "./LoadingAnimation";
import {requestFunctions} from "../functions/requestFunctions";
import PropTypes from "prop-types";
import { submitClick } from '../reducers/manageActivity'


export default function PopupPDFViewer(props) {
	// PROPS
	const { file, page = 0, forClick, title = "Document Viewer", action, contentType, entry, module } = props

	// REDUX
	const dispatch = useDispatch()

	// STATE
	const [visible, setVisible] = useState(false)
	const [inFrame, setInFrame] = useState(<div style={{ marginTop: "300px" }}><LoadingAnimation /></div>)


	const showModal = (e) => {
		e.stopPropagation()
		setVisible(true)
		if (!file) {
			setInFrame("There are no available documents")
			return
		}
		setInFrame(<div style={{ marginTop: "300px" }}><LoadingAnimation /></div>)

		const toAppend = page ? "#zoom=100&page="+page : ""

		axios.get(`${file}#zoom=100`, {
			responseType: "blob",
			headers: { "Content-Type": "application/pdf" }
		}).then(response => {
			if (action && entry && contentType && module) {
				dispatch(submitClick({
					content_type: contentType,
					module: module,
					object_id: entry.id || entry._id,
					action: action
				}))
			}
			setInFrame(
				<iframe
					title="iframe "
					src={URL.createObjectURL(response.data) + toAppend}
					id="gvd-frame"
					frameBorder="0"
					style={{ height: "80vh", width: "100%" }}
				>
				</iframe>
			)
		}).catch(error => {
			requestFunctions.handleError(error, dispatch, "Document could not be loaded...")
			setInFrame("Document could not be loaded...")
		})
	}

	return (
		<Fragment>
			<forClick.type {...forClick.props} onClick={showModal} />
			<Modal
				show={ visible }
				onHide={() => setVisible(false)}
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						{title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ inFrame }
				</Modal.Body>
			</Modal>
		</Fragment>
	)
}

PopupPDFViewer.propTypes = {
	file: PropTypes.string,
	title: PropTypes.string,
	page: PropTypes.number,
	forClick: PropTypes.node.isRequired,
	entry: PropTypes.object,
	module: PropTypes.string,
	contentType: PropTypes.string,
	action: PropTypes.string,
}