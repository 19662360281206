import React from 'react'

// Rechart components
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'


const ViewChart = ({ clicks, context, viewObjsArr }) => {

  const viewObjMap = new Map()
  clicks.forEach(click => {
    const objectId = click.object_id.id
    if (viewObjMap.has(objectId)) {
      viewObjMap.set(objectId, viewObjMap.get(objectId) + 1)
    } else {
      viewObjMap.set(objectId, 1)
    }
  })

  const data = []
  viewObjMap.forEach((count, objectId) => {
    const viewObj = viewObjsArr.find(obj => obj.id === objectId)
    if (!viewObj) {
      return
    }

    let viewObjSubstring
    switch(context) {
      case "KVM":
        viewObjSubstring = (viewObj.message.substring(0, 130) + "...")
        break
      case "Evidence":
        viewObjSubstring = (viewObj.full_reference.substring(0, 130) + "...")
        break
      case "Data Collection":
        viewObjSubstring = (viewObj.description)
        break
      case "Key Resources":
        viewObjSubstring = (viewObj.title)
        break
      default:
        break
    }

    data.push({ message: viewObjSubstring, views: count })
  })


  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart height={300} data={data}>
        <XAxis dataKey="message" tick={false} />
        <YAxis />
        <Tooltip contentStyle={{whiteSpace: "normal", maxWidth: "300px"}} />
        <Bar dataKey="views" fill="rgba(34, 35, 92, 1)" maxBarSize={200} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ViewChart