import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input, Button, Row, Col, Select, message, Checkbox } from 'antd'
import { getUsers } from '../../../reducers/manageUsers'
import axios from 'axios'

const Option = Select.Option

class ManageUsers extends Component {

  state = {
    inactiveUsers: [],
    activeUsers: [],
    selectedUserEmail: "",
    selectedUserObject: "",
    firstName: "",
    lastName: "",
    role: "",
    countries: [],
    activated: false
  }

  componentDidMount() {
    this.setState({
      inactiveUsers: this.props.users.filter(user => !user.is_active),
      activeUsers: this.props.users.filter(user => user.is_active)
    })
  }

  handleFirstNameChange = (event) => {
    this.setState({firstName: event.target.value})
  }
  handleLastNameChange = (event) => {
    this.setState({lastName: event.target.value})
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { countries } = this.props
    const { role } = this.state

    if (!this.state.firstName || !this.state.lastName || !this.state.role || !this.state.countries.length) {
      message.error("Please fill out all of the required fields")
    } else {
      let countryUrls = []
      if(role === "Global") {
        countryUrls = [countries.find(countryObject => countryObject.name === "Global").url]
      } else {
        countryUrls = this.state.countries.map(country => {
          return countries.find(countryObject => {
            return countryObject.name === country
          }).url
        })
      }

      axios.patch(`${process.env.REACT_APP_URL}users/${this.state.selectedUserObject.id}/`, {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          is_active: this.state.activated,
          role: this.state.role,
          countries: countryUrls
        }, {
          headers: {
            "Authorization": "JWT "+localStorage.getItem("token"),
          },
        }
      )
      .then(response => {
        message.success("User Updated Successfully")
        this.setState({
          selectedUserEmail: "",
          selectedUserObject: {},
          firstName: "",
          lastName: "",
          role: "",
          countries: []
        }, () => {
          this.props.getUsers()
        })
      }).catch(error => {
        message.error("User was not updated")
        console.log(error)
        console.log(error.response)
        if (error.response && error.response.status === 401) {
          window.localStorage.clear()
          window.location = '/login'
        }
      })
    }
  }

  handleSelectUser = (value) => {
    if (value) {
      let user = this.props.users.find(user => user.email === value)
      let userCountries = [...user.countries]

      if(userCountries.includes("Global")) {
        userCountries = userCountries.filter(country => country !== "Global")
      }

      this.setState({
        selectedUserObject: user,
        selectedUserEmail: value,
        firstName: user.first_name || "",
        lastName: user.last_name || "",
        role: user.role || "",
        countries: userCountries || [],
        activated: user.is_active || false
      })
    } else {
      this.setState({
        selectedUserObject: {},
        selectedUserEmail: "",
        firstName: "",
        lastName: "",
        role: "",
        countries: [],
      })
    }
  }

  handleRoleChange = (role) => {
    this.setState({ role })
  }

  render () {
    let countries = [...this.props.countries]
    countries = countries.filter(country => country.name !== "Global")
    return(
      <div className="card">
        <div>
          There are {this.props.users.filter(user => user.is_active).length} activated users:
          <br/>
          <br/>
          <Select
            style={{ maxWidth: 400, width: "100%" }}
            value={this.state.selectedUserEmail}
            onChange={this.handleSelectUser}
          >
            <Option value="">Select User</Option>
            {this.state.activeUsers.map(user => {
              return (
                <Option
                  key={user.id}
                  value={user.email}
                >
                  {user.email}
                </Option>
              )
            })
            }
          </Select>
        </div>
        <br/>
        <br/>
        <div>
          {this.state.selectedUserEmail ?
            <div className="user-form">
              <Row>
                <Col md={11} sm={24}>
                  <label>First Name</label>
                <br/>
                  <Input
                    placeholder="First Name"
                    value={this.state.firstName}
                    onChange={this.handleFirstNameChange}
                  />
                </Col>

                <Col md={2} sm={0}></Col>

                <Col md={11} sm={24}>
                  <label>Last Name</label>
                <br/>
                  <Input
                    placeholder="Last Name"
                    value={this.state.lastName}
                    onChange={this.handleLastNameChange}
                  />
                </Col>
              </Row>

              <br/>

              <Row>
                <Col md={11} sm={24}>
                  <label>Role</label>
                  <br/>
                  <Select style={{ width: '100%' }} value={this.state.role} onChange={this.handleRoleChange}>
                    <Option value="Global">Global</Option>
                    <Option value="Affiliate">Affiliate</Option>
                  </Select>
                </Col>

                <Col md={2} sm={0}></Col>

                <Col md={11} sm={24}>
                  <label>Country</label>
                  <br/>
                  <Select 
                    mode="multiple" 
                    style={{ width: '100%' }} 
                    value={ this.state.role !== "Global" ? this.state.countries : ["Global"]}
                    disabled={this.state.role === "Global"}
                    onChange={(value) => this.setState({countries:value})}
                  >
                    {countries.sort((a, b) => a.name.localeCompare(b.name))
                      .map(country => {
                        return (
                          <Option value={country.name} key={country.name}>
                            {country.name}
                          </Option>
                        )
                    })}
                  </Select>
                </Col>
              </Row>

              <br/>

              <Row>
                <Col>
                  <label>Activated</label>
                  <br/>
                  <Checkbox
                    checked={this.state.activated}
                    onChange={() => this.setState({activated: !this.state.activated})}
                  />
                </Col>
              </Row>

              <br/>

              <Button
                className="user-acct-btn"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.manageUsers.user,
    users: state.manageUsers.users,
    countries: state.manageUsers.countries
  }
}

export default connect(mapStateToProps, { getUsers })(ManageUsers);
