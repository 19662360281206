import React, { Component } from 'react';
import { ReactiveBase, DataSearch } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux'

import { setSearchTerm, queryDatabase, queryPDFDatabase } from '../../reducers/manageSearch'

class ReactiveSearchTopNav extends Component {

  state = {
    selected: `${process.env.REACT_APP_ELASTIC_PREFIX}_supporting_evidences,${process.env.REACT_APP_ELASTIC_PREFIX}_parsed_pdf_pages,${process.env.REACT_APP_ELASTIC_PREFIX}_educational_resources,${process.env.REACT_APP_ELASTIC_PREFIX}_facts`,
    searchTerm: ''
  }

  submitSearch = () => {
		this.props.setSearchTerm(this.state.searchTerm)
		// this.props.handleClick("/search")
    if (this.state.searchTerm) {
      window.location = `/search?SearchSensor="${this.state.searchTerm}"`
    }
    // this.props.toggleNavBarExpand()
    // this.props.toggleSearchBarModal()
  }

  handleInput = (value, triggerQuery) => {
    this.setState({searchTerm: value}, () => {
      this.props.setSearchTerm(value)
    })
  }

  render() {
    return (
      <div style={{ display: "inline-block" }}>
        <ReactiveBase
          app={this.state.selected}
          credentials={`${process.env.REACT_APP_ES_USERNAME}:${process.env.REACT_APP_ES_PASSWORD}`}
          url={`${process.env.REACT_APP_ES_URL}`}
        >
          <DataSearch
            componentId="SearchSensor"
            dataField={["abstract","abstract.search","pdf.title","content","title","full_reference","category.category","description"]}
            placeholder="Site Wide Search"
            autosuggest={true}
            fuzziness={"AUTO"}
            showIcon={false}
            URLParams={true}
            highlight={true}
            highlightField="content"
            onValueChange={(value) => {
              this.setState({searchTerm: value},() => this.props.setSearchTerm(value))
            }}
            onValueSelected={(value, category) => {
              value = value.slice(0,125)
              this.setState({searchTerm: value},() => this.submitSearch())
              // this.submitSearch()
            }}
            // onChange={(value, triggerQuery) => this.handleInput(value, triggerQuery)}
            innerClass={{
              input: 'reactive-search-input',
              list: 'reactive-search-list'
            }}
          />
        </ReactiveBase>
      </div>
    );
  }

  // handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     this.submitSearch();
  //   } else {
  //     this.setState({searchTerm: e.target.value})
  //   }
  //
  // }
}

const mapStateToProps = state => {
  return {
    searchTerm: state.manageSearch.searchTerm
  }
}

export default connect(mapStateToProps, { setSearchTerm, queryDatabase, queryPDFDatabase })(ReactiveSearchTopNav);
