import React, { Fragment } from 'react';
// import { Button } from 'react-bootstrap';
import { Button } from 'antd';
import {
  StepBackwardOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  StepForwardOutlined
} from '@ant-design/icons';
const Pagination = ({ totalPages = 1, currentPage = 1, pageNeighbors = 1, handlePageChange, reactiveSearch = false }) => {

  /* ---- FUNCTIONS ---- */

  function navigationClick(context) {
    if (context === "moveLeft" && currentPage !== 1) {
      handlePageChange(reactiveSearch ? currentPage - 2 : currentPage - 1)
    } else if (context === "moveRight" && currentPage !== totalPages) {
      handlePageChange(reactiveSearch ? currentPage : currentPage + 1)
    } else if (context === "toBeginning" && currentPage !== 1) {
      handlePageChange(reactiveSearch ? 0 : 1)
    } else if (context === "toEnd" && currentPage !== totalPages) {
      handlePageChange(reactiveSearch ? totalPages - 1 : totalPages)
    }
  }

  // Function to create a numerical array within a range: [from, ..., to]
  function getRange(from, to, step = 1) {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }
    return range;
  }

  function createPageRange() {
    // totalNumbers is the number of clickable buttons to be shown on the control
    // totalBlocks is the number of items to be shown in total, including ellipses '...'
    let totalNumbers = (pageNeighbors * 2) + 1
    let totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbors);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);
      let pages = getRange(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPages - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = getRange(startPage - spillOffset, startPage - 1);
          pages = ["...", ...extraPages, ...pages];
          break;
        }
        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = getRange(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, "..."];
          break;
        }
        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = ["...", ...pages, "..."];
          break;
        }
      }
      return [1, ...pages, totalPages];
    }
    return getRange(1, totalPages);
    }

    function renderButtons() {
      let pages = createPageRange()
      return (
        <Fragment>
          { pages.map((page, index) => {
            if (page === "...") return <span key={index} style={{margin: "10px"}}>...</span>
            return (
              <Button
                key={index}
                className={`pagination-button${ currentPage === page ? "-active" : "" } ${reactiveSearch ? "reactive-search-pagination-button" : ""}`}
                onClick={() => handlePageChange(reactiveSearch ? page - 1 : page)}
              >
                {page}
              </Button>
            )
          })}
        </Fragment>
      )
    }

  /* ---- RENDER ---- */

  return (
    <Fragment>
      {totalPages > 1 
        ?
          <Fragment>
            <Button className="pagination-navigation-button" onClick={() => navigationClick("toBeginning")}><StepBackwardOutlined /></Button>
            <Button className="pagination-navigation-button" onClick={() => navigationClick("moveLeft")} style={{marginRight: "5px"}}><CaretLeftOutlined /></Button>
            {renderButtons()}
            <Button className="pagination-navigation-button" onClick={() => navigationClick("moveRight")} style={{marginLeft: "5px"}}><CaretRightOutlined /></Button>
            <Button className="pagination-navigation-button" onClick={() => navigationClick("toEnd")}><StepForwardOutlined /></Button>
          </Fragment>
        :
          null
      }
    </Fragment>
  )
}

export default Pagination;