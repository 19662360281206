import React, { Fragment, useEffect, useState } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { Button, Icon, Input, Popover, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getStudyTypes, updateStudy } from '../../../../reducers/manageStudies'
import {Typeahead} from "react-bootstrap-typeahead";


export default function BasicInfo() {
  // REDUX
  const dispatch = useDispatch()
  const study = useSelector(state => state.manageStudies.selectedStudy)
  const countryList = useSelector(state => state.manageUsers.countries)
  const indicationList = useSelector(state => state.manageIndications.indications)
  const typeList = useSelector(state => state.manageStudies.studyTypes)
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)

  // STATE
  const [editable, setEditable] = useState(false)
  // const [name, setName] = useState( study.name || "" )
  const [description, setDescription] = useState( study.description || "" )
  const [primaryObjective, setPrimaryObjective] = useState( study.primary_objective || "" )
  const [secondaryObjective, setSecondaryObjective] = useState( study.secondary_objective || "" )
  const [exploratoryObjective, setExploratoryObjective] = useState( study.exploratory_objective || "" )
  const [status, setStatus] = useState( study.status || "" )
  // const [type, setType] = useState( study.type || "" )
  const [types, setTypes] = useState( study.types || [] )
  const [countries, setCountries] = useState( study.countries || [] )
  const [indications, setIndications] = useState( study.indications || [selectedIndication.url] )
  const [interventions, setInterventions] = useState( study.interventions || "" )
  const [internalLeadInvestigarors, setInternalLeadInvestigarors] = useState( study.internal_lead_investigators || "" )
  const [externalLeadInvestigarors, setExternalLeadInvestigarors] = useState( study.external_lead_investigators || "" )
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    dispatch(getStudyTypes())
  }, [])

  const handleCancel = () => {
    // toggleModal(false)
    setEditable(false)
    // setName( study.name || "" )
    setDescription( study.description || "" )
    setPrimaryObjective( study.primary_objective || "" )
    setSecondaryObjective( study.secondary_objective || "" )
    setExploratoryObjective( study.exploratory_objective || "" )
    setStatus( study.status || "" )
    // setType( study.type || "" )
    setTypes( study.types || [] )
    setCountries( study.countries || [] )
    setIndications( study.indication || [selectedIndication.url] )
    setInterventions( study.interventions || "" )
    setInternalLeadInvestigarors( study.internal_lead_investigators || "" )
    setExternalLeadInvestigarors( study.external_lead_investigators || "" )
    setErrorMessage("")
  }

  const handleSubmit = () => {
    if (/*!name || */!description || !status || !countries.length || !indications.length) {
      setErrorMessage("Please fill out all required fields.")
      return
    }
    setErrorMessage("")
    
    let newStudy = new FormData()

    newStudy.append("url", study.url)
    newStudy.append("id", study.id)
    // newStudy.append("name", name)
    newStudy.append("description", description)
    newStudy.append("primary_objective", primaryObjective)
    newStudy.append("secondary_objective", secondaryObjective)
    newStudy.append("exploratory_objective", exploratoryObjective)
    newStudy.append("status", status)
    // newStudy.append("type", type)
    if (types.length) {
      types.forEach(target => target.url ? newStudy.append("types", target.url) : newStudy.append("new_types", target.name))
    } else {
      newStudy.append("clear_types", "true")
    }
    countries.forEach(country => newStudy.append("countries", country))
    indications.forEach(indication => newStudy.append("indications", indication))
    newStudy.append("interventions", interventions)
    newStudy.append("internal_lead_investigators", internalLeadInvestigarors)
    newStudy.append("external_lead_investigators", externalLeadInvestigarors)

    dispatch(updateStudy(newStudy))
    setEditable(false)
  }

  const durationPopoverContent = (
    <div>
      This field is automatically calculated <br/>
      based on the Start Date and End Date <br/>
      which can be modified in the Key Dates section.
    </div>
  )

  return (
    <div id="BasicInfo">
      <h4>Basic Info</h4>
      <hr/>
      <Row className="field">
        <Col>
          { !editable ? 
            <Button className="new-study-btn" onClick={() => setEditable(true)}>Update</Button>
          :
            <Fragment>
              <Button className="new-study-btn" onClick={ handleCancel }>Cancel</Button>
              <Button className="new-study-btn" onClick={ handleSubmit }>Submit</Button>
            </Fragment>
          }
        </Col>
      </Row>
      <br/>
      { errorMessage ? 
        <Alert variant="danger">
          { errorMessage }
        </Alert> : null
      }
      {/* <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Name</strong></div>
          <Input
            disabled={ !editable }
            placeholder="Enter study name..."
            value={ name }
            onChange={ (e) => setName(e.target.value)}
          />
        </Col>
      </Row>
      <br/> */}
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Description</strong></div>
          <Input.TextArea
            disabled={ !editable }
            rows={4}
            placeholder="Enter description..."
            value={ description }
            onChange={ (e) => setDescription(e.target.value)}
          />
        </Col>
      </Row>
      <br/>
      <Row>
        <Col>
          <div><strong>Primary Objective</strong></div>
          <Input.TextArea
            disabled={ !editable }
            rows={3}
            placeholder={ editable ? "Enter primary objective..." : "N/A" }
            value={ primaryObjective }
            onChange={ (e) => setPrimaryObjective(e.target.value)}
          />
        </Col>
        <Col>
          <div><strong>Secondary Objective</strong></div>
          <Input.TextArea
            disabled={ !editable }
            rows={3}
            placeholder={ editable ? "Enter secondary objective..." : "N/A" }
            value={ secondaryObjective }
            onChange={ (e) => setSecondaryObjective(e.target.value)}
          />
        </Col>
        <Col>
          <div><strong>Exploratory Objective</strong></div>
          <Input.TextArea
            disabled={ !editable }
            rows={3}
            placeholder={ editable ? "Enter exploratory objective..." : "N/A" }
            value={ exploratoryObjective }
            onChange={ (e) => setExploratoryObjective(e.target.value)}
          />
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Status</strong></div>
          <Select value={ status } disabled={ !editable } onChange={ setStatus } style={ { width: "100%" } }>
            <Select.Option value="">Select a status...</Select.Option>
            <Select.Option value="In Planning">In Planning</Select.Option>
            <Select.Option value="Ongoing">Ongoing</Select.Option>
            <Select.Option value="Completed">Completed</Select.Option>
            <Select.Option value="Canceled">Canceled</Select.Option>
          </Select>
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <div>
            <strong>
              Duration&nbsp;
              <Popover content={ durationPopoverContent } trigger="hover">
                <Icon
                  style={{ verticalAlign: "0.125em", color: "var(--brand-color-1)", cursor: "pointer" }} 
                  type="info-circle" 
                />
              </Popover>
            </strong>
          </div>
          <Input
            disabled={ true }
            value={ study.duration }
            onChange={ () => null}
          />
        </Col>
      </Row>
      <br/>
      {/*<Row className="field">*/}
      {/*  <Col md={6} lg={6} xl={6}>*/}
      {/*    <div><strong>Type</strong></div>*/}
      {/*    <Input*/}
      {/*      disabled={ !editable }*/}
      {/*      placeholder={ editable ? "Enter type..." : "N/A" }*/}
      {/*      value={ type }*/}
      {/*      onChange={ (e) => setType(e.target.value)}*/}
      {/*    />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {/*<br/>*/}
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Types</strong></div>
          {/*<Select*/}
          {/*  style={ { width: "100%" } }*/}
          {/*  disabled={ !editable }*/}
          {/*  name="types"*/}
          {/*  mode="tags"*/}
          {/*  value={ types }*/}
          {/*  // mode="multiple"*/}
          {/*  onChange={ (type) => {*/}
          {/*    console.log(type)*/}
          {/*    setTypes(type)*/}
          {/*  } }*/}
          {/*  filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}*/}
          {/*>*/}
          {/*  { typeList.map(studyType => <Select.Option key={ studyType.id } value={ studyType.url }>{ studyType.name }</Select.Option>) }*/}
          {/*</Select>*/}
          <Typeahead
            className="ant-select-selection--multiple"
            disabled={ !editable }
            labelKey="name"
            allowNew
            id="custom-selections-example"
            multiple
            newSelectionPrefix="Add a new Type: "
            options={ typeList }
            selected={ types }
            onChange={ setTypes }
            placeholder={ editable ? "Enter Types..." : "N/A"}
          />
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Countries</strong></div>
          <Select
            style={ { width: "100%" } }
            disabled={ !editable }
            name="countries"
            value={ countries }
            mode="multiple"
            onChange={ setCountries }
            filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          >
            { countryList.map(country => <Select.Option key={ country.id } value={ country.url }>{ country.name }</Select.Option>) }
          </Select>
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Interventions</strong></div>
          <Input
            disabled={ !editable }
            placeholder={ editable ? "Enter interventions..." : "N/A"}
            value={ interventions }
            onChange={ (e) => setInterventions(e.target.value)}
          />
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col>
          <div><strong>Internal Lead Investigator</strong></div>
          <Input
            disabled={ !editable }
            placeholder={ editable ? "Enter internal lead investigaror..." : "N/A"}
            value={ internalLeadInvestigarors }
            onChange={ (e) => setInternalLeadInvestigarors(e.target.value)}
          />
        </Col>
        <Col>
          <div><strong>External Lead Investigator</strong></div>
          <Input
            disabled={ !editable }
            placeholder={ editable ? "Enter external lead investigator..." : "N/A"}
            value={ externalLeadInvestigarors }
            onChange={ (e) => setExternalLeadInvestigarors(e.target.value)}
          />
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Indications</strong></div>
          <Select
            style={ { width: "100%" } }
            disabled={ !editable }
            name="indications"
            value={ indications }
            mode="multiple"
            onChange={ setIndications }
          >
            { indicationList.map(indication => <Select.Option key={ indication.id } value={ indication.url }>{ indication.name }</Select.Option>) }
          </Select>
        </Col>
      </Row>
    </div>
  )
}