import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import LoginSideBar from './components/LoginSideBar'
import axios from 'axios'
import './login.css'
import {clearEverything} from "../../reducers/manageUsers";
import {useDispatch} from "react-redux";


export default function Login() {
	// REACT ROUTER DOM
	const history = useHistory()

	// REDUX
	const dispatch = useDispatch()

	// STATE
	const [authenticating, setAuthenticating] = useState(true)

	const checkAuthentication = () => {
    const token = localStorage.getItem("token")
    axios.get(process.env.REACT_APP_TOKEN_URL, {
      headers: {
        "Authorization": `JWT ${ token }`
      }
    })
    .then(response => {
      setAuthenticating(false)
      if (history.location.state) {
        history.replace(history.location.state.from)
      } else {
        history.replace("/")
      }
    })
    .catch(error => {
      console.log(error, error.response)
			dispatch(clearEverything())
    }).finally(() => {
			setAuthenticating(false)
		})
  }

	useEffect(() => {
		let isIE = !!document.documentMode;
		let isEdge = !isIE && !!window.StyleMedia;
		console.log("EDGE", isEdge);
	}, [])

	useEffect(() => {
    // if (localStorage.getItem("token")) {
			checkAuthentication()
    // } else {
    //   setAuthenticating(false)
    // }
    // eslint-disable-next-line
  }, [history.location.pathname])

	if (authenticating) {
    return(
			<div>
				<div className='home-loader'>
					<div>
						<center>
							<div className="loader-wrapper"><span className="loader"></span></div>
						</center>
						<p className='home-loader-text'>
						</p>
					</div>
				</div>
		 </div>
		)
  } else {
    return (
			<div id="loginScreen">
				<div
					id="sidebar"
					style={{'animationName':'fadeInLeft', 'animationDuration':'1s', 'animationDelay':'.85s', 'animationFillMode':'both'}}
				>
					<LoginSideBar />
				</div>
				<div id="background">
					<img id="logo" src={require("../../static/images/IEP_Platform_Logo_v03.png")} alt="iep-logo" />
				</div>
			</div>
		)
  }
}

