import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
// reusable chart components
import ActionBreakdownChart from '../reusable-charts/ActionBreakdownChart'


const AllModulesCharts = ({ clicks }) => {
  // STATE
  const [chartContext, setChartContext] = useState("all")
  const [selectedActionData, setSelectedActionData] = useState({
    actionName: "All",
    actionClicks: clicks
  })


  // FUNCTIONS
  const renderActionList = () => {
    const actionList = new Set()
    clicks.forEach(click => {
      actionList.add(click.action.split(" - ")[0])
    })

    return [...actionList].sort().map((action, index) => <option key={index} value={action}>{action}</option>)
  }

  const handleSelectAction = (e) => {
    const actionName = e.target.value
    const actionClicks = actionName === "All" ?
      clicks :
      clicks.filter(click => click.action.includes(actionName + " -"))

    setSelectedActionData({actionName: actionName, actionClicks: actionClicks})
    setChartContext(actionName === "All" ? "all" : "action")
  }


  // RENDER
  return (
    <div>
      
      {selectedActionData.actionName === "All" ?
        <h6>Overview of <strong>all </strong>site-wide tracking actions:</h6> :
        <h6>Overview of site-wide <strong>{selectedActionData.actionName.split(" - ")[0]}</strong> actions:</h6>
      }
      <ActionBreakdownChart clicks={selectedActionData.actionClicks} context={chartContext} />
      <br />
      <br />

      <p><em>(optional)</em> Select a specific tracking action to see breakdown by modules:</p>
      <Form.Control as="select" className="action-select" onChange={e => handleSelectAction(e)}>
        <option value={"All"}>All Actions</option>
        {renderActionList()}
      </Form.Control>
      <hr />

    </div>
  )
}

export default AllModulesCharts