import React from 'react'

// Rechart components
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'


const DownloadChart = ({ clicks, downloadObjsArr, context }) => {

  const downloadObjsMap = new Map()
  clicks.forEach(click => {
    const objectId = click.object_id.id
    if (downloadObjsMap.has(objectId)) {
      downloadObjsMap.set(objectId, downloadObjsMap.get(objectId) + 1)
    } else {
      downloadObjsMap.set(objectId, 1)
    }
  })

  const data = []
  downloadObjsMap.forEach((count, objectId) => {
    const downloadObj = downloadObjsArr.find(obj => obj.id === objectId)
    if (!downloadObj) {
      return
    }

    let downloadObjSubstring
    switch(context) {
      case "Evidence":
        downloadObjSubstring = (downloadObj.full_reference.substring(0, 130) + "...")
        break
      default:
        break
    }

    data.push({ message: downloadObjSubstring, downloads: count })
  })


  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart height={300} data={data}>
        <XAxis dataKey="message" tick={false} />
        <YAxis />
        <Tooltip contentStyle={{whiteSpace: "normal", maxWidth: "300px"}} />
        <Bar dataKey="downloads" fill="rgba(34, 35, 92, 1)" maxBarSize={200} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default DownloadChart