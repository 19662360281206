import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'antd'
import LoadingAnimation from '../../../../components/LoadingAnimation'
import AddUpdateKeyDate from './AddUpdateKeyDate'
import {
  getKeyDates,
  setKeyDatePage,
  setKeyDateSearchTerm,
} from '../../../../reducers/manageStudies'
import Pagination from "../../../../components/Pagination";
import {Col, Row} from "react-bootstrap";
import KeyDate from "./KeyDate";


export default function KeyDates() {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  const keyDates = useSelector(state => state.manageStudies.keyDates)
  const totalKeyDateCount = useSelector(state => state.manageStudies.totalKeyDateCount)
  const keyDatePage = useSelector(state => state.manageStudies.keyDatePage)
  const keyDateLimit = useSelector(state => state.manageStudies.keyDateLimit)
  const keyDateSearchTerm = useSelector(state => state.manageStudies.keyDateSearchTerm)
  const fetchingKeyDates = useSelector(state => state.manageStudies.fetchingKeyDates)


  useEffect(() => {
    dispatch(getKeyDates(
      [selectedStudy.id],
      null,
      null,
      keyDateLimit,
      keyDatePage
    ))
  }, [keyDatePage, keyDateLimit])

  return (
    <div id="KeyDates">
      <div>
        <h4>Key Dates</h4>
        <hr/>
        <AddUpdateKeyDate mode="add" />
        {/*<Button onClick={() => dispatch(setKeyDateLimit(2)) }>set Limit to 2</Button>*/}
        {/*<Button onClick={() => dispatch(setKeyDateLimit(10)) }>set Limit to 10</Button>*/}
        <br/>
        <br/>
        <Row>
          <Col md={5} lg={5} xl={5}>
            <Input.Search
              placeholder="Search"
              className="study-search-input"
              defaultValue={ keyDateSearchTerm }
              allowClear
              onSearch={(term) => {
                  dispatch(setKeyDateSearchTerm(term))
                  dispatch(setKeyDatePage(1))
                  dispatch(getKeyDates(
                  [selectedStudy.id],
                  null,
                  null,
                  keyDateLimit,
                ))
              }}
            />
          </Col>
          <Col md={7} lg={7} xl={7}>
            <center>
              <Pagination
                totalPages={ Math.ceil(totalKeyDateCount / keyDateLimit) }
                currentPage={ keyDatePage }
                pageNeighbors={ 1 }
                handlePageChange={ (page) => dispatch(setKeyDatePage(page)) }
              />
            </center>
          </Col>
        </Row>
        <div id="key-document-list">
          <br/>
          <table className="kvmtable" style={{width: "100%"}}>
            <colgroup>
              <col className="kvm-column" />
              <col className="kvm-column" />
            </colgroup>
            <thead className="table-header new-evidence">
              <tr>
                <th>Date</th>
                <th>Event</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              { fetchingKeyDates ?
                <tr className="kvm-label">
                  <td colSpan={3} style={{ width: '100%', overflow: 'hidden', padding: "100px" }}>
                    <br/>
                    <LoadingAnimation />
                  </td>
                </tr>
                :
                 keyDates.length ? keyDates.map(keyDate => <KeyDate key={ keyDate.id } keyDate={ keyDate } />)
                   : <tr><td colSpan={3}>There are no key dates...</td></tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}