import React, {useEffect, useState} from 'react'
import axios from "axios";
import { useLocation } from "react-router-dom";
import LoadingAnimation from "./LoadingAnimation";
import CategoryTypeFields from "./CategoryTypeFields";
import {Alert, Modal, Row} from "react-bootstrap";
import {Button} from "antd";
import {useSelector} from "react-redux";


export default function EntryCreationCategoryList({ entry, indications, selectedCategories, setSelectedCategories, ...props }) {
  // REACT ROUTER DOM
  const location = useLocation()
  // REDUX
  const indicationsFromReducer = useSelector(state => state.manageIndications.indications)

  // STATE
  const [categoryTypes, setCategoryTypes] = useState([])
  const [visitedIndications, setVisitedIndications] = useState([])
  const [fetchingCategoryTypes, setFetchingCategoryTypes] = useState(false)
  const [indicationIndex, setIndicationIndex] = useState(0)
  const [savedValues, setSavedValues] = useState({})
  const [errorMessage, setErrorMessage] = useState("")


  const generateSelectedCategories = (types) => {
    let visitedIndicationsCopy = [...visitedIndications]
    if (visitedIndicationsCopy.includes(indications[indicationIndex])) {
      return
    }
    visitedIndicationsCopy.push(indications[indicationIndex])
    setVisitedIndications(visitedIndicationsCopy)
    let selectedCategoriesObject = { ...selectedCategories }
    types.forEach(categoryType => {
      if (!selectedCategoriesObject[categoryType.id]) selectedCategoriesObject[categoryType.id] = []
      categoryType.categories.forEach(c => {
        if (
          entry && ((entry.category && entry.category.includes(c.url)) || (entry.categories && entry.categories.includes(c.url)))
          && !selectedCategoriesObject[categoryType.id].includes(c.url)
        ) {
          selectedCategoriesObject[categoryType.id].push(c.url)
        }
      })
    })
    setSelectedCategories(selectedCategoriesObject)
  }

  const getCategoryTypes = () => {
    const indicationID = indications[indicationIndex].split("/")[indications[indicationIndex].split("/").length - 2]
    setFetchingCategoryTypes(true)

    axios.get(`${process.env.REACT_APP_URL}category-types/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}`},
      params: { indication: indicationID, module_route: location.pathname }
    }).then(response => {
      setCategoryTypes(response.data)
      generateSelectedCategories(response.data)
    }).catch(error => {
      console.log(error.response)
    }).finally(() => {
      setFetchingCategoryTypes(false)
    })
  }

  const handleChangeCategories = (id, value) => {
    let selectedCategoriesCopy = {...selectedCategories}
    selectedCategoriesCopy[id] = value
    setSelectedCategories(selectedCategoriesCopy)
  }

  const handleChangeIndication = (direction) => {
    setErrorMessage("")
    setSavedValues({ ...savedValues, ...selectedCategories })
    if (direction === "BACK") {
      setIndicationIndex(indicationIndex - 1)
    } else {
      let canContinue = false
      categoryTypes.forEach(ct => {
        if (!!selectedCategories[ct.id] && !!selectedCategories[ct.id].length) {
          canContinue = true
        }
      })
      if (canContinue) {
        if (direction === "NEXT") {
          setIndicationIndex(indicationIndex + 1)
        } else if (direction === "SUBMIT")
          props.handleSubmit()
      } else {
        setErrorMessage("Please select at least one category")
      }
    }
  }

  useEffect(() => {
    getCategoryTypes()
  }, [indicationIndex])

  const selectedIndication = indicationsFromReducer.find(indication => indication.url === indications[indicationIndex])

  const generateFooterButtons = () => {
    let footerButtons = []
    if (indications.length > 1 && indicationIndex !== 0) {
      footerButtons.push(<Button key="2" onClick={ () => handleChangeIndication("BACK") }>Back</Button>)
    } else {
      footerButtons.push(<Button key="2" onClick={ () => props.changeStep(props.step - 1) }>Back</Button>)
    }
    if (indicationIndex + 1 === indications.length) {
      footerButtons.push(<Button key="3" onClick={ () => handleChangeIndication("SUBMIT") }>Submit</Button>)
    } else {
      footerButtons.push(<Button key="3" onClick={ () => handleChangeIndication("NEXT") }>Next</Button>)
    }

    return footerButtons
  }

  return (
    <React.Fragment>
      <Modal.Body>
        <div id="newevidence" className="translucent-background-with-padding">
          <h5>{ selectedIndication && selectedIndication.name }</h5>
          { fetchingCategoryTypes ?
            <div style={{ marginTop: "300px" }}><LoadingAnimation /></div>
            :
            <Row>
              <CategoryTypeFields
                categoryTypes={ categoryTypes }
                selectedCategories={ selectedCategories }
                handleChangeCategories={ handleChangeCategories }
              />
            </Row>
          }
          { errorMessage ?
            <Alert variant="danger">{ errorMessage }</Alert>
            :
            null
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button key="1" onClick={ props.handleCancel }>Cancel</Button>
        { generateFooterButtons() }
      </Modal.Footer>
    </React.Fragment>
  )
}