import React from 'react'
import {Button, Input} from "antd";
import { Alert, Modal } from "react-bootstrap";


export default function StepOne(props) {
  // PROPS
  const {
    message, handleInput, changeStep, /*kvmParent,*/
    handleInputFile, errorMessage, isEvidenceStatement
  } = props

  const renderFooterButtons = () => {
    if (isEvidenceStatement) {
      return <Button onClick={ () =>  changeStep(2) }>Select parent Key Value Message</Button>
    } else {
      return <Button onClick={ () =>  changeStep(3) }>Select Associated Evidence</Button>
    }
  }

  return (
    <React.Fragment>
      <Modal.Body>
        <div id="newevidence" className="translucent-background-with-padding">
          <div className="field">
            Upload Summary Slides:
            <br/>
            <input
              type="file"
              id="file-upload"
              onChange={ handleInputFile }
            />
          </div>
          <div className="field">
            <p>
              Message: <span className='required'>*</span>
            </p>
            <Input.TextArea
              value={ message }
              placeholder="Please enter a message..."
              className="longInput"
              name="message"
              rows={ 4 }
              onChange={ handleInput }
            />
          </div>
          <div>
            <span className="required">*</span> - Marks required fields
          </div>
          { errorMessage ?
            <Alert variant="danger">{ errorMessage }</Alert> : null
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        { renderFooterButtons() }
      </Modal.Footer>
    </React.Fragment>
  )
}