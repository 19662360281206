import React from 'react'
import { connect } from 'react-redux'

// const BrandFooter = ({ mode, loading, user, fetchingModules }) => {
const BrandFooter = ({ mode, loading, user, fetchingModules, selectedIndication}) => {
  // if (mode && mode !== '/loading' && mode !== "/login" && !loading && localStorage.getItem("indication")) {
  if (user && !fetchingModules && selectedIndication) {
    return (
        <div className="brand-footer">
          <img
            src={require('../static/images/logo-genesis-white-orange.png')}
            alt="Genesis Logo"
          />
          <p>
            All materials contained in the IEP must be considered strictly FOR INTERNAL USE ONLY. <br/>
            Any EXTERNAL use should undergo appropriate internal review to ensure compliance with applicable local laws and regulations.
          </p>
        </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => {
	return {
		mode: state.manageMode.mode,
		loading: state.manageMode.loading,
    user: state.manageUsers.user,
    selectedIndication: state.manageIndications.selectedIndication,
		fetchingModules: state.manageModules.fetchingModules
	}
}

export default connect(mapStateToProps)(BrandFooter)
