import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Input, Button, Row} from 'antd'
import { editUser } from '../../../reducers/manageUsers'

import 'antd/dist/antd.css'

class UserProfile extends Component {

  state = {
    edit: false,
    first_name: this.props.user.first_name,
    last_name: this.props.user.last_name,
    email: this.props.user.email,
    // editPassword: false,
    // oldPassword: '',
    // password: '',
    // confirmPassword: ''
  }

  componentDidUpdate(prevProps) {
    const { first_name, last_name, email } = this.props

    if (prevProps.first_name !== first_name || prevProps.last_name !== last_name || prevProps.email !== email ) {
      this.setState({ first_name, last_name, email })
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }


  editUser = () => {
    const { first_name, last_name, email } = this.state
    this.setState({ edit: false })
    this.props.editUser({ first_name, last_name, email, url: this.props.user.url })
  }

  // handlePasswordInput = (event) => {
  //   this.setState({ password: event.target.value })
  // }
  //
  // handleConfirmPasswordInput = (event) => {
  //   this.setState({ confirmPassword: event.target.value })
  // }
  //
  // handlePasswordChange = () => {
  //   if (this.state.password.length < 8) {
  //     message.error('Must be at least 8 characters long')
  //   } else if (this.state.password === this.state.confirmPassword) {
  //     this.props.changePassword(this.state.oldPassword, this.state.password, this.state.confirmPassword)
  //   }
  // }
  //
  // handleOldPasswordInput = (e) => {
  //   this.setState({
  //     oldPassword: e.target.value
  //   })
  // }

  render() {
    // let passwordMessages = []
    // if (this.state.password.length < 6){
    //   passwordMessages.push(" Password must be 6+ characters long")
    // } else {
    //   passwordMessages.push(" Good password")
    // }

    return (
      <div className="card">
        <Row>
          {this.state.edit ?
            <div>
              <Button
                className="user-acct-btn"
                onClick={this.editUser}
                style={{float: "right"}}
              >
                Save
              </Button>
              <Button
                className="user-acct-btn"
                onClick={()=>{this.setState({edit: false})}}
                style={{float: "right"}}
              >
                Cancel
              </Button>
            </div>
            :
            <Button
              className="user-acct-btn"
              onClick={()=>{this.setState({edit: true})}}
              style={{float: "right"}}
            >
              Edit
            </Button>
          }
        </Row>
        {this.state.edit ?
          <div>
            <div className="name">
              First Name:
              <Input
              name="first_name"
              type="text"
              style={{color: "black"}}
              value={this.state.first_name}
              onChange={this.handleChange}
              />

              <br/>
              <br/>

              Last Name:
              <Input
                name="last_name"
                type="text"
                style={{color: "black"}}
                value={this.state.last_name}
                onChange={this.handleChange}
              />

              <br />
              <br/>

              Email:
              <Input
                name="email"
                type="text"
                style={{color: "black"}}
                value={this.state.email}
                onChange={this.handleChange}
              />

              <br/>
              <br/>

            </div>
          </div>
          :
          <div>
            <div className="name">
              {this.props.user.first_name} {this.props.user.last_name}
            </div>
            <div className="field">
              Email: {this.props.user.email}
            </div>
          </div>
        }

        <div className="field">
          Role: {this.props.user.role}
        </div>
        <div className="field">
          Countries: {this.props.user.countries.join(", ")}
        </div>
        <hr />

        {/*   PASSWORD CHANGING NEEDS STYING   */}

          {/*<div className="password">
            { !this.state.editPassword ?
              <div>
                <Button onClick={()=>{this.setState({editPassword: true})}}>Change Password</Button>
              </div> :
              <div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Button onClick={()=>{this.setState({editPassword: false})}}>Cancel</Button>
                  <Button style={{marginBottom: "2vh"}} disabled={(this.state.password === '') || (this.state.password !== this.state.confirmPassword)} onClick={this.handlePasswordChange}>Confirm Password</Button>
                  <p>Enter Old password: <Input style={{width: "15vw", color: "black"}} onChange={this.handleOldPasswordInput} placeholder="Enter old password" value={this.state.oldPassword} type="password" /></p>
                  <p>Enter New password: <Input style={{width: "15vw", color: "black"}} onChange={this.handlePasswordInput} placeholder="Enter new password" value={this.state.password} type="password" />
                  {
                    this.state.password.length < 8 ?
                    <span style={{color: "red", fontSize:'10pt'}}> At least 8 characters long</span>
                      :
                      <span style={{color: "green", fontSize:'10pt'}}> Good password</span>
                  }
                  </p>
                  <p>
                    Confirm New password: <Input style={{width: "15vw", color:'black'}} placeholder="Confirm new password" type="password" value={this.state.confirmPassword} onChange={this.handleConfirmPasswordInput}/>&nbsp;
                    {
                      this.state.password !== '' && this.state.password === this.state.confirmPassword ?
                      <span style={{color: "green", fontSize:'10pt'}}>Passwords match</span>
                        :
                      null
                    }
                  </p>
                </form>
              </div>
            }
          </div>*/}

      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    user: state.manageUsers.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editUser: (user) => {
      dispatch(editUser(user))
    },
    // changePassword: (oldPassword, newPassword, confirmPassword) => {
    //   dispatch(changePassword(oldPassword, newPassword, confirmPassword))
    // }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
