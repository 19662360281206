import React, { useEffect, useState } from "react"
import { Button, Input } from "antd"
import { Alert, Col, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { submitKeyDocument } from "../../../../reducers/manageStudies"
import { Typeahead } from "react-bootstrap-typeahead"

export default function AddUpdateKeyDocument({ document, mode }) {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  const keyDocumentTypes = useSelector(state => state.manageStudies.keyDocumentTypes)

  // STATE
  let [showModal, toggleModal] = useState(false)
  let [title, setTitle] = useState(document ? document.title : "")
  let [documentTypes, setDocumentTypes] = useState(document ? document.document_types : [])
  let [file, setFile] = useState({})
  let [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    setTitle(document ? document.title : "")
    setFile({})
    setDocumentTypes(document ? document.document_types : [])
  }, [document])

  const handleCancel = () => {
    toggleModal(false)
    setTitle(document ? document.title : "")
    setFile({})
    setDocumentTypes(document ? document.document_types : [])
  }

  const handleSubmit = () => {
    let newDocument = new FormData()

    if (document) {
      newDocument.append("url", document.url)
    } else {
      newDocument.append("url", `${process.env.REACT_APP_URL}key-documents/`)
    }
    if (!title || !file || !documentTypes.length) {
      setErrorMessage("Please fill out all required fields")
      return
    }
    setErrorMessage("")
    newDocument.append("title", title)
    newDocument.append("document", file)
    documentTypes.forEach(type => {
      if (type.url) newDocument.append("document_types", type.url)
      else newDocument.append("new_document_types", type.name)
    })
    newDocument.append("study", selectedStudy.url)
    selectedStudy.indications.forEach(indication => newDocument.append("indications", indication))
    newDocument.append("created_by", {})
    newDocument.append("modified_by", {})

    let method = "POST"
    if (mode === "update") {
      method = "PATCH"
    }
    toggleModal(false)
    dispatch(submitKeyDocument(newDocument, method))
  }

  // const handleChangeDocumentType = (types) => {
  //   setErrorMessage("")
  //   let errors = types.find(type => {
  //     if (type.name.trim().toLowerCase() === "protocol" && selectedStudy.has_protocol_document) {
  //       setErrorMessage("This study already has a protocol document. Please change the type before submitting.")
  //       return true
  //     } else if (types[0].name.trim().toLowerCase() === "synopsis" && selectedStudy.has_synopsis_document) {
  //       setErrorMessage("This study already has a Synopsis document. Please change the type before submitting.")
  //       return true
  //     } else if (types[0].name.trim().toLowerCase() === "irbs" && selectedStudy.has_irbs_document) {
  //       setErrorMessage("This study already has a IRBs document. Please change the type before submitting.")
  //       return true
  //     } else {
  //       return false
  //     }
  //   })
  //   setDocumentTypes(types)
  // }

  return (
    <div style={{ display: "inline-block" }}>
      <Button className="new-study-btn" onClick={ () => toggleModal(true) }>{ mode === "add" ? "Add Document" : "Update" }</Button>
      <Modal show={ showModal } onHide={ handleCancel } size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            { mode === "add" ? "Add Document" : "Update" }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div><strong>Title <span className="required">*</span></strong></div>
              <Input value={ title } placeholder="Enter a title..." onChange={ (e) => setTitle(e.target.value) }/>
            </Col>
            <Col>
              <div><strong>Document <span className="required">*</span></strong></div>
              <input
                type="file"
                id="file-upload"
                onChange={ (e) => setFile(e.target.files[0]) }
              />
            </Col>
          </Row>
          <br/>
          <Row>
            <Col md={6} lg={6} xl={6}>
              <div><strong>Type <span className="required">*</span></strong></div>
              <Typeahead
                labelKey="name"
                multiple
                allowNew
                id="custom-selections-example"
                newSelectionPrefix="Add a new type: "
                options={ keyDocumentTypes }
                selected={ documentTypes }
                onChange={ setDocumentTypes }
                placeholder="Please choose a type......"
              />
            </Col>
          </Row>
          <br/>
          <div>
            <span className="required">*</span> - Marks required fields
          </div>
          <br/>
          { errorMessage ?
            <Alert variant="danger">
              { errorMessage }
            </Alert>
            :
            null
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ handleCancel }>Cancel</Button>
          <Button onClick={ handleSubmit }>Submit</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}