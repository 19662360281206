import React from 'react';
import {Col, Container, Row} from 'react-bootstrap'
import WhatsNew from './components/WhatsNew'
import HomeNavCard from "./components/HomeNavCard";
import './landing_page.css'


export default function Home() {
	return (
		<div id="LandingPage">
			<Container>
				<Row className="home-nav-card-row">
					<HomeNavCard
						moduleName="KEY VALUE MESSAGES"
						route="/keyvaluemessages"
						icon={require(`../../static/images/KeyIcon.png`)}
						highlights={[
							"Browse key value messages and evidence statements by topic.",
							"Summary content for key value messages.",
							"Access KVM-associated evidence."
						]}
						animationDelay=".6s"
					/>
					<HomeNavCard
						moduleName="STUDY CENTER"
						route="/studycenter/studies"
						icon={require(`../../static/images/dashboard.svg`)}
						highlights={[
							"Follow Studies from inception to evidence dissemination.",
							"Design and coordinate communication plan. Keep track of critical timelines. Access relevant study documents.",
							"Understand contribution of studies to KVMs and evidence statements."
						]}
						animationDelay=".7s"
					/>
					<HomeNavCard
						moduleName="EVIDENCE LIBRARY"
						route="/library"
						icon={require(`../../static/images/BookIcon.png`)}
						highlights={[
							"All evidence related to the product or indication.",
							"Filter, view, and download evidence by categories, sponsorship, type, and status.",
						]}
						animationDelay=".8s"
					/>
					{/*<HomeNavCard*/}
					{/*	moduleName="DATA COLLECTION"*/}
					{/*	route="/datacollection"*/}
					{/*	icon={require(`../../static/images/dashboard.svg`)}*/}
					{/*	highlights={[*/}
					{/*		"Searchable data repository by outcome and category",*/}
					{/*		"View, modify, and upload key data values associated with outcomes of interest"*/}
					{/*	]}*/}
					{/*	animationDelay=".8s"*/}
					{/*/>*/}
					<HomeNavCard
						moduleName="KEY RESOURCES"
						route="/resources"
						icon={require(`../../static/images/SchoolIcon.png`)}
						highlights={[
							"Continued learning and additional digital assets.",
							"Access resources such as\n" +
							"HTA decisions and policy documents,\n" +
							"objection handlers,\n" +
							"brand materials,\n" +
							"strategy documents, and other critical market context.",
						]}
						animationDelay=".9s"
					/>
				</Row>
				<Row className="bottomNav">
					<Col sm={12} md={12} lg={12}>
						<WhatsNew />
					</Col>
				</Row>
				{/*<Row className="bottomNav">*/}
				{/*	<Col sm={12} md={12} lg={12}>*/}
				{/*		<WhatsNew />*/}
				{/*	</Col>*/}
				{/*</Row>*/}
			</Container>
		</div>
	)
}