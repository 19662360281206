import React from 'react'
import {Alert, Col, Modal, Row} from "react-bootstrap";
import {Button, Input, Select} from "antd";
import {useSelector} from "react-redux";


export default function StepOne(props) {
  // REDUX
  const indicationsFromReducer = useSelector(state => state.manageIndications.indications)

  return (
    <React.Fragment>
      <Modal.Body>
        <div id="neweducation" className="translucent-background-with-padding">
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className='field'>
                <p>
                  Title: <span className='required'>*</span>
                </p>
                <Input
                  style={{ width: "100%" }}
                  type="text"
                  value={props.title}
                  className="shortInput"
                  placeholder='Please enter the title'
                  name="title"
                  onChange={props.handleInput}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className='field'>
                <p>
                  Choose Document: <span className='required'>*</span>
                </p>
                <input
                  type="file"
                  onChange={props.handleInputFile}
                />
                <br/>
                Large documents may take a few moments to upload.
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className='field'>
                <p>
                  Notes: <span className='required'>*</span>
                </p>
                <Input.TextArea
                  style={{ width: "100%" }}
                  value={props.uploaderNotes}
                  className="shortInput"
                  name="uploaderNotes"
                  placeholder='Please enter some notes'
                  onChange={props.handleInput}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="field">
                <p>
                  Indications: <span className='required'>*</span>
                </p>
                <Select
                  style={ { width: "100%" } }
                  value={ props.indications }
                  mode="multiple"
                  placeholder="Please select the indications..."
                  onChange={ (value) => props.handleSelect("indications", value) }
                  filterOption={(inputValue, option) => {
                    return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }}
                >
                  {
                    indicationsFromReducer.map(indication => {
                      return (
                        <Select.Option key={ indication.id } value={ indication.url }>{ indication.name }</Select.Option>
                      )
                    })
                  }
                </Select>
              </div>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col sm={12} md={6} lg={6}>*/}
          {/*    <div className='field'>*/}
          {/*      <p>*/}
          {/*        Resource Folder: <span className='required'>*</span>*/}
          {/*      </p>*/}
          {/*      <AsyncTypeahead*/}
          {/*        labelKey="name"*/}
          {/*        // allowNew*/}
          {/*        id="custom-selections-example"*/}
          {/*        // newSelectionPrefix="Add a new folder: "*/}
          {/*        multiple*/}
          {/*        minLength={1}*/}
          {/*        isLoading={fetchingAllFolders}*/}
          {/*        onSearch={handleGetAllFolders}*/}
          {/*        options={ allFolders }*/}
          {/*        selected={ selectedFolders }*/}
          {/*        onChange={(value) => {*/}
          {/*          handleSelect("selectedFolders", value)*/}
          {/*        }}*/}
          {/*        placeholder="Please select a resource folder"*/}
          {/*        renderMenuItemChildren={(option, props) => (*/}
          {/*          <Fragment>*/}
          {/*            <div>{option.name}</div>*/}
          {/*            {(option.path.length > 1) &&*/}
          {/*              <div style={{fontSize: "8pt"}}>Path: {option.path}</div>*/}
          {/*            }*/}
          {/*          </Fragment>*/}
          {/*        )}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<br/>*/}
          <Row>
            <Col>
              <span className="required">*</span> - Marks required fields
              { props.errorMessage &&
                <div className="alert-wrapper">
                  <Alert variant="danger">
                    Please fill out all required fields
                  </Alert>
                </div>
              }
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ props.handleCancel }>Cancel</Button>
        <Button onClick={ () => props.changeStep(2) }>Select Categories</Button>
      </Modal.Footer>
    </React.Fragment>
  )
}