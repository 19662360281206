/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Button } from 'antd';
import axios from 'axios'

class GVDTableOfContents extends Component {

  state = {
    visible: false,
    fetchingTableOfContents: true,
    content: []
  }

  onShowTableOfContents = () => {
    const { gvd } = this.props
    this.props.toggleShowTableOfContents()
    this.setState({ fetchingTableOfContents: true, content: [] })
    axios.get(`${ gvd.url }get_table_of_contents/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      this.setState({ fetchingTableOfContents: false, content: response.data })
    }).catch(error => {
      console.log(error)
      console.log(error.response)
      if (error.response && error.response.status === 401) {
        window.localStorage.clear()
        window.location = '/login'
      }
      this.setState({ fetchingTableOfContents: false, content: [] })
    })
  }

  onSectionClick = (e, section) => {
    e.preventDefault()
    this.props.showModal(section.page)
  }

  handleShowTable = () => {
    const { fetchingTableOfContents, content } = this.state
    if (this.props.showTableOfContents) {
      if (!this.state.content.length) {
        return <div className="dropdown-content"><a onClick={(e) => e.preventDefault()}>This document has no table of contents</a></div>
      }
      return (
        <div className="dropdown-content">
          { 
            fetchingTableOfContents ? "Loading Table" 
            :
            content.map(section => {
              const paddingLeft = (section.level - 1) * 20
              return <div key={ section.id } style={{ paddingLeft }}><a onClick={ (e) => this.onSectionClick(e, section) }>• {section.title}</a></div>
            })
          }
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <div className="dropdown">
        <Button className="dropdown-btn" onClick={ this.onShowTableOfContents }>Show Table of Contents</Button>
        { this.handleShowTable() }
      </div>
    )
  }
}

export default GVDTableOfContents