import React, { useState } from 'react'
import { Button, Input, Checkbox/*, Select */} from 'antd'
import { Modal } from 'react-bootstrap'
// import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
// const { Option } = Select


const EditUserModal = ({showEditModal, setShowEditModal, user, loggedInUser, handleSubmitEditedUser }) => {
  // STATE
  const [userData, setUserData] = useState({
    id: user.id,
    // email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    is_active: user.is_active
  })

  // FUNCTIONS
  function handleUserDataChange(e) {
    e.target.type === "checkbox" ?
      setUserData({
        ...userData,
        [e.target.name]: e.target.checked
      }) :
      setUserData({
        ...userData,
        [e.target.name]: e.target.value
      })
  }


  // RENDER
  return (
    <Modal
      show={showEditModal}
      onHide={() => setShowEditModal(false)}
      size="lg"
      className="ant-modal-body-custom"
    >

      <Modal.Header closeButton>
        <Modal.Title>
          Edit User
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="field">
          <p>
            First Name: <span className='required'>*</span>
          </p>
          <Input
            value={userData.first_name}
            className="longInput"
            name="first_name"
            onChange={handleUserDataChange}
          />
        </div>
        <br />
        <div className="field">
          <p>
            Last Name: <span className='required'>*</span>
          </p>
          <Input
            value={userData.last_name}
            className="longInput"
            name="last_name"
            onChange={handleUserDataChange}
          />
        </div>
        <br />
        {/* <div className="field">
          <p>
            Role: <span className='required'>*</span>
          </p>
          <Select defaultValue="Global">
            <Option value="Global">Global</Option>
          </Select>
        </div>
        <br /> */}
        <div className="field">
          <p>
            Activated?:
          </p>
          <Checkbox
            checked={userData.is_active}
            disabled={userData.id === loggedInUser.id ? true : false}
            name="is_active"
            onChange={handleUserDataChange}
          />
        </div>
        <br />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => handleSubmitEditedUser(userData)}>Save Changes</Button>
        <Button onClick={() => setShowEditModal(false)}>Close</Button>
      </Modal.Footer>

    </Modal>
  )
}

export default EditUserModal