/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
import swal from 'sweetalert'
import {message} from 'antd'

let defaultState = {
  show: false,
  videos: [],
  fetchingVideos: false,
  totalVideoCount: 0,
}

export default function manageEducationalVideos(state = defaultState, action){
  switch (action.type) {
    case 'SHOW_VIDEO':
      return { ...state, show: action.payload }
    case 'FETCHING_VIDEOS':
      return {...state, fetchingVideos: true }
    case 'SET_VIDEOS':
      return  { ...state, videos: action.payload, fetchingVideos: false }
    case 'UPDATED_VIDEO':
      const index = state.videos.findIndex(ed=> ed.id === action.payload.id)
      return {
        ...state,
        show: state.show,
        videos: [...state.videos.splice(0, index), action.payload, ...state.videos.slice(index)]
      }
    case 'DELETED_VIDEO':
      let newVideos = state.videos.filter(edu => edu.id !== action.payload)
      return {
        ...state,
        videos: newVideos
      }
    case 'SUBMITTED_VIDEO':
      return {
        ...state,
        videos: [...state.videos, action.payload]
      }
    case 'CLEAR_EDUCATIONAL_VIDEOS':
      return defaultState
    case 'SET_TOTAL_VIDEO_COUNT':
      return { ...state, totalVideoCount: action.payload }
    default:
		  return state
	}
}

export const deletedVideo = (id) => {
  return {
    type: 'DELETED_VIDEO',
    payload: id
  }
}

export const editedVideo = (data) => {
  return {type: 'UPDATED_VIDEO', payload: data}
}

export const showVideo = (show) => {
  return {type: 'SHOW_VIDEO', payload: show}
}

const fetchingVideos = () => {
  return {type: 'FETCHING_VIDEOS'}
}

const gotVideos = data => {
  return {type: 'SET_VIDEOS', payload: data}
}

const submittedVideo = data => {
  return {type: 'SUBMITTED_VIDEO', payload: data}
}

export const editVideo = (video) => {
  swal({
    title: "Uploading...",
    text: "Please wait. It might take a minute if the file is large.",
    icon: require("../static/images/spinner.gif"),
    button: false
  })
  return (dispatch) => {
    axios.patch(video.get("url"), video,
      {headers: {"Content-Type": "multipart/form-data", "Authorization": "JWT "+localStorage.getItem("token")}}
    )
    .then(response => {

      swal({
        title: "Success!",
        text: "The file has been edited!",
        icon: "success",
        button: "Continue!",
      });

      dispatch(editedVideo(response.data))
    })
    .catch(error => {
      console.log(error)
      console.log(error.response)
      if (error.response && error.response.status === 401) {
        window.localStorage.clear()
        window.location = '/login'
      }
      swal({
        title: "Error!",
        text: "The file wasn't able to be edited!",
        icon: "error",
        button: "Continue!",
      });
    })
  }
}

export const submitVideo = (video) => {
  swal({
    title: "Uploading...",
    text: "Please wait. It might take a minute if the file is large.",
    icon: require("../static/images/spinner.gif"),
    button: false
  })

  return function(dispatch){
    axios.post(process.env.REACT_APP_URL+"educational-videos/",
      video,
      {
        headers: {"Content-Type": "multipart/form-data", "Authorization": "JWT "+localStorage.getItem("token")}
      }
    )
    .then(response => {
      swal({
        title: "Success!",
        text: "The file has finished uploading!",
        icon: "success",
        button: "Continue!",
      });

      dispatch(submittedVideo(response.data))
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        window.localStorage.clear()
        window.location = '/login'
      }
      swal({
        title: "Error!",
        text: "The file wasn't able to be uploaded!",
        icon: "error",
        button: "Continue!",
      });
    })
  }
}

export const deleteVideo = video => {
  swal({
    title: "Deleting",
    text: "The file is being deleted. Please wait.",
    icon: require("../static/images/spinner.gif"),
    button: false,
  });
  return dispatch => {
    axios.delete(video.url, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "JWT "+localStorage.getItem("token")
      }
    }).then(response => {
      console.log(response)
      swal({
        title: "Success!",
        text: "The file has been deleted!",
        icon: "success",
        button: "Continue!",
      });
      dispatch(deletedVideo(id))
    }).catch(error => {
      console.log(error)
      if (error.response && error.response.status === 401) {
        window.localStorage.clear()
        window.location = '/login'
      }
      swal({
        title: "Error",
        text: "There was an error deleting the video",
        icon: "error",
        button: "Ok",
      })
    })
  }
}

export const getVideos = (searchTerm, limit=12, currentPage=1) => {
  return (dispatch) => {
    let offset = limit * (currentPage - 1)
    dispatch(fetchingVideos())
    axios.get(`${process.env.REACT_APP_URL}educational-videos/`,
      {
        params: { search_term: searchTerm, limit, offset },
        headers: {"Authorization": `JWT ${localStorage.getItem("token")}`}
      }
    ).then(response => {
      dispatch({ type: "SET_TOTAL_VIDEO_COUNT", payload: response.data.count})
      dispatch(gotVideos(response.data.results))
    }).catch(error => {
      if (error.response && error.response.status === 401) {
        window.localStorage.clear()
        window.location = '/login'
      }
      if (error.response && error.response.status === 403) {
        dispatch({type: "CLEAR_EVERYTHING"})
      } else {
        dispatch(gotVideos([]))
        swal({
          title: "Error",
          text: "There was an error loading the data",
          icon: "error",
          button: "Ok",
        })
      }
    })
  }
}

