import React from 'react'
import {Input, Radio} from 'antd'
import {useDispatch, useSelector} from "react-redux";
import FilterGroup from "./FilterGroup";
import { useLocation } from "react-router-dom";
import {changeSelectedRegion, getKVMs, getOverarchingValuePropositions} from "../../reducers/manageKVM";


export default function FilterSideBar(props) {
  // REACT ROUTER DOM
  const location = useLocation()
  // REDUX
  const dispatch = useDispatch()
  const categoryTypes = useSelector(state => state.manageCategories.categoryTypes)
  const fetchingCategoryTypes = useSelector(state => state.manageCategories.fetchingCategoryTypes)
  const kvmRegions = useSelector(state => state.manageKVM.regions)
  const selectedKVMRegion = useSelector(state => state.manageKVM.selectedRegion)

  // PROPS
  const {
    handleSearch,
    searchTerm,
    fetchingEntries,
    getEntries
  } = props

  const selectedModule = categoryTypes.find(module => module.route === location.pathname)

  const handleChangeKVMRegion = (region) => {
    dispatch(changeSelectedRegion(region))
    dispatch(getKVMs(true))
    dispatch(getOverarchingValuePropositions())
  }

  return (
    <div id="FilterSideBar" className={ fetchingCategoryTypes || fetchingEntries ? "transparent" : null }>
      { fetchingCategoryTypes || fetchingEntries ? <div className="loading"></div> : null}
      <h4>
        Filters
      </h4>
      <Input.Search
        placeholder="Search"
        defaultValue={ searchTerm }
        onSearch={ (value) => handleSearch(value) }
        allowClear
        onPressEnter={ (e) => handleSearch(e.target.value) }
      />
      { selectedModule && selectedModule.category_types.sort((a, b) => a.order - b.order).filter(filterGroup => {
        if (filterGroup.type === "RADIO") {
          return filterGroup.categories.length > 1
        }
        return filterGroup.categories.length > 0
      }).map(filterGroup => {
        return (
          <FilterGroup
            key={ filterGroup.id }
            selectedModule={ selectedModule }
            filterGroup={ filterGroup }
            getEntries={ getEntries }
          />
        )
      }) }
      { location.pathname === "/keyvaluemessages" ?
        <React.Fragment>
          <div>
            <h5>
              <span className="filter-title">Region</span>
            </h5>
            <div className="filter-categories">
              <Radio.Group onChange={(e) => handleChangeKVMRegion(e.target.value)} value={selectedKVMRegion}>
                { kvmRegions && kvmRegions.map(region => <div key={region}><Radio value={region} key={region}>{region}</Radio></div>) }
              </Radio.Group>
            </div>
          </div>
        </React.Fragment>
        :
        null
      }
    </div>
  )
}
