import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button } from 'antd'
import LoadingAnimation from '../../components/LoadingAnimation'
import KeyValueMessages from './components/KeyValueMessages'
import {
  getKVMs,
  changeSearchTerm,
  getOverarchingValuePropositions,
  toggleIsOrderEditable, saveKVMList
} from '../../reducers/manageKVM'
import { getCategoryTypesByModule } from '../../reducers/manageCategories'
import CreateUpdateKVM from "./components/CreateUpdateKVM";
import FilterSideBar from "../../components/FilterSidebar";
import { useLocation } from "react-router-dom";
import './key_value_messages.css'


export default function KeyValueInterface() {
  // REACT ROUTER DOM
  const location = useLocation()

  // REDUX
  const dispatch = useDispatch()
  const isOrderEditable = useSelector(state => state.manageKVM.isOrderEditable)
  const displayAllKVMs = useSelector(state => state.manageKVM.displayAllKVMs)
  const searchTerm = useSelector(state => state.manageKVM.searchTerm)
  const fetchingKVMs = useSelector(state => state.manageKVM.fetchingKVMs)
  const fetchingOverarchingValuePropositions = useSelector(state => state.manageKVM.fetchingOverarchingValuePropositions)
  const categoryTypes = useSelector(state => state.manageCategories.categoryTypes)
  const selectedCategories = useSelector(state => state.manageCategories.selectedCategories)
  const fetchingCategoryTypes = useSelector(state => state.manageCategories.fetchingCategoryTypes)

  // STATE
  const [displayEditOrderButton, setDisplayEditOrderButton] = useState(true)

  useEffect(() => {
    dispatch(getCategoryTypesByModule(location.pathname, () => {
      dispatch(getKVMs(true))
      dispatch(getOverarchingValuePropositions())
    }))
  }, [])

  useEffect(() => {
    const currentModule = categoryTypes.find(categoryType => categoryType.route === location.pathname)
    if (currentModule && selectedCategories[currentModule.id]) {
      const selectedCheckboxes = currentModule.category_types.find(categoryType => {
        return (
          categoryType.type === "CHECKBOX"
          && Object.keys(selectedCategories[currentModule.id]).includes(`${categoryType.id}`)
        )
      })
      if (selectedCheckboxes) setDisplayEditOrderButton(false)
      else setDisplayEditOrderButton(true)
    }
  }, [selectedCategories])


  const handleSearch = (searchTerm) => {
    dispatch(toggleIsOrderEditable(false))
    dispatch(changeSearchTerm(searchTerm))
    dispatch(getKVMs(true))
  }

  const handleCancelOrdering = () => {
    dispatch(toggleIsOrderEditable(false))
    dispatch(getKVMs(true))
  }

  const renderExtraActionButtons = () => {
    return (
      isOrderEditable ?
        <React.Fragment>
          <Button
            className="custom-btn"
            onClick={() => dispatch(saveKVMList())}
          >
            Save
          </Button>
          <Button
            className="custom-btn"
            style={{ marginTop: "10px" }}
            onClick={() => handleCancelOrdering()}
          >
            Cancel
          </Button>
        </React.Fragment>
        :
        <Button
          className="custom-btn"
          style={{ marginTop: "10px" }}
          onClick={() => dispatch(toggleIsOrderEditable(true))}
        >
          Edit Key Value Message Order
        </Button>
    )
  }

  return (
    <section id="KeyValueInterface">
      { fetchingCategoryTypes ?
        <div className='home-loader'>
          <LoadingAnimation/>
        </div>
        :
        <Row>
          <Col xs={24} sm={24} md={7} lg={6}>
            <FilterSideBar
              searchTerm={ searchTerm }
              handleSearch={ handleSearch }
              fetchingEntries={ fetchingKVMs || fetchingOverarchingValuePropositions }
              getEntries={ () => dispatch(getKVMs(true)) }
            />
            <div className="kvm-extra-action-buttons">
              { !isOrderEditable && <CreateUpdateKVM /> }
              { !displayAllKVMs && displayEditOrderButton ? renderExtraActionButtons() : null }
            </div>
          </Col>

          <Col xs={24} sm={24} md={17} lg={18}>
            <div id="kvm">
              { fetchingKVMs
              || fetchingOverarchingValuePropositions
                ?
                <LoadingAnimation/>
                :
                <KeyValueMessages />
              }
            </div>
          </Col>

        </Row>
      }
    </section>
  )
}