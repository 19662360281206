import React, { Fragment, useState } from 'react'
import axios from 'axios'
import { Col, Row } from 'react-bootstrap'
import { Button, Input, Popconfirm, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getCommunicationTargets } from '../../../../reducers/manageStudies'
import {requestFunctions} from "../../../../functions/requestFunctions";
import {CustomDatePicker} from "../../../../components/DatePickers";
import formatDateForServer from "../../../../functions/formatDateForServer";


export default function UpdateCommunicationTarget({ communicationTarget, communicationTargetDetails }) {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)

  // STATE
  const [editable, setEditable] = useState(false)
  const [venue, setVenue] = useState(communicationTarget.venue)
  const [dateRange, setDateRange] = useState([
    new Date(communicationTarget.key_dates[0].date),
    new Date(communicationTarget.key_dates[0].end_date)
  ])
  const [date, endDate] = dateRange
  const [content, setContent] = useState(communicationTargetDetails.content)
  const [htas, setHtas] = useState(communicationTargetDetails.htas)
  const [medicalProviders, setMedicalProviders] = useState(communicationTargetDetails.medical_providers)
  const [patientOrganizations, setPatientOrganizations] = useState(communicationTargetDetails.patient_organizations)
  const [payers, setPayers] = useState(communicationTargetDetails.payers)
  const [regulators, setRegulators] = useState(communicationTargetDetails.regulators)
  const [other, setOther] = useState(communicationTargetDetails.other)

  const handleCancel = () => {
    setEditable(false)
    setVenue(communicationTarget.venue)
    // setDate(new Date(communicationTarget.key_dates[0].date))
    setDateRange([new Date(communicationTarget.key_dates[0].date), new Date(communicationTarget.key_dates[0].end_date)])
    setContent(communicationTargetDetails.content)
    setHtas(communicationTargetDetails.htas)
    setMedicalProviders(communicationTargetDetails.medical_providers)
    setPatientOrganizations(communicationTargetDetails.patient_organizations)
    setPayers(communicationTargetDetails.payers)
    setRegulators(communicationTargetDetails.regulators)
    setOther(communicationTargetDetails.other)
  }

  const handleSubmit = () => {
    let updatedCommunicationTarget = {
      url: communicationTarget.url,
      study: selectedStudy.url,
      venue: venue,
      key_date: formatDateForServer(date),
      end_date: formatDateForServer(endDate),
      key_date_id: communicationTarget.key_dates[0].id
    }

    let updatedCommunicationTargetDetails = {
      url: communicationTargetDetails.url,
      study: selectedStudy.url,
      content: content,
      htas: htas,
      medical_providers: medicalProviders,
      patient_organizations: patientOrganizations,
      payers: payers,
      regulators: regulators,
      other: other
    }

    const updateCommunicationTarget = axios.patch(`${communicationTarget.url}`, updatedCommunicationTarget, { headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
    const updateCommunicationTargetDetails = axios.patch(`${communicationTargetDetails.url}`, updatedCommunicationTargetDetails, { headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
    requestFunctions.loadingAnimation("Updating a communication target")

    axios.all([updateCommunicationTarget, updateCommunicationTargetDetails])
    .then(axios.spread((ct, ctd) => {
      requestFunctions.successAnimation("Successfully updated a communication target")
      dispatch(getCommunicationTargets(selectedStudy.id))
    })).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in updating a communication target...")
    })
  }

  /**
   * @summary This function deletes communication targets or communication targeet details \
   * based on the URL of the object that was sent
   *
   * @param {string} targetUrl - CommunicationTarget or CommunicationStudyDetail URL
   */
  const handleRemove = (targetUrl) => {
    requestFunctions.loadingAnimation("Deleting a communication target")

    axios.delete(targetUrl, {
      headers: {"Authorization": `JWT ${localStorage.getItem("token")}`}
    }).then(response => {
      requestFunctions.successAnimation("Successfully deleted a communication target")
      dispatch(getCommunicationTargets(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting a communication target...")
    })
  }

  return (
    <div>
      <Row>
        <Col>
          { editable ? 
            <Fragment>
              <Button className="new-study-btn" onClick={ handleCancel }>Cancel</Button>
              <Button className="new-study-btn" onClick={ handleSubmit }>Submit</Button>
            </Fragment>
            :
            <Button className="new-study-btn" onClick={() => setEditable(true) }>Update</Button>
          }
        </Col>
      </Row>
      <br/>
      <Row>
        <Col md={6} lg={6} xl={6}>
          <div><strong>Venue</strong></div>
          <Input
            disabled={ !editable }
            value={ venue }
            placeholder={ editable ? "Enter the venue..." : "N/A"}
            onChange={ (e) => setVenue(e.target.value) }
          />
        </Col>
        <Col md={6} lg={6} xl={6}>
          <div><strong>Date</strong></div>
          <CustomDatePicker
            selectsRange={ true }
            startDate={ date }
            endDate={ endDate }
            onChange={ (update) => setDateRange(update)}
            disabled={ !editable }
            placeholderText="Enter date range..."
          />
        </Col>
      </Row>
      <br/>
      <Row>
        <Col md={6} lg={6} xl={6}>
          <div><strong>Content</strong></div>
          <Input.TextArea
            disabled={ !editable }
            rows={4}
            value={ content }
            placeholder={ editable ? "Enter content..." : "N/A" }
            onChange={ (e) => setContent(e.target.value) }
          />
        </Col>
      </Row>
      <br/>
      <h4>Relevant Stakeholder Targets</h4>
      <hr/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>HTAs</strong></div>
          <Select
            value={ htas }
            disabled={ !editable }
            style={{ width: "100%" }}
            placeholder={ editable ? "Enter HTAs..." : "N/A"}
            onChange={ setHtas }
            mode="multiple"
          >
            { selectedStudy.htas.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
          </Select>
        </Col>
        <Col md={6} lg={6} xl={6}>
          <div><strong>Medical Providers</strong></div>
          <Select
            value={ medicalProviders }
            disabled={ !editable }
            style={{ width: "100%" }}
            placeholder={ editable ? "Enter medical providers..." : "N/A"}
            onChange={ setMedicalProviders }
            mode="multiple"
          >
            { selectedStudy.medical_providers.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
          </Select>
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Patient Organizations</strong></div>
          <Select
            value={ patientOrganizations }
            disabled={ !editable }
            style={{ width: "100%" }}
            placeholder={ editable ? "Enter patient organizations..." : "N/A"}
            onChange={ setPatientOrganizations }
            mode="multiple"
          >
            { selectedStudy.patient_organizations.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
          </Select>
        </Col>
        <Col md={6} lg={6} xl={6}>
          <div><strong>Payers</strong></div>
          <Select
            value={ payers }
            disabled={ !editable }
            style={{ width: "100%" }}
            placeholder={ editable ? "Enter payers..." : "N/A"}
            onChange={ setPayers }
            mode="multiple"
          >
            { selectedStudy.payers.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
          </Select>
        </Col>
      </Row>
      <br/>
      <Row className="field">
        <Col md={6} lg={6} xl={6}>
          <div><strong>Regulators</strong></div>
          <Select
            value={ regulators }
            disabled={ !editable }
            style={{ width: "100%" }}
            placeholder={ editable ? "Enter regulators..." : "N/A"}
            onChange={ setRegulators }
            mode="multiple"
          >
            { selectedStudy.regulators.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
          </Select>
        </Col>
        <Col md={6} lg={6} xl={6}>
          <div><strong>Other</strong></div>
          <Select
            value={ other }
            disabled={ !editable }
            style={{ width: "100%" }}
            placeholder={ editable ? "Enter other..." : "N/A"}
            onChange={ setOther }
            mode="multiple"
          >
            { selectedStudy.other.map(target => <Select.Option key={ target.url } value={ target.url }>{ target.name }</Select.Option>) }
          </Select>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col>
          <Popconfirm
            title="Are you sure to remove the communication target from this study?"
            placement="left"
            onConfirm={() => handleRemove(communicationTargetDetails.url)}
            okText="Yes"
            cancelText="No"
          >
            <Button className="delete-study-btn">Remove from study</Button>
          </Popconfirm>
        </Col>
        <Col>
          <Popconfirm
            title="Are you sure to remove the communication target?"
            placement="left"
            onConfirm={() => handleRemove(communicationTarget.url)}
            okText="Yes"
            cancelText="No"
          >
            <Button className="delete-study-btn">Remove from indication</Button>
          </Popconfirm>
        </Col>
      </Row>
    </div>
  )
}