import React, {useState} from 'react'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import { Modal } from 'react-bootstrap'
import { Input, Button, message, Form } from 'antd'
import { clearEverything, disableInitialLogin } from '../reducers/manageUsers'
import swal from 'sweetalert'
import {requestFunctions} from "../functions/requestFunctions";

export default function PasswordResetForm() {
  // REDUX
  const dispatch = useDispatch()
  const user = useSelector(state => state.manageUsers.user)

  // STATE
  const [initialLoginModal, setInitialLoginModal] = useState(user.initial_login)
  const [oldPassword, setOldPassword] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")


  const handlePasswordChange = () => {
    if(password.length < 8){
      message.error('Must be at least 8 characters long')
    }else if((password === confirmPassword)){
      requestFunctions.loadingAnimation("Changing Password...")

      axios.post(`${process.env.REACT_APP_URL}password/change/`,{
          "old_password": oldPassword,
          "new_password1": password,
          "new_password2": confirmPassword
        }, {
          headers:{ "Authorization": "JWT "+localStorage.getItem("token") },
      }).then(response => {
        requestFunctions.successAnimation("Password has been changed")
        dispatch(disableInitialLogin())
        setInitialLoginModal(false)
      }).catch(error => {
        console.log(error)
        console.log(error.response)
        if (error.response && error.response.status === 401) {
          dispatch(clearEverything())
          window.localStorage.clear()
          window.location = '/login'
        }
        let errorMessage = ""
        if (error.response.data.old_password) {
          errorMessage = "Invalid password"
        } else if (error.response.data.new_password2){
          errorMessage = "The password is too similar to the email address"
        } else {
          errorMessage = "There was an error changing your password"
        }
        swal({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          button: "Continue!",
        });
      })
    }
  }


  return (
    <Modal
      show={ initialLoginModal }
      size="lg"
      id="PasswordResetForm"
      onHide={ () => setInitialLoginModal(false) }
    >
      <Modal.Header closeButton>
        <Modal.Title>Initial Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>Hello and Welcome to the IEP</h3>
        <h4 style={{ fontWeight: "normal" }}>For security purposes please reset your initial password:</h4>
        <br/>
        <div>
          <Form onSubmit={(e) => e.preventDefault()}>
            <p>
              Enter Old password: <br/>
              <Input.Password
                style={{width: "15vw", color: "black"}}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Enter old password"
                value={oldPassword} type="password"
              />
            </p>
            <p>
              Enter New password: <br/>
              <Input.Password
                style={{width: "15vw", color: "black"}}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter new password"
                value={password} type="password"
              />
              {
                password.length < 8 ?
                <span style={{color: "red", fontSize:'10pt'}}> &nbsp;At least 8 characters long</span>
                :
                <span style={{color: "green", fontSize:'10pt'}}> &nbsp;Good password</span>
              }
            </p>
            <p>
              Confirm New password: <br/>
              <Input.Password
                style={{width: "15vw", color:'black'}}
                placeholder="Confirm new password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />&nbsp;
              {
                password !== '' && password === confirmPassword ?
                <span style={{color: "green", fontSize:'10pt'}}>Passwords match</span>
                :
                null
              }
            </p>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={(password === '') || (password !== confirmPassword)} onClick={handlePasswordChange}>Confirm Password</Button>
      </Modal.Footer>
    </Modal>
  )
}