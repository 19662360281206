/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import './style.css'
import LoadingAnimation from '../../components/LoadingAnimation'
import { clearEverything, getCountries, getUsers } from '../../reducers/manageUsers'
import {useDispatch, useSelector} from 'react-redux'
import { getIndications, selectIndication } from '../../reducers/manageIndications'
import {Redirect, useHistory} from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { getGVDs } from '../../reducers/manageEducationalResources'
import PasswordResetForm from '../../components/PasswordResetForm'
import { getModules } from '../../reducers/manageModules'
// import IndicationPagination from './components/IndicationPagination'


export default function IndicationContainer(props) {
  // REACT ROUTER DOM
  const history = useHistory()

  // REDUX
  const dispatch = useDispatch()
  const indications = useSelector(state => state.manageIndications.indications)
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const fetchingIndications = useSelector(state => state.manageIndications.fetchingIndications)
  const errorMessage = useSelector(state => state.manageIndications.errorMessage)

  // STATE
  // const [currentIndicationIndex, setCurrentIndicationIndex] = useState(0)

  useEffect(() => {
    dispatch(getIndications(history))
  }, [])

  const handleSelectIndication = (indication) => {
    localStorage.setItem("indication", indication.id)
    if (indication) {
      dispatch(selectIndication(indication))
      if (history.location.state && history.location.state.from) {
        history.replace(history.location.state.from.pathname)
      } else {
        history.replace("/")
      }
      dispatch(getGVDs(indication.id))
      dispatch(getUsers())
      dispatch(getModules())
      dispatch(getCountries())
    } else {
      console.log("Selected indication does not exist")
    }
  }

  // const handlePageChange = (page) => {
  //   setCurrentIndicationIndex(page)
  // }

  const handleLogOut = () => {
    dispatch(clearEverything())
    history.replace("/login")
  }

  if (selectedIndication) {
    return <Redirect to="/" />
  }
  return (
    <div id="IndicationContainer">
      <PasswordResetForm />
      <div className="welcome">
        <h1><span>Welcome!</span></h1>
        <br/>
        <h5>Please select an indication...</h5>
      </div>
      <div className="indications">
      { fetchingIndications ? <LoadingAnimation /> :
        <center className="pagination-container">
          <Row>
          { indications.length ? indications.map(indication => {
            return (
              <Col key={indication.id} style={{margin:"5px"}}>
                <div
                  className={`indication-pagination-button`}
                  onClick={() => handleSelectIndication(indication)}
                >
                  <span className="indication-pagination-button-text">{indication.name}</span>
                </div>
              </Col>
            )
          }) : "There are no available indications" }
          </Row>
          <Row>
            <div style={{ width: "100%", marginTop: "50px" }}>
              <a className="logout-btn" onClick={ handleLogOut }>Log Out</a>
            </div>
          </Row>
          {/* { indications.length ?
            <IndicationPagination
              totalPages={ indications.length }
              currentPage={ currentIndicationIndex }
              pageNeighbors={ 1 }
              indications={ indications }
              handlePageChange={ handlePageChange }
              handleSelectIndication={ handleSelectIndication }
            />
           :
            "There are no available indications" } */}
          <p>
          { errorMessage ? errorMessage : null }
          </p>
        </center>
      }
      </div>
    </div>
  )
}