import axios from 'axios'
import {requestFunctions} from "../functions/requestFunctions";

const defaultState = {
  studies: [],
  selectedStudy: null,
  fetchingStudies: true,
  fetchingStudy: true,
  keyDates: [],
  totalKeyDateCount: 0,
  keyDateLimit: 10,
  keyDatePage: 1,
  keyDateSearchTerm: "",
  fetchingKeyDates: true,
  inclusionExclusionCriteria: [],
  fetchingInclusionExclusionCriteria: true,
  outcomes: [],
  fetchingOutcomes: true,
  stakeholderTargets: {},
  fetchingStakeholderTargets: true,
  communicationTargets: [],
  fetchingCommunicationTargets: true,
  studyTypes: [],
  fetchingStudyTypes: true,
  keyDocuments: [],
  keyDocumentTypes: [],
  fetchingKeyDocuments: true,
  fetchingKeyDocumentTypes: true,
  resultDocuments: [],
  fetchingResultDocuments: true,
  studyLimit: 10,
  studyPage: 1,
  totalStudyCount: 0,
  studySearchTerm: "",
  errorMessage: ""
}

export default function manageStudies(state = defaultState, action) {
  switch (action.type) {
    case "SET_STUDIES":
      return { ...state, studies: action.payload, fetchingStudies: false }
    case "SET_SELECTED_STUDY":
      return {
        ...state,
        selectedStudy: action.payload,
        fetchingStudy: false
      }
    case "SET_TOTAL_STUDIES_COUNT":
      return { ...state, totalStudyCount: action.payload }
    case "TOGGLE_FETCHING_STUDIES":
      return { ...state, fetchingStudies: action.payload }
    case "TOGGLE_FETCHING_STUDY":
      return { ...state, fetchingStudy: action.payload }
    case "SET_KEY_DATES":
      return { ...state, keyDates: action.payload }
    case "SET_TOTAL_KEY_DATE_COUNT":
      return { ...state, totalKeyDateCount: action.payload }
    case "SET_KEY_DATE_LIMIT":
      return { ...state, keyDateLimit: action.payload }
    case "SET_KEY_DATE_PAGE":
      return { ...state, keyDatePage: action.payload }
    case "SET_KEY_DATE_SEARCH_TERM":
      return { ...state, keyDateSearchTerm: action.payload }
    case "TOGGLE_FETCHING_KEY_DATES":
      return { ...state, fetchingKeyDates: action.payload }
    case "SET_STUDY_OUTCOMES":
      return { ...state, outcomes: action.payload }
    case "TOGGLE_FETCHING_STAKEHOLDER_TARGETS":
      return { ...state, fetchingStakeholderTargets: action.payload }
    case "SET_STAKEHOLDER_TARGETS":
      return { ...state, stakeholderTargets: action.payload }
    case "TOGGLE_FETCHING_COMMUNICATION_TARGETS":
      return { ...state, fetchingCommunicationTargets: action.payload }
    case "SET_COMMUNICATION_TARGETS":
      return { ...state, communicationTargets: action.payload }
    case "TOGGLE_FETCHING_KEY_DOCUMENTS":
      return { ...state, fetchingKeyDocuments: action.payload }
    case "SET_STUDY_TYPES":
      return { ...state, studyTypes: action.payload }
    case "TOGGLE_FETCHING_STUDY_TYPES":
      return { ...state, fetchingStudyTypes: action.payload }
    case "TOGGLE_FETCHING_KEY_DOCUMENT_TYPES":
      return { ...state, fetchingKeyDocumentTypes: action.payload }
    case "SET_KEY_DOCUMENTS":
      return { ...state, keyDocuments: action.payload }
    case "SET_KEY_DOCUMENT_TYPES":
      return { ...state, keyDocumentTypes: action.payload }
    case "TOGGLE_FETCHING_RESULT_DOCUMENTS":
      return { ...state, fetchingResultDocuments: action.payload }
    case "SET_RESULT_DOCUMENTS":
      return { ...state, resultDocuments: action.payload }
    case "TOGGLE_FETCHING_STUDY_OUTCOMES":
      return { ...state, fetchingOutcomes: action.payload }
    case "SET_INCLUSION_EXCLUSION_CRITERIA":
      return { ...state, inclusionExclusionCriteria: action.payload }
    case "TOGGLE_FETCHING_INCLUSION_EXCLUSION_CRITERIA":
      return { ...state, fetchingInclusionExclusionCriteria: action.payload }
    case "SET_STUDY_SEARCH_TERM":
      return { ...state, studySearchTerm: action.payload }
    case "SET_STUDY_PAGE":
      return { ...state, studyPage: action.payload }
    case "SET_STUDY_ERROR_MESSAGE":
      return { ...state, errorMessage: action.payload }
    case "CLEAR_STUDIES":
      return defaultState
    default:
      return state
  }
}

export const toggleFetchingStudies = (fetching) => {
  return { type: "TOGGLE_FETCHING_STUDIES", payload: fetching }
}

export const toggleFetchingStudy = (fetching) => {
  return { type: "TOGGLE_FETCHING_STUDY", payload: fetching }
}

export const toggleFetchingKeyDates = (fetching) => {
  return { type: "TOGGLE_FETCHING_KEY_DATES", payload: fetching }
}

export const toggleFetchingOutcomes = (fetching) => {
  return { type: "TOGGLE_FETCHING_STUDY_OUTCOMES", payload: fetching }
}

export const toggleFetchingInclusionExclusionCriteria = (fetching) => {
  return { type: "TOGGLE_FETCHING_INCLUSION_EXCLUSION_CRITERIA", payload: fetching }
}

export const toggleFetchingStakeholderTargets = (fetching) => {
  return { type: "TOGGLE_FETCHING_STAKEHOLDER_TARGETS", payload: fetching }
}

export const toggleFetchingCommunicationTargets = (fetching) => {
  return { type: "TOGGLE_FETCHING_COMMUNICATION_TARGETS", payload: fetching }
}

export const toggleFetchingStudyTypes = (fetching) => {
  return { type: "TOGGLE_FETCHING_STUDY_TYPES", payload: fetching }
}

export const toggleFetchigKeyDocuments = (fetching) => {
  return { type: "TOGGLE_FETCHING_KEY_DOCUMENTS", payload: fetching }
}

export const toggleFetchigKeyDocumentTypes = (fetching) => {
  return { type: "TOGGLE_FETCHING_KEY_DOCUMENT_TYPES", payload: fetching }
}

export const toggleFetchigResultDocuments = (fetching) => {
  return { type: "TOGGLE_FETCHING_RESULT_DOCUMENTS", payload: fetching }
}

export const setErrorMessage = (message) => {
  return { type: "SET_STUDY_ERROR_MESSAGE", payload: message }
}

export const setStudySearchTerm = (message) => {
  return { type: "SET_STUDY_SEARCH_TERM", payload: message }
}

export const setKeyDateSearchTerm = (message) => {
  return { type: "SET_KEY_DATE_SEARCH_TERM", payload: message }
}

export const setStudyPage = (page) => {
  return { type: "SET_STUDY_PAGE", payload: page }
}

export const setKeyDatePage = (page) => {
  return { type: "SET_KEY_DATE_PAGE", payload: page }
}

export const setKeyDateLimit = (limit) => {
  return { type: "SET_KEY_DATE_LIMIT", payload: limit }
}

/**
 * @summary Gets studies for the currently selected indication.
 * 
 * @param {number=} limit - Dictates how many studies are received per page.
 * @param {number=} page - Dictates what page.
 */
export const getStudies = (limit, page) => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    const { studySearchTerm } = useState().manageStudies
    
    if (limit !== 0 && !limit) limit = useState().manageStudies.studyLimit
    if (page !== 0 && !page) page = useState().manageStudies.studyPage
    
    let offset = limit * (page - 1)

    dispatch(setErrorMessage(""))
    dispatch(toggleFetchingStudies(true))

    axios.get(`${process.env.REACT_APP_URL}studies/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
      params: { offset, limit, indication: selectedIndication.id, search: studySearchTerm }
    }).then(response => {
      if (Object.keys(response.data).includes("results")) {
        dispatch({ type: "SET_STUDIES", payload: response.data.results })
        dispatch({ type: "SET_TOTAL_STUDIES_COUNT", payload: response.data.count })
      } else {
        dispatch({ type: "SET_STUDIES", payload: response.data })
      }
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting studies.")
    }).finally(() => {
      dispatch(toggleFetchingStudies(false))
    })
  }
}

/**
 * @summary Gets a study for the selected indication.
 * 
 * @param {string} id - ID of the selected study.
 */
export const getStudy = (id) => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    
    dispatch(setErrorMessage(""))
    dispatch(toggleFetchingStudy(true))

    axios.get(`${process.env.REACT_APP_URL}studies/${id}/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
      params: { indication: selectedIndication.id }
    }).then(response => {
      dispatch({ type: "SET_SELECTED_STUDY", payload: response.data })
    }).catch(error => {
      dispatch({ type: "SET_SELECTED_STUDY", payload: null })
      requestFunctions.handleError(error, dispatch, "There was an error in getting the study.")
    })
  }
}

/**
 * @summary Creates a new Study.
 * 
 * @param {FormData} outcome - Outcome FormData object to be sent to the backend.
 * @param {History} history - React Router Dom history object [useHistory()].
 */
export const submitStudy = (study, history) => {
  return dispatch => {
    requestFunctions.loadingAnimation("Adding a study...")

    axios.post(`${process.env.REACT_APP_URL}studies/`, study, {
      headers: {
        "Authorization": `JWT ${localStorage.getItem("token")}`
      }
    }).then(response => {
      requestFunctions.successAnimation("Successfully added a study")
      dispatch(getStudies())
      history.replace(`/studycenter/studies/${response.data.id}/`)
    }).catch(error => {
      console.log(error.response)
      requestFunctions.handleError(error, dispatch, "There was an error in adding a study...", )
    })
  }
}

/**
 * @summary Updates a Study.
 * 
 * @param {FormData} study - Study FormData object to be sent to the backend.
 */
export const updateStudy = (study) => {
  return dispatch => {
    requestFunctions.loadingAnimation("Updating a study...")

    axios.patch(`${study.get("url")}`, study, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully updated a study")
      dispatch(getStudies())
      dispatch(getStudy(study.get("id")))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in updating a study...")
    })
  }
}

/**
 * 
 * @param {string} url - URL of the study
 * @param {History} history - React Router Dom history object [useHistory()].
 */
export const deleteStudy = (url, history) => {
  return dispatch => {
    requestFunctions.loadingAnimation("Deleting a study...")

    axios.delete(url, {
      headers: {
        "Authorization": `JWT ${ localStorage.getItem("token") }`
      }
    }).then(response => {
      requestFunctions.successAnimation("Successfully deleted a study")
      dispatch(setStudyPage(1))
      dispatch(getStudies())
      history.replace(`/studycenter/studies/`)
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting a study...")
    })
  }
}

/**
 * @summary Gets all Key Dates that are associated with a study if provided a study id.
 *
 * @param {string[]=} studyIds - An array of study IDs.
 * @param {string=} indicationId - ID of the indication.
 * @param {Object=} dateRange - A range of dates to which to filter key dates by.
 * @param {number=} limit - Dictates how many studies are received per page.
 * @param {number=} page - Dictates what page.
 */
export const getKeyDates = (studyIds=null, indicationId=null, dateRange=null, limit, page) => {
  return (dispatch, useState) => {
    const { keyDateSearchTerm } = useState().manageStudies

    if (limit !== 0 && !limit) limit = useState().manageStudies.keyDateLimit
    if (page !== 0 && !page) page = useState().manageStudies.keyDatePage

    let offset = limit * (page - 1)

    dispatch(toggleFetchingKeyDates(true))
    axios.get(`${process.env.REACT_APP_URL}key-dates/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
      params: {
        offset, limit,
        studies: studyIds,
        indication: indicationId,
        range: dateRange,
        search: keyDateSearchTerm
      }
    }).then(response => {
      if (Object.keys(response.data).includes("results")) {
        dispatch({ type: "SET_KEY_DATES", payload: response.data.results })
        dispatch({ type: "SET_TOTAL_KEY_DATE_COUNT", payload: response.data.count })
      } else {
        dispatch({ type: "SET_KEY_DATES", payload: response.data})
      }
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in fetching key dates...")
      console.log(error.response)
    }).finally(() => {
      dispatch(toggleFetchingKeyDates(false))
    })
  }
}

/**
 * @summary Creates a new or updates an existing Key Date depending on what method was chosen.
 * 
 * @param {FormData} outcome - Key Date FormData object to be sent to the backend.
 * @param {string} method - Request method (POST or PATCH).
 */
export const submitKeyDate = (keyDate, method) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation(`${ method === "POST" ? "Adding" : "Updating" } a key date...`)

    axios({
      method: method,
      url: keyDate.get("url") ? keyDate.get("url") : `${ process.env.REACT_APP_URL }key-dates/`,
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` },
      data: keyDate,
    }).then(response => {
      requestFunctions.successAnimation("Successfully added a key date")
      dispatch(getKeyDates([selectedStudy.id]))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in adding a key date...")
    })
  }
}

/**
 * @summary Deletes a selected Key Date.
 * 
 * @param {string} url - URL of the Key Date.
 */
export const deleteKeyDate = (url) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation("Deleting a key date...")

    axios.delete(url, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully deleted a key date")
      dispatch(setKeyDatePage(1))
      dispatch(getKeyDates([selectedStudy.id]))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting a key date...")
    })
  }
}

/**
 * @summary Gets all Outcomes that are associated with a study if provided a study id.
 * 
 * @param {string=} studyId - ID of the selected study.
 */
export const getOutcomes = (studyId=null) => {
  return dispatch => {
    dispatch(toggleFetchingOutcomes(true))
    axios.get(`${process.env.REACT_APP_URL}outcomes/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
      params: { study: studyId }
    }).then(response => {
      dispatch({ type: "SET_STUDY_OUTCOMES", payload: response.data})
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting outcomes...")
    }).finally(() => {
      dispatch(toggleFetchingOutcomes(false))
    })
  }
}

/**
 * @summary Creates a new or updates an existing Outcome depending on what method was chosen.
 * 
 * @param {FormData} outcome - Outcome FormData object to be sent to the backend.
 * @param {string} method - Request method (POST or PATCH).
 */
export const submitOutcome = (outcome, method) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation(`${ method === "POST" ? "Adding" : "Updating" } an outcome...`)

    axios({
      method: method,
      url: outcome.get("url") ? outcome.get("url") : `${ process.env.REACT_APP_URL }outcomes/`,
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` },
      data: outcome,
    }).then(response => {
      requestFunctions.successAnimation("Successfully added an outcome")
      dispatch(getOutcomes(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in adding and outcome...")
      console.log(error, error.response)
    })
  }
}

/**
 * @summary Deletes a selected outcome.
 * 
 * @param {string} url - URL of the outcome.
 */
export const deleteOutcome = (url) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation("Deleting an outcome...")

    axios.delete(url, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      dispatch(getOutcomes(selectedStudy.id))
      requestFunctions.successAnimation("Successfully deleted an outcome")
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting an outcome...")
    })
  }
}
/**
 * @summary Gets all inclusion exclusion criteria that are associated with a study if provided a study id.
 * 
 * @param {string=} studyId - ID of the selected study.
 */
export const getInclusionExclusionCriteria = (studyId=null) => {
  return dispatch => {
    dispatch(toggleFetchingInclusionExclusionCriteria(true))
    axios.get(`${process.env.REACT_APP_URL}inclusion-exclusion-criteria/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
      params: { study: studyId }
    }).then(response => {
      dispatch({ type: "SET_INCLUSION_EXCLUSION_CRITERIA", payload: response.data})
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was en error in getting inclusion/exclusion criteria")
      console.log(error.response)
    }).finally(() => {
      dispatch(toggleFetchingInclusionExclusionCriteria(false))
    })
  }
}

/**
 * @summary Creates a new or updates an existing Inclusion Exclusion Criteria depending on what method was chosen.
 * 
 * @param {FormData} criteria - Inclusion exclusion criteria FormData object to be sent to the backend.
 * @param {string} method - Request method (POST or PATCH).
 */
export const submitInclusionExclusionCriteria = (criteria, method) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation(`${ method === "POST" ? "Adding" : "Updating" } a criteria...`)

    axios({
      method: method,
      url: criteria.get("url") ? criteria.get("url") : `${ process.env.REACT_APP_URL }inclusion-exclusion-criteria/`,
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` },
      data: criteria,
    }).then(response => {
      requestFunctions.successAnimation("Successfully added a criteria")
      dispatch(getInclusionExclusionCriteria(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in adding a criteria...")
    })
  }
}

/**
 * @summary Deletes a selected inclusion exclusion criteria.
 * 
 * @param {string} url - URL of the outcome.
 */
export const deleteInclusionExclusionCriteria = (url) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation(`Deleting a criteria...`)

    axios.delete(url, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully deleted a criteria")
      dispatch(getInclusionExclusionCriteria(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting a criteria...")
    })
  }
}

/**
 * @summary Gets all stakeholder targets for a selected study.
 * 
 * @param {string=} studyId - ID of a study that the stakeholder targets, that are returned \
 * by the request, belong to.
 */
export const getStakeholderTargets = (studyId=null) => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    dispatch(toggleFetchingStakeholderTargets(true))

    // DECLARING ALL REQUESTS AS VARIABLES THAT WILL BE CALLED AT THE SAME TIME
    // BY THE axios.all() FUNCTION
    const htasRequest = axios.get(`${ process.env.REACT_APP_URL }htas/`, { params: { study: studyId, indication: selectedIndication.id }, headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
    const medicalProvidersRequest = axios.get(`${ process.env.REACT_APP_URL }medical-providers/`, { params: { study: studyId, indication: selectedIndication.id }, headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
    const otherRequest = axios.get(`${ process.env.REACT_APP_URL }other/`, { params: { study: studyId, indication: selectedIndication.id }, headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
    const patientOrganizationsRequest = axios.get(`${ process.env.REACT_APP_URL }patient-organizations/`, { params: { study: studyId, indication: selectedIndication.id }, headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
    const payersRequest = axios.get(`${ process.env.REACT_APP_URL }payers/`, { params: { study: studyId, indication: selectedIndication.id }, headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
    const regulatorsRequest = axios.get(`${ process.env.REACT_APP_URL }regulators/`, { params: { study: studyId, indication: selectedIndication.id }, headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
    
    axios.all([htasRequest, medicalProvidersRequest, otherRequest, patientOrganizationsRequest, payersRequest, regulatorsRequest])
    .then(axios.spread((htas, medicalProviders, other, patientOrganizations, payers, regulators) => {
      let stakeholderTargets = {
        htas: htas.data,
        medicalProviders: medicalProviders.data,
        other: other.data,
        patientOrganizations: patientOrganizations.data,
        payers: payers.data,
        regulators: regulators.data,
      }

      dispatch({ type: "SET_STAKEHOLDER_TARGETS", payload: stakeholderTargets})
    })).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting stakeholder targets...")
      console.log(error.response)
    }).finally(() => {
      dispatch(toggleFetchingStakeholderTargets(false))
    })
  }
}

/**
 * @summary Updates a Stakeholder Target.
 * 
 * @param {FormData} target - Stakeholder Target FormData object to be sent to the backend.
 */
export const updateStakeholderTarget = (target) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation("Updating a stakeholder target...")

    axios.patch(target.get("url"), target, {
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` },
    }).then(response => {
      requestFunctions.successAnimation("Successfully updated a stakeholder target")
      dispatch(getStakeholderTargets(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in updating a stakeholder target...")
    })
  }
}

/**
 * @summary Gets all Communication Targets associated with a selected Study (if a Study id is being passed).
 * 
 * @param {string=} studyId - ID of a selected Study
 * @param {string=} indicationId - ID of a selected indication
 */
export const getCommunicationTargets = (studyId=null, indicationId=null) => {
  return dispatch => {
    dispatch(toggleFetchingCommunicationTargets(true))

    axios.get(`${process.env.REACT_APP_URL}communication-targets/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
      params: { study: studyId, indication: indicationId }
    }).then(response => {
      dispatch({ type: "SET_COMMUNICATION_TARGETS", payload: response.data})
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting communication targets...")
    }).finally(() => {
      dispatch(toggleFetchingCommunicationTargets(false))
    })
  }
}

/**
 * @summary Creates a new Communication Target.
 * 
 * @param {FormData} communicationTarget - Communication Target FormData object to be sent to the backend.
 */
export const submitCommunicationTarget = (communicationTarget) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation("Adding a communication target")

    axios.post(`${process.env.REACT_APP_URL}communication-targets/`, communicationTarget, {
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` },
    }).then(response => {
      requestFunctions.successAnimation("Successfully added a communication target")
      dispatch(getCommunicationTargets(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in adding a communication target...")
    })
  }
}

/**
 * @summary Updates a Communication Target.
 * 
 * @param {FormData} target - Communication Target FormData object to be sent to the backend.
 */
export const updateCommunicationTarget = (communicationTarget) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation("Updating a Communication target...")

    axios.patch(communicationTarget.get("url"), communicationTarget, {
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` },
    }).then(response => {
      requestFunctions.successAnimation("Successfully updated a stakeholder target")
      dispatch(getCommunicationTargets(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in updating a stakeholder target...")
    })
  }
}

export const getStudyTypes = () => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    dispatch(toggleFetchingStudyTypes(true))

    axios.get(`${ process.env.REACT_APP_URL }study-types/`, {
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` },
      params: { indication: selectedIndication.id }
    }).then(response => {
      dispatch({ type: "SET_STUDY_TYPES", payload: response.data })
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in fetching study types...")
    }).finally(() => {
      dispatch(toggleFetchingStudyTypes(false))
    })
  }
}

/**
 * @summary Grabs all Key Documents for the selected Study.
 * 
 * @param {string} studyId - Selected Study ID.
 */
export const getKeyDocuments = (studyId) => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    // const { selectedStudy } = useState().manageStudies

    dispatch(toggleFetchigKeyDocuments(true))
    dispatch(toggleFetchigKeyDocumentTypes(true))

    const documentRequest = axios.get(`${ process.env.REACT_APP_URL }key-documents/`, { params: { study: studyId }, headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
    const documentTypeRequest = axios.get(`${ process.env.REACT_APP_URL }key-document-types/`, { params: { study: studyId, indication: selectedIndication.id }, headers: { "Authorization": `JWT ${localStorage.getItem("token")}` } })
    
    axios.all([documentRequest, documentTypeRequest])
    .then(axios.spread((documents, documentTypes) => {
      dispatch({ type: "SET_KEY_DOCUMENTS", payload: documents.data })
      dispatch({ type: "SET_KEY_DOCUMENT_TYPES", payload: documentTypes.data })
    })).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting key documents...")
    }).finally(() => {
      dispatch(toggleFetchigKeyDocuments(false))
      dispatch(toggleFetchigKeyDocumentTypes(false))
    })
  }
}

/**
 * @summary Creates or updates a document of instance Key Document or Results/Data.
 * 
 * @param {FormData} document - Document FormData object to be sent to the backend.
 * @param {string} method - Request method (POST or PATCH).
 */
export const submitKeyDocument = (document, method) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation(`${ method === "POST" ? "Adding" : "Updating" } a document...`)

    axios({
      method: method,
      url: document.get("url"), 
      data: document,
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      console.log(response.data)
      requestFunctions.successAnimation("Successfully added a document")
      dispatch(getStudy(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in adding a document...")
    })
  }
}

/**
 * @summary Deletes a selected Key Document.
 * 
 * @param {string} url - URL of the Key Document.
 */
 export const deleteKeyDocument = (url) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation("Deleting a document...")

    axios.delete(url, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully deleted a document")
      dispatch(getStudy(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting a document...")
    })
  }
}

/**
 * @summary Grabs all Results/Data for the selected Study.
 * 
 * @param {string} studyId - Selected Study ID.
 */
 export const getResultDocuments = (studyId) => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    dispatch(toggleFetchigResultDocuments(true))
    axios.get(`${process.env.REACT_APP_URL}result-documents/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
      params: { study: studyId, indication: selectedIndication.id }
    }).then(response => {
      dispatch({ type: "SET_RESULT_DOCUMENTS", payload: response.data })
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in getting result documents...")
    }).finally(() => {
      dispatch(toggleFetchigResultDocuments(false))
    })
  }
}

/**
 * @summary Creates or updates a document of instance Key Document or Results/Data.
 * 
 * @param {FormData} document - Document FormData object to be sent to the backend.
 * @param {string} method - Request method (POST or PATCH).
 */
 export const submitResultDocument = (document, method) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation(`${ method === "POST" ? "Adding" : "Updating" } a document...`)

    axios({
      method: method,
      url: document.get("url"), 
      data: document,
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully added a document")
      dispatch(getResultDocuments(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in adding a document...")
    })
  }
}

/**
 * @summary Deletes a selected Result Document.
 * 
 * @param {string} url - URL of the Result Document.
 */
 export const deleteResultDocument = (url) => {
  return (dispatch, useState) => {
    const { selectedStudy } = useState().manageStudies
    requestFunctions.loadingAnimation("Deleting a result...")

    axios.delete(url, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully deleted a result")
      dispatch(getStudy(selectedStudy.id))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting a result...")
    })
  }
}