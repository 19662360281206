import axios from 'axios'
import {requestFunctions} from "../functions/requestFunctions";

const defaultState = {
  sections: [],
  fetchingSections: false,
  figureTableSections: [],
  fetchingFigureTableSections: false,
  figureTableSubsections: [],
  fetchingFigureTableSubsections: false,
  trialCSR: {
    sections: [],
    subsections: []
  }
}

export default function manageSections(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHING_SECTIONS':
      return { ...state, fetchingSections: true }

    case 'FETCHING_FIGURE_TABLE_SECTIONS':
      return { ...state, fetchingFigureTableSections: true }

    case 'FETCHING_FIGURE_TABLE_SUBSECTIONS':
      return { ...state, fetchingFigureTableSubsections: true }

    case 'SET_SECTIONS':
    /// POSSIBLE PROBLEM BUT PROBABLY FIXED ON LINE 9
      // let newSections = action.payload.filter(section => section.id > 73).sort((a,b) => a.title.localeCompare(b.title))
      return { ...state, sections: action.payload, fetchingSections: false }

    case "SET_FIGURE_TABLE_SECTIONS":
      /// FILTERS THE SECTIONS BASED ON THE DOCUMENT THEY BELONG TO
      let trialCSRSections = action.payload.filter(section => section.csr === "TRIAL CSR")
      return {
        ...state,
        figureTableSections: action.payload,
        fetchingFigureTableSections: false,
        trialCSR: {
          ...state.trialCSR,
          sections: trialCSRSections
        }
      }

    case "SET_FIGURE_TABLE_SUBSECTIONS":
      let trialCSRSubsections = action.payload.filter(subsection => subsection.csr === "TRIAL CSR")
      return {
        ...state,
        figureTableSubsections: action.payload,
        fetchingFigureTableSubsections: false,
        trialCSR: {
          ...state.trialCSR,
          subsections: trialCSRSubsections
        }
      }
    case 'CLEAR_SECTIONS':
      return defaultState
    default:
		  return state
	}
}

const fetchingSections = () => {
  return {type: 'FETCHING_SECTIONS'}
}

const gotSections = (data) => {
  return {type: 'SET_SECTIONS', payload: data}
}

const gotFigureTableSections = (data) => {
  return {type: "SET_FIGURE_TABLE_SECTIONS", payload: data}
}

const gotFigureTableSubsections = (data) => {
  return {type: "SET_FIGURE_TABLE_SUBSECTIONS", payload: data}
}

export const getSections = (inputs) => {
  return (dispatch) => {
    dispatch(fetchingSections())
    axios({
      method: "GET",
      url: process.env.REACT_APP_URL+"gvdsections/",
      headers: {"Authorization": "JWT "+localStorage.getItem("token")}
    }).then(response => {
      dispatch(gotSections(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data...")
      dispatch(gotSections([]))
    })
  }
}

export const getFigureTableSections = () => {
  return (dispatch) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_URL + "figure-table-sections/",
      headers:{
        "Authorization": "JWT " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      }
    }).then(response => {
      dispatch(gotFigureTableSections(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data...")
      dispatch(gotFigureTableSections([]))
    })
  }
}

export const getFigureTableSubsections = () => {
  return (dispatch) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_URL + "figure-table-subsections/",
      headers: {
        "Authorization": "JWT " + localStorage.getItem("token"),
        "Content-Type": "application/json"
      }
    }).then(response => {
      dispatch(gotFigureTableSubsections(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data...")
      dispatch(gotFigureTableSubsections([]))
    })
  }
}

