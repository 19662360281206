import React from 'react'
import { useSelector } from 'react-redux'
import KVMAndEvidenceList from './KVMAndEvidenceList'
import Message from '../../../keyvaluemessages/components/Message'
import { sortingCallbacks } from '../../../../functions/sortingCallbacks'

export default function KVMsAndEvidenceStatements() {
  // REDUX
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)

  const sortedList = selectedStudy.key_value_messages.sort(sortingCallbacks.KVMSort)

  return (
    <div>
      <h4>KVMs & Evidence Statements</h4>
      <hr/>
      <KVMAndEvidenceList />
      <br/>
      <br/>
      { sortedList.length ? sortedList.map(kvm => {
        return (
          <div key={ kvm.id }>
            <Message
              source="study"
              className="message"
              message={kvm} />
          </div>
        )
      }) : <center>There are no KVMs or Evidence Statements for the selected study...</center> }
    </div> 
  )
}