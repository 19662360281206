import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { message, Popconfirm } from 'antd'
import {Col, Card, Row} from 'react-bootstrap'
import PopupPDFViewer from '../../../components/PopupPDFViewer'
import PopupVideoViewer from '../../../components/PopupVideoViewer'
import MediaDownloader from '../../../components/MediaDownloader'
import {deleteEducation} from '../../../reducers/manageEducationalResources'
import CreateUpdateEducation from "./CreateUpdateEducation";
import PropTypes from "prop-types";


export default function Education({ education = null, index=null }) {
  // REDUX
  const dispatch = useDispatch()
  const user = useSelector(state => state.manageUsers.user)

  // STATE
  const [delay, setDelay] = useState(index * 0.25)

  useEffect(() => {
    if (index % 3 === 0) {
      setDelay(delay + 0.5)
    }
  }, [])
  return (
    <Card className="education-card">
      <Card.Header className="education-card-header truncate" title={education.title}>
        {education.title}
      </Card.Header>
      <Card.Body className="education-card-body">
        <Card.Text className="card-description">
         {education.uploader_notes}
        </Card.Text>
      </Card.Body>
      <Card.Footer className="education-card-filetype">
        { education.filetype.toUpperCase() }
      </Card.Footer>
      <Card.Footer className="education-card-action-buttons">
        <Row>
          {education.filetype === 'mp4' || education.filetype === 'webm' || education.filetype === 'mkv' ?
            <PopupVideoViewer
              videoURL={ education.document }
              title={ education.title }
              forClick={<Col sm={3} md={3} lg={3} className="education-card-action-button">View</Col>}
            />
            :
            <PopupPDFViewer
              file={ education.document }
              title={ education.title }
              entry={ education }
              module="Key Resources"
              contentType="educationalresource"
              action="view"
              forClick={<Col sm={3} md={3} lg={3} className="education-card-action-button">View</Col>}
            />
          }
          <MediaDownloader
            file={ education.document }
            forClick={ <Col sm={3} md={3} lg={3} className="education-card-action-button">Download</Col> }
            module="Key Resources"
            filename={ education.filename }
            contentType="educationalresource"
            objectId={ education.id }
          />
          <CreateUpdateEducation
            education={ education }
            mode="update"
            forClick={<Col sm={3} md={3} lg={3} className="education-card-action-button" style={user.role !== "Global" ? {cursor: 'not-allowed'} : null}>Edit</Col>}
          />

          <Popconfirm
           title="Are you sure you want to delete this educational resource?"
           onConfirm={() => {
             if (!education.is_locked && user.is_superuser) {
               dispatch(deleteEducation(education))
             } else {
               message.error("This file is locked")
             }
           }}
           okText="Yes"
           cancelText="No"
          >
           <Col sm={3} md={3} lg={3} className="education-card-action-button">Delete</Col>
         </Popconfirm>
        </Row>
      </Card.Footer>
    </Card>
  )
}

Education.propTypes = {
  education: PropTypes.object.isRequired,
  index: PropTypes.number
}