import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Popconfirm } from 'antd'
import LoadingAnimation from '../../../../components/LoadingAnimation'
import { deleteResultDocument, getResultDocuments } from '../../../../reducers/manageStudies'
import AddUpdateResultDocument from './AddUpdateResultDocument'
import PopupPDFViewer from '../../../../components/PopupPDFViewer'
import MediaDownloader from "../../../../components/MediaDownloader";

export default function ResultDocuments() {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  const fetchingResultDocuments = useSelector(state => state.manageStudies.fetchingResultDocuments)
  const resultDocuments = useSelector(state => state.manageStudies.resultDocuments)

  useEffect(() => {
    dispatch(getResultDocuments(selectedStudy.id))
  }, [])

  let resultDocumentList = resultDocuments.map((resultDocument, index) => {
    return (
      <tr
        key={index}
        className="kvm-label"
      >
        <td className="kvm-message" style={{ textAlign: "center" }}>
          { resultDocument.title }
        </td>
        <td>
        </td>
        <td>
          <div className="document-action-btns">
            <PopupPDFViewer
              file={ resultDocument.document }
              forClick={<Button className="new-study-btn">View</Button> }
              title={ resultDocument.title }
              entry={ resultDocument }
              module="Study Center"
              contentType="resultdocument"
              action="view"
            />
          </div>
          <div className="document-action-btns">
            <MediaDownloader
              buttonClassName="new-study-btn"
              file={resultDocument.document}
              filename={resultDocument.filename}
              type="button"
              module="Study Center"
              contentType="resultdocument"
              objectId={resultDocument.id}
            />
          </div>
          <div className="document-action-btns">
            <AddUpdateResultDocument document={ resultDocument } mode="update" /><br/>
          </div>
          <div className="document-action-btns">
            <Popconfirm
              title="Are you sure to delete this document?"
              placement="left"
              onConfirm={() => dispatch(deleteResultDocument(resultDocument.url))}
              okText="Yes"
              cancelText="No"
            >
              <Button className="delete-study-btn">Delete</Button>
            </Popconfirm>
          </div>
        </td>
      </tr>
    )
  })

  if (!resultDocuments.length) {
    resultDocumentList = (
      <tr className="kvm-label">
        <td></td>
        <td style={{ maxWidth: '20vw', width: '100%', overflow: 'hidden' }}>
          There are no Documents for the selected study...
        </td>
        <td></td>
      </tr>
    )
  }

  return (
    <div>
      <div>
        <h4>Results/Data</h4>
        <hr/>
        <AddUpdateResultDocument mode="add" />
        <br/>
        <br/>
        <div id="key-document-list">
          <table className="kvmtable" style={{width: "100%"}}>
            <colgroup>
              <col className="kvm-column" />
              <col className="kvm-column" />
            </colgroup>
            <thead className="table-header new-evidence">
              <tr>
                <th>Title</th>
                <th></th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              { fetchingResultDocuments ? 
                <tr className="kvm-label">
                  <td colSpan={3} style={{ width: '100%', overflow: 'hidden', padding: "100px" }}>
                    <br/>
                    <LoadingAnimation />
                  </td>
                </tr>
                : resultDocumentList }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}