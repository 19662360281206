import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingAnimation from '../../../../components/LoadingAnimation'
import { getStakeholderTargets } from '../../../../reducers/manageStudies'
import UpdateStakeholderTarget from './UpdateStakeholderTarget'


export default function StakeholderTargets() {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  const fetchingStakeholderTargets = useSelector(state => state.manageStudies.fetchingStakeholderTargets)
  
  useEffect(() => {
    dispatch(getStakeholderTargets())
  }, [])

  return (
    <div>
      { fetchingStakeholderTargets ? 
        <div style={{ marginTop: "300px" }}>
          <LoadingAnimation />
        </div>
        :
        <div>
          <h4>Stakeholder Targets</h4>
          <hr/>
          <UpdateStakeholderTarget study={ selectedStudy } />
        </div>
      }
    </div>
  )
}