import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingAnimation from '../../../../components/LoadingAnimation'
import { getCommunicationTargets } from '../../../../reducers/manageStudies'
import AddCommunicationTarget from './AddCommunicationTarget'
import CommunicationTarget from './CommunicationTarget'
import { Col, Row } from 'react-bootstrap'
import { Input } from "antd";


export default function CommunicationTargets() {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  const communicationTargets = useSelector(state => state.manageStudies.communicationTargets)
  const fetchingCommunicationTargets = useSelector(state => state.manageStudies.fetchingCommunicationTargets)

  // STATE
  const [selectedCommunicationTarget, setSelectedCommunicationTarget] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    dispatch(getCommunicationTargets(selectedStudy.id))
  }, [])

  useEffect(() => {
    if (!!selectedCommunicationTarget) {
      setSelectedCommunicationTarget(communicationTargets.find(target => target.id === selectedCommunicationTarget.id))
    }
  }, [communicationTargets])


  const formatDate = (target) => {
    const months = [
      "January", "February", "March",
      "April", "May", "June",
      "July", "August", "September",
      "October", "November", "December",
    ]
    let dateString = "N/A"
    if (target.key_dates.length) {
      let date = target.key_dates[0].date.split("T")[0].split("-")
      dateString = `${months[date[1] - 1]} ${date[2]}, ${date[0]}`
      if (target.key_dates[0].end_date) {
        let endDate = target.key_dates[0].end_date.split("T")[0].split("-")
        dateString += ` - ${months[endDate[1] - 1]} ${endDate[2]}, ${endDate[0]}`
      }
    }
    return dateString
  }

  return (
    <div id="ComunicationTargets">
      { fetchingCommunicationTargets ?
        <div style={{ marginTop: "300px" }}>
          <LoadingAnimation />
        </div>
        :
          <div>
            <h4>
              Communication Targets
            </h4>
            <hr/>
            <AddCommunicationTarget />
            <br/>
            <br/>
            { communicationTargets.length ?
              <Row>
                <Col md={4} lg={4} xl={4} style={{borderRight: "1px solid rgba(0,0,0,.1)"}}>
                  <Input.Search
                    placeholder="Search"
                    className="study-search-input"
                    value={ searchTerm }
                    onChange={(e) => setSearchTerm(e.target.value)}
                    allowClear
                    // onSearch={ handleSearch }
                  />
                  { communicationTargets.filter(target => target.venue.toLowerCase().includes(searchTerm.toLowerCase())).map(target => {
                    return (
                      <div
                        className={
                          `communication-target-item
                           ${ selectedCommunicationTarget && selectedCommunicationTarget.id === target.id ? "active" : null}
                          `
                        }
                        key={ target.id }
                        onClick={ () => setSelectedCommunicationTarget(target) }
                      >
                        { target.venue }
                        <div className="communication-target-item-date">{ formatDate(target) }</div>
                      </div>
                    )
                  }) }
                </Col>
                <Col md={8} lg={8} xl={8}>
                  { !!selectedCommunicationTarget ?
                    <CommunicationTarget
                      key={ selectedCommunicationTarget.id }
                      communicationTarget={ selectedCommunicationTarget }
                    />
                  : "Please select a communication target." }
                </Col>
              </Row>
              : "There are no communication targets for this study..." }
          </div>
      }
    </div>
  )
}