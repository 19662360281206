import React from 'react'


export default function LoadingAnimation() {
  return (
    <div className="loading-animation">
      <div className="loader-wrapper"><span className="loader"></span></div>
    </div> 
  )
}
