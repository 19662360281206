import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Popconfirm } from 'antd'
import { Alert } from 'react-bootstrap'
import LoadingAnimation from '../../../../components/LoadingAnimation'
import { deleteKeyDocument, getKeyDocuments } from '../../../../reducers/manageStudies'
import AddUpdateKeyDocument from './AddUpdateKeyDocument'
import PopupPDFViewer from '../../../../components/PopupPDFViewer'

export default function KeyDocuments() {
  // REDUX
  const dispatch = useDispatch()
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)
  const fetchingKeyDocuments = useSelector(state => state.manageStudies.fetchingKeyDocuments)
  const keyDocuments = useSelector(state => state.manageStudies.keyDocuments)


  useEffect(() => {
    dispatch(getKeyDocuments(selectedStudy.id))
  }, [])

  let keyDocumentList = keyDocuments.map((keyDocument, index) => {
    return (
      <tr
        key={index}
        className="kvm-label"
      >
        <td className="kvm-message" style={{ textAlign: "center" }}>
          { keyDocument.title }
        </td>
        <td>
          { keyDocument.document_types
            .map(docType => docType.name)
            .join(", ") || "N/A" }
        </td>
        <td>
          <div className="document-action-btns">
            <PopupPDFViewer
              file={ keyDocument.document }
              forClick={<Button className="new-study-btn">View</Button> }
              title={ keyDocument.title }
              entry={ keyDocument }
              module="Study Center"
              contentType="keydocument"
              action="view"
            />
          </div>
          <div className="document-action-btns">
            <AddUpdateKeyDocument document={ keyDocument } mode="update" /><br/>
          </div>
          <div className="document-action-btns">
            <Popconfirm
              title="Are you sure to delete this document?"
              placement="left"
              onConfirm={() => dispatch(deleteKeyDocument(keyDocument.url))}
              okText="Yes"
              cancelText="No"
            >
              <Button className="delete-study-btn">Delete</Button>
            </Popconfirm>
          </div>
        </td>
      </tr>
    )
  })

  if (!keyDocuments.length) {
    keyDocumentList = (
      <tr className="kvm-label">
        <td></td>
        <td style={{ maxWidth: '20vw', width: '100%', overflow: 'hidden' }}>
          There are no Documents for the selected study...
        </td>
        <td></td>
      </tr>
    )
  }

  return (
    <div>
      {/* { fetchingKeyDocuments ? <LoadingAnimation /> :  */}
      <div>
        <h4>Key Documents</h4>
        <hr/>
        <AddUpdateKeyDocument mode="add" />
        <br/>
        <br/>
        { selectedStudy.has_protocol_document && selectedStudy.has_irbs_document && selectedStudy.has_synopsis_document ? null :
          <Fragment>
            { selectedStudy.has_protocol_document ? null : <Alert style={{ marginBottom: ".5rem" }} variant="danger">Please upload Protocol</Alert> }
            { selectedStudy.has_irbs_document ? null : <Alert style={{ marginBottom: ".5rem" }} variant="danger">Please upload IRBs</Alert> }
            { selectedStudy.has_synopsis_document ? null : <Alert style={{ marginBottom: ".5rem" }} variant="danger">Please upload Synopsis</Alert> }
            {/*<Alert variant="danger">*/}
            {/*  Please upload missing document types*/}
            {/*  <ul>*/}
            {/*    { selectedStudy.has_protocol_document ? null : <li>Protocol</li> }*/}
            {/*    { selectedStudy.has_irbs_document ? null : <li>IRBs</li> }*/}
            {/*    { selectedStudy.has_synopsis_document ? null : <li>Synopsis</li> }*/}
            {/*  </ul>*/}
            {/*</Alert>*/}
          </Fragment>
        }
        <div id="key-document-list">
          <table className="kvmtable" style={{width: "100%"}}>
            <colgroup>
              <col className="kvm-column" />
              <col className="kvm-column" />
            </colgroup>
            <thead className="table-header new-evidence">
              <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              { fetchingKeyDocuments ? 
                <tr className="kvm-label">
                  <td colSpan={3} style={{ width: '100%', overflow: 'hidden', padding: "100px" }}>
                    <br/>
                    <LoadingAnimation />
                  </td>
                </tr>
                : keyDocumentList }
            </tbody>
          </table>
        </div>
      </div>
      {/* } */}
    </div>
  )
}