import axios from 'axios'
import { clearEverything } from './manageUsers'

const defaultState = {
  fetchingTimelineStudyList: true,
  timelineStudyList: [],
  selectedStudyList: [],
  timelineFilters: ["Study Duration", "Key Dates", "Communication Targets"],
  selectedTimelineFilters: [],
  searchTerm: ""
}


export default function manageStudyTimelines(state = defaultState, action) {
  switch(action.type) {
    case "SET_TIMELINE_STUDY_LIST":
      return { ...state, timelineStudyList: action.payload } 
    case "SET_SELECTED_STUDY_LIST":
      return { ...state, selectedStudyList: action.payload } 
    case "TOGGLE_FETCHING_TIMELINE_STUDY_LIST":
      return { ...state, fetchingTimelineStudyList: action.payload }
    case "SET_SELECTED_TIMELINE_FILTERS":
      return { ...state, selectedTimelineFilters: action.payload }
    case "SET_TIMELINE_STUDY_SEARCH_TERM":
      return { ...state, searchTerm: action.payload }
    case "CLEAR_STUDY_TIMELINES":
      return defaultState
    default:
      return state
  }
}


export const toggleFetchingTimelineStudyList = (fetching) => {
  return { type: "TOGGLE_FETCHING_TIMELINE_STUDY_LIST", payload: fetching }
}

export const setSelectedStudyList = (studyList) => {
  return { type: "SET_SELECTED_STUDY_LIST", payload: studyList }
}

export const changeSearchTerm = (searchTerm) => {
  return { type: "SET_TIMELINE_STUDY_SEARCH_TERM", payload: searchTerm }
}

export const setSelectedTimelineFilters = (filters) => {
  return { type: "SET_SELECTED_TIMELINE_FILTERS", payload: filters }
}

export const getTimelineStudyList = () => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    const { searchTerm } = useState().manageStudyTimelines
    dispatch(toggleFetchingTimelineStudyList(true))
    axios.get(`${process.env.REACT_APP_URL}studies/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` },
      params: { indication: selectedIndication.id, search: searchTerm }
    }).then(response => {
      dispatch({ type: "SET_TIMELINE_STUDY_LIST", payload: response.data })
    }).catch(error => {
      console.log(error,error.response)
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          dispatch(clearEverything())
          window.location = '/login'
        }
      }
    }).finally(() => {
      dispatch(toggleFetchingTimelineStudyList(false))
    })
  }
}