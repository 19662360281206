import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button, Input, Popconfirm, Select } from "antd";
import { Col, Row } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import LoadingAnimation from "../../../components/LoadingAnimation";
import Category from "./Category";
import CreateUpdateCategory from "./CreateUpdateCategory";
import Swal from "sweetalert2";
import { requestFunctions } from "../../../functions/requestFunctions";
import { selectCategories } from "../../../reducers/manageCategories";


export default function CategoryType({ categoryType, categoryTypes, getCategoryTypes, setSelectedCategoryType }) {
  // REDUX
  const dispatch = useDispatch()
  const indicationsFromReducer = useSelector(state => state.manageIndications.indications)

  // STATE
  const [categories, setCategories] = useState([])
  const [fetchingCategories, toggleFetchingCategories] = useState(true)
  const [isEditable, setIsEditable] = useState(false)
  const [name, setName] = useState(categoryType.name)
  const [type, setType] = useState(categoryType.type)
  const [indication, setIndication] = useState(categoryType.indication)
  const [searchTerm, setSearchTerm] = useState("")
  const [isCategoryOrderEditable, setIsCategoryOrderEditable] = useState(false)

  const getCategories = (term = searchTerm) => {
    toggleFetchingCategories(true)
    setIsCategoryOrderEditable(false)
    setSearchTerm(term)
    axios.get(`${ process.env.REACT_APP_URL }categories/`, {
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` },
      params: { type: categoryType.id, name__icontains: term }
    }).then(response => {
      setCategories(response.data)
    }).catch(error => {
      requestFunctions.handleError(error, dispatch)
    }).finally(() => {
      toggleFetchingCategories(false)
    })
  }

  useEffect(() => {
    if (categoryType) {
      setCategories([])
      setSearchTerm("")
      setIsEditable(false)
      setIsCategoryOrderEditable(false)
      setName(categoryType.name)
      setType(categoryType.type)
      setIndication(categoryType.indication)
      getCategories("")
    }
  }, [categoryType])

  const cancelCategoryOrdering = () => {
    setIsCategoryOrderEditable(false)
    getCategories()
  }

  const handleCancel = () => {
    setIsEditable(false)
    setName(categoryType.name)
    setType(categoryType.type)
    setIndication(categoryType.indication)
  }

  const updateCategoryType = () => {
    requestFunctions.loadingAnimation("Editing filter type...")
    axios.patch(categoryType.url, { name, type, indication }, {
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` }
    }).then(response => {
      requestFunctions.successAnimation("Successfully edited the filter type.")
      getCategoryTypes()
      setIsEditable(false)
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in saving the filter type.")
    })
  }

  const handleSave = () => {
    if (indication !== categoryType.indication) {
      Swal.fire({
        title: "Warning",
        text: 'Switching the indication of the filter type will also move all existing filters to the selected indication.',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Save',
        confirmButtonColor: "var(--brand-color-2)",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          updateCategoryType()
        }
      })
    } else {
      updateCategoryType()
    }
  }

  const handleDelete = () => {
    axios.delete(categoryType.url, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token") }`}
    }).then(response => {
      requestFunctions.successAnimation("Successfully deleted a filter type.")
      setSelectedCategoryType(null)
      dispatch(selectCategories({}))
      getCategoryTypes()
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in deleting the filter type.")
    })
  }

  const handleSaveOrder = () => {
    // console.log(categories)
    requestFunctions.loadingAnimation("Updating the filter order...")
    const updatedOrder = categories.map((category, index) => {
      return {...category, order: index + 1}
    })
    axios.post(`${ process.env.REACT_APP_URL }categories/edit_category_order/`, updatedOrder, {
      headers: { "Authorization": `JWT ${ localStorage.getItem("token") }` }
    }).then(response => {
      console.log(response)
      requestFunctions.successAnimation("Successfully updated the filter order.")
      setIsCategoryOrderEditable(false)
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error updating the filter order...")
    })
    console.log(updatedOrder)
  }

  const orderAlphabetically = () => {
    const orderedCategories = [...categories].sort((a, b) => a.name.localeCompare(b.name))
    setCategories(orderedCategories)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    if (result.type === "category") {
      const items = reorder(categories, sourceIndex, destIndex)
      setCategories(items)
    }
  }


  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        { !isEditable ?
          <Row>
            <Col sm={ 6 } md={ 6 } lg={ 6 }>
            <Button
              className="custom-btn"
              style={{ width: "100%" }}
              onClick={() => setIsEditable(true)}
            >
              Edit
            </Button>
            </Col>
            <Col sm={ 6 } md={ 6 } lg={ 6 }>
              <Popconfirm
                title="Are you sure to delete this filter type?"
                placement="left"
                onConfirm={ handleDelete }
                okText="Yes"
                cancelText="No"
              >
                <Button className="custom-btn delete-btn" style={{ width: "100%" }}>
                  Delete
                </Button>
              </Popconfirm>
            </Col>
          </Row>
          :
          <Row>
            <Col sm={ 6 } md={ 6 } lg={ 6 }>
              <Button
                className="custom-btn"
                onClick={ handleCancel }
                style={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Col>
            <Col sm={ 6 } md={ 6 } lg={ 6 }>
              <Button
                className="custom-btn"
                onClick={ handleSave }
                style={{ width: "100%" }}
              >
                Save
              </Button>
            </Col>
          </Row>
        }
      </div>
      <h6>Name</h6>
      <Input value={ name } onChange={ (e) => setName(e.target.value) } disabled={ !isEditable }/>
      {/*<h6 style={{ marginTop: "10px" }}>Type</h6>*/}
      {/*<Select style={{ width: "100%" }} value={ type } onChange={ (value) => setType(value) } disabled={ !isEditable }>*/}
      {/*  <Select.Option value="CHECKBOX">Checkbox</Select.Option>*/}
      {/*  <Select.Option value="RADIO">Radio</Select.Option>*/}
      {/*</Select>*/}
      <br/>
      <h6 style={{ marginTop: "10px" }}>Indications</h6>
      <Select
        style={ { width: "100%" } }
        value={ indication }
        disabled={ !isEditable }
        placeholder="Please select the indications..."
        onChange={ setIndication }
        filterOption={(inputValue, option) => {
          return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }}
      >
        {
          indicationsFromReducer.map(indicationFromReducer => {
            return (
              <Select.Option key={ indicationFromReducer.id } value={ indicationFromReducer.url }>{ indicationFromReducer.name }</Select.Option>
            )
          })
        }
      </Select>
      <h6 style={{ marginTop: "10px" }}>Filters</h6>
      <Input.Search
        placeholder="Search"
        className="study-search-input"
        allowClear
        onSearch={ getCategories }
      />
      { fetchingCategories ?
        <div style={{marginTop: "400px"}}>
          <LoadingAnimation/>
        </div>
        :
        <div style={{ marginTop: "5px" }}>
          { !searchTerm ?
            <div>
              { !isCategoryOrderEditable ?
                <Row>
                  { categories.length ?
                  <Col sm={ 6 } md={ 6 } lg={ 6 }>
                    <Button
                      className="custom-btn"
                      style={ { width: "100%" } }
                      onClick={() => setIsCategoryOrderEditable(true)}
                    >
                      Edit Order
                    </Button>
                  </Col> : null }
                  <Col sm={ 6 } md={ 6 } lg={ 6 }>
                    <CreateUpdateCategory
                      forClick={
                        <Button
                          className="custom-btn"
                          style={ { width: "100%" } }
                          onClick={() => setIsCategoryOrderEditable(true)}
                        >
                          Add Filter
                        </Button>
                      }
                      getCategories={ getCategories }
                      categoryType={ categoryType }
                      categoryTypes={ categoryTypes }
                    />
                  </Col>
                </Row>
                :
                <React.Fragment>
                  <Row>
                    <Col sm={ 6 } md={ 6 } lg={ 6 }>
                      <Button
                        className="custom-btn"
                        style={ { width: "100%" } }
                        onClick={ cancelCategoryOrdering }
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col sm={ 6 } md={ 6 } lg={ 6 }>
                      <Button
                        className="custom-btn"
                        style={ { width: "100%" } }
                        onClick={ handleSaveOrder }
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={ 12 } md={ 12 } lg={ 12 }>
                      <Button
                        className="custom-btn"
                        style={ { width: "100%", marginTop: "5px" } }
                        onClick={ orderAlphabetically }
                      >
                        Order Alphabetically
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              }
            </div>
            : null
          }
          { categories.length ?
          <DragDropContext onDragEnd={ onDragEnd }>
            <Droppable droppableId="category" type="category" direction="vertical">
              {(provided) => (
                <div { ...provided.droppableProps } ref={ provided.innerRef }>
                  {categories.map((category, index) => {
                    return (
                      <Draggable key={ category.id } draggableId={`${ category.id }`} index={ index } isDragDisabled={ !isCategoryOrderEditable }>
                        {(provided, snapshot) => (
                          <Category
                            innerRef={ provided.innerRef }
                            provided={ provided }
                            category={ category }
                            snapshot={ snapshot }
                            categoryTypes={ categoryTypes }
                            getCategories={ getCategories }
                            index={ index }
                            isCategoryOrderEditable={ isCategoryOrderEditable }
                          />
                        )}
                      </Draggable>
                    )}
                  )}
                  { provided.placeholder }
                </div>
              )}
            </Droppable>
          </DragDropContext>
        :
        <div style={{ marginTop: "5px" }}>There are no available filters...</div>
        }
      </div> }
    </div>
  )
}