import React from 'react'
import { Col, Row } from "react-bootstrap";
import { Icon } from "antd";


export default function CategoryTypeListItem({ categoryType, selectedCategoryType, setSelectedCategoryType, isCategoryTypeOrderEditable, snapshot, ...props }) {

  const getItemStyle = (isDragging, draggableStyle) => ({
    border: "1px solid",
    borderColor: isDragging ? "var(--brand-color-2)" : "var(--brand-color-1)",
    marginLeft: "0",
    marginRight: "0",
    margin: "5px 0",
    padding: "5px 10px",
    cursor: isCategoryTypeOrderEditable ? "grab" : "pointer",
    borderRadius: "10px",
    backgroundColor: "white",
    transition: ".25s",
    ...draggableStyle
  })


  return (
    <Row
      ref={ props.innerRef }
      { ...props.provided.draggableProps }
      { ...props.provided.dragHandleProps }
      style={getItemStyle(
        snapshot.isDragging,
        props.provided.draggableProps.style
      )}
      className={`list-item ${ selectedCategoryType && selectedCategoryType.id === categoryType.id ? "active" : "" }`}
      onClick={ isCategoryTypeOrderEditable ? null : () => setSelectedCategoryType(categoryType) }
    >
      <Col xs={ 7 } md={ 7 } lg={ 7 } style={{ padding: "0" }}>
        {categoryType.name}
      </Col>
      <Col style={{ padding: "0" }}>
        <div style={{ float: "right" }}>
          { !isCategoryTypeOrderEditable ?
            null
            :
            <Icon type="menu" style={{ verticalAlign: "0.025em" }} />
          }
        </div>
      </Col>
      { props.provided.placeholder }
    </Row>
  )
}