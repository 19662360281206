/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Row, Col, Button, Menu, Dropdown, Popconfirm} from 'antd'
import { DownOutlined } from '@ant-design/icons';
import PopupPDFViewer from '../../../components/PopupPDFViewer';
import KVMEvidence from './KVMEvidence'
import MessageEvidenceStatements from './MessageEvidenceStatements';
import CreateUpdateKVM from "./CreateUpdateKVM";
import DeleteKVMModal from "./DeleteKVMModal";
import {deleteKVM} from "../../../reducers/manageKVM";


export default function Message({ message, source, index, ...props }) {
  // REDUX
  const dispatch = useDispatch()
  const displayAllKVMs = useSelector(state => state.manageKVM.displayAllKVMs)
  const isOrderEditable = useSelector(state => state.manageKVM.isOrderEditable)

  // STATE
  const [showDeleteModal, toggleShowDeleteModal] = useState(false)

  const handleDeleteKVM = () => {
    toggleShowDeleteModal(false)
    dispatch(deleteKVM(message))
  }

  const handleDeleteKVMButton = () => {
    if (message.evidence_statements.length && !showDeleteModal) {
      toggleShowDeleteModal(true)
    } else {
      handleDeleteKVM()
    }
  }


  const numberClass = String(message.number).length > 1 ? "two-digit" : "one-digit"

  const menu = (
    <Menu>
      {/* <Menu.Item key="0">
        <a onClick={(e) => e.preventDefault()}>View Supporting Studies</a>
      </Menu.Item> */}
      { message.supporting_evidence.length || (message.is_parent && message.parent_evidence_list.length) ?
        <Menu.Item key="1">
          <KVMEvidence
            entry={ message }
            relevantEvidence={ message.is_parent && message.parent_evidence_list.length ? message.parent_evidence_list : message.supporting_evidence }
            buttonText="View Associated Evidence"
            buttonClassName="kvm-view-evidence-btn"
            module="Key Value Messages"
            contentType="keyvaluemessage"
            action="view associated evidence"
          />
        </Menu.Item>
        :
        null
      }
      { message.document ?
        <Menu.Item key="3">
          <PopupPDFViewer
            page={0}
            file={message.document}
            title="Summary Slides"
            entry={message}
            module="Key Value Messages"
            contentType="keyvaluemessage"
            action="view summary slides"
            forClick={<a onClick={(e) => e.preventDefault()}>View Summary Slides</a>}
          />
        </Menu.Item>
        :
        null
      }
      { !!message.is_parent ?
        <Menu.Item key="4">
          <CreateUpdateKVM kvmParent={ message.url } forClick={<a>Add Evidence Statement</a>} />
        </Menu.Item> : null }
      { !!message.is_parent ?
        <Menu.Item key="5">
          <CreateUpdateKVM kvm={ message } mode="update" forClick={<a>Edit Key Value Message</a>} />
        </Menu.Item>
        : null }
      { !message.is_parent ?
        <Menu.Item key="5">
          <CreateUpdateKVM kvm={ message } kvmParent={ message.parent } mode="update" forClick={<a>Edit Evidence Statement</a>} />
        </Menu.Item> : null
      }
      <Menu.Item>
        <Popconfirm
          title="Are you sure to delete this key value message?"
          placement="left"
          onConfirm={ handleDeleteKVMButton }
          okText="Yes"
          cancelText="No"
        >
          Delete Key Value Message
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )

  let providedProps = null
  if (source !== "study") {
    providedProps = { ...props.provided.draggableProps, ...props.provided.dragHandleProps }
  }

  return (
    <div
      className={`message ${isOrderEditable ? "editable" : ""}`}
      ref={props.innerRef}
      {...providedProps}
    >
      { source !== "study" ?
        <DeleteKVMModal
          message={ message }
          show={ showDeleteModal }
          toggleShow={ toggleShowDeleteModal }
          handleDeleteKVM={ handleDeleteKVM }
      /> : null}
      <Row>
        <Col xs={9} sm={4} md={ displayAllKVMs ? 3 : 3} lg={ displayAllKVMs ? 3 : 3 }>
          <div className="number-container">
            <span className={"number " + numberClass}>
              { !message.is_parent ? `${ message.parent_number }.${ message.number }`
                :
                message.number
              }
            </span>
          </div>
        </Col>
        { displayAllKVMs ? <Col xs={1} sm={1} md={1} lg={1}></Col> : null}
        <Col xs={9} sm={6} md={0} lg={0}>
          { !isOrderEditable && source !== "study" ?
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <Button className="kvm-show-btn ant-btn-background">
              Show More <DownOutlined />
            </Button>
          </Dropdown> : null }
        </Col>
        <Col xs={24} sm={24} md={ displayAllKVMs ? 15 : 16 } lg={ displayAllKVMs ? 15 : 16 }>
          <p className="text">
            {message.message}
          </p>
        </Col>
          {!isOrderEditable  && source !== "study" ?
            <React.Fragment>
              <Col xs={0} sm={0} md={1} lg={1}></Col>
              <Col xs={0} sm={0} md={5} lg={5}>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <Button className="kvm-show-btn ant-btn-background">
                    Show More <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
            </React.Fragment>
            :
            null
          }

      </Row>
      { message.is_parent && !displayAllKVMs && source !== "study" ?
        <MessageEvidenceStatements message={message} />
        : null
      }
    </div>
  )
}