import React, { useState } from 'react'
import { Button, Input } from 'antd'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { updateStudy } from '../../../reducers/manageStudies'


export default function RenameStudy({ study }) {
  // REDUX
  const dispatch = useDispatch()

  // STATE
  const [showModal, toggleModal] = useState(false)
  const [studyName, setStudyName] = useState(study.name)

  const handleCancel = () => {
    toggleModal(false)
    setStudyName(study.name)
  }

  const handleSubmit = () => {
    let newStudy = new FormData()
    newStudy.append("url", study.url)
    newStudy.append("id", study.id)
    newStudy.append("name", studyName)

    dispatch(updateStudy(newStudy))
    toggleModal(false)
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Button
        className="new-study-btn"
        onClick={ () => toggleModal(true) }
      >
        Rename
      </Button>
      <Modal show={ showModal } onHide={ handleCancel } size="md">
        <Modal.Header closeButton>
          <Modal.Title>Rename Study - { study.name }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div><strong>Name</strong></div>
          <Input value={ studyName } onChange={ (e) => setStudyName(e.target.value) } />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={ handleCancel }
          >
            Cancel
          </Button>
          <Button
            onClick={ handleSubmit }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}