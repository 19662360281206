import axios from "axios"
import { submitClick } from './manageActivity'
import { requestFunctions } from "../functions/requestFunctions";
import { getListOfEvidence } from "./manageFacts";
import { getListOfKVMs } from "./manageKVM";

let defaultState = {
  limit: 10,
  selectedEvidence: null,
  evidence: [],
  allEvidence: [],
  // evidenceCategories: [],
  // selectedEvidenceCategories: [],
  sponsorships: ["Rhythm", "Non-Rhythm"],
  // selectedSponsorships: ["Rhythm", "Non-Rhythm"],
  selectedSponsorships: [],
  types: ["Poster", "Abstract", "Article", "Manuscript", "Clinical Guidelines", "Oral Presentations", "Prescribing Information", "Clinical Trial", "Other"],
  // selectedTypes: ["Poster", "Abstract", "Article", "Manuscript", "Clinical Guidelines", "Oral Presentations", "Prescribing Information", "Clinical Trial", "Other"],
  selectedTypes: [],
  statuses: ["Published", "Submitted"],
  // selectedStatuses: ["Published", "Submitted"],
  selectedStatuses: [],
  fetchingEvidence: false,
  fetchingEvidenceCategories: false,
  totalEvidenceCount: 0,
  currentPage: 1,
  searchTerm: "",
  deletingEvidence: false,
}

export default function SupportingEvidence(state = defaultState, action) {
  switch (action.type) {
    case "SHOW_EVIDENCE":
      return { ...state, show: action.payload }
    case "TOGGLE_FETCHING_EVIDENCE":
      return { ...state, fetchingEvidence: action.payload }
    case "SET_EVIDENCE":
      return { ...state, evidence: action.payload, fetchingEvidence: false }
    case "SET_ALL_EVIDENCE":
      return { ...state, allEvidence: action.payload, fetchingEvidence: false }
    case "SET_TOTAL_EVIDENCE_COUNT":
      return { ...state, totalEvidenceCount: action.payload }
    case "SET_EVIDENCE_LIMIT":
      return { ...state, limit: action.payload, currentPage: 1 }
    case "TOGGLE_DELETING_EVIDENCE":
      return { ...state, deletingEvidence: action.payload }
    case "CHANGE_EVIDENCE_SEARCH_TERM":
      return { ...state, searchTerm: action.payload, currentPage: 1 }
    case "CHANGE_SELECTED_SPONSORSHIPS":
      return { ...state, selectedSponsorships: action.payload, currentPage: 1 }
    case "CHANGE_SELECTED_TYPES":
      return { ...state, selectedTypes: action.payload, currentPage: 1 }
    case "CHANGE_SELECTED_STATUSES":
      return { ...state, selectedStatuses: action.payload, currentPage: 1 }
    case "SET_SELECTED_EVIDENCE":
      return { ...state, selectedEvidence: action.payload }
    case 'CHANGE_EVIDENCE_PAGE':
      return { ...state, currentPage: action.payload }
    case "UPDATED_EVIDENCE":
      const index = state.evidence.findIndex(evi=>{return evi.id === action.payload.id})
      return {
        ...state,
        selectedEvidence: action.payload,
        evidence: [...state.evidence.slice(0, index), action.payload, ...state.evidence.slice(index + 1)]
      }
    case "SUBMITTED_EVIDENCE":
      return {
        ...state,
        selectedEvidence: action.payload,
        evidence: [action.payload, ...state.evidence]
      }
    case "CLEAR_SUPPORTING_EVIDENCE":
      return defaultState
    default:
		  return state
	}
}

const fetchingEvidence = (fetching) => {
  return { type: "TOGGLE_FETCHING_EVIDENCE", payload: fetching }
}

export const editedEvidence = (data) => {
  return { type: "UPDATED_EVIDENCE", payload: data }
}

export const showEvidence = (show) => {
  return { type: "SHOW_EVIDENCE", payload: show }
}

const gotEvidence = data => {
  return { type: "SET_EVIDENCE", payload: data }
}

const gotAllEvidence = data => {
  return { type: "SET_ALL_EVIDENCE", payload: data }
}

const submittedEvidence = data => {
  return { type: "SUBMITTED_EVIDENCE", payload: data }
}

export const setSelectedEvidence = (evidence) => {
  return { type: "SET_SELECTED_EVIDENCE", payload: evidence }
}

export const selectEvidenceCategories = (categories) => {
  return { type: "SELECT_EVIDENCE_CATEGORIES", payload: categories }
}

export const setEvidenceLimit = (limit) => {
  return { type: "SET_EVIDENCE_LIMIT", payload: limit }
}

export const changePage = (page) => {
  return { type: "CHANGE_EVIDENCE_PAGE", payload: page }
}

export const changeSearchTerm = (term) => {
  return { type: "CHANGE_EVIDENCE_SEARCH_TERM", payload: term }
}

export const changeSelectedSponsorships = (sponsorships) => {
  return { type: "CHANGE_SELECTED_SPONSORSHIPS", payload: sponsorships }
}

export const changeSelectedTypes = (types) => {
  return { type: "CHANGE_SELECTED_TYPES", payload: types }
}

export const changeSelectedStatuses = (statuses) => {
  return { type: "CHANGE_SELECTED_STATUSES", payload: statuses }
}

/**
 * @summary Edits an evidence.
 * @param {FormData} evidence - A Form Data object that contains the evidence with updated values.
 */
export const editEvidence = (evidence) => {
  return (dispatch) => {
    requestFunctions.loadingAnimation("Editing evidence, It might take a few moments if the file is large...")
    axios.patch(evidence.get("url"), evidence, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `JWT ${localStorage.getItem("token")}`
      }
    }).then(response => {
      requestFunctions.successAnimation("Successfully updated the evidence")
      dispatch(submitClick({content_type: "supportingevidence", module: "Evidence Library", object_id: response.data.id, action: "modify" }))
      dispatch(editedEvidence(response.data))
      dispatch(getEvidence())
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in updating the evidence")
    })
  }
}

/**
 * @summary Creates an evidence.
 * @param {FormData} evidence - A Form Data object that contains the evidence data.
 * @param {string=} mode - A Form Data object that contains the evidence data.
 */
export const submitEvidence = (evidence, mode="create") => {
  return (dispatch) => {
    requestFunctions.loadingAnimation("Submitting evidence, It might take a few moments if the file is large...")

    axios.post(`${process.env.REACT_APP_URL}supporting-evidences/`, evidence, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `JWT ${localStorage.getItem("token")}`
      }
    }).then(response => {
      console.log(response)
      dispatch(submitClick({content_type: "supportingevidence", module: "Evidence Library", object_id: response.data.id, action: "create" }))
      requestFunctions.successAnimation("Successfully submitted evidence")
      dispatch(submittedEvidence(response.data))
      dispatch(changePage(1))
      if (mode === "create-list-item") {
        dispatch(getListOfEvidence())
      } else {
        dispatch(getEvidence())
      }
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in submitting evidence...")
    })
  }
}

/**
 * @summary Gets evidence for the currently selected indication.
 */
export const getEvidence = () => {
  return (dispatch, useState) => {
    const { limit, currentPage, searchTerm } = useState().manageSupportingEvidence
    const { selectedIndication } = useState().manageIndications
    const { selectedCategories } = useState().manageCategories
    const { modules } = useState().manageModules

    let offset = limit * (currentPage - 1)

    let filteredCategories = []
    const selectedModule = modules.find(module => module.route === "/library")
    if (selectedModule) {
      if (selectedCategories[selectedModule.id]) {
        Object.keys(selectedCategories[selectedModule.id]).forEach(filterType => {
          filteredCategories.push(...selectedCategories[selectedModule.id][filterType])
        })
      }
      filteredCategories = filteredCategories.map(category => category.id)
    }

    const filters = { term: searchTerm, categories: filteredCategories }

    dispatch(fetchingEvidence(true))
    axios.get(`${process.env.REACT_APP_URL}supporting-evidences/`, {
      params: { limit, filters, offset, indication: selectedIndication.id },
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      if(Object.keys(response.data).includes("results")) {
        dispatch({ type: "SET_TOTAL_EVIDENCE_COUNT", payload: response.data.count })
        if(response.data.results.length) {
          dispatch(setSelectedEvidence(response.data.results[0]))
        }
        dispatch(gotEvidence(response.data.results))
      } else {
        if(response.data.length) {
          dispatch({ type: "SET_TOTAL_EVIDENCE_COUNT", payload: response.data.length })
          dispatch(setSelectedEvidence(response.data[0]))
        }
        dispatch(gotEvidence(response.data))
      }
      dispatch(getListOfKVMs())
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data...")
    }).finally(() => {
      dispatch(fetchingEvidence(false))
    })
  }
}

/**
 * @summary Gets all evidence without pagination for the currently selected indication.
 */
export const getAllEvidence = () => {
  return (dispatch) => {
    dispatch(fetchingEvidence(true))
    axios.get(process.env.REACT_APP_URL+"supporting-evidences/", {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}` }
    }).then(response => {
      dispatch(gotAllEvidence(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading the data...")
    }).finally(() => {
      dispatch(fetchingEvidence(false))
    })
  }
}

/**
 * @summary Deletes an evidence.
 * @param {object} evidence - Evidence object that is meant to be deleted.
 */
export const deleteEvidence = (evidence) => {
  return (dispatch, useState) => {
    requestFunctions.loadingAnimation("Deleting evidence, It might take a few moments if the file is large...")
    dispatch({ type: "TOGGLE_DELETING_EVIDENCE", payload: true })

    axios.delete(evidence.url, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `JWT ${localStorage.getItem("token")}`
      }
    }).then(response => {
      requestFunctions.successAnimation("Evidence has been successfully deleted")
      dispatch(changePage(1))
      dispatch(getEvidence())
    }).catch(error => {
      console.log(error)
      requestFunctions.handleError(error, dispatch, "There was an error in deleting evidence...")
    }).finally(() => {
      dispatch({ type: "TOGGLE_DELETING_EVIDENCE", payload: false })
    })
  }
}