import axios from 'axios'
import {requestFunctions} from "../functions/requestFunctions";

const defaultState = {
  tableOfContents: {},
  fetchingCSRTableOfContents: false,
}

export default function manageCSRContents(state = defaultState, action){
  switch (action.type) {
    case "FETCHING_CSR_TABLE_OF_CONTENTS":
      return { ...state, fetchingCSRTableOfContents: true }
    case "SET_CSR_TABLE_OF_CONTENTS":
      return {
        ...state,
        tableOfContents: action.payload,
        fetchingCSRTableOfContents: false,
      }
    case "CLEAR_CSR_CONTENTS":
      return defaultState
    default:
      return state
  }
}

const fetchingCSRTableOfContents = () => {
  return {type: "FETCHING_CSR_TABLE_OF_CONTENTS"}
}

const gotCSRTableOfContents = (data) => {
  return {type: "SET_CSR_TABLE_OF_CONTENTS", payload: data}
}

/**
 *
 * @param {string=} studyId - ID of a study
 * @summary - Filters the CSR table of contents by the currently selected indication and optionally by a study
 */
export const getCSRTableOfContents = (studyId="") => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    dispatch(fetchingCSRTableOfContents())
    axios.get(`${process.env.REACT_APP_URL}clinical-study-report-table-of-contents/`, {
      headers: {"Authorization": "JWT "+localStorage.getItem("token")},
      params: { "indication": selectedIndication.id, "study": studyId }
    }).then(response => {
      dispatch(gotCSRTableOfContents(response.data))
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error loading data...")
      dispatch(gotCSRTableOfContents({}))
    })
  }
}
