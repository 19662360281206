import React, {Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { Modal } from "react-bootstrap";
import { editEducation, submitEducation } from "../../../../reducers/manageEducationalResources";
import PropTypes from "prop-types";
import StepOne from "./StepOne";
import EntryCreationCategoryList from "../../../../components/EntryCreationCategoryList";


export default function CreateUpdateEducation({ education = {}, mode = "create", forClick = null }) {
  // REDUX
  const dispatch = useDispatch()
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const user = useSelector(state => state.manageUsers.user)
  const countries = useSelector(state => state.manageUsers.countries)

  // STATE
  const globalCountry = countries.find(country => country.name === "Global")
  const [selectedResourceCategories, setSelectedResourceCategories] = useState({})

  const initialState = {
    visible: false,
    title: education.title || "",
    uploaderNotes: education.uploader_notes || "",
    educationCountries: education.countries || (globalCountry ? [globalCountry.url] : []),
    resourceCategories: education.categories || [],
    indications: education.indications || (selectedIndication ? [selectedIndication.url] : []),
    selectedFolders: education.folders ? education.folders : [],
    file: {},
    step: 1,
    errorMessage: ""
  }
  const [{
    visible, title, uploaderNotes,
    educationCountries,
    indications, selectedFolders,
    file, step, errorMessage
  }, setState] = useState(initialState)
  // const [allFolders, setAllFolders] = useState([])
  // const [fetchingAllFolders, setFetchingAllFolders] = useState(false)


  const handleInput = (e) => {
    const { name, value } = e.target
    setState(prevState => ({ ...prevState, [name]: value }))
  }
  const handleSelect = (field, value) => setState(prevState => ({ ...prevState, [field]: value }))
  const handleCancel = () => {
    setState({...initialState})
    setSelectedResourceCategories({})
  }

  const handleInputFile = (e) => {
    handleSelect("file", e.target.files[0])
  }

  // const handleGetAllFolders = (query) => {
  //   setFetchingAllFolders(true)
  //   axios.get(`${process.env.REACT_APP_URL}folders/`, {
  //     headers: {"Authorization": `JWT ${localStorage.token}`},
  //     params: { indication: selectedIndication.id, name: query, module__name: keyResourcesModule.name }
  //   }).then(response => {
  //     setAllFolders(response.data)
  //   }).catch(error => {
  //     requestFunctions.handleError(error, dispatch, "There was an error with fetching folders...")
  //   }).finally(() => {
  //     setFetchingAllFolders(false)
  //   })
  // }

  const handleSubmit = () => {
    let allSelectedCategories = []
    Object.keys(selectedResourceCategories).forEach(c => {
      allSelectedCategories = [...allSelectedCategories, ...selectedResourceCategories[c]]
    })

    if ((mode === "create" && !file.name)
      || !title
      // || !selectedFolders.length
      || !allSelectedCategories.length
      || !uploaderNotes
    ) {
      handleSelect("errorMessage", "Please fill out all required fields.")
      return
    }
    handleSelect("errorMessage", "")

    const newEducation = new FormData()
    newEducation.append("document", file)
    newEducation.append("title", title)
    newEducation.append("uploader_notes", uploaderNotes)
    educationCountries.forEach(country => newEducation.append('countries', country))
    selectedFolders.forEach(folder => newEducation.append('folders', folder.url))
    allSelectedCategories.forEach(cat => newEducation.append('categories', cat))
    indications.forEach(indication => newEducation.append("indications", indication))
    newEducation.append("is_active", true)
    newEducation.append("created_by", {})
    newEducation.append("modified_by", {})

    if (mode === "update") {
      newEducation.append("url", education.url)
      dispatch(editEducation(newEducation))
    } else {
      dispatch(submitEducation(newEducation))
      handleCancel()
    }
  }


  const changeStep = (newStep) => {
    if (step === 1) {
      if (!file.name && mode === "create") {
        handleSelect("errorMessage", "Please choose a Document")
      } else if (!title) {
        handleSelect("errorMessage", "Please fill out the Title field")
      } else if (!uploaderNotes) {
        handleSelect("errorMessage", "Please fill out the Uploader Notes field")
      } else if (indications.length < 1) {
        handleSelect("errorMessage", "Please select at least one Indication")
      } else {
        handleSelect("step", newStep)
        handleSelect("errorMessage", "")
      }
    } else {
      handleSelect("step", newStep)
      handleSelect("errorMessage", "")
    }
  }

  const renderStep = () => {
    if (step === 1) {
      return (
        <StepOne
          title={ title }
          uploaderNotes={ uploaderNotes }
          indications={ indications }
          file={ file }
          errorMessage={ errorMessage }
          changeStep={ changeStep }
          handleInput={ handleInput }
          handleInputFile={ handleInputFile }
          handleSelect={ handleSelect }
          handleCancel={ handleCancel }
        />
      )
    } else if (step === 2) {
      return (
        <EntryCreationCategoryList
          entry={ education }
          indications={ indications }
          step={ step }
          handleCancel={ handleCancel }
          changeStep={ changeStep }
          handleSubmit={ handleSubmit }
          selectedCategories={ selectedResourceCategories }
          setSelectedCategories={ setSelectedResourceCategories }
        />
      )
    }
  }

  return (
    <Fragment>
      <forClick.type
        {...forClick.props}
        onClick={user.role === "Global" ? () => handleSelect("visible", true) : null}
      />
      <Modal
        onHide={handleCancel}
        size="lg"
        show={visible}
        className="ant-modal-body-custom"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            { mode === "create" ? "Add Key Resource" : "Edit Key Resource" }
          </Modal.Title>
        </Modal.Header>
        { renderStep() }
      </Modal>
    </Fragment>
  )
}

CreateUpdateEducation.propTypes = {
  education: PropTypes.object,
  mode: PropTypes.string,
  forClick: PropTypes.node
}