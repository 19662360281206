import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import LoadingAnimation from '../../../../components/LoadingAnimation'
import { getListOfEvidence } from '../../../../reducers/manageFacts'
import { Button, Checkbox, Input } from "antd";


export default function StepThree(props) {
  // PROPS
  const {
    indications, supporting_evidence, handleChangeEvidence,
    changeStep, isEvidenceStatement, handleSubmit, kvmParent
  } = props

  // REDUX
  const dispatch = useDispatch()
  const listOfEvidence = useSelector(state => state.manageFacts.listOfEvidence)
  const fetchingListOfEvidence = useSelector(state => state.manageFacts.fetchingListOfEvidence)

  // STATE
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    const indicationIDs = indications.map(indication => {
      indication = indication.split("/")
      return indication[indication.length - 2]
    })

    dispatch(getListOfEvidence(indicationIDs))
  }, [])

  const handleSearch = (e) => {
    e.preventDefault()
    const indicationIDs = indications.map(indication => {
      indication = indication.split("/")
      return indication[indication.length - 2]
    })
    dispatch(getListOfEvidence(indicationIDs, searchTerm))
  }

  let evidence = listOfEvidence.map((evidence, index) => {
    return (
      <tr key={ index }
        className="kvm-label"
        onClick={(e) => {
          e.preventDefault()
          handleChangeEvidence(evidence)
        }}
        >
        <td>
          <Checkbox
            id={ `evidence-${evidence.id}` }
            checked={ !!supporting_evidence.find(evidenceObject => evidence.url === evidenceObject.url) }
            onChange={() => null}
            onClick={(e) => {
              e.stopPropagation()
              handleChangeEvidence(evidence)
            }}
          />
        </td>
        <td style={{ maxWidth: '20vw', width: '100%', overflow: 'hidden', textAlign: "left" }}>
          <label
            className="kvm-message"
            htmlFor={ `evidence-${evidence.id}` }
          >
            { evidence.full_reference }
          </label>
        </td>
      </tr>
    )
  })

  if (!evidence.length) {
    evidence = (
      <tr className="kvm-label">
        <td></td>
        <td style={{ maxWidth: '20vw', width: '100%', overflow: 'hidden' }}>
          There are no evidence for the selected indications...
        </td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      <Modal.Body>
        <div id="newkvm" className="translucent-background-with-padding">
          <p className="title">
            <strong>Select Associated Evidence:</strong>
          </p>
          <div>
            {/*<CreateUpdateEvidence mode="create" />*/}
          </div>
          <center style={{ marginBottom: "10px" }}>
            <form onSubmit={ handleSearch }>
              <Input
                value={ searchTerm }
                onChange={ (e) => setSearchTerm(e.target.value) }
                placeholder="Search..."
              />
              </form>
          </center>
          <div className="evidence-container" id="kvm-evidence-statement-list">
            <table className="evidencetable">
              <thead>
                <tr>
                  <th className="label"></th>
                  <th className="label">
                    Select from Existing Evidence
                  </th>
                </tr>
              </thead>
              <tbody>
                { fetchingListOfEvidence ?
                <tr className="kvm-label">
                  <td></td>
                  <td style={{ maxWidth: '20vw', width: '100%', overflow: 'hidden', padding: "100px" }}>
                    <br/>
                    <LoadingAnimation />
                  </td>
                </tr>
                : evidence }
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ () => changeStep(isEvidenceStatement ? 2 : 1) }>Back</Button>
        { !kvmParent ?
          <Button onClick={ () => changeStep(4) }>Select Categories</Button>
          :
          <Button onClick={ handleSubmit }>Submit</Button>
        }
      </Modal.Footer>
    </React.Fragment>
  )
}