import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingAnimation from '../../../../components/LoadingAnimation'
import { getListOfKVMs } from '../../../../reducers/manageKVM'
import {Button, Checkbox, Input} from "antd";
import { Modal } from 'react-bootstrap'


function KVMRow({ kvm, index, onChangeKVM, key_value_messages }) {
  return (
    <tr
      key={index}
      className="kvm-label"
      onClick={(e) => {
        e.preventDefault()
        onChangeKVM(kvm.id)
      }}
    >
      <td className="kvm-checkbox" style={{ textAlign: "center" }}>
        <Checkbox
          id={"kvm" + kvm.id}
          onChange={() => null}
          checked={ !!key_value_messages.find(objectKVM => objectKVM.id === kvm.id || kvm.url === objectKVM) }
          onClick={(e) => {
            e.stopPropagation()
            onChangeKVM(kvm.id)
          }}
        />
      </td>
      <td className="kvm-id" style={{ textAlign: "center" }}>
        { kvm.parent_number ? `${ kvm.parent_number }.${ kvm.number }` : kvm.number }
      </td>
      <td>
      <label
        className="kvm-message"
        htmlFor={"kvm"+kvm.id}
      >
        {kvm.message}
      </label>
      </td>
    </tr>
  )
}

export default function StepTwo({indications, key_value_messages, onChangeKVM, handleCancel, changeStep}) {
  // REDUX
  const dispatch = useDispatch()
  const listOfKVMs = useSelector(state => state.manageKVM.listOfKVMs)
  const fetchingListOfKVMs = useSelector(state => state.manageKVM.fetchingListOfKVMs)

  // STATE
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    const indicationIDs = indications.map(indication => {
      indication = indication.split("/")
      return indication[indication.length - 2]
    })

    dispatch(getListOfKVMs(indicationIDs))
  }, [])

  const handleSearch = (e) => {
    e.preventDefault()
    const indicationIDs = indications.map(indication => {
      indication = indication.split("/")
      return indication[indication.length - 2]
    })
    dispatch(getListOfKVMs(indicationIDs, searchTerm))
  }

  const generateKVMList = () => {
    if (listOfKVMs.length) {
      return (
        <React.Fragment>
          { listOfKVMs.map((kvm, index) => {
            return (
              <KVMRow
                key={ kvm.id }
                key_value_messages={ key_value_messages }
                kvm={ kvm }
                index={ index }
                onChangeKVM={ onChangeKVM }
              />
            )
          }) }
        </React.Fragment>
      )
    } else {
      return (
        <tr className="kvm-label">
          <td></td>
          <td></td>
          <td style={{ maxWidth: '20vw', width: '100%', overflow: 'hidden' }}>
            There are no Key Value Messages for the selected indications...
          </td>
        </tr>
      )
    }
  }

  return (
    <React.Fragment>
      <Modal.Body>
        <div id="create-edit-evidence" className='translucent-background-with-padding'>
          <p className="title">
            <strong>Select Relevant Key Value Messages:</strong>
          </p>
          <center style={{ marginBottom: "10px" }}>
            <form onSubmit={ handleSearch }>
              <Input
                value={ searchTerm }
                onChange={ (e) => setSearchTerm(e.target.value) }
                placeholder="Search..."
              />
              </form>
          </center>
          <table className="kvmtable" style={{width: "100%"}}>
            <colgroup>
              <col className="kvm-column" />
              <col className="kvm-column" />
            </colgroup>
            <thead className="table-header new-evidence">
              <tr>
                <th className='kvm-checkbox-header' style={{ width:"50%" }}>Selected</th>
                <th>Number</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
            { fetchingListOfKVMs ?
              <tr className="kvm-label">
                <td></td>
                <td></td>
                <td style={{ width: '100%', overflow: 'hidden', padding: "100px" }}>
                  <br/>
                  <LoadingAnimation />
                </td>
              </tr>
              : generateKVMList()
            }
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button key="1" onClick={ handleCancel }>Cancel</Button>
        <Button key="2" onClick={ () => changeStep(1) }>Back</Button>
        <Button key="3" onClick={ () => changeStep(3) }>Select Categories</Button>
      </Modal.Footer>
    </React.Fragment>
  )
}