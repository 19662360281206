const defaultState = {
  products: [],
  countries: [],
  technologies: []
}

export default function manageGlobalFilters(state=defaultState, action) {
  switch (action.type) {
    case "SET_PRODUCTS":
      return { ...state, products: action.payload }
    case "SET_COUNTRIES":
      return { ...state, countries: action.payload }
    case "SET_TECHNOLOGIES":
      return { ...state, technologies: action.payload }
    case "CLEAR_GLOBAL_FILTERS":
      return defaultState
    default:
      return state
  }
}

export const setProducts = (products) => {
  return { type: "SET_PRODUCTS", payload: products }
}

export const setCountries = (countries) => {
  return { type: "SET_COUNTRIES", payload: countries }
}

export const setTechnologies = (technologies) => {
  return { type: "SET_TECHNOLOGIES", payload: technologies }
}