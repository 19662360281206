import React from 'react'
import DatePicker from "react-datepicker";
import {Button} from "antd";


/**
 * Custom DatePicker component that has selectable Years
 * in the header and instead of days it has selectable months.
 * @param { startDate, startDate } - Date object and Date setter function.
 * @returns {JSX.Element}
 */
export function TimelineDatePicker({ startDate, setStartDate, showQuarterYearPicker = false }) {
  function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }

  const years = range(1990, (new Date()).getFullYear() + 10)

  return (
    <DatePicker
      className="ant-input"
      dateFormat="MMMM yyyy"
      showMonthYearPicker={ !showQuarterYearPicker }
      showQuarterYearPicker={ showQuarterYearPicker }
      showFullMonthYearPicker
      showFourColumnMonthYearPicker
      renderCustomHeader={({
        date,
        changeYear,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            // margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={decreaseYear} disabled={prevMonthButtonDisabled} className="date-picker-button">
            {"Back"}
          </Button>
          <select
            className="date-picker-month-selector"
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <Button onClick={increaseYear} disabled={nextMonthButtonDisabled} className="date-picker-button">
            {"Next"}
          </Button>
        </div>
      )}
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  )
}


/**
 * Custom DatePicker component that has selectable Years and Months in the header.
 * @returns {JSX.Element}
 */
export function CustomDatePicker(props) {
  function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }
  const years = range(1990, (new Date()).getFullYear() + 10)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  return (
    <DatePicker
      className="ant-input"
      dateFormat="MMMM dd, yyyy"
      todayButton="Today"
      disabledKeyboardNavigation
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="date-picker-button">
            Back
          </Button>
          <select
            className="date-picker-month-selector"
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            className="date-picker-month-selector"
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <Button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="date-picker-button">
            Next
          </Button>
        </div>
      )}
      {...props}
    />
  );
}