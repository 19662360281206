import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import UpdatePopulationCharacteristics from './UpdatePopulationCharacteristics'
import InclusionExclusionCriteria from "./InclusionExclusionCriteria";


export default function PopulationCharacteristics() {
  // REDUX
  const selectedStudy = useSelector(state => state.manageStudies.selectedStudy)

  return (
    <div>
      <h4>Population Characteristics</h4>
      <hr/>
      <Row>
        <Col>
          <h5>Population</h5>
        </Col>
        <Col>
          <UpdatePopulationCharacteristics study={ selectedStudy } />
        </Col>
      </Row>
      <div style={{ marginLeft: "25px" }}>
        <div>
          {/*<strong>Age Range:</strong> { selectedStudy.min_population_age } - { selectedStudy.max_population_age } years*/}
          <strong>Age Range:</strong> { selectedStudy.population_age || "N/A" }
        </div>
        <div>
          <strong>Number of Patients:</strong> { selectedStudy.number_of_patients }
        </div>
      </div>
      <br/>
      <InclusionExclusionCriteria />
    </div>
  )
}