import React, {Fragment, useState} from 'react'
import {ListGroup, Row} from "react-bootstrap";
import Subsection from "./Subsection";
import {Icon} from "antd";
import { Col } from 'react-bootstrap'
import PopupPDFViewer from "../../../../components/PopupPDFViewer";


export default function Section({ section, csr }) {
  // STATE
  const [visible, setVisible] = useState(false)

  const handleExpand = (e) => {
    e.preventDefault()
    setVisible(!visible)
  }

  return (
    <Fragment>
      <ListGroup.Item
        action
        onClick={ section.subsections.length ? handleExpand : null }
        className={`csr-section-item ${!visible || "active"}`}
      >
        <Row>
          <Col sm={10} md={10} lg={10}>
            <PopupPDFViewer
              page={section.page}
              file={csr.document_url}
              forClick={<span className="csr-link">{section.name}</span>}
            />
            {/*{ section.name }*/}
          </Col>
          <Col sm={2} md={2} lg={2}>
            { section.subsections.length ?
            <Icon
              style={{cursor:"pointer", padding: '5px', float: "right"}}
              type={visible ? "caret-down" : "caret-right"}
            /> : null }
          </Col>
        </Row>
      </ListGroup.Item>
      { section.subsections.length && visible ?
        section.subsections.map((subsection, index) => {
          return <Subsection key={index} subsection={subsection} csrDocument={csr.document_url}/>
        })
        :
        null
      }
    </Fragment>
  )
}