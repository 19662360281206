const KVMSort = (a, b) => {
  let result, aNumber, bNumber;
  aNumber = a.parent_number ? `${a.parent_number}.${a.number}` : `${a.number}`
  bNumber = b.parent_number ? `${b.parent_number}.${b.number}` : `${b.number}`
  a = aNumber.split('.')
  b = bNumber.split('.')

  while (a.length) {
    result = a.shift() - (b.shift() || 0);

    if (result) {
      return result;
    }
  }
  return -b.length;
}


export const sortingCallbacks = {
  KVMSort: KVMSort
}