import React, {Component} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {BarChart, LineChart, PieChart, Bar, Line, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts'
import {Card, Row, Col/*, Switch*/, Select} from 'antd'

import ClicksViewer from './ClicksViewer'

const Option = Select.Option

class ActivityViewer extends Component {
  constructor(props){
    super(props)
    const userIndex = props.users.findIndex(user => user.id === props.user.id)

    this.state = {
      userIndex: userIndex,
      mode: "day",
      alphSort: true,
      dateTime: null,
      activeUsersTime: "all time"
    }
  }

  switchUser = (value) => {
    this.setState({userIndex: value})
  }

  organizeUsers = (user, index) => {
    if (user.is_active) {
      if (user.first_name || user.last_name) {
        return (
          <Option key={index} value={index}>
            {user.first_name} {user.last_name}
          </Option>
        )
      } else {
        return (
          <Option key={index} value={index}>
            {user.email}
          </Option>
        )
      }
    }
  }

  countServes = (serves, uniqueServe) => {
    const count = (serve, unique) => {
      return serve === unique
    }
    return serves.filter(serve => count(serve, uniqueServe)).length
  }

  getLastWeek = (today) => {
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
    return lastWeek.toLocaleDateString()
  }

  checkDate = (date) => {
    // CHECKS THE DATE OF SERVES
    let dateOfServe = new Date(date)
    let dateToFilterBy = new Date(this.state.dateTime)
    if (dateOfServe.getTime() > dateToFilterBy.getTime()) {
      return true
    } else {
      return false
    }
  }

  mapServeCounts = (serves, propType, category, includeAllServes) => {
    // The serves argument is an array of serves filtered by type
    // The propType argument is an array of the objects that match the type of the serves
    // The category argument is a string element name of a property in the objects contained in the propType argument
    let serveCountsMapper = {}

    serves.forEach(serve => {
      // check if serve matches the users selected in the dropdown of user name
      const currentUserUrl = this.props.users[this.state.userIndex].url
      const includeServe = serve.serve_user === currentUserUrl || includeAllServes

      if (includeServe) {
        // find the object in the propType array that corresponds to the serve
        let foundObject = propType.find(prop => prop.url === serve.object_id)

        if (foundObject) {
          // check if the serve exists in the mapper object. If so, increment the count. If not set its count to 1.
          const name = foundObject[category]

          if (serveCountsMapper[name]) {
            serveCountsMapper[name] += 1
          } else {
            serveCountsMapper[name] = 1
          }
        }
      }
    })

    return Object.keys(serveCountsMapper).map(serve => {
      return {
        serve: serve,
        count: serveCountsMapper[serve]
      }
    })
    .sort((a, b) => a.serve.localeCompare(b.serve))
  }

  render() {
    const users = this.props.users.map((user, index) => this.organizeUsers(user, index))

    let { serves, evidence, kvms, educations } = this.props

    // For the selected user, count the number of times they've logged into the account on each day that they have logged in
    let loginByDayMapper = {}

    this.props.logins.forEach(login => {
      let loginDate = moment(login.login_date).format("YYYY MM DD")
      if (login.login_user === this.props.users[this.state.userIndex].url) {
        if (loginByDayMapper[loginDate]) {
          loginByDayMapper[loginDate] += 1
        } else {
          loginByDayMapper[loginDate] = 1
        }
      }
    })

    const loginByDay = Object.keys(loginByDayMapper).map(login => {
      return {
        day: login,
        count: loginByDayMapper[login]
      }
    })
    .sort((a, b) => a.day.localeCompare(b.day))


    // Here we iterate through the serves and sort them into categories. Handled with declaration of empty arrays, a call to serves.forEach and pushing the serves into their respective arrays in order to only iterate through the serves once
    let evidenceServes = []
    let educationServes = []
    let kvmServes = []

    serves.forEach(serve => {
      const { content_type } = serve
      if (content_type.includes('supporting-evidences')) {
        evidenceServes.push(serve)
      } else if (content_type.includes('educational-resources')) {
        educationServes.push(serve)
      } else if (content_type.includes('key-value-messages')) {
        kvmServes.push(serve)
      }
    })

    const serveCountsEvidenceByUser = this.mapServeCounts(evidenceServes, evidence, 'full_reference')
    const serveCountsEvidenceAllUsers = this.mapServeCounts(evidenceServes, evidence, 'full_reference', true)


    const serveCountsEducationsByUser = this.mapServeCounts(educationServes, educations, 'title')
    const serveCountsEducationsAllUsers = this.mapServeCounts(educationServes, educations, 'title', true)


    const serveCountsKVMsByUser = this.mapServeCounts(kvmServes, kvms, 'message')
    const serveCountsKVMsAllUsers = this.mapServeCounts(kvmServes, kvms, 'message', true)



    // Counts how many active users we've had in the past month
    let activeUserLogins = []
    const { activeUsersTime } = this.state
    let timePeriod
    if (activeUsersTime === "last month") {
      timePeriod = new Date().getDate() - 30
    } else if (activeUsersTime === "last week") {
      timePeriod = new Date().getDate() - 7
    }

    this.props.logins.forEach(login => {
      const loginDate = new Date(login.login_date).getDate()
      if (timePeriod < loginDate || activeUsersTime === "all time") {
        activeUserLogins.push(login.login_user)
      }
    })
    activeUserLogins = Array.from(new Set(activeUserLogins))

    const activeUsers = [{name: 'Active Users', value: activeUserLogins.length}, {name: 'Inactive Users', value: this.props.users.length - activeUserLogins.length}]
    const userColors = ['#0098B3', '#F9352B']
    const fileColors = ['#00B38B', '#F8352B', '#0097B2', '#FDAE00']

    // if (this.state.dateTime) {
    //   /// THIS LINE FILTERS THE SERVES THAT HAPPENED IN THE LAST WEEK
    //   serveTotals = serveTotals.filter(serve => this.checkDate(new Date(serve.serve.modified_date)))
    // }
    // Clicks stuff, not ready for use yet
    // const clicks = this.props.clicks.filter(click=>{
    //   return click.user === this.props.users[this.state.userIndex].url
    // })
    return (
      <div id="activity">
        {/*<center>Sort Alphabetical <Switch onChange={()=>{this.setState({alphSort: !this.state.alphSort})}} /> Numerical</center>*/}
          <Row>
            <ClicksViewer clicks={this.props.clicks} />
          </Row>
          <Card
            title={
              <div>
                Active Users
                <br/>
                  <Select
                    value={this.state.activeUsersTime}
                    onChange={(value) => this.setState({activeUsersTime: value})}
                  >
                    <Option value="all time">All Time</Option>
                    <Option value="last week">Last Week</Option>
                    <Option value="last month">Last Month</Option>
                </Select>
              </div>
            }
            style={{ width: '100%' }}
          >
            <Row>
              <Col span={24}> 
                <center>
                  <ResponsiveContainer width={'100%'} height={300} debounce={1}>
                    <PieChart>
                      <Tooltip />
                      <Pie
                        data={activeUsers}
                        dataKey="value"
                        outerRadius={100}
                        fill="#8884d8"
                        label
                      >
                        {activeUsers.map((entry, index) => {
                          return (
                            <Cell
                              key={index}
                              fill={userColors[index % userColors.length]}
                            />
                          )
                        })}
                      </Pie>
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </center>
              </Col>
            </Row>
          </Card>
        <Card
          title={
            <div>Content Utilization<br/>
              {/*<Select value={this.state.dateTime} onChange={(value) => this.setState({dateTime: value})}>
                <Option value={null}>All Time</Option>
                <Option value={this.getLastWeek(new Date())}>Last Week</Option>
              </Select>*/}
            </div>
          }
          style={{ width: '100%' }}>

          <Row>
            <Col span={24}>
              <center>
              <ResponsiveContainer width={'100%'} height={300} debounce={1}>
                <BarChart
                  data={serveCountsEvidenceAllUsers}
                  margin={{top: 5, right: 0, left: 0, bottom: 5}}
                >
                  <XAxis dataKey="serve" tick={null} />
                  <YAxis dataKey="count" />
                  <CartesianGrid strokeDasharray="3 3"/>
                  <Tooltip content={<CustomServesTooltip />}/>
                  <Legend />
                  <Bar dataKey="count" name='Total Evidence View Counts' fill="#ffffff">
                    {serveCountsEvidenceAllUsers.map((serve, index) => {
                      return (
                        <Cell
                          key={index}
                          fill={fileColors[index % fileColors.length]}
                        />
                      )
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              </center>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col span={24}>
              <center>
              <ResponsiveContainer width={'100%'} height={300} debounce={1}>
                <BarChart
                  data={serveCountsKVMsAllUsers}
                  margin={{top: 5, right: 0, left: 0, bottom: 5}}
                >
                  <XAxis dataKey="serve" tick={null} />
                  <YAxis dataKey="count" />
                  <CartesianGrid strokeDasharray="3 3"/>
                  <Tooltip content={<CustomServesTooltip />}/>
                  <Legend />
                  <Bar dataKey="count" name='Total Key Value Message View Counts' fill="#ffffff">
                    {serveCountsKVMsAllUsers.map((serve, index) => {
                      return (
                        <Cell
                          key={index}
                          fill={fileColors[index % fileColors.length]}
                        />
                      )
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              </center>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col span={24}>
              <center>
              <ResponsiveContainer width={'100%'} height={300} debounce={1}>
                <BarChart
                  data={serveCountsEducationsAllUsers}
                  margin={{top: 5, right: 0, left: 0, bottom: 5}}
                >
                  <XAxis dataKey="serve" tick={null} />
                  <YAxis dataKey="count" />
                  <CartesianGrid strokeDasharray="3 3"/>
                  <Tooltip content={<CustomServesTooltip />}/>
                  <Legend />
                  <Bar dataKey="count" name='Total Educational Resource View Counts' fill="#ffffff">
                    {serveCountsEducationsAllUsers.map((serve, index) => {
                      return (
                        <Cell
                          key={index}
                          fill={fileColors[index % fileColors.length]}
                        />
                      )
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              </center>
            </Col>
          </Row>

        </Card>

        <Card
          title={
            <div>
              User Behavior
              <br/>
              <Select
                value={this.state.userIndex}
                style={{color:"black"}}
                onChange={this.switchUser}
              >
                {users}
              </Select>
            </div>
          }
          style={{ width: '100%' }}
        >
          <Row>
            <Col span={24}>
              <center>
                <ResponsiveContainer width={'100%'} height={300} debounce={1}>
                  <LineChart
                    data={loginByDay}
                    margin={{top: 5, right: 30, left: 20, bottom: 5}}
                  >
                    <XAxis dataKey="day" tick={null}/>
                    <YAxis dataKey="count" />
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip content={<CustomDatesTooltip />}/>
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="count"
                      strokeWidth={5}
                      name='Login Count By Day'
                      stroke="#8884d8"
                      activeDot={{r: 8}}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </center>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col span={24}>
              <center>
                <ResponsiveContainer width={'100%'} height={300} debounce={1}>
                  <BarChart
                    data={serveCountsEvidenceByUser}
                    margin={{top: 5, right: 30, left: 20, bottom: 5}}
                  >
                    <XAxis dataKey="serve" />
                    <YAxis dataKey="count" />
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip content={<CustomServesTooltip />}/>
                    <Legend />
                    <Bar
                      dataKey="count"
                      name='User Evidence View Counts'
                      fill="#ffffff"
                    >
                      {serveCountsEvidenceByUser.map((serve, index) => {
                        return (
                          <Cell
                            key={index}
                            fill={fileColors[index % fileColors.length]}
                          />
                        )
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </center>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col span={24}>
              <center>
                <ResponsiveContainer width={'100%'} height={300} debounce={1}>
                  <BarChart
                    data={serveCountsKVMsByUser}
                    margin={{top: 5, right: 30, left: 20, bottom: 5}}
                  >
                    <XAxis dataKey="serve" />
                    <YAxis dataKey="count" />
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip content={<CustomServesTooltip />}/>
                    <Legend />
                    <Bar
                      dataKey="count"
                      name='User Key Value Messages View Counts'
                      fill="#ffffff"
                    >
                      {serveCountsKVMsByUser.map((serve, index) => {
                        return (
                          <Cell
                            key={index}
                            fill={fileColors[index % fileColors.length]}
                          />
                        )
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </center>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col span={24}>
              <center>
                <ResponsiveContainer width={'100%'} height={300} debounce={1}>
                  <BarChart
                    data={serveCountsEducationsByUser}
                    margin={{top: 5, right: 30, left: 20, bottom: 5}}
                  >
                    <XAxis dataKey="serve" />
                    <YAxis dataKey="count" />
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip content={<CustomServesTooltip />}/>
                    <Legend />
                    <Bar
                      dataKey="count"
                      name='User Educational Resources View Counts'
                      fill="#ffffff"
                    >
                      {serveCountsEducationsByUser.map((serve, index) => {
                        return (
                          <Cell
                            key={index}
                            fill={fileColors[index % fileColors.length]}
                          />
                        )
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </center>
            </Col>
          </Row>

        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    clicks: state.manageActivity.clicks,
    logins: state.manageActivity.logins,
    serves: state.manageActivity.serves,
    users: state.manageUsers.users,
    evidence: state.manageSupportingEvidence.evidence,
    kvms: state.manageKVM.KVMs,
    educations: state.manageEducation.education,
    sections: state.manageSections.sections,
    user: state.manageUsers.user,
  }
}

export default connect(mapStateToProps)(ActivityViewer)

const CustomServesTooltip = (props) => {
  if (props.active && props.payload) {
    return (
        <p className="label">
          {props.label}
          <br />
          {props.payload[0].name}: {props.payload[0].value}
        </p>
    )
  } else {
    return null
  }
}

const CustomDatesTooltip = (props) => {
  if (props.active && props.payload) {
    return (
        <p className="label">
          {moment(props.payload[0].payload.day).format("dddd, MMMM Do, YYYY")}
          <br />
          Login Count: {props.payload[0].payload.count}
        </p>
    )
  }  else {
    return null
  }
}
