import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Row} from 'antd'
import Message from './Message'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import OverarchingValueProposition from "./OverarchingValueProposition"
import CreateOverarchingValueProposition from "./CreateOverarchingValueProposition"
import {setEditableKVMList} from "../../../reducers/manageKVM"


export default function KeyValueMessages() {
  // REDUX
  const dispatch = useDispatch()
  const kvms = useSelector(state => state.manageKVM.KVMs)
  const editableKVMList = useSelector(state => state.manageKVM.editableKVMList)
  const overarchingValuePropositions = useSelector(state => state.manageKVM.overarchingValuePropositions)
  const isOrderEditable = useSelector(state => state.manageKVM.isOrderEditable)

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    if (result.type === "KVM") {
      const items = reorder(editableKVMList, sourceIndex, destIndex)
      dispatch(setEditableKVMList(items))
    } else if (result.type === "ES") {
      const itemSubItemMap = editableKVMList.reduce((acc, item) => {
        acc[item.id] = item.evidence_statements
        return acc
      }, {})

      const sourceParentId = parseInt(result.source.droppableId, 10)
      const destParentId = parseInt(result.destination.droppableId, 10)

      const sourceSubItems = itemSubItemMap[sourceParentId]
      const destSubItems = itemSubItemMap[destParentId]

      let newItems = [...editableKVMList]

      // In this case subItems are reOrdered inside the same Parent
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(
          sourceSubItems,
          sourceIndex,
          destIndex
        )
        newItems = newItems.map(item => {
          if (item.id === sourceParentId) {
            item.evidence_statements = reorderedSubItems
          }
          return item
        })
        dispatch(setEditableKVMList(newItems))
      } else {
        let newSourceSubItems = [...sourceSubItems]
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = [...destSubItems]
        newDestSubItems.splice(destIndex, 0, draggedItem)
        newItems = newItems.map(item => {
          if (item.id === sourceParentId) {
            item.evidence_statements = newSourceSubItems
          } else if (item.id === destParentId) {
            item.evidence_statements = newDestSubItems
          }
          return item
        })
        dispatch(setEditableKVMList(newItems))
      }
    }
  }

  let displayMessages = isOrderEditable ? editableKVMList : kvms
  displayMessages = displayMessages.filter(msg => !!msg)

  return (
    <div>
      { !isOrderEditable ?
        overarchingValuePropositions.length ? overarchingValuePropositions.map(ovp => {
          return <OverarchingValueProposition key={ovp.id} ovp={ovp}/>
        })
          :
          <Row><CreateOverarchingValueProposition /><br/></Row>
        : null
      }
      { displayMessages.length ?
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="keyValueMessages" type="KVM" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {displayMessages.map((msg, index) => {
                  return (
                    <Draggable key={msg.id} draggableId={`${msg.id}`} index={index} isDragDisabled={!isOrderEditable}>
                      {(provided) => (
                        <Message
                          innerRef={ provided.innerRef }
                          provided={ provided }
                          className="message"
                          message={ msg }
                          index={ index }
                        />
                      )}
                    </Draggable>
                  )}
                )}
                {provided.placeholder}
              </div>
            )}
            {/*{ displayMessages }*/}
          </Droppable>
        </DragDropContext>
        :
        <center><h4>No KVMs to Display</h4></center>
      }
    </div>
  )
}
