/* eslint-disable */
import axios from 'axios'
import qs from 'qs'

let defaultState = {
  loading: true,
  mode: null,
}

export default function Mode(state = defaultState, action) {
  switch (action.type) {
    case 'SET_MODE':
      return { ...state, mode: action.payload }
    case 'CLEAR_EVERYTHING':
      return {
        ...state,
        loading: true,
        mode: '/loading'
      }
    case 'LOADING':
      return {
        ...state,
        loading: false
      }
    case "CLEAR_MODE":
      return defaultState
    default:
		  return state
	}
}

export const setMode = (mode) => {
  return {type: 'SET_MODE', payload: mode}
}

export const setLoading = () => {
  return {type: 'LOADING'}
}
