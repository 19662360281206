import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import { ReactiveBase, DataSearch, ReactiveList } from '@appbaseio/reactivesearch';
import {Col, Row} from 'antd'
import SearchResult from './SearchResult'
import Pagination from "../../../components/Pagination";


export default function DataViewerReactiveSearch() {
  // REDUX
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)

  // STATE
  const [selected] = useState(`${process.env.REACT_APP_ELASTIC_PREFIX}_figure_tables`)

  const customQuery = () => {
    return {
      "sort": [
        {"page": {"order": "asc"}}
      ],
      "query": {
        "nested": {
          "path": "indications",
          "query": {
            "bool": {
              "must": [
                {"match": {"indications.id": selectedIndication.id}}
              ]
            }
          }
        }
      }
    }
  }

  return (
    <div id="data-viewer-reactive-search">
      <ReactiveBase
        app={selected}
        credentials={`${process.env.REACT_APP_ES_USERNAME}:${process.env.REACT_APP_ES_PASSWORD}`}
        url={`${process.env.REACT_APP_ES_URL}`}
        >
        <Row>
          <Col
            xs={23} sm={23} md={12} lg={12}
          >
            <DataSearch
              componentId="SearchSensor"
              dataField={["name", "phase", "section.name", "subsection.name", "description","tag.name"]}
              placeholder="Clinical Data Search"
              autosuggest={true}
              showIcon={false}
              URLParams={true}
              innerClass={{
                input: 'csr-reactive-search-input'
              }}
              defaultQuery={ customQuery }
            />
          </Col>
        </Row>

        <br/>
        <ReactiveList
          componentId="SearchResult"
          dataField="name,phase,section.name,sub_section.name,tag.name,description"
          loader={"Loading Results..."}
          className="result-list-container"
          pagination={true}
          paginationAt="top"
          pages={10}
          react={{
            and: ["SearchSensor", "TagFilter"]
          }}
          defaultQuery={ customQuery }
          renderItem={res => <SearchResult key={res._id} data={res}/>}
          renderPagination={({ pages, totalPages, currentPage, setPage, setSize }) => {
            return (
              <div className="reactive-search-pagination-container">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage + 1}
                  pageNeighbors={1}
                  handlePageChange={setPage}
                  reactiveSearch={true}
                />
              </div>
            )
          }}
        />
      </ReactiveBase>
    </div>
  )
}