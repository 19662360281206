import axios from 'axios'
import {requestFunctions} from "../functions/requestFunctions";

let defaultState = {
  categories: [],
  categoryTypes: [],
  selectedCategories: {},
  evidenceCategories: [],
  selectedEvidenceCategories: [],
  factCategories: [],
  selectedFactCategories: [],
  selectedFactCategory: "All",
  keyResourceCategories: [],
  selectedKeyResourceCategory: "All",
  selectedKeyResourceCategories: [],
  keyValueMessageCategories: [],
  selectedKeyValueMessageCategories: [],
  fetchingCategories: false,
  fetchingCategoryTypes: true,
  selectedKeyResourceFolder: "",
  selectedKeyResourceSubFolder: "",
}

export default function manageCategories(state = defaultState, action){
  switch (action.type) {
    case 'FETCHING_CATEGORIES':
      return { ...state, fetchingCategories: true }
    case 'FETCHING_CATEGORY_TYPES':
      return { ...state, fetchingCategoryTypes: action.payload }
    case 'SET_CATEGORIES':
      return { ...state, categories: action.payload, fetchingCategories: false }
    case 'SET_SELECTED_CATEGORIES':
      return { ...state, selectedCategories: action.payload }
    case 'SET_CATEGORY_TYPES':
      return { ...state, categoryTypes: action.payload, fetchingCategories: false }
    case 'SET_SELECTED_EVIDENCE_CATEGORIES':
      return { ...state, selectedEvidenceCategories: action.payload }
    case 'SET_EVIDENCE_CATEGORIES':
      return { ...state, evidenceCategories: action.payload }
    case 'SET_FACT_CATEGORIES':
      return { ...state, factCategories: action.payload }
    case 'SET_SELECTED_FACT_CATEGORY':
      return { ...state, selectFactCategory: action.payload }
    case 'SET_SELECTED_FACT_CATEGORIES':
      return { ...state, selectedFactCategories: action.payload }
    case 'SET_KEY_RESOURCE_CATEGORIES':
      return { ...state, keyResourceCategories: action.payload }
    case 'SET_SELECTED_KEY_RESOURCE_CATEGORY':
      return { ...state, selectedKeyResourceCategory: action.payload }
    case 'SET_SELECTED_KEY_RESOURCE_CATEGORIES':
      return { ...state, selectedKeyResourceCategories: action.payload }
    case 'SET_KEY_VALUE_MESSAGE_CATEGORIES':
      return { ...state, keyValueMessageCategories: action.payload }
    case 'SET_SELECTED_KEY_VALUE_MESSAGE_CATEGORIES':
      return { ...state, selectedKeyValueMessageCategories: action.payload }
    case 'SET_SELECTED_KEY_RESOURCE_FOLDER':
      return { ...state, selectedKeyResourceFolder: action.payload }
    case 'SET_SELECTED_KEY_RESOURCE_SUB_FOLDER':
      return { ...state, selectedKeyResourceSubFolder: action.payload }
    case 'CLEAR_CATEGORIES':
      return { ...defaultState, selectedCategories: {} }
    default:
      return state
	}
}

export const selectCategories = (categories) => {
  return { type: "SET_SELECTED_CATEGORIES", payload: categories }
}

export const selectEvidenceCategories = (categories) => {
  return { type: "SET_SELECTED_EVIDENCE_CATEGORIES", payload: categories }
}

export const selectFactCategories = (categories) => {
  return { type: "SET_SELECTED_FACT_CATEGORIES", payload: categories }
}

export const selectFactCategory = (category) => {
  return { type: "SET_SELECTED_FACT_CATEGORY", payload: category }
}

export const selectKeyResourceCategory = (category) => {
  return { type: "SET_SELECTED_KEY_RESOURCE_CATEGORY", payload: category }
}

export const selectKeyResourceCategories = (categories) => {
  return { type: "SET_SELECTED_KEY_RESOURCE_CATEGORIES", payload: categories }
}

export const selectKeyValueMessageCategories = (categories) => {
  return { type: "SET_SELECTED_KEY_VALUE_MESSAGE_CATEGORIES", payload: categories }
}

export const selectKeyResourceFolder = (folder) => {
  return { type: "SET_SELECTED_KEY_RESOURCE_FOLDER", payload: folder }
}

export const selectKeyResourceSubFolder = (folder) => {
  return { type: "SET_SELECTED_KEY_RESOURCE_SUB_FOLDER", payload: folder }
}

export const getCategories = () => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    dispatch({type: "FETCHING_CATEGORIES"})
    axios.get(`${process.env.REACT_APP_URL}categories/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}`},
      params: { indication: selectedIndication.id }
    }).then(response => {
      let evidenceCategories = [],
          factCategories = [],
          keyResourceCategories = [],
          keyValueMessageCategories = []

      response.data.forEach( category => {
        if (category.module_name === "Evidence Library") {
          evidenceCategories.push(category)
        } else if (category.module_name === "Data Collection") {
          factCategories.push(category)
        } else if (category.module_name === "Key Resources") {
          keyResourceCategories.push(category)
        } else if (category.module_name === "Key Value Messages") {
          keyValueMessageCategories.push(category)
        }
      })

      dispatch({ type: "SET_EVIDENCE_CATEGORIES", payload: evidenceCategories })
      dispatch({ type: "SET_FACT_CATEGORIES", payload: factCategories })
      dispatch({ type: "SET_KEY_RESOURCE_CATEGORIES", payload: keyResourceCategories })
      dispatch({ type: "SET_KEY_VALUE_MESSAGE_CATEGORIES", payload: keyValueMessageCategories })
      dispatch({ type: "SET_CATEGORIES", payload: response.data })

    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading data...")
      dispatch({type: "SET_CATEGORIES", payload: []})
    })
  }
}

/**
 *
 * @param {string} moduleRoute - Module route (ex. "/library", "/resources")
 * @param {function=} getEntries - Function that gets entries after the categories have been fetched
 */
export const getCategoryTypesByModule = (moduleRoute, getEntries = null) => {
  return (dispatch, useState) => {
    const { selectedIndication } = useState().manageIndications
    const { selectedCategories } = useState().manageCategories
    dispatch({type: "FETCHING_CATEGORY_TYPES", payload: true})
    axios.get(`${process.env.REACT_APP_URL}category-types/get_module_category_types/`, {
      headers: { "Authorization": `JWT ${localStorage.getItem("token")}`},
      params: { indication: selectedIndication.id, route: moduleRoute }
    }).then(response => {
      response.data.forEach(module => {
        module.category_types.forEach(category_type => {
          if (category_type.type === "RADIO" && category_type.categories.length > 1) {
            if (
              selectedCategories[module.id]
              && selectedCategories[module.id][category_type.id]
              && selectedCategories[module.id][category_type.id].length
            ) {
              return
            } else {
              if (!selectedCategories[module.id]) selectedCategories[module.id] = {}
              if (!selectedCategories[module.id][category_type.id]) {
                let firstInOrder = category_type.categories.reduce((prev, curr) => {
                    return prev.order < curr.order ? prev : curr
                });
                selectedCategories[module.id][category_type.id] = [firstInOrder]
              }
              dispatch(selectCategories(selectedCategories))
            }
          }
        })
      })
      dispatch({ type: "SET_CATEGORY_TYPES", payload: response.data })
      if (getEntries) getEntries()
    }).catch(error => {
      requestFunctions.handleError(error, dispatch, "There was an error in loading data...")
    }).finally(() => {
      dispatch({type: "FETCHING_CATEGORY_TYPES", payload: false})
    })
  }
}