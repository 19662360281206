import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'antd'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { getKeyDates, getStudy } from '../../../reducers/manageStudies'
import { getTimelineStudyList } from '../../../reducers/manageStudyTimelines'
import LoadingAnimation from '../../../components/LoadingAnimation'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import TimelineFilters from './TimelineFilters'
import Quarter from './Quarter'
import Agenda from './Agenda'
import EventModal from './EventModal'
import Toolbar from "react-big-calendar/lib/Toolbar";
import { TimelineDatePicker } from "../../../components/DatePickers";


export default function TimelineContainer() {
  // REDUX
  const dispatch = useDispatch()
  const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
  const selectedStudyList = useSelector(state => state.manageStudyTimelines.selectedStudyList)
  const fetchingKeyDates = useSelector(state => state.manageStudies.fetchingKeyDates)
  const keyDates = useSelector(state => state.manageStudies.keyDates)
  const selectedTimelineFilters = useSelector(state => state.manageStudyTimelines.selectedTimelineFilters)

  // STATE
  let [selectedEvents, setSelectedEvents] = useState(undefined)
  let [date, setDate] = useState(new Date())
  let [view, setView] = useState("month")

  useEffect(() => {
    dispatch(getTimelineStudyList())
    dispatch(getKeyDates(selectedStudyList, selectedIndication.id, null, 0, 0))
  }, [])

  const handleSelectEvent = (event) => {
    if (!event.communication_target) {
      setSelectedEvents([event])
      dispatch(getStudy(event.study.id))
    }
  }

  const handleSelectEvents = (events) => {
    setSelectedEvents(events.filter(event => !event.communication_target))
  }

  const handleClose = () => {
    setSelectedEvents(undefined)
  }

  const localizer = momentLocalizer(moment)

  const events = keyDates.filter(keyDate => {
    if (selectedTimelineFilters.length) {
      if (selectedTimelineFilters.includes("Study Duration") && keyDate.name === "Start Date") {
        return true
      } else if (selectedTimelineFilters.includes("Key Dates") && keyDate.name !== "Start Date" && keyDate.name !== "End Date" && !!keyDate.study) {
        return true
      } else if (selectedTimelineFilters.includes("Communication Targets") && !!keyDate.communication_target) {
        return true
      } else {
        return false
      }
    }
    return keyDate.name !== "End Date"
  }).map(keyDate => {
    let eventTitle = keyDate.name
    let endDate = new Date(keyDate.end_date).setHours(23,59,59,999)

    if (keyDate.name === "Start Date") {
      let endDateObject = keyDates.find(date => (date.name === "End Date" && keyDate.study.id === date.study.id))
      if (endDateObject) {
        eventTitle = keyDate.study.name
        endDate = new Date(endDateObject.end_date).setHours(23,59,59,999)
      }
    }
    return {
      ...keyDate,
      start: new Date(keyDate.date).setHours(0,0,0,0),
      end: endDate,
      title: eventTitle,
      allDay: true,
      action: "select" | "click" | "doubleClick",
      backgroundColor: keyDate.communication_target ? "var(--brand-color-2)" : "var(--brand-color-1)",
    }
  })

  const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
        backgroundColor: view !== "agenda" ? event.backgroundColor : "white",
    };
    return {
        style: style
    };
  }

  const selectQuarterDate = (date, view) => {
    setDate(date)
    setView(view)
  }

  return (
    <div className="study-center" id="TimelineContainer">
      <div>
        <Row>
          <Col xs={24} sm={24} md={7} lg={6}>
            <TimelineFilters />
          </Col>
          <Col xs={24} sm={24} md={17} lg={18} className="calendar-container">
          { fetchingKeyDates ?
            <div style={{ marginTop: "300px" }}>
              <LoadingAnimation />
            </div>
            :
            <Calendar
              date={ date }
              onNavigate={ setDate }
              selectable
              popup
              localizer={ localizer }
              events={ events }
              startAccessor="start"
              view={ view }
              onView={ setView }
              components={{
                toolbar: ({ label, ...props }, children) => (
                  <Toolbar
                    {...props}
                    // label={ <button>{label}</button> }
                    label={
                      <TimelineDatePicker
                        startDate={ date }
                        setStartDate={ setDate }
                        showQuarterYearPicker={ view === "quarter" }
                      />
                    }
                  >
                    {children}
                  </Toolbar>
                ),
              }}
              endAccessor="end"
              selectQuarterDate={ selectQuarterDate }
              views={{ month: true, quarter: Quarter, agenda: Agenda }}
              messages={{ quarter: "Quarter" }}
              onSelectEvent={ handleSelectEvent }
              onSelectEvents={ handleSelectEvents }
              eventPropGetter={ eventStyleGetter }
            />
            }
          </Col>
        </Row>
      </div>
      <EventModal events={ selectedEvents } date={ date } handleClose={ handleClose } />
    </div>
  )
}


// export default function TimelineContainer() {
//   // REDUX
//   const dispatch = useDispatch()
//   const selectedIndication = useSelector(state => state.manageIndications.selectedIndication)
//   const selectedStudyList = useSelector(state => state.manageStudyTimelines.selectedStudyList)
//   const fetchingKeyDates = useSelector(state => state.manageStudies.fetchingKeyDates)
//   const keyDates = useSelector(state => state.manageStudies.keyDates)
//   const selectedTimelineFilters = useSelector(state => state.manageStudyTimelines.selectedTimelineFilters)
//
//   // STATE
//   let [selectedEvents, setSelectedEvents] = useState(undefined)
//   let [date, setDate] = useState(new Date())
//   let [view, setView] = useState("month")
//
//   useEffect(() => {
//     dispatch(getTimelineStudyList())
//     dispatch(getKeyDates(selectedStudyList, selectedIndication.id, null, 0, 0))
//   }, [])
//
//   const handleSelectEvent = (event) => {
//     if (!event.communication_target) {
//       setSelectedEvents([event])
//       dispatch(getStudy(event.study.id))
//     }
//   }
//
//   const handleSelectEvents = (events) => {
//     setSelectedEvents(events.filter(event => !event.communication_target))
//   }
//
//   const handleClose = () => {
//     setSelectedEvents(undefined)
//   }
//
//   const localizer = momentLocalizer(moment)
//
//   const events = keyDates.filter(keyDate => {
//     if (selectedTimelineFilters.length) {
//       if (selectedTimelineFilters.includes("Study Duration") && (keyDate.name === "Start Date" || keyDate.name === "End Date")) {
//         return true
//       } else if (selectedTimelineFilters.includes("Key Dates") && keyDate.name !== "Start Date" && keyDate.name !== "End Date" && !!keyDate.study) {
//         return true
//       } else if (selectedTimelineFilters.includes("Communication Targets") && !!keyDate.communication_target) {
//         return true
//       } else {
//         return false
//       }
//     }
//     return true
//     // return keyDate.name !== "End Date"
//   }).map(keyDate => {
//     let eventTitle = keyDate.name
//     let endDate = new Date(keyDate.end_date).setHours(23,59,59,999)
//
//     // if (keyDate.name === "Start Date") {
//     //   let endDateObject = keyDates.find(date => (date.name === "End Date" && keyDate.study.id === date.study.id))
//     //   if (endDateObject) {
//     //     eventTitle = keyDate.study.name
//     //     endDate = new Date(endDateObject.end_date).setHours(23,59,59,999)
//     //   }
//     // }
//     return {
//       ...keyDate,
//       start: new Date(keyDate.date).setHours(0,0,0,0),
//       end: endDate,
//       title: eventTitle,
//       allDay: true,
//       action: "select" | "click" | "doubleClick",
//       backgroundColor: keyDate.communication_target ? "var(--brand-color-2)" : "var(--brand-color-1)",
//     }
//   })
//
//   const eventStyleGetter = (event, start, end, isSelected) => {
//     let style = {
//         backgroundColor: view !== "agenda" ? event.backgroundColor : "white",
//     };
//     return {
//         style: style
//     };
//   }
//
//   const selectQuarterDate = (date, view) => {
//     setDate(date)
//     setView(view)
//   }
//
//   return (
//     <div className="study-center" id="TimelineContainer">
//       <div>
//         <Row>
//           <Col xs={24} sm={24} md={7} lg={6}>
//             <TimelineFilters />
//           </Col>
//           <Col xs={24} sm={24} md={17} lg={18} className="calendar-container">
//           { fetchingKeyDates ?
//             <div style={{ marginTop: "300px" }}>
//               <LoadingAnimation />
//             </div>
//             :
//             <Calendar
//               date={ date }
//               onNavigate={ setDate }
//               selectable
//               popup
//               localizer={ localizer }
//               events={ events }
//               startAccessor="start"
//               view={ view }
//               onView={ setView }
//               components={{
//                 toolbar: ({ label, ...props }, children) => (
//                   <Toolbar
//                     {...props}
//                     // label={ <button>{label}</button> }
//                     label={
//                       <TimelineDatePicker
//                         startDate={ date }
//                         setStartDate={ setDate }
//                         showQuarterYearPicker={ view === "quarter" }
//                       />
//                     }
//                   >
//                     {children}
//                   </Toolbar>
//                 ),
//               }}
//               endAccessor="end"
//               selectQuarterDate={ selectQuarterDate }
//               views={{ month: true, quarter: Quarter, agenda: Agenda }}
//               messages={{ quarter: "Quarter" }}
//               onSelectEvent={ handleSelectEvent }
//               onSelectEvents={ handleSelectEvents }
//               eventPropGetter={ eventStyleGetter }
//             />
//             }
//           </Col>
//         </Row>
//       </div>
//       <EventModal events={ selectedEvents } date={ date } handleClose={ handleClose } />
//     </div>
//   )
// }