import React, {Fragment, useState} from "react";
import PopupPDFViewer from "../../../../components/PopupPDFViewer";
import {Button} from "antd";
import {ListGroup} from "react-bootstrap";
import Section from "./Section";

export function CSRContent({ csr, csrName }) {
  // STATE
  const [visible, setVisible] = useState(false)


  return (
    <div>
      <ListGroup.Item action className={`csr-content-title ${!visible || "active"}`} onClick={() => setVisible(!visible)}>
        {csrName}
      </ListGroup.Item>
      {
        visible ?
          <Fragment>
            { csr.sections.map((section, index) => <Section key={index} section={section} csr={csr} />) }
            <PopupPDFViewer
              file={csr.document_url}
              forClick={<Button className="csr-action-button">View {csrName}</Button>}
            />
            <br/>
            <br/>
          </Fragment>
          :
          null
      }
    </div>
  )
}